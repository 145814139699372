import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {addSite,updateSite,siteExtraParameter,getSiteExtraParameter} from '../../redux/actions/siteAdminAction'
import {projectList} from '../../redux/actions/projectAdminAction'
import {getSiteDetails,clearSiteData} from '../../redux/actions/siteAction'
import {types} from '../../redux/actions/types';
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import ModelPopup from "../../Components/ModelPopup";
import {getSiteExtraParameterAPI,updateSiteExtraParameterAPI,getCountryAPI,getStateAPI,getDistrictAPI,getBlockAPI, addCountryAPI, addStateAPI, addDistrictAPI, addBlockAPI} from '../../store/apis';
// import {call} from 'redux-saga/effects';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../utils'
import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useToast } from '@chakra-ui/react';
import statesData from '../../utils/countries.json';
import { MdAdd } from 'react-icons/md';
const AddSiteDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [siteId, setSiteId] = useState(null);
  const [inName, setInName] = useState('');
  const [inScheme, setInScheme] = useState('');
  const [inLongitude, setInLongitude] = useState('');
  const [inLatitude, setInLatitude] = useState('');
  const [listProjects, setListProjects] = useState([]);
  const [selProject, setSelProject] = useState('');
  const [inImei, setInImei] = useState('');
  const [fhtc, setFHTC] = useState('');
  const [localSiteId, setLocalSiteId] = useState(null); 
  const [siteRating, setSiteRating] = useState('');
  const [pumpType, setPumpType] = useState('');
  const [population, setPopulation] = useState(0);
  const [noofsources, setNoofsources] = useState(''); 
  const [typeEnergySource, setTypeEnergySource] = useState('');
  const [noofsolarpanels, setNoofsolarpanels ] = useState('');   
  const [yearOfCommissioning, setYearOfCommissioning] = useState('');
  const [capacity, setCapacity] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [addState, setAddState] = useState('');
  const [addDistrict, setAddDistrict] = useState('');
  const [addBlock, setAddBlock] = useState('');
  const [addVillage, setAddVillage] = useState('');
  const [addPanchayat, setAddPanchayat] = useState('');
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const projectAdminReducer = useSelector(state => state.projectAdminReducer);
  const siteReducer = useSelector(state => state.siteReducer);
  

  ////////////////////////////////////////////////////////////////////////
  ////////////////// Site Extra Parameters ///////////////////////////////
  const [isPumpPopup, setIsPumpPopup] = useState(false);
  const [currSrNo, setCurrSrNo] = useState('');
  const [currType, setCurrType] = useState('');
  const [currSensorId, setCurrSensorId] = useState('')
  const [selPump, setSelPump] = useState('');  
  const [isMotorPopup, setIsMotorPopup] = useState(false);
  const [selMotor, setSelMotor] = useState('');  
  const [isFlowMeterPopup, setIsFlowMeterPopup] = useState(false);
  const [selFlowMeter, setSelFlowMeter] = useState(''); 
  const [isPressureMeterPopup, setIsPressureMeterPopup] = useState(false);
  const [selPressureMeter, setSelPressureMeter] = useState(''); 
  const [isMultiFuncMeterPopup, setIsMultiFuncMeterPopup] = useState(false);
  const [selMultiFuncMeter, setSelMultiFuncMeter] = useState('');
  const [isPHSensorPopup, setIsPHSensorPopup] = useState(false);
  const [selPHSensorMeter, setSelPHSensorMeter] = useState(''); 
  const [isLvlSensorPopup, setIsLvlSensorPopup] = useState(false);
  const [selLvlSensor, setSelLvlSensor] = useState('');
  const [isTrbtMonPopup, setIsTrbtMonPopup] = useState(false);
  const [selTrbtMon, setSelTrbtMon] = useState('');
  const [isClrnSensorPopup, setIsClrnSensorPopup] = useState(false);
  const [selClrnSensor, setSelClrnSensor] = useState('');
  const [isActuatorPopup, setIsActuatorPopup] = useState(false);
  const [selActuator, setSelActuator] = useState('');
  const [selActuatorId, setSelActuatorId] = useState([{selActuator:""}]);
  const [selActuatorSerial, setSelActuatorSerial] = useState([{txtActuatorSerialNo:""}]);
  const [selActuatorType, setSelActuatorType] = useState([{txtActuatorType:""}]);
  

  const [pumpMake, setPumpMake] = useState('');
  const [pumpModel, setPumpModel] = useState('');  
  const [pumpRating, setPumpRating] = useState('');
  const [pumpHead, setPumpHead] = useState('');
  const [pumpDischarge, setPumpDischarge] = useState('');
  const [pumpSerialNo, setPumpSerialNo] = useState('');  
  const [currentField, setCurrentField] = useState('sitedetails');

  const [motorType, setMotorType] = useState('');
  const [motorKW, setMotorKW] = useState('');
  const [motorRPM, setMotorRPM] = useState('');  
  const [motorCoupling, setMotorCoupling] = useState('');
  const [motorOutDiameter, setMotorOutDiameter] = useState('');
  const [motorMake, setMotorMake] = useState('');
  const [motorOvrCurr, setMotorOvrCurr] = useState('');
  const [motorUndVol, setMotorUndVol] = useState('');
  const [motorOvrVol, setMotorOvrVol] = useState('');
  const [motorUndCurr, setMotorUndCurr] = useState('');
  const [motorSerialNo, setMotorSerialNo] = useState('');

  const [allActuators, setAllActuators] = useState([])

  const [flowMeterMake,setFlowMeterMake] = useState('');
  const [flowMeterModel,setFlowMeterModel] = useState('');
  const [flowMeterFMType,setFlowMeterFMType] = useState('');
  const [flowMeterSize,setFlowMeterSize] = useState('');
  const [flowMeterMaxFlow,setFlowMeterMaxFlow] = useState('');
  const [flowMeterSupplyto,setFlowMeterSupplyto] = useState('');
  const [flowMeterSerialNo,setFlowMeterSerialNo] = useState('');

  const [pressureMeterMake,setPressureMeterMake] = useState('');
  const [pressureMeterModel,setPressureMeterModel] = useState('');
  const [pressureMeterMaxValue,setPressureMeterMaxValue] = useState('');
  const [pressureMeterSerialNo,setPressureMeterSerialNo] = useState('');

  const [multiFuncMeterMake,setMultiFuncMeterMake] = useState('');
  const [multiFuncMeterModel,setMultiFuncMeterModel] = useState('');
  const [multiFuncMeterSerialNo,setMultiFuncMeterSerialNo] = useState('');

  const [pHSensorModel,setPHSensorModel] = useState('');
  const [pHSensorMake,setPHSensorMake] = useState('');
  const [pHSensorMaxVal,setPHSensorMaxVal] = useState('');
  const [pHSensorMinVal,setPHSensorMinVal] = useState('');
  const [pHSensorSerialNo,setPHSensorSerialNo] = useState('');

  const [lvlSensorMake,setLvlSensorMake] = useState('');
  const [lvlSensorModel,setLvlSensorModel] = useState('');
  const [lvlSensorHighLvl,setLvlSensorHighLvl] = useState('');
  const [lvlSensorLowLvl,setLvlSensorLowLvl] = useState('');
  const [lvlSensorSerialNo,setLvlSensorSerialNo] = useState('');

  const [trbtMonMake,setTrbtMonMake] = useState('');
  const [trbtMonModel,setTrbtMonModel] = useState('');
  const [trbtMonMaxVal,setTrbtMonMaxVal] = useState('');
  const [trbtMonSerialNo,setTrbtMonSerialNo] = useState('');

  const [clrnSensorMake,setClrnSensorMake] = useState('');
  const [clrnSensorModel,setClrnSensorModel] = useState('');
  const [clrnSensorMaxVal,setClrnSensorMaxVal] = useState('');
  const [clrnSensorSerialNo,setClrnSensorSerialNo] = useState('');

  const [actuatorMake,setActuatorMake] = useState('');
  const [actuatorModel,setActuatorModel] = useState('');
  const [actuatorOptVlt,setActuatorOptVlt] = useState('');
  const [actuatorSerialNo,setActuatorSerialNo] = useState('');
  const [actuatorType,setActuatorType] = useState('');

  const [solarKWs,setSolarKWs] = useState('');
  const [panelCompany,setPanelCompany] = useState('');
  const [panelModel,setPanelModel] = useState('');
  const [dieselGenKWs,setDieselGenKWs] = useState('');
  const [dieselGenCompany,setDieselGenCompany] = useState('');
  const [dieselGenModel,setDieselGenModel] = useState('');
  const [dieselGenSerialNo,setDieselGenSerialNo] = useState('');

  const [aqualogixID,setAqualogixID] = useState('');
  const [ipAddress,setIpAddress] = useState('');
  const [simNo,setSimNo] = useState('');
  const [mobileNumber,setMobileNumber] = useState('');


  const [currTab,setCurrTab] = useState(1);
  const [pumpList,setPumpList] = useState([]);
  const [motorList,setMotorList] = useState([]);
  const [flowMeterList,setFlowMeterList] = useState([]);
  const [pressureMeterList,setPressureMeterList] = useState([]);
  const [multiFunctionMeterList,setMultiFunctionMeterList] = useState([]);
  const [phSensorList,setPhSensorList] = useState([]);
  const [levelSensorList,setLevelSensorList] = useState([]);
  const [turbidityMonitorList,setTurbidityMonitorList] = useState([]);
  const [chlorineSensorList,setChlorineSensorList] = useState([]);
  const [actuatorSensorList,setActuatorSensorList] = useState([]);

  const [isSolorAvailable,setIsSolorAvailable] = useState(false);
  const [isDieselmotorAvailable,setIsDieselmotorAvailable] = useState(false);

  const [noOfActuator,setNoOfActuator] = useState(3);
  ////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  ///////////////////////////////////////////////////////////////// 
  const [isEdit, setIsEdit] = useState(false);

  ////Manage country, state, district, block ////////////////////
  const [countryList,setCountryList] = useState([]);
  
  const [selCountry,setSelCountry] = useState(null);
  const [stateList,setStateList] = useState([]);
  const [selState,setSelState] = useState(null);
  const [districtList,setDistrictList] = useState([]);
  const [selDistrict,setSelDistrict] = useState(null);
  const [blockList,setBlockList] = useState([]);
  const [selBlock,setSelBlock] = useState(null);
  //////////////////////////////////////////////////////////////
  const [allState, setAllState] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false);


  const [newEntityName, setNewEntityName] = useState('');
  const [newCountryCode, setNewCountryCode] = useState('');
  const [newCountryISOCode, setNewCountryISOCode] = useState('');
  const [newStateCode, setNewStateCode] = useState('');
  const [currentRegionAdd, setCurrentRegionAdd] = useState('');


  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [districtName, setDistrictName]= useState('');

  const toast =  useToast()

  const location = useLocation();
  useEffect(()=>{
    chkLogin(); 
    setPermission();
    fetchProjectList();
    fetchCountryList();
    if(location){
      if(location.state){
        if(location.state.siteId){
          setLocalSiteId(location.state.siteId);
          fetchSiteDetails();
        }
      }
    }
    // const getStates = statesData.map((item, index) => {
    //   return {
    //     stateName : item.state_name
    //   }
    // })
    // setAllState(getStates)
  },[])

  useEffect(()=> {
    const res = stateList.filter((item) => item._id == selState);
    console.log('xxx res',  res)
    if(res.length > 0 && res[0].name) setStateName(res[0].name);

  }, [selState])

  useEffect(()=> {
    const res = countryList.filter((item) => item._id == selCountry);
    if(res.length > 0 && res[0].name) setCountryName(res[0].name);
  }, [selCountry])

  useEffect(()=> {
    const res = districtList.filter((item) => item._id == selDistrict);
    console.log('xxx res', res[0]?.name)
    if(res.length > 0 && res[0].name) setDistrictName(res[0].name);
  }, [selDistrict])

  useEffect(()=>{
    if(projectAdminReducer.action){
      if(projectAdminReducer.action===types.LIST_PROJECT_REQUEST_SUCCESS){
        if(projectAdminReducer.status==="success"){
          setListProjects(projectAdminReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
            title: projectAdminReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }
    }
  },[projectAdminReducer.action])
  
  // useEffect(()=>{
  //   //console.log('AddSite-listProjects',listProjects);    
    
  // },[listProjects])

  // useEffect(()=> {
  //   if(selState?.length > 0) {
  //     const districtList = statesData.filter((item) => {
  //       if(item.state_name == selState) {
  //         return item.cities;
  //       }
  //     })
  //     console.log('xxxxx dist', districtList[0].cities)
  //     setDistrictList(districtList[0].cities)
  //   }
  // }, [selState])

  useEffect(()=>{
    // console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.ADD_SITE_REQUEST_SUCCESS){
        if(siteAdminReducer.status==="success"){
          // console.log('ADD_SITE_REQUEST_SUCCESS-siteAdminReducer',siteAdminReducer);
          // console.log('ADD_SITE_REQUEST_SUCCESS-siteAdminReducer.siteId',siteAdminReducer.siteId);
          setSiteId(siteAdminReducer.data.siteId);
          // navigate('/Admin/SiteListing');
          //console.log('useEffect-ADD_SITE_REQUEST_SUCCESS-siteAdminReducer',siteAdminReducer);
          setCurrTab(2);
          // checkExtraParams();
          // if(currentField=='sitedetails')
          //   fetchExtraParamValue('type=pump');
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }else if(siteAdminReducer.action===types.ADD_SITE_REQUEST_FAILURE){
        toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
      }else if(siteAdminReducer.action===types.EDIT_SITE_REQUEST_SUCCESS){
        if(siteAdminReducer.status==="success"){
          //console.log('useEffect-EDIT_SITE_REQUEST_SUCCESS-siteAdminReducer',siteAdminReducer);
          // navigate('/Admin/SiteListing');
          setCurrTab(2);
          // checkExtraParams();
          // if(currentField=='sitedetails')
          //   fetchExtraParamValue('type=pump');
          // fetchExtraParamValue('pump');
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }else if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){  
          checkExtraParams();        
          // if(currentField=='pump')
          // {
          //   alert('PumpAdded');
          //   checkExtraParams();
          // }
          // siteAdminReducer.data._id;
          //navigate('/Admin/SiteListing');
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      // else if(siteAdminReducer.action===types.GET_SITE_EXTRA_PARAMETER_SUCCESS){
      //   if(siteAdminReducer.status==="success"){
      //     //console.log('useEffect-GET_SITE_EXTRA_PARAMETER_SUCCESS-siteAdminReducer',siteAdminReducer);
      //     //console.log('useEffect-GET_SITE_EXTRA_PARAMETER_SUCCESS-currentField',currentField);
      //     if(currentField=='sitedetails')
      //     {
      //       setPumpList(siteAdminReducer.data.list);
      //     }
      //     else if(currentField=='pump')
      //     {
      //       alert(siteAdminReducer.data);
      //     }
      //     // siteAdminReducer.data._id;
      //     //navigate('/Admin/SiteListing');
      //   }else {
      //     toast({
        // title: siteAdminReducer.message,
    //     status: 'warning',
    //     duration: 2000,
    //     isClosable: true,
    //     position: "top-right",
    //     positionOptions: {
    //       right: "0",
    //       top: "0",
    //     },
    // });
      //   }
      }
  },[siteAdminReducer.action])
  useEffect(()=>{
    if(siteReducer.action){
      if(siteReducer.action===types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS){
        //console.log('AddSiteDetails-siteReducer-useEffect',siteReducer);
        //console.log('GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS-siteReducer',siteReducer.data);
        setSiteId(siteReducer.data._id);
        setAqualogixID(siteReducer.data.aqualogixID);
        setInName(siteReducer.data.pumpName);
        setInScheme(siteReducer.data.scheme);
        setInLongitude(siteReducer.data.longitude);
        setInLatitude(siteReducer.data.latitude);
        setInImei(siteReducer.data.IMEI);
        setSelProject(siteReducer.data.projectID);
        setFHTC(siteReducer.data.noConnections);
        setSiteRating(siteReducer.data.rating?siteReducer.data.rating:'');
        setNoofsolarpanels(siteReducer.data.noOfSolarPanels);
        setYearOfCommissioning(siteReducer.data.yearCommissioning);
        setSourceType(siteReducer.data.sourceType);
        setPumpType(siteReducer.data.typePump);
        setNoofsources(siteReducer.data.numberOfSourcessources);
        setCapacity(siteReducer.data.capacity);
        setPopulation(siteReducer.data.population);
        setAddState(siteReducer.data.address.state);
        setAddDistrict(siteReducer.data.address.district);
        setAddBlock(siteReducer.data.address.block);
        setAddVillage(siteReducer.data.address.village);
        setAddPanchayat(siteReducer.data.address.panchayat);
        setIpAddress(siteReducer.data.IPDomain);
        setSimNo(siteReducer.data.simNo);
        setMobileNumber(siteReducer.data.mobileNumber);
        if(siteReducer.data.pump){
          setSelPump(siteReducer.data.pump.id);
          setPumpSerialNo(siteReducer.data.pump.serialNo);
        }
        if(siteReducer.data.motor){
          setSelMotor(siteReducer.data.motor.id);
          setMotorSerialNo(siteReducer.data.motor.serialNo);
        }
        if(siteReducer.data.flowMeter){
          setSelFlowMeter(siteReducer.data.flowMeter.id);
          setFlowMeterSupplyto(siteReducer.data.flowMeter.supplyTo);
          setFlowMeterSerialNo(siteReducer.data.flowMeter.serialNo);
        }
        if(siteReducer.data.multiFunctionMeter){
          setSelMultiFuncMeter(siteReducer.data.multiFunctionMeter.id);
          setMultiFuncMeterSerialNo(siteReducer.data.multiFunctionMeter.serialNo);
        }
        if(siteReducer.data.chlorineSensor){
          setSelClrnSensor(siteReducer.data.chlorineSensor.id);
          setClrnSensorSerialNo(siteReducer.data.chlorineSensor.serialNo);
        }
        if(siteReducer.data.pHSensor){
          setSelPHSensorMeter(siteReducer.data.pHSensor.id);
          setPHSensorSerialNo(siteReducer.data.pHSensor.serialNo);
        }
        if(siteReducer.data.turbidityMonitor){
          setSelTrbtMon(siteReducer.data.turbidityMonitor.id);
          setTrbtMonSerialNo(siteReducer.data.turbidityMonitor.serialNo);
        }
        if(siteReducer.data.levelSensor){
          setSelLvlSensor(siteReducer.data.levelSensor.id);
          setLvlSensorSerialNo(siteReducer.data.levelSensor.serialNo);
          setLvlSensorHighLvl(siteReducer.data.levelSensor.highLVL);
          setLvlSensorLowLvl(siteReducer.data.levelSensor.lowLVL);
        }
        // if(siteReducer.data.actuatorSensor){
        //   setSelActuator(siteReducer.data.actuatorSensor.id);
        //   setActuatorType(siteReducer.data.actuatorSensor.actuatorType);
        //   setActuatorSerialNo(siteReducer.data.actuatorSensor.serialNo);
        // }
        if(siteReducer.data.pressureMeter){
          setSelPressureMeter(siteReducer.data.pressureMeter.id);
          setPressureMeterSerialNo(siteReducer.data.pressureMeter.serialNo);
        }
        //console.log('siteReducer.data.actuatorSensor',siteReducer.data.actuatorSensor);
        if(siteReducer.data.actuatorSensor){
          if(siteReducer.data.actuatorSensor.length>0){
            setAllActuators(siteReducer.data.actuatorSensor);
            let listSelActuator=[];
            let listSelActuatorSerial=[];
            let listSelActuatorType=[];
            siteReducer.data.actuatorSensor.map((item,index)=>{    
              const selActuator={selActuator:item.id};
              const selActuatorSerial={txtActuatorSerialNo:item.serialNo};
              const setActuatorMainId={setActuatorMainId: item._id}
              const selActuatorType={txtActuatorType:item.actuatorType};
              listSelActuator.push(selActuator);
              listSelActuatorSerial.push(selActuatorSerial);
              listSelActuatorType.push(selActuatorType);


            })
            // console.log('listSelActuator',listSelActuator);
            // console.log('listSelActuatorSerial',listSelActuatorSerial);
            // console.log('listSelActuatorType',listSelActuatorType);
            setSelActuatorId(listSelActuator);
            setSelActuatorSerial(listSelActuatorSerial);
            setSelActuatorType(listSelActuatorType);
            // setSelActuator(siteReducer.data.actuatorSensor[0].id);
            // setActuatorSerialNo(siteReducer.data.actuatorSensor[0].serialNo);
            // setActuatorType(siteReducer.data.actuatorSensor[0].actuatorType);
          }
        }  
        if(siteReducer.data.dieselGenerator){
          setDieselGenKWs(siteReducer.data.dieselGenerator.KWs);
          setDieselGenCompany(siteReducer.data.dieselGenerator.company);
          setDieselGenModel(siteReducer.data.dieselGenerator.model);
          setDieselGenSerialNo(siteReducer.data.dieselGenerator.serialNo);
          setIsDieselmotorAvailable(true);
        } 
        if(siteReducer.data.solor){
          setSolarKWs(siteReducer.data.solor.KWs);
          setPanelCompany(siteReducer.data.solor.panelCompany);
          setPanelModel(siteReducer.data.solor.panelModel);
          setIsSolorAvailable(true);
        }

        if(siteReducer.data.addressId){
          // selCountry();
          setSelState(siteReducer.data.addressId.stateId);
          setSelDistrict(siteReducer.data.addressId.districtId);
          setSelBlock(siteReducer.data.addressId.blockId);
        }
      }
      else if(siteReducer.action===types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE){
        toast({
        title: siteReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
      }
    }
  },[siteReducer.action])
  useEffect(()=>{
    checkExtraParams();
  },[currTab])
  // const fetchExtraParamValue=(type)=>{    
  //   dispatch(getSiteExtraParameter(type));
  // }
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  
  const setPermission=async()=>{
    setIsEdit(await checkPermission('Sites','edit'));
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////// Function to check current tab value and based on that featching the extra parameter values ///////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const checkExtraParams=async()=>{
    if(currTab==2){
      if(currentField==CONSTANTVALUEEXTRAPARAM.PUMP){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PUMP);
      }
      else if(currentField==CONSTANTVALUEEXTRAPARAM.MOTOR){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.MOTOR);
      }
      else{
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PUMP);
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.MOTOR);
      }            
    }
    else if(currTab==3){
      if(currentField==CONSTANTVALUEEXTRAPARAM.FLOWMETER){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.FLOWMETER);
      }
      else if(currentField==CONSTANTVALUEEXTRAPARAM.PRESSUREMETER){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PRESSUREMETER);
      }
      else if(currentField==CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER);
      }
      else{
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.FLOWMETER);
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PRESSUREMETER);
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER);
      }           
    }
    else if(currTab==4){
      if(currentField==CONSTANTVALUEEXTRAPARAM.PHSENSOR){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PHSENSOR);
      }
      else if(currentField==CONSTANTVALUEEXTRAPARAM.LEVELSENSOR){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.LEVELSENSOR);
      }
      else if(currentField==CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR);
      }
      else if(currentField==CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR){
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR);
      }
      else{
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PHSENSOR);
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.LEVELSENSOR);
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR);
        fetchExtraParams(CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR);
      }            
    }
    else if(currTab==5){
      fetchExtraParams(CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR);
    }    
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////// Function to make api call based on extra parameter ///////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const fetchExtraParams=async(type)=>{
    // console.log('type',type);
    if(type==CONSTANTVALUEEXTRAPARAM.PUMP){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.PUMP).then((responseObject)=>{
        //console.log('pump-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        if(selPump=="Other" && pumpMake && pumpModel){
          setSelPump(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.MOTOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.MOTOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setMotorList(responseObject.data.list);
        if(selMotor=="Other" && motorType && motorMake){
          setSelMotor(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.FLOWMETER){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.FLOWMETER).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setFlowMeterList(responseObject.data.list);
        if(selFlowMeter=="Other" && flowMeterMake && flowMeterModel){
          setSelFlowMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.PRESSUREMETER){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.PRESSUREMETER).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPressureMeterList(responseObject.data.list);
        if(selPressureMeter=="Other" && pressureMeterMake && pressureMeterModel){
          setSelPressureMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setMultiFunctionMeterList(responseObject.data.list);
        if(selMultiFuncMeter=="Other" && multiFuncMeterMake && multiFuncMeterModel){
          setSelMultiFuncMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.PHSENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.PHSENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPhSensorList(responseObject.data.list);
        if(selPHSensorMeter=="Other" && pHSensorMake && pHSensorModel){
          setSelPHSensorMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.LEVELSENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.LEVELSENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setLevelSensorList(responseObject.data.list);
        if(selLvlSensor=="Other" && lvlSensorMake && lvlSensorModel){
          setSelLvlSensor(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setTurbidityMonitorList(responseObject.data.list);
        if(selTrbtMon=="Other" && trbtMonMake && trbtMonModel){
          setSelTrbtMon(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setChlorineSensorList(responseObject.data.list);
        if(selClrnSensor=="Other" && clrnSensorMake && clrnSensorModel){
          setSelClrnSensor(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setActuatorSensorList(responseObject.data.list);
        if(selActuator=="Other" && actuatorMake && actuatorModel){
          setSelActuator(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        }
      })
    }     
  }

  const submitSite=()=>{
    //console.log('mobileNumber',mobileNumber);
      if (isEmpty(inName)) {
        toast({
          title: 'Please enter name',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(aqualogixID)) {
        toast({
          title: 'Please enter Aqualogix ID',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(inScheme)) {
        toast({
          title: 'Please enter scheme',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(inLatitude)) {
        toast({
          title: 'Please enter latitude',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(inLongitude)) {
        toast({
          title: 'Please enter longitude',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(siteRating)) {
        toast({
          title: 'Please enter site rating',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(inImei)) {
        toast({
          title: 'Please enter IMEI',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(ipAddress)) {
        toast({
          title: 'Please enter IP address',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(simNo)) {
        toast({
          title: 'Please enter sim number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(mobileNumber)) {
        toast({
          title: 'Please enter mobile number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(fhtc)) {
        toast({
          title: 'Please enter No. of connections',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selProject)) {
        toast({
          title: 'Please select project',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(capacity)) {
        toast({
          title: 'Please enter capacity',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(population)) {
        toast({
          title: 'Please enter population',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (!isEmpty(population)) {
        if (population == 0) {
          toast({
            title: 'Please enter population',
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          return;
        }
      } else if (isEmpty(pumpType)) {
        toast({
          title: 'Please select pump type',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(yearOfCommissioning.toString())) {
        toast({
          title: 'Please select year of commissioning',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(noofsolarpanels.toString())) {
        toast({
          title: 'Please enter no of solar panels',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(sourceType)) {
        toast({
          title: 'Please select source type',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selState)) {
        toast({
          title: 'Please enter state',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selDistrict)) {
        toast({
          title: 'Please enter district',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selBlock)) {
        toast({
          title: 'Please enter block',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(addVillage)) {
        toast({
          title: 'Please enter village',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(addPanchayat)) {
        toast({
          title: 'Please enter panchayat',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      }
    
    
    
    if(localSiteId){
      const request={
        "id": localSiteId,
        "aqualogixID":aqualogixID,
        "pumpName":inName,
        "pumpType":pumpType,
        "projectID":selProject,
        "latitude":inLatitude,
        "longitude":inLongitude,
        "operatorID": "63a4824bd2efd13e38a9a24d",
        "population": population,
        "scheme": inScheme,
        "isGreenEnergy": true,
        "noConnections": fhtc,
        "rating":siteRating,
        "numberOfSourcessources":1,
        "capacity": capacity,
        "typePump": pumpType,
        "address": {
          "state": stateList.find((item)=>item._id==selState)?.name,
          "district": districtList.find((item)=>item._id==selDistrict)?.name,
          "block": blockList.find((item)=>item._id==selBlock)?.name,
          "village": addVillage,
          "panchayat": addPanchayat
        },
        "addressId": {
          "stateId":selState,
          "districtId":selDistrict,
          "blockId":selBlock
        },
        "yearCommissioning": yearOfCommissioning,
        "noOfSolarPanels": noofsolarpanels,
        "sourceType":sourceType,
        "IMEI":inImei.toString(),
        "IPDomain": ipAddress,
        "simNo": simNo,
        "mobileNumber":parseInt(mobileNumber),
        "solorKWs": solarKWs,
        "solorPanelCompany": panelCompany,
        "solorPanelModel": panelModel,
        "dieselKWs": dieselGenKWs,
        "dieselSerialNo" :dieselGenSerialNo,
        "dieselCompany" :dieselGenCompany,
        "dieselModel":dieselGenModel,
      };
      console.log('xxxxx request', request)
      //console.log('addSite-request',request);
      dispatch(updateSite(request));
    }else{
      const request={
        "pumpName":inName,
        "aqualogixID":aqualogixID,
        "pumpType":pumpType,
        "projectID":selProject,
        "latitude":inLatitude,
        "longitude":inLongitude,
        "operatorID": "63a4824bd2efd13e38a9a24d",
        "population": population,
        "scheme": inScheme,
        "isGreenEnergy": true,
        "noConnections": fhtc,
        "rating":siteRating,
        "numberOfSourcessources":1,
        "capacity": capacity,
        "typePump":pumpType,
        "address": {
          "state": stateList.find((item)=>item._id==selState).name,
          "district": districtList.find((item)=>item._id==selDistrict).name,
          "block": blockList.find((item)=>item._id==selBlock).name,
          "village": addVillage,
          "panchayat": addPanchayat
        },
        "addressId": {
          "stateId":selState,
          "districtId":selDistrict,
          "blockId":selBlock
        },
        "yearCommissioning": yearOfCommissioning,
        "noOfSolarPanels": noofsolarpanels,
        "sourceType":sourceType,
        "IMEI":inImei.toString(),
        "IPDomain":ipAddress,
        "simNo":simNo,
        "mobileNumber":parseInt(mobileNumber)
      };
      //console.log('addSite-request',request);
      console.log("xxxxx requesr", request);
      dispatch(addSite(request));
    }     
    // navigate('/dashboard');
  }
  const handlePreviousSiteData=()=>{
    setCurrTab((currTab)=>currTab=currTab-1);
  }
  const handleUpdateSiteData=()=>{
    console.log("ININININININN")
    // console.log('handleUpdateSiteData-currTab',currTab);
    if(currTab==2){
      ////////////////////////////////////////////////////////////////////
      ///////////////////// Update Pump & Motor Data /////////////////////
      if (isEmpty(selPump)) {
        toast({
          title: 'Please select pump',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(pumpSerialNo)) {
        toast({
          title: 'Please enter pump serial number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selMotor)) {
        toast({
          title: 'Please select motor',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(motorSerialNo)) {
        toast({
          title: 'Please enter motor serial number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      }
      
      else{
        const requestPump={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.PUMP,
                  "id":selPump,
                  "serialNo" :pumpSerialNo
              }
          ]
        };
        updateSiteExtraParameterAPI(requestPump).then((response)=>{
          //console.log(response);
        });

        const requestMotor={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.MOTOR,
                  "id":selMotor,
                  "serialNo" :motorSerialNo
              }
          ]
        };
        updateSiteExtraParameterAPI(requestMotor).then((response)=>{
          //console.log(response);
        });
      }
      setCurrTab(3);
      setCurrentField('meters');
    }
    else if(currTab==3){
      ////////////////////////////////////////////////////////////////////
      ///////////////////// Update Flow meter & Pressure meter & Multi-function Meter /////////////////////
      if (isEmpty(selFlowMeter)) {
        toast({
          title: 'Please select flow meter',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(flowMeterSupplyto)) {
        toast({
          title: 'Please insert supply to',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(flowMeterSerialNo)) {
        toast({
          title: 'Please insert flow meter serial no',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selMultiFuncMeter)) {
        toast({
          title: 'Please select multi-function meter',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(multiFuncMeterSerialNo)) {
        toast({
          title: 'Please insert multi-function meter serial no',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      }
      
      else{
        const requestFlowmeter={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.FLOWMETER,
                  "id":selFlowMeter,
                  "serialNo" :flowMeterSerialNo,
                  "supplyTo" :flowMeterSupplyto
              }
          ]
        };
        updateSiteExtraParameterAPI(requestFlowmeter).then((response)=>{
          //console.log(response);
        });

        const requestPressuremeter={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.PRESSUREMETER,
                  "id":selPressureMeter,
                  "serialNo" :pressureMeterSerialNo
              }
          ]
        };
        updateSiteExtraParameterAPI(requestPressuremeter).then((response)=>{
          ////console.log(response);
        });

        const requestMultiFunctionmeter={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER,
                  "id":selMultiFuncMeter,
                  "serialNo" :multiFuncMeterSerialNo
              }
          ]
        };
        updateSiteExtraParameterAPI(requestMultiFunctionmeter).then((response)=>{
          //console.log(response);
        });
      }
      setCurrTab(4);
      setCurrentField('sensors');
    }
    else if(currTab==4){
      console.log("MOMOMOM")
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////// Update pH Sensor & Level Sensor & Turbidity monitor & Chlorine Sensor /////////////////////
      if (isEmpty(selPHSensorMeter)) {
        toast({
          title: 'Please select pH Sensor',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(pHSensorSerialNo)) {
        toast({
          title: 'Please enter pH sensor serial number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selLvlSensor)) {
        toast({
          title: 'Please select level sensor',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(lvlSensorSerialNo)) {
        toast({
          title: 'Please enter level sensor serial number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(lvlSensorHighLvl)) {
        toast({
          title: 'Please enter level sensor high level',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(lvlSensorLowLvl)) {
        toast({
          title: 'Please enter level sensor low level',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selTrbtMon)) {
        toast({
          title: 'Please select turbidity monitor',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(trbtMonSerialNo)) {
        toast({
          title: 'Please enter turbidity monitor serial number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(selClrnSensor)) {
        toast({
          title: 'Please select chlorine sensor',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      } else if (isEmpty(clrnSensorSerialNo)) {
        toast({
          title: 'Please enter chlorine sensor serial number',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      }
      else{
        const requestPHSensor={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.PHSENSOR,
                  "id":selPHSensorMeter,
                  "serialNo" :pHSensorSerialNo
              }
          ]
        };
        updateSiteExtraParameterAPI(requestPHSensor).then((response)=>{
          //console.log(response);
        });

        const requestLevel={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.LEVELSENSOR,
                  "id":selLvlSensor,
                  "serialNo" :lvlSensorSerialNo,
                  "highLVL" :lvlSensorHighLvl,
                  "lowLVL" :lvlSensorLowLvl
              }
          ]
        };
        updateSiteExtraParameterAPI(requestLevel).then((response)=>{
          //console.log(response);
        });

        const requestTurb={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR,
                  "id":selTrbtMon,
                  "serialNo" :trbtMonSerialNo
              }
          ]
        };
        updateSiteExtraParameterAPI(requestTurb).then((response)=>{
          //console.log(response);
        });

        const requestClrn={
          "siteId": siteId,
          "details": [
              {
                  "type": CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR,
                  "id":selClrnSensor,
                  "serialNo" :clrnSensorSerialNo
              }
          ]
        };
        updateSiteExtraParameterAPI(requestClrn).then((response)=>{
          //console.log(response);
        });
      }
      setCurrTab(5);
      setCurrentField('energysource');
    }
    else if(currTab==5){
      console.log("piukacbhubcuhducbdu")
      ///////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////// Update Actuator sensor and other energy details /////////////////////
      // const isEmptyActuatorSerial=selActuatorSerial.filter(item=>{
      //                                                     if(isEmpty(item.txtActuatorSerialNo))
      //                                                     {return true;}
      //                                                   }
      //                                                 )
      // const isEmptyActuatorType=selActuatorType.filter(item=>{
      //                                                     if(isEmpty(item.txtActuatorType))
      //                                                     {return true;}
      //                                                   }
      //                                                 )
      // const isEmptyActuatorId=selActuatorId.filter(item=>{
      //                                                 if(isEmpty(item.selActuator))
      //                                                 {return true;}
      //                                               }
      //                                             )
      //console.log('handleUpdateSiteData-isEmptyActuatorSerial',isEmptyActuatorSerial);
      // if (isEmptyActuatorId.length > 0) {
        // toast({
        //   title: 'Please select Actuator',
        //   status: 'warning',
        //   duration: 2000,
        //   isClosable: true,
        //   position: "top-right",
        //   positionOptions: {
        //     right: "0",
        //     top: "0",
        //   },
        // });
        // return;
      // } else if (isEmptyActuatorSerial.length > 0) {
        // toast({
        //   title: 'Please enter actuator serial number',
        //   status: 'warning',
        //   duration: 2000,
        //   isClosable: true,
        //   position: "top-right",
        //   positionOptions: {
        //     right: "0",
        //     top: "0",
        //   },
        // });
        // return;
      // } else if (isEmptyActuatorType.length > 0) {
        // toast({
        //   title: 'Please enter actuator type',
        //   status: 'warning',
        //   duration: 2000,
        //   isClosable: true,
        //   position: "top-right",
        //   positionOptions: {
        //     right: "0",
        //     top: "0",
        //   },
        // });
        // return;
      // }else{
        const actuatorList=[];

        selActuatorId.map((item,index)=>{          
          const actuatorObj={
            "id":item.selActuator,
            "serialNo" :selActuatorSerial[index].txtActuatorSerialNo,
            "actuatorType" :selActuatorType[index].txtActuatorType,
          }
          //console.log('actuatorObj',actuatorObj);
          actuatorList.push(actuatorObj);
        })
        //console.log('actuatorList',actuatorList);
        const requestActuatorSensor={
          "siteId": siteId,
          "details": [
              {
                "type": CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR,
                "data":allActuators
                // "data":[{
                //   "id":selActuator,
                //   "serialNo" :actuatorSerialNo,
                //   "actuatorType" :actuatorType
                // }]
              }
          ]
        };
        //console.log('handleUpdateSiteData-requestActuatorSensor',requestActuatorSensor);
        updateSiteExtraParameterAPI(requestActuatorSensor).then((response)=>{
          //console.log(response);
        });  
              
        
        const requestDieselGeneratorSensor={
          "siteId": siteId,
          "details": [
              {
                  "type": "dieselGenerator",
                  "KWs": dieselGenKWs,
                  "serialNo" :dieselGenSerialNo,
                  "company" :dieselGenCompany,
                  "model":dieselGenModel
              }
          ]
        };
        //console.log('handleUpdateSiteData-requestDieselGeneratorSensor',requestDieselGeneratorSensor);
        updateSiteExtraParameterAPI(requestDieselGeneratorSensor).then((response)=>{
          //console.log(response);
        });

        const requestSolorSensor={
          "siteId": siteId,
          "details": [
              {
                  "type": "solor",
                  "KWs": solarKWs,
                  "panelCompany" :panelCompany,
                  "panelModel" :panelModel
              }
          ]
        };
        //console.log('handleUpdateSiteData-requestSolorSensor',requestSolorSensor);
        updateSiteExtraParameterAPI(requestSolorSensor).then((response)=>{
          //console.log(response);
        });

        // submitSite();    
        dispatch(clearSiteData());    
        // clearParameter();
        navigate('/Admin/SiteListing');
      // }
      //setCurrTab(6);
      // setCurrentField('');
    }
  }
  const handleSiteInput=(e)=>{
    // console.log('e.target.name',e.target.name);
    // console.log('e.target.value',e.target.value);
    if(e.target.name==="txtName"){
        setInName(e.target.value);
    }
    if(e.target.name==="txtScheme"){
        setInScheme(e.target.value);
    }
    if(e.target.name==="txtLong"){
        setInLongitude(e.target.value);
    }
    if(e.target.name==="txtLat"){
        setInLatitude(e.target.value);
    }
    if(e.target.name==="txtIMEI"){
      setInImei(e.target.value);
    }
    if(e.target.name==="txtRating"){
      setSiteRating(e.target.value);
    }
    if(e.target.name==="txtFHTC"){
      setFHTC(e.target.value);
    }
    // if(e.target.name==="txtPumpType"){
    //   setPumpType(e.target.value);
    // }
    if(e.target.name==="txtPopulation"){
      setPopulation(e.target.value);
    }
    if(e.target.name==="txtNoofsources"){
      setNoofsources(e.target.value);
    }
    if(e.target.name==="txtYear"){
      setYearOfCommissioning(e.target.value);
    }
    if(e.target.name==="txtNoOfSolarPanels"){
      setNoofsolarpanels(e.target.value);
    }
    if(e.target.name==="txtCapacity"){
      setCapacity(e.target.value);
    }
    if(e.target.name==="txtState"){
      setAddState(e.target.value);
    }
    if(e.target.name==="txtDistrict"){
      setAddDistrict(e.target.value);
    }
    if(e.target.name==="txtBlock"){
      setAddBlock(e.target.value);
    }
    if(e.target.name==="txtVillage"){
      setAddVillage(e.target.value);
    }
    if(e.target.name==="txtPanchayat"){
      setAddPanchayat(e.target.value);
    }
    if(e.target.name==="txtAqualogixID"){
      setAqualogixID(e.target.value);
    }
    if(e.target.name==="txtIpAddress"){
      setIpAddress(e.target.value);
    }
    if(e.target.name==="txtSimNo"){
      setSimNo(e.target.value);
    }
    if(e.target.name==="txtMobileNumber"){
      setMobileNumber(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// Pump Popup ////////////////////////////////////
    if(e.target.name==="txtPumpMake"){
      setPumpMake(e.target.value);
    }
    if(e.target.name==="txtPumpModel"){
      setPumpModel(e.target.value);
    }
    if(e.target.name==="txtPumpRating"){
      setPumpRating(e.target.value);
    }
    if(e.target.name==="txtPumpHead"){
      setPumpHead(e.target.value);
    }
    if(e.target.name==="txtPumpDischarge"){
      setPumpDischarge(e.target.value);
    }
    if(e.target.name==="txtPumpSerialNo"){
      setPumpSerialNo(e.target.value);
    }    
    ////////////////////////////////////////////////////////////////
    //////////////// Motor Popup ////////////////////////////////////
    if(e.target.name==="txtMotorType"){
      setMotorType(e.target.value);
    }       
    if(e.target.name==="txtMotorKW"){
      setMotorKW(e.target.value);
    }
    if(e.target.name==="txtMotorRPM"){
      setMotorRPM(e.target.value);
    } 
    if(e.target.name==="txtMotorCoupling"){
      setMotorCoupling(e.target.value);
    }
    if(e.target.name==="txtMotorOutDiameter"){
      setMotorOutDiameter(e.target.value);
    }
    if(e.target.name==="txtMotorMake"){
      setMotorMake(e.target.value);
    }
    if(e.target.name==="txtMotorOvrCurr"){
      setMotorOvrCurr(e.target.value);
    }
    if(e.target.name==="txtMotorUndVol"){
      setMotorUndVol(e.target.value);
    }
    if(e.target.name==="txtMotorOvrVol"){
      setMotorOvrVol(e.target.value);
    }
    if(e.target.name==="txtMotorUndCurr"){
      setMotorUndCurr(e.target.value);
    }
    if(e.target.name==="txtMotorSerialNo"){
      setMotorSerialNo(e.target.value);
    }  
    ////////////////////////////////////////////////////////////////
    //////////////// Flow Meter Popup ////////////////////////////////////
    if(e.target.name==="txtFlowMeterMake"){
      setFlowMeterMake(e.target.value);
    }
    if(e.target.name==="txtFlowMeterModel"){
      setFlowMeterModel(e.target.value);
    }
    if(e.target.name==="txtFlowMeterFMType"){
      setFlowMeterFMType(e.target.value);
    }
    if(e.target.name==="txtFlowMeterSize"){
      setFlowMeterSize(e.target.value);
    }
    if(e.target.name==="txtFlowMeterMaxFlow"){
      setFlowMeterMaxFlow(e.target.value);
    }
    if(e.target.name==="txtFlowMeterSupplyto"){
      setFlowMeterSupplyto(e.target.value);
    }
    if(e.target.name==="txtFlowMeterSerialNo"){
      setFlowMeterSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// Pressure Meter Popup ////////////////////////////////////
    if(e.target.name==="txtPressureMeterMake"){
      setPressureMeterMake(e.target.value);
    }
    if(e.target.name==="txtPressureMeterModel"){
      setPressureMeterModel(e.target.value);
    }
    if(e.target.name==="txtPressureMeterMaxValue"){
      setPressureMeterMaxValue(e.target.value);
    }
    if(e.target.name==="txtPressureMeterSerialNo"){
      setPressureMeterSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// Multi Function Meter Popup ////////////////////////////////////
    if(e.target.name==="txtMultiFuncMeterMake"){
      setMultiFuncMeterMake(e.target.value);
    }
    if(e.target.name==="txtMultiFuncMeterModel"){
      setMultiFuncMeterModel(e.target.value);
    }
    if(e.target.name==="txtMultiFuncMeterSerialNo"){
      setMultiFuncMeterSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// pH Sensor Popup ////////////////////////////////////
    if(e.target.name==="txtPHSensorModel"){
      setPHSensorModel(e.target.value);
    }
    if(e.target.name==="txtPHSensorMake"){
      setPHSensorMake(e.target.value);
    }
    if(e.target.name==="txtPHSensorMaxVal"){
      setPHSensorMaxVal(e.target.value);
    }
    if(e.target.name==="txtPHSensorMinVal"){
      setPHSensorMinVal(e.target.value);
    }
    if(e.target.name==="txtPHSensorSerialNo"){
      setPHSensorSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// Level Sensor Popup ////////////////////////////////////
    if(e.target.name==="txtLvlSensorMake"){
      setLvlSensorMake(e.target.value);
    }
    if(e.target.name==="txtLvlSensorModel"){
      setLvlSensorModel(e.target.value);
    }
    if(e.target.name==="txtLvlSensorHighLvl"){
      setLvlSensorHighLvl(e.target.value);
    }
    if(e.target.name==="txtLvlSensorLowLvl"){
      setLvlSensorLowLvl(e.target.value);
    }
    if(e.target.name==="txtLvlSensorSerialNo"){
      setLvlSensorSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// Turbidity Monitor Popup ////////////////////////////////////
    if(e.target.name==="txtTrbtMonMake"){
      setTrbtMonMake(e.target.value);
    }
    if(e.target.name==="txtTrbtMonModel"){
      setTrbtMonModel(e.target.value);
    }
    if(e.target.name==="txtTrbtMonMaxVal"){
      setTrbtMonMaxVal(e.target.value);
    }
    if(e.target.name==="txtTrbtMonSerialNo"){
      setTrbtMonSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// Chlorine Sensor Popup ////////////////////////////////////
    if(e.target.name==="txtClrnSensorMake"){
      setClrnSensorMake(e.target.value);
    }
    if(e.target.name==="txtClrnSensorModel"){
      setClrnSensorModel(e.target.value);
    }
    if(e.target.name==="txtClrnSensorMaxVal"){
      setClrnSensorMaxVal(e.target.value);
    }
    if(e.target.name==="txtClrnSensorSerialNo"){
      setClrnSensorSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
    //////////////// Actuator Popup ////////////////////////////////////
    if(e.target.name==="txtActuatorMake"){
      setActuatorMake(e.target.value);
    }
    if(e.target.name==="txtActuatorModel"){
      setActuatorModel(e.target.value);
    }
    if(e.target.name==="txtActuatorOptVlt"){
      setActuatorOptVlt(e.target.value);
    }
    if(e.target.name==="txtActuatorSerialNo"){
      setActuatorSerialNo(e.target.value);
    }
    if(e.target.name==="txtActuatorType"){
      setActuatorType(e.target.value);
    }
    if(e.target.name==="txtSolarKWs"){
      setSolarKWs(e.target.value);
    }
    if(e.target.name==="txtPanelCompany"){
      setPanelCompany(e.target.value);
    }
    if(e.target.name==="txtPanelModel"){
      setPanelModel(e.target.value);
    }
    if(e.target.name==="txtDieselGenKWs"){
      setDieselGenKWs(e.target.value);
    }
    if(e.target.name==="txtDieselGenCompany"){
      setDieselGenCompany(e.target.value);
    }
    if(e.target.name==="txtDieselGenModel"){
      setDieselGenModel(e.target.value);
    }
    if(e.target.name==="txtDieselGenSerialNo"){
      setDieselGenSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }
  const fetchSiteDetails=()=>{
    //console.log('AddSiteDetails-fetchSiteDetails-siteId',location.state.siteId);
    dispatch(getSiteDetails(location.state.siteId));
  }
  const fetchProjectList=async()=>{
    dispatch(projectList("page=1&limit=9999"));
  } 
  const fetchCountryList=async()=>{
    setSelState(selState);
    setSelDistrict(selDistrict);
    setSelBlock(selBlock);
    getCountryAPI().then((response)=>{
      // console.log('fetchCountryList-response',response);
      if(response)
        setCountryList(response.data);
        setSelCountry(response.data[0]._id);
    })
  }
  const fetchState = () => {
     getStateAPI("countryId="+selCountry).then((response)=>{
        console.log('xxx changing state');
        if(response){
          setStateList(response.data);
        }
        setModalOpen(false);
      })
  }

  const fetchDistrict = () => {
    getDistrictAPI("stateId="+selState).then((response)=>{
      // console.log('getDistrictAPI-response',response);
      if(response) {
        setDistrictList(response.data);
      } 
    })
  }


  const fetchBlock = () => {
    getBlockAPI("districtId="+selDistrict).then((response)=>{
      // console.log('getBlockAPI-response',response);
      if(response){
        setBlockList(response.data);
      }
    })
  }
  useEffect(()=>{
    if(selCountry){
      setSelDistrict(selDistrict);
      setSelBlock(selBlock);
      getStateAPI("countryId="+selCountry).then((response)=>{
        console.log('xxx changing state');
        if(response){
          setSelDistrict(selDistrict);
          setSelBlock(selBlock);
          setStateList(response.data);
        }
      })
    }    
  },[selCountry])
  useEffect(()=>{
    if(selState){
      setSelBlock(selBlock);
      getDistrictAPI("stateId="+selState).then((response)=>{
        // console.log('getDistrictAPI-response',response);
        if(response) {
          setDistrictList(response.data);
        } 
      })
    }    
  },[selState])

  useEffect(()=>{
    if(selDistrict){
      getBlockAPI("districtId="+selDistrict).then((response)=>{
        // console.log('getBlockAPI-response',response);
        if(response){
          setBlockList(response.data);
        }
      })
    }    
  },[selDistrict])  
  

  const addCountry = async () => {
    const payload = {name: newEntityName, code: newCountryCode, ISOCode: newCountryISOCode};
    try {
      const data = await addCountryAPI(payload);
      fetchCountryList()
      toast({
        title: `Country Added !`,
        description : `${newEntityName} has been added`,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      console.log('xxx api result error', error.message);
    } finally {
      resetAllWithModalClose();
    }
  }

  const resetAllWithModalClose = () => {
    setModalOpen(false);
    setNewCountryCode('');
    setNewCountryISOCode('');
    setNewEntityName('');
    // setSelCountry('');
    // setSelState('');
    // setSelDistrict('');
    // setSelBlock('');
    // setCurrentRegionAdd('');
  }

  const addStateFun = async () => {
    // {name: "Brandana", shortCode: "+21", countryId: "64c0aac81c55ff0c8f492c1e"}}
    if(selCountry.length == '0') {
      toast({
        title: 'Please Select Country First',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }

    const payload = {name: newEntityName, countryId: selCountry, shortCode: newStateCode};
    try {
      const data = await addStateAPI(payload);
      fetchState()
      toast({
        title: `State Added !`,
        description : `${newEntityName} has been added`,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      console.log('xxx api result error', error.message);
    } finally {
      resetAllWithModalClose();
    }
  }

  const addDistrictFun = async () => {
    // {name: "Brandana", shortCode: "+21", countryId: "64c0aac81c55ff0c8f492c1e"}}
    if(selCountry.length == '0') {
      toast({
        title: 'Please Select Country First',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }
    if(selState.length == '0') {
      toast({
        title: 'Please Select State First',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }
    //name: "Naruto", stateId: "64c0b70d1c55ff0c8f4937a9", countryId: "64c0a9781c55ff0c8f492afb"

    const payload = {name: newEntityName, countryId: selCountry, stateId: selState};
    try {
      const data = await addDistrictAPI(payload);
      fetchDistrict()
      toast({
        title: `District Added !`,
        description : `${newEntityName} has been added`,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      console.log('xxx api result error', error.message);
    } finally {
      resetAllWithModalClose();
    }
  }

  const addBlockFun = async () => {
     // {name: "Brandana", shortCode: "+21", countryId: "64c0aac81c55ff0c8f492c1e"}}
     if(selCountry.length == '0') {
      toast({
        title: 'Please Select Country First',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }
    if(selState.length == '0') {
      toast({
        title: 'Please Select State First',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }
    if(selDistrict.length == '0') {
      toast({
        title: 'Please Select State First',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }

    const payload = {name: newEntityName, countryId: selCountry, stateId: selState, districtId: selDistrict};
    try {
      const data = await addBlockAPI(payload);
      fetchBlock()
      toast({
        title: `Block Added !`,
        description : `${newEntityName} has been added`,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      console.log('xxx api result error', error.message);
    } finally {
      resetAllWithModalClose();
    }

  }


  const handleChangeEvent=(e)=>{
    //console.log('AddSiteDetails-handleChangeEvent-e',e.target.value);
    if(e.target.name==="selProject"){
      setSelProject(e.target.value);
    }
    if(e.target.name==="selPumpType"){
      setPumpType(e.target.value);
    }
    if(e.target.name==="selSourceType"){
      setSourceType(e.target.value);
    }
    if(e.target.name==="selTypeEnergySource"){
      setTypeEnergySource(e.target.value);
    }
    if(e.target.name==="selCountry"){
      // console.log('selCountry-e',countryList.find((item)=>item._id==e.target.value).name);
      setSelCountry(e.target.value);
      setSelState('');
      setSelDistrict('');
      setSelBlock('');

    }
    if(e.target.name==="selState"){
      setSelState(e.target.value);

      setSelDistrict('');
      setSelBlock('');
    }
    if(e.target.name==="selDistrict"){
     setSelDistrict(e.target.value);
     setSelBlock('');

    }
    if(e.target.name==="selBlock"){
    setSelBlock(e.target.value);
    }      
  }

  ////////////////////////////////////////////////////////////////////////////////
  ///// Functions to manage the extra parameters for other tabs //////////////////
  ////////////////////////////////////////////////////////////////////////////////
  const handlePumpShow = () => setIsPumpPopup((current) => !current);
  const handleMotorShow = () => setIsMotorPopup((current) => !current);
  const handleFlowMeterShow = () => setIsFlowMeterPopup((current) => !current);
  const handlePressureMeterShow = () => setIsPressureMeterPopup((current) => !current);
  const handleMultiFuncMeterShow = () => setIsMultiFuncMeterPopup((current) => !current);
  const handlePHSensorShow = () => setIsPHSensorPopup((current) => !current);
  const handleLvlSensorShow = () => setIsLvlSensorPopup((current) => !current);
  const handleTrbtMonShow = () => setIsTrbtMonPopup((current) => !current);
  const handleClrnSensorShow = () => setIsClrnSensorPopup((current) => !current);
  const handleActuatorShow = () => setIsActuatorPopup((current) => !current);

  const handlePumpSubmit=()=>{
    if (isEmpty(pumpMake)) {
      toast({
        title: 'Please enter pump make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(pumpModel)) {
      toast({
        title: 'Please enter pump model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(pumpRating)) {
      toast({
        title: 'Please enter pump rating',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(pumpHead)) {
      toast({
        title: 'Please enter pump head',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(pumpDischarge)) {
      toast({
        title: 'Please enter pump discharge',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }
    else{
      const request={
        "type": CONSTANTVALUEEXTRAPARAM.PUMP,
        "make":pumpMake,
        "model":pumpModel,
        "ratingKW":pumpRating,
        "headM":pumpHead,
        "dischargeM3":pumpDischarge,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.PUMP);
      dispatch(siteExtraParameter(request));      
      handlePumpShow();
    }    
  } 
   const handleMotorSubmit = () => {
    if (isEmpty(motorType)) {
      toast({
        title: 'Please enter motor type',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorKW)) {
      toast({
        title: 'Please enter motor KW',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorRPM)) {
      toast({
        title: 'Please enter motor RPM',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorCoupling)) {
      toast({
        title: 'Please enter motor coupling',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorOutDiameter)) {
      toast({
        title: 'Please enter motor outer diameter',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorMake)) {
      toast({
        title: 'Please enter motor make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorOvrCurr)) {
      toast({
        title: 'Please enter motor overload current (A)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorUndVol)) {
      toast({
        title: 'Please enter motor under voltage (V)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorOvrVol)) {
      toast({
        title: 'Please enter motor over voltage (V)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(motorUndCurr)) {
      toast({
        title: 'Please enter motor under current (A)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }
    else{
      const request={
        "type": CONSTANTVALUEEXTRAPARAM.MOTOR,
        "motorType":motorType,
        "motorKW":motorKW,
        "motorRPM":motorRPM,
        "motorCoupling":motorCoupling,
        "motorOutDiameter":motorOutDiameter,
        "motorMake":motorMake,
        "motorOvrCurr":motorOvrCurr,
        "motorUndVol":motorUndVol,
        "motorOvrVol":motorOvrVol,
        "motorUndCurr":motorUndCurr,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.MOTOR);
      dispatch(siteExtraParameter(request));
      handleMotorShow();
    }    
  }
  const handleFlowMeterSubmit=()=>{
    if(isEmpty(flowMeterMake)){
    toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })  
    return;
    }else if(isEmpty(flowMeterModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(flowMeterFMType)){
      toast({
        title: 'Please select FM type',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(flowMeterSize)){
      toast({
        title: 'Please select size',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
     return;
    }else if(isEmpty(flowMeterMaxFlow)){
      toast({
        title: 'Please select flow',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }
    else{
      const request={
        "type":CONSTANTVALUEEXTRAPARAM.FLOWMETER,
        "flowMeterMake":flowMeterMake,
        "flowMeterModel":flowMeterModel,
        "flowMeterFMType":flowMeterFMType,
        "flowMeterSize":flowMeterSize,
        "flowMeterMaxFlow":flowMeterMaxFlow,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.FLOWMETER);
      dispatch(siteExtraParameter(request));
      handleFlowMeterShow();
    }    
  }
  const handlePressureMeterSubmit=()=>{
    if(isEmpty(pressureMeterMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
     return;
    }else if(isEmpty(pressureMeterModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(pressureMeterMaxValue)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }
    else{
      const request={
        "type": CONSTANTVALUEEXTRAPARAM.PRESSUREMETER,
        "pressureMeterMake":pressureMeterMake,
        "pressureMeterModel":pressureMeterModel,
        "pressureMeterMaxValue":pressureMeterMaxValue,
      };
      setCurrentField('pressuremeter');
      dispatch(siteExtraParameter(request));
      handlePressureMeterShow();
    }    
  }  
  const handleMultiFuncMeterSubmit=()=>{
    if(isEmpty(multiFuncMeterMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(multiFuncMeterModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }
    else{
      const request={
        "type": CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER,
        "multiFuncMeterMake":multiFuncMeterMake,
        "multiFuncMeterModel":multiFuncMeterModel,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER);
      dispatch(siteExtraParameter(request));
      handleMultiFuncMeterShow();
    }    
  }
  const handlePHSensorSubmit=()=>{
    if(isEmpty(pHSensorModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pHSensorMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(pHSensorMaxVal)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(pHSensorMinVal)){
      toast({
        title: 'Please select min value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
     return;
    }
    else{
      const request={
        "type": CONSTANTVALUEEXTRAPARAM.PHSENSOR,
        "pHSensorModel":pHSensorModel,
        "pHSensorMake":pHSensorMake,
        "pHSensorMaxValue":pHSensorMaxVal,
        "pHSensorMinValue":pHSensorMinVal,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.PHSENSOR);
      dispatch(siteExtraParameter(request));
      handlePHSensorShow();
    }    
  }
  const handleLvlSensorSubmit=()=>{

    if(isEmpty(lvlSensorMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(lvlSensorModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }
    else{
      const request={
        "type": CONSTANTVALUEEXTRAPARAM.LEVELSENSOR,
        "levelSensorMake":lvlSensorMake,
        "levelSensorModel":lvlSensorModel,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.LEVELSENSOR);
      dispatch(siteExtraParameter(request));
      handleLvlSensorShow();
    }    
  }
  const handleTrbtMonSubmit=()=>{
    if(isEmpty(trbtMonMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(trbtMonModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(trbtMonMaxVal)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      const request={
        "type": CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR,
        "turbidityMonitorMake":trbtMonMake,
        "turbidityMonitorModel":trbtMonModel,
        "turbidityMonitorMaxValue":trbtMonMaxVal,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR);
      dispatch(siteExtraParameter(request));
      handleTrbtMonShow();
    }    
  }
  const handleClrnSensorSubmit=()=>{
    if(isEmpty(clrnSensorMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;

    }else if(isEmpty(clrnSensorModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(clrnSensorMaxVal)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }
    else{
      const request={
        "type":CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR,
        "chlorineSensorMake":clrnSensorMake,
        "chlorineSensorModel":clrnSensorModel,
        "chlorineSensorMaxValue":clrnSensorMaxVal,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR);
      dispatch(siteExtraParameter(request));
      handleClrnSensorShow();
    }    
  }
  const handleActuatorSubmit=()=>{
    if(isEmpty(actuatorMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(actuatorModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }else if(isEmpty(actuatorOptVlt)){
      toast({
        title: 'Please select operating voltage',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
    return;
    }
    else{
      const request={
        "type":CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR,
        "actuatorMake":actuatorMake,
        "actuatorModel":actuatorModel,
        "actuatorOperatingVoltage":actuatorOptVlt,
      };
      setCurrentField(CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR);
      dispatch(siteExtraParameter(request));
      handleActuatorShow();
    }    
  }

  const handleNextStepEvent=(index)=>{
    setCurrTab(index);
  }
  const onParameterSelect = (event) => {
    const value = event.target.value;
    if(event.target.name==="selPump"){
      if (value === 'Other') {
        handlePumpShow();
      }
      setSelPump(value);
    }else if(event.target.name==="selForMotor"){
      if (value === 'Other') {
        handleMotorShow();
      }
      setSelMotor(value);
    }else if(event.target.name==="selFlowMeter"){
      if (value === 'Other') {
        handleFlowMeterShow();
      }
      setSelFlowMeter(value);
    }else if(event.target.name==="selPressureMeter"){
      if (value === 'Other') {
        handlePressureMeterShow();
      }
      setSelPressureMeter(value);
    }else if(event.target.name==="selMultiFuncMeter"){
      if (value === 'Other') {
        handleMultiFuncMeterShow();
      }
      setSelMultiFuncMeter(value);
    }else if(event.target.name==="selPHSensor"){
      if (value === 'Other') {
        handlePHSensorShow();
      }
      setSelPHSensorMeter(value);
    }else if(event.target.name==="selLvlSensor"){
      if (value === 'Other') {
        handleLvlSensorShow();
      }
      setSelLvlSensor(value);
    }else if(event.target.name==="selTrbtMon"){
      if (value === 'Other') {
        handleTrbtMonShow();
      }
      setSelTrbtMon(value);
    }else if(event.target.name==="selClrnSensor"){
      if (value === 'Other') {
        handleClrnSensorShow();
      }
      setSelClrnSensor(value);
    }else if(event.target.name==="selActuator"){
      if (value === 'Other') {
        handleActuatorShow();
      }
      setSelActuator(value);
    }      
  };
  
  const handleSolordetails=()=>{
    if(isSolorAvailable){
      setSolarKWs('');
      setPanelCompany('');
      setPanelModel('');
    }
    setIsSolorAvailable(currValue=>!currValue);
  }


  const addNewInActuator = () => {
    console.log("DATA CHECK", currSensorId, currSrNo, currType)
    let newActs = [...allActuators, {id : currSensorId, serialNo : currSrNo, actuatorType : currType}];
    setAllActuators(newActs)
    setModalOpen2(false);
    setCurrSensorId('');
    setCurrSrNo('');
    setCurrType('');
  }

  const handleDieselmotordetails=()=>{
    if(isDieselmotorAvailable){
      setDieselGenKWs('');
      setDieselGenCompany('');
      setDieselGenModel('');
      setDieselGenSerialNo('');
    }
    setIsDieselmotorAvailable(currValue=>!currValue);
  }
  ////////////////////////////////////////////////////////////////////////////////
  const addNewActuator=()=>{
    return (
      <ModelPopup isShow={isActuatorPopup} title={'Actuator'} clickCloseBtn={handleActuatorShow} clickSubmitBtn={handleActuatorSubmit}>
        <div>
          <div className="mb-3 row">
            <label for="actuatorMake" className="col-sm-4 col-form-label">Make</label>
            <div className="col-sm-8">
              <input type="text" name="txtActuatorMake" id="txtActuatorMake" value={actuatorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
            </div>
          </div>
          <div className="mb-3 row">
            <label for="actuatorModel" className="col-sm-4 col-form-label">Model</label>
            <div className="col-sm-8">
              <input type="text" name="txtActuatorModel" id="txtActuatorModel" value={actuatorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
            </div>
          </div>
          <div className="mb-3 row">
            <label for="actuatorOptVlt" className="col-sm-4 col-form-label">Operating Voltage</label>
            <div className="col-sm-8">
              <input type="text" name="txtActuatorOptVlt" id="txtActuatorOptVlt" value={actuatorOptVlt} onChange={(event) => handleSiteInput(event)} className="form-control" />
            </div>
          </div>                  
        </div>
      </ModelPopup>
    )
  }

  const style = (option) => {
    if (option.name === 'Add New Country') {
      return {
        color: 'blue',
      };
    } else {
      return null;
    }
  };
  const handleDynSiteInput=(index, evnt)=>{
    const { name, value } = evnt.target;
    if(name=='txtActuatorSerialNo'){
      const list = [...selActuatorSerial];
      list[index][name] = value;
      setSelActuatorSerial(list);
    }
    if(name=='txtActuatorType'){
      const list = [...selActuatorType];
      list[index][name] = value;
      setSelActuatorType(list);
    }
    if(name=='selActuator'){
      if (value === 'Other') {
        handleActuatorShow();
      }
      // setSelActuator(value);
      else{
        const list = [...selActuatorId];
        list[index][name] = value;
        setSelActuatorId(list);
      }
      
    }  
  }
  const removeActuatorFields = (index)=>{
    let newData = allActuators.filter((item, ind) => ind != index);
    setAllActuators(newData)
    // const actuatorSerialList = [...selActuatorSerial];
    // actuatorSerialList.splice(index, 1);
    // setSelActuatorSerial(actuatorSerialList);
    // const actuatorType = [...selActuatorType];
    // actuatorType.splice(index, 1);
    // setSelActuatorType(actuatorType);
    // const actuatorId = [...selActuatorId];
    // actuatorId.splice(index, 1);
    // setSelActuatorId(actuatorId);
  }

  const siteOptionsChangeHandler = (index, newID) => {
    console.log("INDEXX", index, newID)
  }
  const bindAddNewOption=()=>{
    if(isEdit)
      return (<option value="Other">Add New</option>);
  }
  const handleInputChange = (index, field, value) => {
    const updatedActuators = [...allActuators];
    updatedActuators[index][field] = value;
    setAllActuators(updatedActuators);
  };const manageMultipleActuators = () => {
    return (
        <div className="mb-3 row">
            {allActuators.map((item, index) => (
                <div key={index}>
                    <div className="mb-3 row">
                        <label htmlFor="selActuator" className="col-sm-3 col-form-label">Actuator</label>
                        <div className="col-sm-7">
                            <select onChange={(event) => siteOptionsChangeHandler(index, event.target.value)} id={"selActuator"} name={"selActuator"}>
                                <option value="">Select Actuator</option>
                                {console.log("SITEEEE ACTTT", actuatorSensorList)}
                                {actuatorSensorList.map((actSensObj, actIndex) => (
                                    <option key={actIndex} value={item.id} selected={item.id === actSensObj._id ? true : false}>{actSensObj.actuatorMake + ' ' + actSensObj.actuatorModel}</option>
                                ))}
                                {bindAddNewOption()}
                            </select>
                        </div>
                        {isEdit ? (
                            <div className="col-sm-2">
                                <ButtonWithSpinner onClick={() => removeActuatorFields(index)} isLoading={siteAdminReducer.loading ? true : false} label={'Remove'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />
                            </div>
                        ) : null}
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="actuatorSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                        <div className="col-sm-7">
                            <input type="text" name="txtActuatorSerialNo" id="txtActuatorSerialNo" value={allActuators[index].serialNo} onChange={(e) => handleInputChange(index, 'serialNo', e.target.value)} className="form-control" />
                        </div>
                        <div className="col-sm-2"></div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="actuatorType" className="col-sm-3 col-form-label">Type</label>
                        <div className="col-sm-7">
                            <input type="text" name="txtActuatorType" id="txtActuatorType" value={allActuators[index].actuatorType} onChange={(e) => handleInputChange(index, 'actuatorType', e.target.value)} className="form-control" />
                        </div>
                        <div className="col-sm-2"></div>
                    </div>
                </div>
            ))}
        </div>
    );
}


  const clearParameter=()=>{
    setSiteId(null);
    setAqualogixID('');
    setInName('');
    setInScheme('');
    setInLongitude('');
    setInLatitude('');
    setInImei('');
    setSelProject('');
    setFHTC('');
    setSiteRating('');
    setNoofsolarpanels('');
    setYearOfCommissioning('');
    setSourceType('');
    setPumpType('');
    setNoofsources('');
    setCapacity('');
    setPopulation(0);
    setAddState('');
    setAddDistrict('');
    setAddBlock('');
    setAddVillage('');
    setAddPanchayat('');
    setIpAddress('');
    setSimNo('');
    setMobileNumber('');
    setSelPump('');
    setPumpSerialNo('');
    setSelMotor('');
    setMotorSerialNo('');
    
    setSelFlowMeter('');
    setFlowMeterSupplyto('');
    setFlowMeterSerialNo('');
  
    setSelMultiFuncMeter('');
    setMultiFuncMeterSerialNo('');
  
    setSelClrnSensor('');
    setClrnSensorSerialNo('');
  
    setSelPHSensorMeter('');
    setPHSensorSerialNo('');
  
    setSelTrbtMon('');
    setTrbtMonSerialNo('');
  
    setSelLvlSensor('');
    setLvlSensorSerialNo('');
    setLvlSensorHighLvl('');
    setLvlSensorLowLvl('');
  
    setSelPressureMeter('');
    setPressureMeterSerialNo('');
  
    setSelActuator('');
    setActuatorSerialNo('');
    setActuatorType('');
  
    setDieselGenKWs('');
    setDieselGenCompany('');
    setDieselGenModel('');
    setDieselGenSerialNo('');
    setIsDieselmotorAvailable(false);
  
    setSolarKWs('');
    setPanelCompany('');
    setPanelModel('');
    setIsSolorAvailable(false);
    
  }
  return (
    //comeon
    <div className="page">
       <Modal blockScrollOnMount={false} isOpen={isModalOpen2} onClose={()=> setModalOpen2(false)} motionPreset='scale'>
            <ModalOverlay />
            <ModalContent border={'1px solid black'}>
              <ModalHeader>
                Add Actuator
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <FormLabel>Serial No :</FormLabel>
              <Input value={currSrNo} onChange={(e) => setCurrSrNo(e.target.value)}/>
              <FormLabel>Actuator Type : </FormLabel>
              <Input
                value={currType}
                onChange={(e) => setCurrType(e.target.value)}
              />
              <FormLabel>Actuator :</FormLabel>
              <Select onChange={(event)=>setCurrSensorId(event.target.value)} variant={'outline'} id={"selActuator"} name={"selActuator"}>
                <option value="">Select Actuator</option>
                {actuatorSensorList.map((actSensObj,index)=>{                          
                  return <option key={index} value={actSensObj._id}>{actSensObj.actuatorMake+' '+actSensObj.actuatorModel}</option>
                })}
                {bindAddNewOption()}
              </Select>     
              <Button mt={3} onClick={()=> addNewInActuator()} isDisabled={currType.length == 0 || currSensorId.length == 0 || currSrNo.length == 0}>Add</Button>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
              </ModalContent>
            </Modal>
      <NavBarAdmin />
      {console.log('xxxx all info', selState, selDistrict, selBlock)}
       {
            <Modal blockScrollOnMount={false} isOpen={isModalOpen} onClose={()=> {{setModalOpen(false); } }} motionPreset='scale'>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {`Add ${currentRegionAdd}`}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontWeight='bold' mb='1rem'>
                {currentRegionAdd != 'Country' && !currentRegionAdd != 'Block' &&  <Text>{`${(selDistrict && districtName) || (selState && stateName) || (selCountry && countryName)  }`}</Text>}
                </Text>
                <Input placeholder={`Enter ${currentRegionAdd} Name`} type='text' onChange={(e) => setNewEntityName(e.target.value)} />
                {currentRegionAdd == 'Country' && <><Input placeholder='Enter Country Code' style={{marginTop:'15px'}} onChange={(e) => setNewCountryCode(e.target.value)} />
                <Input placeholder='Enter Country ISO Code' style={{marginTop:'15px'}} onChange={(e) => setNewCountryISOCode(e.target.value)} />
                <Button mt='10px' colorScheme='blue'  onClick={() => {addCountry()}} isDisabled={newCountryCode.length == 0 || newEntityName.length == 0 || newCountryISOCode.length == 0}>Add Country</Button>
                </>
                }
                {
                  currentRegionAdd == 'State' && <>
                <Input placeholder='Enter State Code' style={{marginTop:'15px'}} onChange={(e) => setNewStateCode(e.target.value)} />
                <Button mt='10px' colorScheme='blue'  onClick={() => {addStateFun()}} isDisabled={newStateCode.length == 0 || newEntityName.length == 0 }>Add State</Button>
                </>
                }
                {
                     currentRegionAdd == 'District' && <>
                     <Button mt='10px' colorScheme='blue'  onClick={() => {addDistrictFun()}} isDisabled={newEntityName.length == 0 }>Add District</Button>
                    </>
                }
                 {
                     currentRegionAdd == 'Block' && <>
                     <Button mt='10px' colorScheme='blue'  onClick={() => {addBlockFun()}} isDisabled={newEntityName.length == 0 }>Add Block</Button>
                    </>
                }
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          }
      {
        console.log('xxx countryList', countryList)
      }
      <AdminMenu selMenu="Site" />
      {console.log("DISSSSSS", currSensorId, currType, currSrNo)}
      <div className="project-page-head">
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">Sites</div>
            <h3 className="project-title fw-bold">{localSiteId?"Update Site":"Add Site"}</h3>
            {!isEdit?<div className="sub-head">Note: You do not have edit permission so no data will be updating on next button click.</div>:null}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <ul className="nav nav-tabs add-site-tabs ms-3 mt-5" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={currTab==1?'nav-link active':'nav-link'} id="site-info-tab" type="button" role="tab" aria-controls="site-info-tab-pane" aria-selected="false" onClick={()=>{if(localSiteId)handleNextStepEvent(1)}}>Site Info</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class={currTab==2?'nav-link active':'nav-link'} id="source-tab1" type="button" role="tab" aria-controls="source-tab-pane" aria-selected="true" onClick={()=>{if(localSiteId)handleNextStepEvent(2)}}>Pump & Motor</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class={currTab==3?'nav-link active':'nav-link'} id="meters-tab" type="button" role="tab" aria-controls="meters-tab-pane" aria-selected="false" onClick={()=>{if(localSiteId)handleNextStepEvent(3)}}>Meters</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class={currTab==4?'nav-link active':'nav-link'} id="sensors-tab" type="button" role="tab" aria-controls="sensors-tab-pane" aria-selected="false" onClick={()=>{if(localSiteId)handleNextStepEvent(4)}}>Sensors</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class={currTab==5?'nav-link active':'nav-link'} id="actuator-tab" data-bs-target="#actuator-tab-pane" type="button" role="tab" aria-controls="actuator-tab-pane" aria-selected="false" onClick={()=>{if(localSiteId)handleNextStepEvent(5)}}>Energy Sources and Actuators</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          {/******** Site Basic Details *********************/}
          <div class={currTab==1?'tab-pane fade show active':'tab-pane fade'} role="tabpanel" aria-labelledby="site-info-tab" tabindex="0">
            <div className="card">
              <div className="card-body">
                <div className="add-site-form">
                  <div className="mb-3 row">
                    <label for="name" className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtName" id="txtName" value={inName} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="aqualogixID" className="col-sm-3 col-form-label">Aqualogix ID</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtAqualogixID" id="txtAqualogixID" value={aqualogixID} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="scheme" className="col-sm-3 col-form-label">Scheme</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtScheme" id="txtScheme" value={inScheme} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="latitude" className="col-sm-3 col-form-label">Latitude</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtLat" id="txtLat" value={inLatitude} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="longitude" className="col-sm-3 col-form-label">Longitude</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtLong" id="txtLong" value={inLongitude} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>    
                  <div className="mb-3 row">
                    <label for="siteRating" className="col-sm-3 col-form-label">Site Rating</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtRating" id="txtRating" value={siteRating} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>              
                  <div className="mb-3 row">
                    <label for="location" className="col-sm-3 col-form-label">IMEI</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtIMEI" id="txtIMEI" value={inImei} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="ipAddress" className="col-sm-3 col-form-label">IP Address/Domain Name</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtIpAddress" id="txtIpAddress" value={ipAddress} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="simNo" className="col-sm-3 col-form-label">Sim No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtSimNo" id="txtSimNo" value={simNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="mobileNumber" className="col-sm-3 col-form-label">Mobile Number</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtMobileNumber" id="txtMobileNumber" value={mobileNumber} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="location" className="col-sm-3 col-form-label">FHTC</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtFHTC" id="txtFHTC" value={fhtc} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>   
                  <div className="mb-3 row">
                    <label for="location" className="col-sm-3 col-form-label">Project</label>
                    <div className="col-sm-9">
                      <select className="selectpicker form-control" name="selProject" onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select Project</option>
                        {listProjects.map((item)=>{
                          return(<option key={item._id} value={item._id} selected={selProject==item._id?true:false}>{item.ProjectName}</option>)
                        })}
                        {/* <option value="Ketchup">Ketchup</option>
                        <option value="Relish">Relish</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="capacity" className="col-sm-3 col-form-label">Capacity</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtCapacity" id="txtCapacity" value={capacity} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/* <div className="mb-3 row">
                    <label for="pumptype" className="col-sm-3 col-form-label">Pump Type</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtPumpType" id="txtPumpType" value={pumpType} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div> */}
                  <div className="mb-3 row">
                    <label for="population" className="col-sm-3 col-form-label">Population</label>
                    <div className="col-sm-9">
                    <input type="number" name="txtPopulation" id="txtPopulation" value={population} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/* <div className="mb-3 row">
                    <label for="noofsources" className="col-sm-3 col-form-label">No. of Sources</label>
                    <div className="col-sm-9">
                    <input type="number" name="txtNoofsources" id="txtNoofsources" value={noofsources} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div> */}
                  {/* <div className="mb-3 row">
                    <label for="typeEnergySource" className="col-sm-3 col-form-label">Type of Energy source</label>
                    <div className="col-sm-9">
                      <select className="selectpicker form-control" name="selTypeEnergySource" onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select Type of Energy source</option>
                        <option value="Solar" selected={typeEnergySource=="Solar"?true:false}>Solar</option>
                        <option value="DieselGenerator" selected={typeEnergySource=="DieselGenerator"?true:false}>Diesel Generator</option>
                        <option value="Grid" selected={typeEnergySource=="Grid"?true:false}>On-Grid</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="mb-3 row">
                    <label for="pumptype" className="col-sm-3 col-form-label">Pump Type</label>
                    <div className="col-sm-9">
                      <select className="selectpicker form-control" name="selPumpType" onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select Pump Type</option>
                        <option value="Solar" selected={pumpType=="Solar"?true:false}>Solar</option>
                        <option value="Grid" selected={pumpType=="Grid"?true:false}>Grid</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="yearofcommissioning" className="col-sm-3 col-form-label">Year of Commissioning</label>
                    <div className="col-sm-9">
                      <input type="number" name="txtYear" id="txtYear" value={yearOfCommissioning} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="noofsolarpanels" className="col-sm-3 col-form-label">No Of Solar Panels</label>
                    <div className="col-sm-9">
                      <input type="number" name="txtNoOfSolarPanels" id="txtNoOfSolarPanels" value={noofsolarpanels} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Source Type</label>
                    <div className="col-sm-9">
                      <select className="selectpicker form-control" name="selSourceType" onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select Source Type</option>
                        <option value="SurfaceWater" selected={sourceType=="SurfaceWater"?true:false}>Surface Water</option>
                        <option value="GroundWater" selected={sourceType=="GroundWater"?true:false}>Ground Water</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Country</label>
                    <div className="col-sm-9">
                      <div style={{ display : 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <select className="selectpicker form-control" name="selCountry" style={{flexGrow : '1'}}onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select Country</option>
                        {countryList.map((item,index)=>{
                          return <option key={index} value={item._id} selected={selCountry==item._id?true:false} style={item.name === 'Add New Country' ? { color: 'blue' } : null} >{item.name}</option>
                        })}
                      </select>
                      <MdAdd size='27px' style={{backgroundColor : "#BEE3F8", borderRadius : '100%', marginLeft : '6px'}} onClick={()=> {setCurrentRegionAdd('Country'); setModalOpen(true); }}/>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="state" className="col-sm-3 col-form-label">State</label>
                    <div className="col-sm-9">
                    <div style={{ display : 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <select className="selectpicker form-control" name="selState" onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select State</option>
                        {stateList.map((item,index)=>{
                          return <option key={index} value={item._id} selected={selState==item._id?true:false}>{item.name}</option>
                        })}
                      </select>
                      <MdAdd size='27px' style={{backgroundColor : "#BEE3F8", borderRadius : '100%', marginLeft : '6px'}} onClick={()=> {setCurrentRegionAdd('State'); setModalOpen(true);}}/>
                      </div>
                    {/* <input type="text" name="txtState" id="txtState" value={addState} onChange={(event) => handleSiteInput(event)} className="form-control" /> */}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="district" className="col-sm-3 col-form-label">District</label>
                    <div className="col-sm-9">
                    <div style={{ display : 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <select className="selectpicker form-control" name="selDistrict" onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select District</option>
                        {districtList.map((item,index)=>{
                          return <option key={index} value={item._id} selected={selDistrict==item._id?true:false}>{item.name}</option>
                        })}
                      </select>
                      <MdAdd size='27px' style={{backgroundColor : "#BEE3F8", borderRadius : '100%', marginLeft : '6px'}} onClick={()=> {setCurrentRegionAdd('District'); setModalOpen(true);}}/>
                      </div>
                    {/* <input type="text" name="txtDistrict" id="txtDistrict" value={addDistrict} onChange={(event) => handleSiteInput(event)} className="form-control" /> */}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="block" className="col-sm-3 col-form-label">Block</label>
                    <div className="col-sm-9">
                    <div style={{ display : 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <select className="selectpicker form-control" name="selBlock" onChange={(e)=>handleChangeEvent(e)}>
                        <option value="">Select Block</option>
                        {blockList.map((item,index)=>{
                          return <option key={index} value={item._id} selected={selBlock==item._id?true:false}>{item.name}</option>
                        })}
                      </select>
                      <MdAdd size='27px' style={{backgroundColor : "#BEE3F8", borderRadius : '100%', marginLeft : '6px'}} onClick={()=> {setCurrentRegionAdd('Block'); setModalOpen(true); }}/>
                      </div>
                    {/* <input type="text" name="txtBlock" id="txtBlock" value={addBlock} onChange={(event) => handleSiteInput(event)} className="form-control" /> */}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="village" className="col-sm-3 col-form-label">Village</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtVillage" id="txtVillage" value={addVillage} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="panchayat" className="col-sm-3 col-form-label">Panchayat</label>
                    <div className="col-sm-9">
                    <input type="text" name="txtPanchayat" id="txtPanchayat" value={addPanchayat} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mx-2 mb-3 mb-lg-2">
                    <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/SiteListing"}>Back</Link>
                    {isEdit?<ButtonWithSpinner onClick={()=>submitSite()} isLoading={siteAdminReducer.loading?true:false} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />:<ButtonWithSpinner onClick={()=>handleNextStepEvent(2)} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/******** PUMP and MOTOR Details *********************/}
          <div class={currTab==2?'tab-pane fade show active':'tab-pane fade'} role="tabpanel" aria-labelledby="source-tab" tabindex="0">
            <div className="card">
              <div className="card-body">                
                <div className="add-site-form">    
                  {/******** PUMP Details *********************/}
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Pump</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selPump" name="selPump">
                        <option value="select">Select Pump</option>
                        {pumpList.map((item,index)=>{
                          return <option key={index} value={item._id} selected={selPump==item._id?true:false}>{item.make+' '+item.model}</option>
                        })}
                        {bindAddNewOption()}
                      </select>
                      <ModelPopup isShow={isPumpPopup} title={'Pump Detail'} clickCloseBtn={handlePumpShow} clickSubmitBtn={handlePumpSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="make" className="col-sm-4 col-form-label">Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPumpMake" id="txtPumpMake" value={pumpMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="pumpModel" className="col-sm-4 col-form-label">Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPumpModel" id="txtPumpModel" value={pumpModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="rating" className="col-sm-4 col-form-label">Rating(KW)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPumpRating" id="txtPumpRating" value={pumpRating} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="head" className="col-sm-4 col-form-label">Head(m)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPumpHead" id="txtPumpHead" value={pumpHead} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="discharge" className="col-sm-4 col-form-label">Discharge(m3/hr)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPumpDischarge" id="txtPumpDischarge" value={pumpDischarge} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                       
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="PumpSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtPumpSerialNo" id="txtPumpSerialNo" value={pumpSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/******** MOTOR Details *********************/}
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Motor</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selForMotor" name="selForMotor">
                        <option value="select">Select Motor</option>
                        {motorList.map((item,index)=>{                          
                          return <option key={index} value={item._id} selected={selMotor==item._id?true:false}>{item.motorType+' '+item.motorKW}</option>
                        })}
                        {bindAddNewOption()}
                      </select>
                      <ModelPopup isShow={isMotorPopup} title={'Motor Detail'} clickCloseBtn={handleMotorShow} clickSubmitBtn={handleMotorSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="motorType" className="col-sm-4 col-form-label">Motor Type</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorType" id="txtMotorType" value={motorType} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorKW" className="col-sm-4 col-form-label">Motor KW</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorKW" id="txtMotorKW" value={motorKW} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorRPM" className="col-sm-4 col-form-label">Motor RPM</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorRPM" id="txtMotorRPM" value={motorRPM} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorCoupling" className="col-sm-4 col-form-label">Coupling :Regular or Nema</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorCoupling" id="txtMotorCoupling" value={motorCoupling} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorOutDiameter" className="col-sm-4 col-form-label">Outer Diameter (in inch)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorOutDiameter" id="txtMotorOutDiameter" value={motorOutDiameter} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorMake" className="col-sm-4 col-form-label">Motor Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorMake" id="txtMotorMake" value={motorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorOvrCurr" className="col-sm-4 col-form-label">Overload Current (A)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorOvrCurr" id="txtMotorOvrCurr" value={motorOvrCurr} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorUndVol" className="col-sm-4 col-form-label">Under Voltage (V)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorUndVol" id="txtMotorUndVol" value={motorUndVol} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorOvrVol" className="col-sm-4 col-form-label">Over Voltage (V)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorOvrVol" id="txtMotorOvrVol" value={motorOvrVol} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="motorUndCurr" className="col-sm-4 col-form-label">Under Current(A)</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMotorUndCurr" id="txtMotorUndCurr" value={motorUndCurr} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                      
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="MotorSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtMotorSerialNo" id="txtMotorSerialNo" value={motorSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mx-2 mb-3 mb-lg-2">
                    <Link className="btn btn-danger btn-lg btnMarginRight" onClick={()=>handlePreviousSiteData()}>Previous</Link>
                    {isEdit?<ButtonWithSpinner onClick={()=>handleUpdateSiteData(1)} isLoading={siteAdminReducer.loading?true:false} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />:<ButtonWithSpinner onClick={()=>handleNextStepEvent(3)} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />}
                  </div>
                </div>                
              </div>
            </div>
          </div>
          {/******** METER Details *********************/}
          <div class={currTab==3?'tab-pane fade show active':'tab-pane fade'} role="tabpanel" aria-labelledby="meters-tab" tabindex="0">
            <div className="card">
              <div className="card-body">                
                <div className="add-site-form">   
                  {/******** FLOW METER Details *********************/} 
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Flow meter</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selFlowMeter" name="selFlowMeter">
                        <option value="select">Select Flow Meter</option>
                        {flowMeterList.map((item,index)=>{                          
                          return <option key={index} value={item._id} selected={selFlowMeter==item._id?true:false}>{item.flowMeterMake+' '+item.flowMeterModel}</option>
                        })}
                        {bindAddNewOption()}
                      </select>
                      <ModelPopup isShow={isFlowMeterPopup} title={'Flow Meter'} clickCloseBtn={handleFlowMeterShow} clickSubmitBtn={handleFlowMeterSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="flowMeterMake" className="col-sm-4 col-form-label">Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtFlowMeterMake" id="txtFlowMeterMake" value={flowMeterMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="flowMeterModel" className="col-sm-4 col-form-label">Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtFlowMeterModel" id="txtFlowMeterModel" value={flowMeterModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="flowMeterFMType" className="col-sm-4 col-form-label">FM Type</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtFlowMeterFMType" id="txtFlowMeterFMType" value={flowMeterFMType} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="flowMeterSize" className="col-sm-4 col-form-label">Size</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtFlowMeterSize" id="txtFlowMeterSize" value={flowMeterSize} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="flowMeterMaxFlow" className="col-sm-4 col-form-label">Max Flow</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtFlowMeterMaxFlow" id="txtFlowMeterMaxFlow" value={flowMeterMaxFlow} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                       
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="flowMeterSupplyto" className="col-sm-3 col-form-label">Supply to</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtFlowMeterSupplyto" id="txtFlowMeterSupplyto" value={flowMeterSupplyto} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="flowMeterSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtFlowMeterSerialNo" id="txtFlowMeterSerialNo" value={flowMeterSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/******** PRESSURE METER Details *********************/} 
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Pressure meter</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selPressureMeter" name="selPressureMeter">
                        <option value="select">Select Pressure Meter</option>
                        {pressureMeterList.map((item,index)=>{                          
                          return <option key={index} value={item._id} selected={selPressureMeter==item._id?true:false}>{item.pressureMeterMake+' '+item.pressureMeterModel}</option>
                        })}
                        {bindAddNewOption()}
                      </select>
                      <ModelPopup isShow={isPressureMeterPopup} title={'Pressure Meter'} clickCloseBtn={handlePressureMeterShow} clickSubmitBtn={handlePressureMeterSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="pressureMeterMake" className="col-sm-4 col-form-label">Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPressureMeterMake" id="txtPressureMeterMake" value={pressureMeterMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="pressureMeterModel" className="col-sm-4 col-form-label">Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPressureMeterModel" id="txtPressureMeterModel" value={pressureMeterModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                          
                          <div className="mb-3 row">
                            <label for="pressureMeterMaxValue" className="col-sm-4 col-form-label">Max Flow</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPressureMeterMaxValue" id="txtPressureMeterMaxValue" value={pressureMeterMaxValue} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                       
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="pressureMeterSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtPressureMeterSerialNo" id="txtPressureMeterSerialNo" value={pressureMeterSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/******** Multi-function METER Details *********************/} 
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Multi-function Meter</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selMultiFuncMeter" name="selMultiFuncMeter">
                        <option defaultValue>Select Multi Function Meter</option>
                        {multiFunctionMeterList.map((item,index)=>{
                          // console.log('multiFunction',selMultiFuncMeter);
                          // console.log('item._id',item._id);
                          // console.log('multiFunction==item._id',(selMultiFuncMeter==item._id));
                          return(<option key={index} value={item._id} selected={selMultiFuncMeter==item._id?true:false}>{item.multiFuncMeterMake+' '+item.multiFuncMeterModel}</option>)
                        })}                        
                        {bindAddNewOption()}
                      </select>
                      <ModelPopup isShow={isMultiFuncMeterPopup} title={'Multi-function Meter'} clickCloseBtn={handleMultiFuncMeterShow} clickSubmitBtn={handleMultiFuncMeterSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="multiFuncMeterMake" className="col-sm-4 col-form-label">Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMultiFuncMeterMake" id="txtMultiFuncMeterMake" value={multiFuncMeterMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="multiFuncMeterModel" className="col-sm-4 col-form-label">Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtMultiFuncMeterModel" id="txtMultiFuncMeterModel" value={multiFuncMeterModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                   
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="multiFuncMeterSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtMultiFuncMeterSerialNo" id="txtMultiFuncMeterSerialNo" value={multiFuncMeterSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mx-2 mb-3 mb-lg-2">
                    <Link className="btn btn-danger btn-lg btnMarginRight" onClick={()=>handlePreviousSiteData()}>Previous</Link>
                    {isEdit?<ButtonWithSpinner onClick={()=>handleUpdateSiteData(2)} isLoading={siteAdminReducer.loading?true:false} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />:<ButtonWithSpinner onClick={()=>handleNextStepEvent(4)} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />}                                       
                  </div>
                </div>                
              </div>
            </div>
          </div>
          {/******** Sensors Details *********************/}
          <div class={currTab==4?'tab-pane fade show active':'tab-pane fade'} role="tabpanel" aria-labelledby="sensors-tab" tabindex="0">
            <div className="card">
              <div className="card-body">                
                <div className="add-site-form">   
                  {/******** pH sensor Details *********************/} 
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">pH Sensor</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selPHSensor" name="selPHSensor">
                        <option value="select">Select pH Sensor</option>
                        {phSensorList.map((item,index)=>{                          
                          return <option key={index} value={item._id} selected={selPHSensorMeter==item._id?true:false}>{item.pHSensorMake+' '+item.pHSensorModel}</option>
                        })}
                        {bindAddNewOption()}                      
                      </select>
                      <ModelPopup isShow={isPHSensorPopup} title={'pH Sensor'} clickCloseBtn={handlePHSensorShow} clickSubmitBtn={handlePHSensorSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="pHSensorModel" className="col-sm-4 col-form-label">pH Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPHSensorModel" id="txtPHSensorModel" value={pHSensorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="pHSensorMake" className="col-sm-4 col-form-label">pH Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPHSensorMake" id="txtPHSensorMake" value={pHSensorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="pHSensorMaxVal" className="col-sm-4 col-form-label">Max Value</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPHSensorMaxVal" id="txtPHSensorMaxVal" value={pHSensorMaxVal} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="pHSensorMinVal" className="col-sm-4 col-form-label">Min Value</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtPHSensorMinVal" id="txtPHSensorMinVal" value={pHSensorMinVal} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                     
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="pHSensorSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtPHSensorSerialNo" id="txtPHSensorSerialNo" value={pHSensorSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/******** Level Sensor Details *********************/} 
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Level Sensor</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selLvlSensor" name="selLvlSensor">
                        <option value="select">Select Level Sensor</option>
                        {levelSensorList.map((item,index)=>{                          
                          return <option key={index} value={item._id} selected={selLvlSensor==item._id?true:false}>{item.levelSensorMake+' '+item.levelSensorModel}</option>
                        })}
                        {bindAddNewOption()}                      
                      </select>
                      <ModelPopup isShow={isLvlSensorPopup} title={'Level Sensor'} clickCloseBtn={handleLvlSensorShow} clickSubmitBtn={handleLvlSensorSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="lvlSensorMake" className="col-sm-4 col-form-label">Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtLvlSensorMake" id="txtLvlSensorMake" value={lvlSensorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="lvlSensorModel" className="col-sm-4 col-form-label">Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtLvlSensorModel" id="txtLvlSensorModel" value={lvlSensorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                  
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="lvlSensorSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtLvlSensorSerialNo" id="txtLvlSensorSerialNo" value={lvlSensorSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="lvlSensorHighLvl" className="col-sm-3 col-form-label">High level %</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtLvlSensorHighLvl" id="txtLvlSensorHighLvl" value={lvlSensorHighLvl} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="lvlSensorLowLvl" className="col-sm-3 col-form-label">Low level %</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtLvlSensorLowLvl" id="txtLvlSensorLowLvl" value={lvlSensorLowLvl} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/******** Turbidity Monitor Details *********************/} 
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Turbidity Monitor</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selTrbtMon" name="selTrbtMon">
                        <option value="select">Select Turbidity Monitor</option>
                        {turbidityMonitorList.map((item,index)=>{                          
                          return <option key={index} value={item._id} selected={selTrbtMon==item._id?true:false}>{item.turbidityMonitorMake+' '+item.turbidityMonitorModel}</option>
                        })}
                        {bindAddNewOption()}
                      </select>
                      <ModelPopup isShow={isTrbtMonPopup} title={'Turbidity Monitor'} clickCloseBtn={handleTrbtMonShow} clickSubmitBtn={handleTrbtMonSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="trbtMonMake" className="col-sm-4 col-form-label">Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtTrbtMonMake" id="txtTrbtMonMake" value={trbtMonMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="trbtMonModel" className="col-sm-4 col-form-label">Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtTrbtMonModel" id="txtTrbtMonModel" value={trbtMonModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="trbtMonMaxVal" className="col-sm-4 col-form-label">Max Value</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtTrbtMonMaxVal" id="txtTrbtMonMaxVal" value={trbtMonMaxVal} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                   
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="trbtMonSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtTrbtMonSerialNo" id="txtTrbtMonSerialNo" value={trbtMonSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  {/******** Chlorine Sensor Details *********************/} 
                  <div className="mb-3 row">
                    <label for="sourcetype" className="col-sm-3 col-form-label">Chlorine Sensor</label>
                    <div className="col-sm-9">
                      <select onChange={onParameterSelect} class="form-select" id="selClrnSensor" name="selClrnSensor">
                        <option value="select">Select Chlorine Sensor</option>
                        {chlorineSensorList.map((item,index)=>{                          
                          return <option key={index} value={item._id} selected={selClrnSensor==item._id?true:false}>{item.chlorineSensorMake+' '+item.chlorineSensorModel}</option>
                        })}
                        {bindAddNewOption()}
                      </select>
                      <ModelPopup isShow={isClrnSensorPopup} title={'Chlorine Sensor'} clickCloseBtn={handleClrnSensorShow} clickSubmitBtn={handleClrnSensorSubmit}>
                        <div>
                          <div className="mb-3 row">
                            <label for="clrnSensorMake" className="col-sm-4 col-form-label">Make</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtClrnSensorMake" id="txtClrnSensorMake" value={clrnSensorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="clrnSensorModel" className="col-sm-4 col-form-label">Model</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtClrnSensorModel" id="txtClrnSensorModel" value={clrnSensorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label for="clrnSensorMaxVal" className="col-sm-4 col-form-label">Max Value</label>
                            <div className="col-sm-8">
                              <input type="text" name="txtClrnSensorMaxVal" id="txtClrnSensorMaxVal" value={clrnSensorMaxVal} onChange={(event) => handleSiteInput(event)} className="form-control" />
                            </div>
                          </div>                   
                        </div>
                      </ModelPopup>
                    </div>                    
                  </div>
                  <div className="mb-3 row">
                    <label for="clrnSensorSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtClrnSensorSerialNo" id="txtClrnSensorSerialNo" value={clrnSensorSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mx-2 mb-3 mb-lg-2">
                    <Link className="btn btn-danger btn-lg btnMarginRight" onClick={()=>handlePreviousSiteData()}>Previous</Link>
                    {isEdit?<ButtonWithSpinner onClick={()=>handleUpdateSiteData(3)} isLoading={siteAdminReducer.loading?true:false} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />:<ButtonWithSpinner onClick={()=>handleNextStepEvent(5)} label={'Next'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />}                                        
                  </div>
                </div>                
              </div>
            </div>
          </div>
          {/******** Energy Source And Actuator *********************/}
          <div class={currTab==5?'tab-pane fade show active':'tab-pane fade'} role="tabpanel" aria-labelledby="actuator-tab" tabindex="0">
            <div className="card">
              <div className="card-body">                
                <div className="add-site-form">   
                  {/******** Solar Details *********************/}                  
                  <div className="mb-3 row">
                    <label for="solarKWs" className="col-sm-3 col-form-label">Solor</label>
                    <div className="col-sm-9">
                      <label>
                        <input
                          type="checkbox"
                          checked={isSolorAvailable}
                          onChange={handleSolordetails}
                        />
                        (Tick to add solor panel details)
                      </label>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="solarKWs" className="col-sm-3 col-form-label">KWs</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtSolarKWs" id="txtSolarKWs" value={solarKWs} onChange={(event) => handleSiteInput(event)} className="form-control" disabled={isSolorAvailable?false:true}/>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="panelCompany" className="col-sm-3 col-form-label">Panel Company</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtPanelCompany" id="txtPanelCompany" value={panelCompany} onChange={(event) => handleSiteInput(event)} className="form-control" disabled={isSolorAvailable?false:true} />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="panelModel" className="col-sm-3 col-form-label">Panel Model</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtPanelModel" id="txtPanelModel" value={panelModel} onChange={(event) => handleSiteInput(event)} className="form-control" disabled={isSolorAvailable?false:true} />
                    </div>
                  </div>
                  {/******** Diesel Generator *********************/}  
                  <div className="mb-3 row">
                    <label for="solarKWs" className="col-sm-3 col-form-label">Diesel Generator</label>
                    <div className="col-sm-9">
                      <label>
                        <input
                          type="checkbox"
                          checked={isDieselmotorAvailable}
                          onChange={handleDieselmotordetails}
                        />
                        (Tick to add diesel generator details)
                      </label>
                    </div>
                  </div>                
                  <div className="mb-3 row">
                    <label for="dieselGenKWs" className="col-sm-3 col-form-label">KWs</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtDieselGenKWs" id="txtDieselGenKWs" value={dieselGenKWs} onChange={(event) => handleSiteInput(event)} className="form-control" disabled={isDieselmotorAvailable?false:true} />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="dieselGenCompany" className="col-sm-3 col-form-label">Company</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtDieselGenCompany" id="txtDieselGenCompany" value={dieselGenCompany} onChange={(event) => handleSiteInput(event)} className="form-control" disabled={isDieselmotorAvailable?false:true} />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="dieselGenModel" className="col-sm-3 col-form-label">Model</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtDieselGenModel" id="txtDieselGenModel" value={dieselGenModel} onChange={(event) => handleSiteInput(event)} className="form-control" disabled={isDieselmotorAvailable?false:true} />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label for="dieselGenSerialNo" className="col-sm-3 col-form-label">Serial No.</label>
                    <div className="col-sm-9">
                      <input type="text" name="txtDieselGenSerialNo" id="txtDieselGenSerialNo" value={dieselGenSerialNo} onChange={(event) => handleSiteInput(event)} className="form-control" disabled={isDieselmotorAvailable?false:true} />
                    </div>
                  </div>
                  {/******** Actuator Details *********************/} 
                  {manageMultipleActuators()}
                  <ModelPopup isShow={isActuatorPopup} title={'Actuator'} clickCloseBtn={handleActuatorShow} clickSubmitBtn={handleActuatorSubmit}>
                    <div>
                      <div className="mb-3 row">
                        <label for="actuatorMake" className="col-sm-4 col-form-label">Make</label>
                        <div className="col-sm-8">
                          <input type="text" name="txtActuatorMake" id="txtActuatorMake" value={actuatorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label for="actuatorModel" className="col-sm-4 col-form-label">Model</label>
                        <div className="col-sm-8">
                          <input type="text" name="txtActuatorModel" id="txtActuatorModel" value={actuatorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label for="actuatorOptVlt" className="col-sm-4 col-form-label">Operating Voltage</label>
                        <div className="col-sm-8">
                          <input type="text" name="txtActuatorOptVlt" id="txtActuatorOptVlt" value={actuatorOptVlt} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>                  
                    </div>
                  </ModelPopup>
                  {isEdit?<div className="mb-3 row"><div className="text-center"><Link className="btn btn-outline-primary btn-lg btnMarginRight w-25 text-center" onClick={()=>(setModalOpen2(true))}>Add New Actuator</Link></div></div>:null}
                  <div className="d-flex justify-content-center mx-2 mb-3 mb-lg-2">
                    <Link className="btn btn-danger btn-lg btnMarginRight" onClick={()=>handlePreviousSiteData()}>Previous</Link>
                    {isEdit?<ButtonWithSpinner onClick={()=>handleUpdateSiteData(4)} isLoading={siteAdminReducer.loading?true:false} label={'Finish'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />:<ButtonWithSpinner onClick={()=>handleNextStepEvent(1)} label={'Complete'} id="site-info-tab" data-bs-toggle="tab" data-bs-target="#site-info-tab-pane" />}                                  
                  </div>             
                </div>                
              </div>
            </div>
          </div>
        </div>          
      </div>
    </div>
  );
};

export default AddSiteDetails;
