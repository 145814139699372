// import { createSlice } from "@reduxjs/toolkit";
import {types} from '../actions/types';

const initialState = {
  loading: false,
  status:null,
  data:null,
  message:null,
  graphType:null,
  action:null,
};

const graphReducer=(state = initialState, {type, payload})=>{
  switch (type) {
    case types.GET_GRAPH_LPCD_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        graphType:payload.graphFor,
        // isLogin:false,
        action:types.GET_GRAPH_LPCD_REQUEST
      };
    case types.GET_GRAPH_LPCD_REQUEST_SUCCESS:
      // console.log('graphReducer',payload);
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        graphType:payload.graphFor,
        loading: false,
        action:types.GET_GRAPH_LPCD_REQUEST_SUCCESS
      };
    case types.GET_GRAPH_LPCD_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_SITELIST_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        graphType:payload.graphFor,
        token:null,    
        loading: false,
        action:types.GET_GRAPH_LPCD_REQUEST_FAILURE
      };
    case types.GET_GRAPH_CHLORINE_REQUEST_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        graphType:payload.graphFor,
        // isLogin:false,
        action:types.GET_GRAPH_CHLORINE_REQUEST_REQUEST
      };
    case types.GET_GRAPH_CHLORINE_REQUEST_REQUEST_SUCCESS:
      // console.log('graphReducer',payload);
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        graphType:payload.graphFor,
        loading: false,
        action:types.GET_GRAPH_CHLORINE_REQUEST_REQUEST_SUCCESS
      };
    case types.GET_GRAPH_CHLORINE_REQUEST_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_SITELIST_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        graphType:payload.graphFor,
        token:null,    
        loading: false,
        action:types.GET_GRAPH_CHLORINE_REQUEST_REQUEST_FAILURE
      };
    case types.GET_GRAPH_PRESSURE_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        graphType:payload.graphFor,
        // isLogin:false,
        action:types.GET_GRAPH_PRESSURE_REQUEST
      };
    case types.GET_GRAPH_PRESSURE_REQUEST_SUCCESS:
      // console.log('graphReducer',payload);
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        graphType:payload.graphFor,
        loading: false,
        action:types.GET_GRAPH_PRESSURE_REQUEST_SUCCESS
      };
    case types.GET_GRAPH_PRESSURE_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_SITELIST_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        graphType:payload.graphFor,
        token:null,    
        loading: false,
        action:types.GET_GRAPH_PRESSURE_REQUEST_FAILURE
      };
    case types.GET_GRAPH_WS_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        graphType:payload.graphFor,
        // isLogin:false,
        action:types.GET_GRAPH_WS_REQUEST
      };
    case types.GET_GRAPH_WS_REQUEST_SUCCESS:
      // console.log('graphReducer',payload);
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        graphType:payload.graphFor,
        loading: false,
        action:types.GET_GRAPH_WS_REQUEST_SUCCESS
      };
    case types.GET_GRAPH_WS_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_SITELIST_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        graphType:payload.graphFor,
        token:null,    
        loading: false,
        action:types.GET_GRAPH_WS_REQUEST_FAILURE
      };
    case types.GET_GRAPH_TURBIDITY_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        graphType:payload.graphFor,
        // isLogin:false,
        action:types.GET_GRAPH_TURBIDITY_REQUEST
      };
    case types.GET_GRAPH_TURBIDITY_REQUEST_SUCCESS:
      // console.log('graphReducer',payload);
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        graphType:payload.graphFor,
        loading: false,
        action:types.GET_GRAPH_TURBIDITY_REQUEST_SUCCESS
      };
    case types.GET_GRAPH_TURBIDITY_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_SITELIST_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        graphType:payload.graphFor,
        token:null,    
        loading: false,
        action:types.GET_GRAPH_TURBIDITY_REQUEST_FAILURE
      };
    case types.GET_GRAPH_OHRS_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        graphType:payload.graphFor,
        // isLogin:false,
        action:types.GET_GRAPH_OHRS_REQUEST
      };
    case types.GET_GRAPH_OHRS_REQUEST_SUCCESS:
      // console.log('graphReducer',payload);
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        graphType:payload.graphFor,
        loading: false,
        action:types.GET_GRAPH_OHRS_REQUEST_SUCCESS
      };
    case types.GET_GRAPH_OHRS_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_SITELIST_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        graphType:payload.graphFor,
        token:null,    
        loading: false,
        action:types.GET_GRAPH_OHRS_REQUEST_FAILURE
      };
    default:
      return state;
  }
}

export default graphReducer;