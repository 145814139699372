import React, { useEffect } from 'react';
import "../index.css";
import PropTypes from "prop-types";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate, Link } from "react-router-dom";
import { isEmpty, isLoggedInUser, getLoggedInUser } from '../utils';
import './admin.css';
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useRef, useState } from 'react';
import { MdAccessibility, MdNewspaper, MdSettings } from 'react-icons/md';
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons'
import { Divider } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Drawer, Input, Button, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { addCountryAPI, updateCountryAPI } from '../store/apis';
import { checkPermission } from '../utils';
import { set } from 'firebase/database';

const AdminMenu = ({ selMenu }) => {
  const [isView, setIsView] = useState(false);
  useEffect(() => {
    chkLogin();
    getUserDetails();
  }, []); const btnRef = useRef(); // Reference for main drawer button



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [ISOCode, setISOCode] = useState('');
  const [localCountryId, setLocalCountryId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imeiApprovalpermissions, setImeiApprovalPermissions] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
    isView: false
  });
  const [thresholdLogspermissions, setThresholdPermissions] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
    isView: false
  });
  const [liveDataLogPermissions, setLiveDataLogsPermissions] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
    isView: false
  });
  const [districtTokenPermissions, setDistrictTokenPermissions] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
    isView: false
  });

  useEffect(() => {
    chkLogin();
    setPermission();

    if (location?.state?.item) {
      console.log('Country-item', location.state.item);
      setCountryName(location.state.item.name);
      setCountryCode(location.state.item.code);
      setISOCode(location.state.item.ISOCode);
      setLocalCountryId(location.state.item._id);
    }
  }, []);

  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  };

  const setPermission = async () => {
    setIsEdit(await checkPermission('Country', 'edit'));

    setImeiApprovalPermissions({
      isCreate: await checkPermission('IMEI Approval', 'create'),
      isEdit: await checkPermission('IMEI Approval', 'edit'),
      isDelete: await checkPermission('IMEI Approval', 'delete'),
      isView: await checkPermission('IMEI Approval', 'view')
    })
    setThresholdPermissions({
      isCreate: await checkPermission('Threshold Logs', 'create'),
      isEdit: await checkPermission('Threshold Logs', 'edit'),
      isDelete: await checkPermission('Threshold Logs', 'delete'),
      isView: await checkPermission('Threshold Logs', 'view')
    })
    setLiveDataLogsPermissions({
      iscreate: await checkPermission("Live Data Logs", 'create'),
      isEdit: await checkPermission("Live Data Logs", 'edit'),
      isDelete: await checkPermission("Live Data Logs", 'delete'),
      isView: await checkPermission("Live Data Logs", 'view')
    })
    setDistrictTokenPermissions({
      iscreate: await checkPermission("Distict Token Management", 'create'),
      isEdit: await checkPermission("Distict Token Management", 'edit'),
      isDelete: await checkPermission("Distict Token Management", 'delete'),
      isView: await checkPermission("Distict Token Management", 'view')
    })

    console.log("HIHIIHIHH Master Data", isEdit, districtTokenPermissions, liveDataLogPermissions, thresholdLogspermissions, imeiApprovalpermissions);


    // setIsCreate(await checkPermission('Company','create'));
    // setIsEdit(await checkPermission('Company','edit'));
    // setIsDelete(await checkPermission('Company','delete'));
    // setIsView(await checkPermission('Company','view'));

  };


  const handleSiteInput = (e) => {
    if (e.target.name === 'txtName') {
      setCountryName(e.target.value);
    }
    if (e.target.name === 'txtCode') {
      setCountryCode(e.target.value);
    }
    if (e.target.name === 'txtISOcode') {
      setISOCode(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (isEmpty(countryName)) {
      message.warning('Please enter country name');
      return;
    } else if (isEmpty(countryCode)) {
      message.warning('Please enter country code');
      return;
    } else if (isEmpty(ISOCode)) {
      message.warning('Please enter ISO Code');
      return;
    } else {
      const request = {
        name: countryName,
        code: countryCode,
        ISOCode: ISOCode,
      };
      setLoading(true);
      if (localCountryId) {
        request.id = localCountryId;
        updateCountryAPI(request)
          .then((response) => {
            setLoading(false);
            if (response) {
              message.success('Country updated successfully');
              setCountry(false);
              navigate('/Admin/Country');
            }
          })
          .catch((error) => {
            setLoading(false);
            message.error('Failed to update country. Please try again.');
          });
      } else {
        addCountryAPI(request)
          .then((response) => {
            setLoading(false);
            if (response) {
              message.success('Country added successfully');
              setCountry(false);
              navigate('/Admin/Country');
            }
          })
          .catch((error) => {
            setLoading(false);
            message.error('Failed to add country. Please try again.');
          });
      }
    }
  };


  const getUserDetails = async () => {
    const userObject = await getLoggedInUser();
    console.log("AdminMenu-userObject", userObject);
    if (userObject.MobileNo === '9876543210') {
      setIsView(true);
    }
  };

  const [country, setCountry] = useState(false);

  const [isMasterOpen, setMasterOpen] = useState(false);
  const [isSensorOpen, setSensorOpen] = useState(false);
  const [isRegionalOpen, setRegionalOpen] = useState(false);

  const showMasterDrawer = () => {
    setMasterOpen(true);
  };

  const closeMasterDrawer = () => {
    setMasterOpen(false);
  };

  const showSensorDrawer = () => {
    setSensorOpen(true);
  };

  const closeSensorDrawer = () => {
    setSensorOpen(false);
  };

  const showRegionalDrawer = () => {
    setRegionalOpen(true);
  };

  const closeRegionalDrawer = () => {
    setRegionalOpen(false);
  };

  return (
    <Navbar bg='light' expand="lg" style={{ display: 'flex' }} >
      <Navbar.Brand href="#home"></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-2" />
      <Navbar.Collapse id="basic-navbar-nav" className="mx-3">
        <Nav className="me-auto">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Drawer
              title={localCountryId ? 'Update Country' : 'Add Country'}
              placement="left"
              closable={false}
              onClose={() => setCountry(false)}
              visible={country}
              width={400}
            >
              <div>
                <label>Name</label>
                <Input name="txtName" value={countryName} onChange={handleSiteInput} />
              </div>
              <div>
                <label>Code</label>
                <Input name="txtCode" value={countryCode} onChange={handleSiteInput} />
              </div>
              <div>
                <label>ISO Code</label>
                <Input name="txtISOcode" value={ISOCode} onChange={handleSiteInput} />
              </div>
              <div>
                {isEdit ? (
                  <Button onClick={handleSubmit} loading={loading}>
                    Save
                  </Button>
                ) : null}
              </div>
            </Drawer>
            <Link className={selMenu === "Site" ? "nav-link active" : "nav-link"} to="/Admin/SiteListing">Sites</Link>
            <Link className={selMenu === "Project" ? "nav-link active" : "nav-link"} to="/Admin/ProjectListing">Projects</Link>
            {isView ? <Link className={selMenu === "Users" ? "nav-link active" : "nav-link"} to="/Admin/Users">Users</Link> : null}
            <Link className={selMenu === "Company" ? "nav-link active" : "nav-link"} to="/Admin/Company">Company</Link>
            {imeiApprovalpermissions.isView && <Link className={selMenu === "AssetReport" ? "nav-link active" : "nav-link"} to="/Admin/ImeiApproval">IMEI Approval</Link>}
            {thresholdLogspermissions.isView && <Link className={selMenu === "Parameter Threshold Logs" ? "nav-link active" : "nav-link"} to="/ParameterThresholdLogs">Threshold Logs</Link>}
            {liveDataLogPermissions.isView && <Link className={selMenu === "Parameter Threshold Logs" ? "nav-link active" : "nav-link"} to="/livelogs">Live Data Logs</Link>}


          </div>
          <Button ref={btnRef} type="primary" onClick={showMasterDrawer} style={{ marginLeft: '20px', marginTop: "5px", display: 'flex', alignItems: 'center', gap: '4px' }}>
            Master
            <MdSettings />
          </Button>

          <Drawer
            title="Master Menu"
            placement='right'
            closable={false}
            onClose={closeMasterDrawer}
            visible={isMasterOpen}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button type="primary" size="large" icon={<MdNewspaper />} className='blob' onClick={() => { navigate('/Admin/News') }}>
                News
              </Button>
              <Button type='default' size="large" icon={<MdAccessibility />} className='blob' onClick={() => { navigate('/Admin/Roles') }}>
                Roles
              </Button>

            </div>
            <Divider />
            <Button type="text" size="large" icon={<CaretRightOutlined />} onClick={showSensorDrawer}>
              Sensor Options
            </Button>

            <Drawer
              title="Sensor Options"
              placement='right'
              closable={false}
              onClose={closeSensorDrawer}
              visible={isSensorOpen}
              style={{ display: 'flex', flexDirection: 'column' }} // Update the style for a column layout
            >
              <Button type="default" size="large" onClick={() => { navigate('/Admin/Motor') }} >
                Motor
              </Button>
              <Divider />

              <a href="/Admin/Flowmeter" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Flow Meter
                </Button>
              </a>
              <Divider />
              <a href="/Admin/Pressuremeter" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Pressure Meter
                </Button>
              </a>
              <Divider />

              <a href="/Admin/Multifunctionmeter" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Multifunction Meter
                </Button>
              </a>
              <Divider />

              <a href="/Admin/Phsensor" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  pH Sensor
                </Button>
              </a>
              <Divider />
              <a href="/Admin/Levelsensor" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Level Sensor
                </Button>
              </a>
              <Divider />

              <a href="/Admin/Turbiditymonitor" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Turbidity Monitor
                </Button>
              </a>
              <Divider />

              <a href="/Admin/Pump" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Pump
                </Button>
              </a>
              <Divider />

              <a href="/Admin/Actuatorsensor" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Chlorine Sensor
                </Button>
              </a>
              <Divider />
              <a href="/Admin/Actuatorsensor" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button type="default" size="large" >
                  Actuator Sensor
                </Button>
              </a>
              <Divider />

              {/* Add other options */}
            </Drawer>

            <Divider />
            <Button type="text" size="large" icon={<CaretRightOutlined />} onClick={showRegionalDrawer}>
              Regional Management
            </Button>
            <Divider />
            {districtTokenPermissions.isView && <Button type="text" size="large" icon={<CaretRightOutlined />} onClick={() => {
              navigate("/tokenManagement", { state: { permissions: districtTokenPermissions } });
            }}>
              District Token Management
            </Button>}

            <Drawer
              title="Regional Management"
              placement='right'
              closable={false}
              onClose={closeRegionalDrawer}
              visible={isRegionalOpen}
              style={{ display: 'flex', flexDirection: 'column' }} // Update the style for a column layout
            >
              <Button type="text" size="large" icon={<CaretRightOutlined />} onClick={() => { navigate('/Admin/Country') }}>
                Country
              </Button>
              {/* <Button onClick={()=> {setCountry(true); setMasterOpen(false); setRegionalOpen(false)}}>
                <PlusOutlined/>
              </Button> */}
              <Divider />
              <Button type="text" size="large" icon={<CaretRightOutlined />} onClick={() => { navigate('/Admin/State') }}>
                State
              </Button>
              <Divider />
              <Button type="text" size="large" icon={<CaretRightOutlined />} onClick={() => { navigate('/Admin/Block') }}>
                Block
              </Button>
              <Divider />

              <Button type="text" size="large" icon={<CaretRightOutlined />} onClick={() => { navigate('/Admin/District') }}>
                District
              </Button>
            </Drawer>

            <Divider />

          </Drawer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

AdminMenu.propTypes = {
  selMenu: PropTypes.string,
};

AdminMenu.defaultProps = {
  selMenu: 'Site',
};

export default AdminMenu;
