import React, { useEffect,useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
// import menuBurger from "../assets/menu-burger.svg";
import mainLogo from "../assets/images/aqualogix-logo.png";
import secondaryLogo from "../assets/images/hargharjal-logo.png";
import userIcon from "../assets/images/user-placeholder.png";
import blueUser from '../Pages/userIconBlue.png'
import {clearData} from '../redux/actions/userAction'
import {types} from '../redux/actions/types';
import ButtonWithSpinner from "./ButtonWithSpinner";
import {CONSTANTVALUE, getData, isEmpty} from '../utils'
import {MdAdminPanelSettings, MdDashboard, MdDehaze, MdLogout, MdReport, MdSettings, MdTimer, MdVerifiedUser} from 'react-icons/md'
import "../index.css";
import { Avatar, Tag, TagLabel } from '@chakra-ui/react';

const NavBarAdmin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [IMEINumber, setIMEINumber] = useState('');
  const userReducer = useSelector(state => state.userReducer);
  const [userData, setUserData] = useState(null);
  const doLogout=async()=>{
    localStorage.clear();
    dispatch(clearData());
    setTimeout(()=> {
      window.location.reload();
    }, 700)

  }
  useEffect(()=>{
    // console.log('userReducer',userReducer);
    if(userReducer.action){
      if(userReducer.action===types.CLEAR_LOGIN_REQUEST_APPSETTINGS_SUCCESS){
        navigate('/Admin');
      }
    }
  },[userReducer.action])
  useEffect(()=> {
    fetchUserData();
  }, [])

  const fetchUserData = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT)
    console.log("xxx uuuu",data)
    if(data) setUserData(data);
  }

  return (
    <nav className="navBar navbar navbar-expand-lg navbar-light fixed-top bg-light w-100">
      <div className="container-fluid">
        {console.log("CURRENT PATH", location.pathname)}
        <h1 className="logo-main">
          <img
            src={mainLogo}
            alt="mainLogo"
            className="d-inline-block align-top"
          />{" "}
        </h1>

        <Link className="navbar-brand d-none d-md-block" to={"/"}>
          <img
            src={secondaryLogo}
            alt="secondaryLogo"
            style={{ height: "80px", width: "294px" }}
            className="d-inline-block align-top"
          />
        </Link>

        <div className="dropdown user-dropdown">
          <Link
            className="dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            to={"#"}>
            <Tag size='lg' colorScheme='blue' borderRadius='full' style={{display : 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Avatar
                src= {blueUser}
                size='xs'
                name={userData?.OperatorName ? userData.OperatorName : 'User'}    ml={-1}
                mr={2}
                />
              <TagLabel > {userData?.OperatorName ? userData.OperatorName : 'User'} </TagLabel>
                <MdDehaze style={{marginTop: '4px'}}/>
            </Tag>
          </Link>
          <div
            className="dropdown-menu user-dropdown-menu"
            aria-labelledby="navbarDropdown">
            <span className="dropdown-arrow"></span>
            <ul className="user-dropdown-menu">
              <li className="user-info">
                {/* <img
                  src={userIcon}
                  alt="User icon"
                  style={{ height: "48px", width: "48px" }}
                  className="d-inline-block align-top"
                /> */}
                <Link className="user-name" to={"#"}>Admin</Link></li>
              <li><Link to={"/dashboard"} ><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdDashboard style={{marginRight: '4px'}}/> Dashboard</div></Link></li>
              {location.pathname != '/Admin/SiteListing' && <li><Link to={"/Admin/SiteListing"} ><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdSettings style={{marginRight: '4px'}}/> Admin</div></Link></li>}
              <li><Link to={"/AllReports"}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdReport style={{marginRight: '4px'}}/> Reports </div></Link></li>
              <li><Link to={"/ParameterThresholdLogs"}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdReport style={{marginRight: '4px'}}/> Parameter Threshold Logs </div></Link></li>
             
              <li><Link onClick={doLogout}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdLogout style={{marginRight: '4px'}}/>LOGOUT</div></Link></li>
            </ul>
          </div>          
        </div>
      </div>
    </nav>
  );
};

export default NavBarAdmin;
