import React from 'react';
import PropTypes from "prop-types";
import {CONSTANTVALUEEXTRAPARAM} from '../../../utils'

const Header = ({moduleType}) => {  
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PUMP){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Pump</div>
              <h3 className="project-title fw-bold">Pump Listing</h3>
            </div>
          </div>
        </div>)
    }
    else if(moduleType==CONSTANTVALUEEXTRAPARAM.MOTOR){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Motor</div>
              <h3 className="project-title fw-bold">Motor Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.FLOWMETER){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Flow Meter</div>
              <h3 className="project-title fw-bold">Flow Meter Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.PRESSUREMETER){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Pressure Meter</div>
              <h3 className="project-title fw-bold">Pressure Meter Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Multi Function Meter</div>
              <h3 className="project-title fw-bold">Multi Function Meter Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.PHSENSOR){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Ph Sensor</div>
              <h3 className="project-title fw-bold">Ph Sensor Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.LEVELSENSOR){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Level Sensor</div>
              <h3 className="project-title fw-bold">Level Sensor Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Turbidity Monitor</div>
              <h3 className="project-title fw-bold">Turbidity Monitor Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Chlorine Sensor</div>
              <h3 className="project-title fw-bold">Chlorine Sensor Listing</h3>
            </div>
          </div>
        </div>)
    }else if(moduleType==CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR){
      return (
        <div className="project-page-head">
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Actuator Sensor</div>
              <h3 className="project-title fw-bold">Actuator Sensor Listing</h3>
            </div>
          </div>
        </div>)
    }
};
Header.propTypes = {
  moduleType: PropTypes.string,
};
Header.defaultProp = {
  moduleType: CONSTANTVALUEEXTRAPARAM.PUMP,
};
export default Header;
