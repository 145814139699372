import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Box, Text, Container, VStack, Input, Button } from '@chakra-ui/react';
import NavBarAdmin from './NavBarAdmin';
import AdminMenu from './AdminMenu';

const LiveLogs = () => {
    const [liveData, setLiveData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const socket = io('http://mqtt.aqualogix.in:85');

        const handleMessage = (data) => {
            setLiveData(prevData => [...prevData, data]);
        };

        socket.on('message', handleMessage);

        return () => {
            socket.off('message', handleMessage);
        };
    }, []);

    const handleSearch = () => {
        // Filter liveData based on the searchTerm
        const filteredData = liveData.filter(data => JSON.stringify(data).includes(searchTerm));
        setLiveData(filteredData);
    };

    const handleReset = () => {
        // Reset search term and display all data
        setSearchTerm('');
        // You might need to fetch the original data again if needed
    };

    return (
        <div className='page'>
            <NavBarAdmin />
            <AdminMenu selMenu="AssetReport" />

        <Container maxW="container.xl">
            <Box p="6" bg="gray.100" borderRadius="xl" mt="8">
                <Text fontSize="xl" fontWeight="bold">Live Data Logs</Text>
                <Box mt="4" mb="4">
                    <Input
                        placeholder="Search data..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        mr="2"
                    />
                </Box>
                <VStack spacing="4" align="stretch">
                    {liveData.filter((item) => searchTerm.length == 0 || item.includes(searchTerm)).map((data, index) => (
                        <Box key={index} p="4" borderWidth="1px" borderRadius="lg">
                            <Text fontSize="md" fontWeight="bold">Data received:</Text>
                            <Text fontSize="sm">{data}</Text>
                        </Box>
                    ))}
                </VStack>
            </Box>
        </Container>
        </div>
    );
};

export default LiveLogs;
