import React, { useEffect, useState } from 'react';
import { getReport } from '../redux/actions/siteAction'
import { useDispatch, useSelector } from 'react-redux';
import { types } from '../redux/actions/types';
import NavBar from "../Components/NavBar";
import { siteList } from '../redux/actions/siteAdminAction'
import { AiFillFileExcel, AiFillPrinter, AiOutlineSearch as SearchIcon } from 'react-icons/ai';

import { Button, IconButton, useToast } from '@chakra-ui/react';
import DateRangePicker from './DateRangePicker';
import { CONSTANTVALUE, getData } from '../utils';
import { apiName } from '../store/serverConfig';
import { apiGetCallWithPromise } from '../store/axiosApicall';
import { useRef } from 'react';
import * as XLSX from 'xlsx';
import { CursorMarker } from 'react-calendar-timeline';
import { reportDataAPI } from '../store/apis';
import { Box, Select, Flex, background } from '@chakra-ui/react';
import moment from 'moment';
import axios from 'axios';
import { MoonLoader } from 'react-spinners';

// import AdminMenu from "../Components/AdminMenu";
// import moment from 'moment'
// import angleLeft from "../../assets/images/down-arrow.svg";
// import { leftCanvasToggle } from "../../redux/reducers/dashboardSlice";
// import { Link } from "react-router-dom";
// import "./offCanvas/offCanvas.css";
// import CanvasItem from "./offCanvas/CanvasItem";
// import PropTypes from "prop-types";

const ReportComponent = ({ allProjects, newAssignedSites }) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);


  { console.log('PPPPPPPPIIIIII', allProjects) }

  const siteReducer = useSelector(state => state.siteReducer);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [mqttData, setMqttData] = useState([]);
  const [isLoading, setisLoding] = useState(false);

  const [assignedSites, setAssignedSites] = useState([]);
  const [projectsList, setProjects] = useState(allProjects);
  const [selectedProject, setSelectedProject] = useState('');
  useEffect(() => {
    const p = localStorage.getItem('selectedProject');
    setTimeout(() => {
      if (p) {
        setSelectedProject(p)
      }
    }, 2000)
  }, [])
  const [selectedSites, setSelectedSites] = useState(newAssignedSites);
  const [site, setSite] = useState('')

  const [imeino, setImeino] = useState("");
  const [listSite, setListSite] = useState([]);
  const toast = useToast()

  useEffect(() => {
    setMqttData([])
  }, [selectedProject])


  const formatDate = (date) => date ? moment(date).format('DD-MM-YYYY') : '';


  const fetchReportData = async () => {
    setisLoding(true);
    const startDateObj = new Date(startDate);
    startDateObj.setDate(startDateObj.getDate() - 1);
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    if (!imeino || imeino == "") {
      const response = await axios.post('http://mqtt.aqualogix.in:85/report/getAllSiteReports', {
        userId: data.id,
        startDate: formatDateToYYYYMMDD(startDate, true),
        endDate: formatDateToYYYYMMDD(endDate),
        projectID: selectedProject
      });

      setMqttData(response.data.data.list);
      setisLoding(false);
      setisLoding(false);
      return;
    }
    const request = {
      "IMEI": imeino,
      "C1": 0,
      "startDate": formatDateToYYYYMMDD(startDate, true),
      "endDate": formatDateToYYYYMMDD(endDate)
    }
    console.log('requestIJIJIJIJ', request)
    const info = await reportDataAPI(request)
    setMqttData(info.data.list)
    setisLoding(false);
  }
  useEffect(() => {
    // Fetch sites only if the selected project is not 'ALL'
    if (selectedProject !== 'ALL') {
      const selectedProjectData = allProjects?.find((item) => item.project._id === selectedProject);
      setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
    } else {
      // Clear sites when 'All Projects' is selected
      setSelectedSites([]);
    }
  }, [selectedProject]);
  const handleSiteChange = (event) => {

    const selectedSiteName = event.target.options[event.target.selectedIndex].text;
    const selectedSiteIMEI = event.target.options[event.target.selectedIndex].getAttribute('imei');

    // console.log('Selected Site Name:', selectedSiteName);
    setMqttData([]);
    setSite(selectedSiteName);
    // console.log('Selected Site IMEI:', selectedSiteIMEI);
    setImeino(selectedSiteIMEI);
    console.log('Selected Site IMEI:', imeino);
  };
  const handleSearch = () => {
    // console.log("HIHIHIHIH")
    fetchReportData();


  }

  function formatDateToYYYYMMDD(originalDateStr, subtractOneDay = false) {
    if (originalDateStr == null) return null
    const dateObject = new Date(originalDateStr);
    if (subtractOneDay) {
      dateObject.setDate(dateObject.getDate() - 1);
    }
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    console.log('xxx huuu', year, month, day)
    const formattedDate = `${year}-${month}-${day}`;
    console.log('xxx huuuXXXX', formattedDate)
    return formattedDate;
  }

  const handleUserInput = (e) => {
    // console.log('e.target.name',e.target.name);
    // console.log('e.target.value',e.target.value);
    if (e.target.name === "imeino") {
      setImeino(e.target.value);
    }
  }
  const handleChangeEvent = (e) => {
    console.log('AddUser-handleChangeEvent-name-', e.target.name + "-value-" + e.target.value);
    if (e.target.name === "selSite") {
      // fetchProjectList();
      setImeino(e.target.value);
    }
  }



  const exportToExcel = () => {

    // console.log(mqttData)
    if (mqttData.length > 0) {

      const customHeaders = ['Date', 'IMEI', 'Site Name', 'Total Flow', 'Total kWh', 'Opening Flow', 'Opening kWh', 'Closing Flow', 'Closing kWh'];


      const dataArray = mqttData.map(item => [
        item.date,

        Number(item.IMEI),
        item.pumpName,
        item.totalFlow,
        item.totalkWh,
        item.openingFlow,
        item.openingkWh,
        item.cloginFlow,
        item.clogingkWh,
      ]);

      console.log(dataArray)

      dataArray.unshift(customHeaders);

      const worksheet = XLSX.utils.aoa_to_sheet(dataArray);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'MQTT Data');

      XLSX.writeFile(workbook, `Daily Reports ${moment(startDate).format("DD-MM-YYYY")} - ${moment(endDate).format("DD-MM-YYYY")}.xlsx`);
    }
  };




  const fontFamily = 'Work Sans'

  const handlePrint = () => {
    if (tableRef.current) {
      const printWindow = window.open('Daily_Report', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
   <html>
     <head>
       <title>Daily_Report</title>
       <h3>From: ${moment(startDate).format("DD-MM-YYYY")}  To:${moment(endDate).format("DD-MM-YYYY")}</h3>
       <style>
         /* Add your custom styles here */
         table {
           border-collapse: collapse;
           width: 100%;
         }
         th, td {
           border: 1px solid #ccc;
           padding: 8px;
           text-align: left;
         }
         th {
           background-color: #f2f2f2;
         }
         td.align-right {
          text-align: right;
        }
       </style>
     </head>
     <body>
       <table style="font-family: ${fontFamily}">
         ${tableRef.current.innerHTML}
       </table>
       <script>
              
       document.querySelectorAll('td').forEach(cell => {
         if (!isNaN(cell.innerText) && cell.innerText.trim() !== '') {
           cell.classList.add('align-right');
         }
       });
     </script>
     </body>
   </html>
 `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  const bindSites = () => {
    if (newAssignedSites) {
      // console.log('bindProjects1-listProjects',listProjects);
      if (newAssignedSites.length > 0) {
        // console.log('bindProjects2-listProjects',listProjects);
        return (newAssignedSites.map((item, index) => (
          <option key={index} value={item.IMEI}>{item.pumpName.toString() + "   (IMEI " + item.IMEI + ")"}</option>
        )))
      }
    }
  }

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const handleStopRequest=()=>{
  //   clearInterval(intervalId);
  // }
  return (
    <div className="panel_contentmqttdata  m-3">
      <div className="row mt-3 mb-3">
        <div className="col-8 mb-8 pl-2"><h2>Daily Reports</h2></div>
        {/* {bindSites()} */}
        {console.log('xxx dates', startDate, endDate)}
        <div style={{ display: 'flex' }}>
          <div className="row">

            {/* <div className="col"><input name="imeino" id="imeino" value={imeino} onChange={(event) => handleUserInput(event)} className="form-control" /></div> */}
            {/* <div className="col-3"><ButtonWithSpinnerSmall onClick={()=>handleSearch()} isLoading={siteReducer.loading?true:false} label={'Search'} /></div> */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <div>
                {/* <label className="form-label" for="form3Example3c">Project</label> */}
                <Flex >
                  <Select
                    id="pageSizeSelect"
                    value={selectedProject}
                    variant={'filled'}
                    onChange={(e) => setSelectedProject(e.target.value)}
                    color="black"
                  >
                    <option value={'ALL'}>All Project</option>
                    {allProjects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                      <option key={item.project._id} value={item.project._id}>
                        {item.project.ProjectName}
                      </option>
                    ))}
                  </Select>
                  {selectedProject !== 'ALL' && (
                    <Select
                      // w={'15%'}
                      variant={'filled'}
                      ml={2}
                      // value={site}
                      onChange={(e) => handleSiteChange(e)}

                      color="black"
                    >
                      <option value={'ALL'}>All Site</option>
                      {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                        <option key={site._id} value={site._id} imei={site.IMEI}>
                          {site.pumpName}
                        </option>
                      ))}
                    </Select>
                  )}
                </Flex>
                {/* Note: Data will refresh in 5 sec time interval. */}
              </div>
              <DateRangePicker setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} displayFormat="DD-MM-YYYY" endDate={endDate} />

              <IconButton
                colorScheme='blue'
                ml={'3'}
                aria-label='Search database'
                icon={isLoading == true ? <MoonLoader size={20} color='white' /> : <SearchIcon />}
                size='md' onClick={() => handleSearch()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='table-responsive'>
        <table className="table table-striped" ref={tableRef}>
          <thead>
            <tr>
              <th scope="col">IMEI</th>
              <th scope="col">Site Name</th>
              <th scope="col">Date</th>
              <th scope="col">Flow Meter Opening Reading (L)</th>
              <th scope="col">Flow Meter Closing Reading (L)</th>
              <th scope="col">Total Production (L)</th>
              {/* <th scope="col">Energy Meter Opening Reading (KWh)</th>
              <th scope="col">Energy Meter Closing Reading (KWh)</th>
              <th scope="col">Total Power Consumption (KWh)</th> */}
            </tr>
          </thead>
          <tbody>
            {
              mqttData.length > 0 ?
                Array.from(new Set(mqttData.map(item => item.date + item.pumpName))).map((key, index) => {
                  const filteredData = mqttData.filter(item => (item.date + item.pumpName) === key);
                  const item = filteredData[0]; // Assuming each combination has only one record
                  return (
                    <tr key={index}>
                      <td>{item.IMEI ? item.IMEI.toString() : (imeino ? imeino.toString() : "")}</td>
                      <td>{item.pumpName ? item.pumpName.toString() : ""}</td>
                      <td>{item.date ? item.date : ""}</td>
                      <td>{item.openingFlow ? item.openingFlow : 0}</td>
                      <td>{item.cloginFlow ? item.cloginFlow : 0}</td>
                      <td>{item.cloginFlow && item.openingFlow ? item.cloginFlow - item.openingFlow : 0}</td>
                      {/* <td>{item.openingkWh ? item.openingkWh.toFixed(2) : 0}</td>
                      <td>{item.clogingkWh ? item.clogingkWh.toFixed(2) : 0}</td>
                      <td>{(item.clogingkWh - item.openingkWh).toFixed(2)}</td> */}
                    </tr>
                  )
                }) : <tr><td colSpan={9}>No Record Found</td></tr>
            }

          </tbody>
        </table>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
          <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrint}>
            Print
            <AiFillPrinter />
          </Button>
          <Button
            mt='15px'
            colorScheme='blue'
            style={{ display: 'flex', gap: '5px' }}
            onClick={exportToExcel}
          >
            Export to Excel
            <AiFillFileExcel />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ReportComponent;