import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from 'prop-types';
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const cDate = new Date();
// export const options = {
//   scales: {
//     y:
//       {
        
//       },
//     x:
//       {
//         min: 0,
//         max: cDate.getHours(),
//         stepSize: 1,
//       },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Line Chart",
//     },
//   },
// };


let data=null;
const LineChartChlorine = ({dataList,xLabel,yLabel}) => {
  const [graphStatus, setGraphStatus] = useState(false);
  useEffect(()=>{      
    // fetchGraphData();
    let labels=[];
    let countArray=[];
    dataList.map((item,index)=>{
      // console.log('data-item',item);
      labels.push(item.x);
      countArray.push((item.y/10));
    })
    // console.log('labelArray',labelArray);
    // console.log('countArray',countArray);
    labels.map(() => countArray.map((count) => {
      // console.log("countArray-count-"+count);
      return count
    }))
    data = {
      labels,
      datasets: [
        {
          label: "Chlorine",
          data: countArray.map((count) => parseFloat(count.toFixed(2))),
          //   faker.datatype.number({ min: 0, max: 1000 })),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        }
        // ,
        // {
        //   label: "Dataset 2",
        //   data: labels.map(() => counts.map((count) => count)),
        //   //   faker.datatype.number({ min: 0, max: 1000 })),
        //   backgroundColor: "rgba(53, 162, 235, 0.5)",
        // },
      ],
    };    
    if(labels.length>0)
      setGraphStatus(true);
  },[dataList])

  const options = {
    scales: {
      y:
        {
          title: {
            display: true,
            text: yLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      x:
        {
          title: {
            display: true,
            text: xLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
          min: 0,
          max: cDate.getHours(),
          stepSize: 1,
        },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Chart",
      },
    },
  };
  // console.log('dataList',dataList);
  // console.log('graphData1',graphData);
  if(graphStatus){
    // console.log('LineChartChlorine-graphData-12',graphData);
    return (
      // <div className="card p-4">
        <Line options={options} data={data} />
      // </div>
    );
  } 
};

LineChartChlorine.propTypes = {
  dataList: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};
export default LineChartChlorine;
