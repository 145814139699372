import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
// import dashboardReducer from '../redux/reducers/dashboardSlice'
// import userReducer from "../redux/reducers/userReducer";
// import siteReducer from "../redux/reducers/siteReducer";
// import basicInfoReducer from "../redux/reducers/basicInfoReducer";
// import newsReducer from "../redux/reducers/newsReducer";
import rootSaga from '../redux/sagas/index';
import combineReducers from '../redux/reducers/index';

const storeConfigure = () => {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()
  
  //mount it on the Store
  // const store = configureStore(rootReducer,applyMiddleware(sagaMiddleware));
  // const store = configureStore({reducer: {userReducer: userReducer,dashboard: dashboardReducer,siteReducer:siteReducer,basicInfoReducer:basicInfoReducer,newsReducer:newsReducer},middleware: [sagaMiddleware]});
  const store = configureStore({reducer: combineReducers,middleware: [sagaMiddleware]});

  // then run the saga
  sagaMiddleware.run(rootSaga);
  return store;
}
export default storeConfigure;
