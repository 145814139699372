import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {addNews,editNews,getNewsById} from '../../redux/actions/newsAction';
import {types} from '../../redux/actions/types';
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import {isEmpty, isLoggedInUser, checkPermission} from '../../utils';
import { useToast } from '@chakra-ui/react';

const AddNews = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newsName, setNewsName] = useState('');
  const [newsDesc, setNewsDesc] = useState('');
  const [newsId, setLocalNewsId] = useState(null); 
  const toast = useToast()
  

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isEdit, setIsEdit] = useState(false);

  // const [inImei, setInImei] = useState(''); 
  // const [isLoading, setIsLoading] = useState(false);
  const newsReducer = useSelector(state => state.newsReducer);
  // const newsReducer = useSelector(state => state.newsReducer);
  const location = useLocation();
  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    chkLogin();
    setPermission();
    if(location){
      if(location.state){
        if(location.state.newsId){
          setLocalNewsId(location.state.newsId);
          fetchNewsDetails();
        }
      }
    }    
  },[])
  useEffect(()=>{
    console.log('AddProject-userReducer-useEffect',newsReducer);    
    if(newsReducer.action){
      if(newsReducer.action===types.ADD_NEWS_REQUEST_SUCCESS){
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if(newsReducer.status==="success"){
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/News');
        }else {
          toast({
        title: newsReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }else if(newsReducer.action===types.EDIT_NEWS_REQUEST_SUCCESS){
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if(newsReducer.status==="success"){
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/News');
        }else {
          toast({
        title: newsReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }else if(newsReducer.action===types.NEWS_BY_ID_REQUEST_SUCCESS){
        // if(newsReducer.activeCheckbox==="activeCheckbox"){
          setNewsName(newsReducer.data.newsTitle);
          setNewsDesc(newsReducer.data.newsDetails);
        //   setInLongitude(newsReducer.data.longitude);
        //   setInLatitude(newsReducer.data.latitude);
          setLocalNewsId(newsReducer.data._id);
          // setIsACtive(activeCheckbox)
          // activeCheckbox
          // setInImei(newsReducer.data.IMEI);
        // }else {
        //   toast({
  //       title: newsReducer.message,
  //       status: 'warning',
  //       duration: 2000,
  //       isClosable: true,
  //       position: "top-right",
  //       positionOptions: {
  //         right: "0",
  //         top: "0",
  //       },
  //   });
  //       // }
      }
    }
  },[newsReducer.action])
  // useEffect(()=>{
  //   // console.log('Dashboard-newsReducer-useEffect',newsReducer);    
  //   if(newsReducer.action){
  //     if(newsReducer.action===types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS){
  //       // console.log('AddNews-newsReducer-useEffect',newsReducer);
  //       // console.log('AddNews-newsReducer-useEffect.data',newsReducer.data);
  //       setNewsName(newsReducer.data.pumpName);
  //       setNewsDesc(newsReducer.data.scheme);
  //       setInLongitude(newsReducer.data.longitude);
  //       setInLatitude(newsReducer.data.latitude);
  //       setInImei(newsReducer.data.IMEI);
  //     }
  //     else if(newsReducer.action===types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE){
  //       toast({
    //     title: newsReducer.message,
    //     status: 'warning',
    //     duration: 2000,
    //     isClosable: true,
    //     position: "top-right",
    //     positionOptions: {
    //       right: "0",
    //       top: "0",
    //     },
    // });
  //     }
  //   }
  // },[newsReducer.action])
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  
  const setPermission=async()=>{
    setIsEdit(await checkPermission('News','edit'));
  }
  const submitNews=()=>{
    console.log('newsName',newsName);
    console.log('newsDesc',newsDesc);
    // console.log('inLatitude',inLatitude);
    // console.log('inLongitude',inLongitude);
    // console.log('inImei',inImei);
    if (isEmpty(newsName)) {
      toast({
        title: 'Please enter news title',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(newsDesc)) {
      toast({
        title: 'Please enter news description',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }
    

    // console.log('loginRequest',loginRequest);
    if(newsId){
      const request={
        "id":newsId,
        "newsTitle":newsName,
        "newsDetails":newsDesc,
        "active":true
      };  
      console.log('AddProject-request',request);
      dispatch(editNews(request));
    }else{
      const request={
        "newsTitle":newsName,
        "newsDetails":newsDesc,
        "active":true
      };
      console.log('AddProject-request',request);
      dispatch(addNews(request));
    }      
    // navigate('/dashboard');
  }
  const handleProjectInput=(e)=>{
    // console.log('e.target.name',e.target.name);
    // console.log('e.target.value',e.target.vwalue);
    if(e.target.name==="txtName"){
        setNewsName(e.target.value);
    }
    if(e.target.name==="txtNewsDesc"){
        setNewsDesc(e.target.value);
    }
    // if(e.target.name==="txtLong"){
    //     setInLongitude(e.target.value);
    // }
    // if(e.target.name==="txtLat"){
    //     setInLatitude(e.target.value);
    // }
  }
  const handleActiveStatus=(e)=>{
    console.log('e.target.name',e.target.name);

  }
  const fetchNewsDetails=()=>{
    console.log('AddNews-fetchNewsDetails-newsId',location.state.newsId);
    dispatch(getNewsById(location.state.newsId));
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="News" />
        <section>      
        <div className="container">        
            <div className="row d-flex justify-content-center pt-4 h-100">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
                <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body pt-5">
                    <div className="row justify-content-center">                  
                    <div className="col-12 order-2 order-lg-1">  
                        <p className="h1 fw-bold mb-4 ">{newsId?"Update News":"Add News"}</p>
                        <form className="mx-1 mx-md-2">
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">News Title</label>
                                <input type="text" name="txtName" id="txtName" value={newsName} onChange={(event) => handleProjectInput(event)} className="form-control" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">News Source Link</label>
                                <input name="txtNewsDesc" id="txtNewsDesc" value={newsDesc} onChange={(event) => handleProjectInput(event)} className="form-control" />
                                {/* <input type="text" name="txtNewsDesc" id="txtNewsDesc" value={newsDesc} onChange={(event) => handleProjectInput(event)} className="form-control" /> */}
                            </div>
                        </div>                        
                        {/* <div className="d-flex flex-row mb-3">
                            <div className="form-outline flex-fill mb-0">
                              <input className="form-check-input me-2" type="checkbox" value="" id="activeCheckbox" onChange={(event)=>handleActiveStatus(event)} />
                              <label className="form-check-label" for="activeCheckbox">Check for make it active</label>
                            </div>
                        </div> */}
                        <div className="d-flex justify-content-center mb-3">
                            {/* <button type="button" className="btn btn-primary btn-lg" disabled={isLoading} onClick={doSignin}>{userReducer.loading?<LoadingSpinner />:"Submit"}</button> */}
                            <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/News"}>Back</Link>
                            {isEdit?<ButtonWithSpinner onClick={()=>submitNews()} isLoading={newsReducer.loading?true:false} label={'Save'} />:null}                         
                        </div>
                        </form>                        
                    </div>                  
                    </div>
                </div>
                </div>
            {/* </div> */}
            </div>
        </div>
        </section>
    </div>
  );
};

export default AddNews;
