import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

const districtList = ({data,handleEditEvent,handleDeleteEvent,isDelete,isEdit}) => {
    return (
      <div class="table-responsive">
        <TableContainer>
          <Table variant='striped' colorScheme='gray'>
            <Thead>
              <Tr>
                {/* <th scope="col">Id</th> */}
                <Th fontSize={'md'}>Name</Th>
                {/*<th scope="col">Code</th>
                <th scope="col">Head(m)</th>
                <th scope="col">Discharge(m3/hr)</th> */}
                <Th fontSize={'md'}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.length>0?
              data.map((item,index)=>(
                <Tr key={index}>
                  {/* <td>{item._id.toString()}</td> */}
                  <Td>{item.name.toString()}</Td>
                  {/* <td>{item.shortCode.toString()}</td> */}
                  {/* <td>{item.SupportAdmin.toString()}</td> */}
                  <Td width={'100px'}><Button colorScheme='blue' size={'sm'} onClick={()=>handleEditEvent(item)}>{isEdit?"Update":"View"}</Button>  {isDelete?<Button colorScheme='red' size={'sm'} onClick={()=>handleDeleteEvent(item)}>Delete</Button>:null}</Td>
                  {/* <button type="button" className="btn btn-outline-danger" onClick={()=>handleDeleteEvent(item)}>Delete</button> */}
                </Tr>)): <Tr><Td colSpan={3}>No Record Found</Td></Tr>
              }
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    )
};
districtList.propTypes = {
    data: PropTypes.array,
    handleEditEvent: PropTypes.func,
    handleDeleteEvent: PropTypes.func,
    isDelete: PropTypes.bool,
    isEdit: PropTypes.bool
};
districtList.defaultProp = {
    data: [],
    handleEditEvent: {},
    handleDeleteEvent: {},
    isDelete: false,
    isEdit: false
};
export default districtList;
