import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import SiteDashboard from "./Pages/SiteDashboard";
// import { useNavigate } from "react-router-dom";
// import ErrorPage from "./Pages/ErrorPage";
import LogIn from "./Pages/LogIn";
import AdminLogIn from "./Adminpanel/LogIn";
// import SignUp from "./Pages/SignUp";
// import {isLoggedInUser} from './utils'
// require('dotenv').config()
import Footer from "./Components/Footer";
import ProjectDashboard from "./Pages/ProjectDashboard";
// import Mqttdata from "./Pages/Mqttdata";
import Report from "./Pages/Report";
import HourlyReport from "./Pages/HourlyReport";
import HourlyReportAdmin from "./Adminpanel/Reports/HourlyReport";
import MqttdataAdmin from "./Adminpanel/Reports/Mqttdata";
import AssetdataAdmin from "./Adminpanel/Reports/Assetdata";
import Pumpactivity from "./Adminpanel/Reports/Pumpactivity";
import ReportAdmin from "./Adminpanel/Reports/Report";
import Sites from "./Adminpanel/Sites/Sites";
import AddSiteDetails from "./Adminpanel/Sites/AddSiteDetails";
import AddRemoveInputField from "./Adminpanel/Sites/addremoveinput";
import Projects from "./Adminpanel/Projects/Projects";
import Users from "./Adminpanel/Users/User";
import Roles from "./Adminpanel/Roles";
import AddRole from "./Adminpanel/Roles/AddRole";
import AssignSites from "./Adminpanel/AssignSites";
import Company from "./Adminpanel/Company/Company";
import News from "./Adminpanel/News/News";
import AddCompany from "./Adminpanel/Company/AddCompanyDetails";
import Addnews from "./Adminpanel/News/Addnews";
import AddUser from "./Adminpanel/Users/AddUser";
import AddProjectDetails from "./Adminpanel/Projects/AddProjectDetails";
import Pump from "./Adminpanel/Modules/Pump/pump";
import Country from "./Adminpanel/Modules/Country/country";
import State from "./Adminpanel/Modules/State/state";
import District from "./Adminpanel/Modules/District/district";
import Block from "./Adminpanel/Modules/Block/block";
import Addblock from "./Adminpanel/Modules/Block/Addblock";
import Adddistrict from "./Adminpanel/Modules/District/Adddistrict";
import Addstate from "./Adminpanel/Modules/State/Addstate";
import Addcountry from "./Adminpanel/Modules/Country/Addcountry";
import AddPump from "./Adminpanel/Modules/Pump/addPump";
import AddMotor from "./Adminpanel/Modules/Pump/addMotor";
import AddFlowmeter from "./Adminpanel/Modules/Pump/addFlowmeter";
import AddPressureMeter from "./Adminpanel/Modules/Pump/addPressureMeter";
import AddMultifunctionmeter from "./Adminpanel/Modules/Pump/addMultifunctionMeter";
import AddPHSensor from "./Adminpanel/Modules/Pump/addPHSensor";
import AddLevelSensor from "./Adminpanel/Modules/Pump/addLevelSensor";
import AddTurbidityMonitor from "./Adminpanel/Modules/Pump/addTurbidityMonitor";
import AddChlorineSensor from "./Adminpanel/Modules/Pump/addChlorineSensor";
import AddActuatorSensor from "./Adminpanel/Modules/Pump/addActuatorSensor";
import Motorlist from "./Adminpanel/Motor/MotorList";
// import Master from "./Adminpanel/Modules/masters/master";
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission, getData, CONSTANTVALUE} from './utils'
import ImeiApprovalPage from './Components/ImeiApprovalPage';
import NotFound from './Pages/404';
import UserAndSiteReports from './Components/UserAndSiteReports';
import AllReports from './Components/AllReports';
import { get5MinsData } from './store/apis';
import { useToast } from '@chakra-ui/react';
import ParameterThresholdLogs from './Components/ParameterThresholdLogs';
import LiveLogs from './Components/LiveLogs';
import TokenManagement from './Components/TokenManagement';



function App() {
  
  const toast = useToast()
  const checkOverflow = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    if(data) {
      let isAdmin = data.EmploymentType == 'Admin';
      console.log("PIIIIIIII", data.companyDetails);
      const p = await get5MinsData(data.companyDetails._id, data.id, isAdmin);
      console.log("PIIIIIII data", p.unsafeData)
      if(p.unsafeData.length > 0) {
        toast({
          title:"Parameters Problem" ,
          description: p.unsafeData[0].message,
          status: 'error',
          position: "top-right",
          duration: 10000,
          isClosable: true,
        })
      }
      }
    }
  setInterval(()=> {
    checkOverflow()
  }, [3 * 60 * 1000])
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LogIn />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/site-dashboard" element={<SiteDashboard />} />
        <Route exact path="/project" element={<ProjectDashboard />} />
        <Route exact path="/Admin/Mqttdata" element={<MqttdataAdmin />} />
        <Route exact path="/Admin/Assetdata" element={<AssetdataAdmin />} /> 
        <Route exact path="/Admin/Pumpactivity" element={<Pumpactivity />} />      
        <Route exact path='/Admin/UserSiteReports' element={<UserAndSiteReports/>} />
        <Route exact path="/Report" element={<Report />} />
        <Route exact path="/ReportAdmin" element={<ReportAdmin />} />        
        <Route exact path="/HourlyReport" element={<HourlyReport />} />
        <Route exact path="/HourlyReportAdmin" element={<HourlyReportAdmin />} />        
        <Route path="/signin" element={<LogIn />} />
        <Route exact path="/Admin/SiteListing" element={<Sites />} />
        <Route exact path="/Admin/AddSite" element={<AddSiteDetails />} />
        <Route exact path="/Admin/ProjectListing" element={<Projects />} />
        <Route exact path="/Admin/Users" element={<Users />} />
        <Route exact path="/Admin/AddUser" element={<AddUser />} />        
        <Route exact path="/Admin/AddProject" element={<AddProjectDetails />} />
        <Route exact path="/Admin/Company" element={<Company />} />
        <Route exact path="/Admin/AddCompany" element={<AddCompany />} />
        <Route exact path="/Admin/News" element={<News />} />
        <Route exact path="/Admin/AddNews" element={<Addnews />} />
        <Route exact path="/Admin" element={<AdminLogIn />} />
        <Route exact path="/Admin/Roles" element={<Roles />} />
        <Route exact path='/Admin/ImeiApproval' element={<ImeiApprovalPage/>} />
        <Route exact path="/Admin/AddRole" element={<AddRole />} /> 
        <Route exact path="/Admin/AssignSites" element={<AssignSites />} />
        <Route exact path="/Admin/AddRemoveInputField" element={<AddRemoveInputField />} />
        <Route exact path="/Admin/Pump" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.PUMP} />} />
        <Route exact path="/Admin/Country" element={<Country />} />
        <Route exact path="/Admin/State" element={<State />} />
        <Route exact path="/Admin/District" element={<District />} />
        <Route exact path="/Admin/Block" element={<Block />} />
        <Route exact path="/Admin/Addblock" element={<Addblock />} />
        <Route exact path="/Admin/Adddistrict" element={<Adddistrict />} />        
        <Route exact path="/Admin/Addstate" element={<Addstate />} />
        <Route exact path="/Admin/Addcountry" element={<Addcountry />} />
        <Route exact path="/Admin/AddPump" element={<AddPump />} />
        <Route exact path="/Admin/AddMotor" element={<AddMotor />} />
        <Route exact path="/Admin/AddFlowmeter" element={<AddFlowmeter />} /> 
        <Route exact path="/Admin/AddPressureMeter" element={<AddPressureMeter />} />
        <Route exact path="/Admin/AddMultifunctionmeter" element={<AddMultifunctionmeter />} />
        <Route exact path="/Admin/AddPHSensor" element={<AddPHSensor />} />
        <Route exact path="/Admin/AddLevelSensor" element={<AddLevelSensor />} /> 
        <Route exact path="/Admin/AddLevelSensor" element={<AddLevelSensor />} />        
        <Route exact path="/Admin/AddTurbidityMonitor" element={<AddTurbidityMonitor />} />
        <Route exact path="/Admin/AddChlorineSensor" element={<AddChlorineSensor />} />  
        <Route exact path="/Admin/AddActuatorSensor" element={<AddActuatorSensor />} />        
        <Route exact path="/Admin/Motor" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.MOTOR} />} />
        <Route exact path="/Admin/Flowmeter" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.FLOWMETER} />} />
        <Route exact path="/Admin/Pressuremeter" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.PRESSUREMETER} />} />
        <Route exact path="/Admin/Multifunctionmeter" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER} />} />
        <Route exact path="/Admin/Phsensor" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.PHSENSOR} />} />
        <Route exact path="/Admin/Levelsensor" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.LEVELSENSOR} />} />
        <Route exact path="/Admin/Turbiditymonitor" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR} />} />
        <Route exact path="/Admin/Chlorinesensor" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR} />} />
        <Route exact path="/Admin/Actuatorsensor" element={<Pump moduleType={CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR} />} /> 
        <Route exact path='/AllReports' element={<AllReports/>} />
        <Route exact path='/ParameterThresholdLogs' element={<ParameterThresholdLogs/>} />
        <Route exact path='/livelogs' element={<LiveLogs/>} />
        <Route exact path='/tokenManagement' element={<TokenManagement/>} />
        
        <Route exact path='*' element={<NotFound/>}/>     
        {/* <Route exact path="/Admin/Pump" element={<Master type={CONSTANTVALUEEXTRAPARAM.PUMP} />} />         */}
        {/* <Route path="/sign-up" element={<SignUp />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
