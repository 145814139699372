import {types} from './types';

export function addProject(payload) {
  return {
    type: types.ADD_PROJECT_REQUEST,
    payload,
  };
}
export function updateProject(payload) {
  return {
    type: types.EDIT_PROJECT_REQUEST,
    payload,
  };
}
export function deleteProject(payload) {
  return {
    type: types.DELETE_PROJECT_REQUEST,
    payload,
  };
}
export function projectList(payload) {
  return {
    type: types.LIST_PROJECT_REQUEST,
    payload,
  };
}
export function getProjectById(payload) {
  return {
    type: types.PROJECT_BY_ID_REQUEST,
    payload,
  };
}