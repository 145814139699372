import React, { useEffect, useState } from 'react';
import NavBarAdmin from './NavBarAdmin';
import AdminMenu from './AdminMenu';
import { getCompleteAssignedSitesByUserIdAPI, getParameterLogsByCompanyId, getSiteListWithProjectsAPI } from '../store/apis';
import { Table, Tbody, Th, Thead, Tr, Td, Link, Button, IconButton, Box, FormControl, Select, filter } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    useDisclosure,
    Code,
    Flex,
} from '@chakra-ui/react'
import { CONSTANTVALUE, getData } from '../utils';
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';


const ParameterThresholdLogs = () => {
    useEffect(() => {
        getAllData();
        fetch();
    }, []);

    const [isModalOpen, setModalOpen] = useState(false);
    const [allData, setAllData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [uniqueSitesArray, setUniqueSites] = useState([]);
    const [filterSiteName, setFilterSiteName] = useState('');

    const [assignedSites, setAssignedSites] = useState([]);
    const [projects, setProjects] = useState();
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedSites, setSelectedSites] = useState();
    const [currItem, setCurrItem] = useState(null)
    const [reportDate, setReportDate] = useState(new Date().toISOString().slice(0, 10));

    const getAllData = async () => {
        const userData = await getData(CONSTANTVALUE.USEROBJECT);
        console.log("ISEEEE", userData)
        const data = await getParameterLogsByCompanyId(userData.companyDetails._id, reportDate, userData.id, userData.EmploymentType);
        let uniqueSiteNames = new Set();

        console.log("Dataaa", data)
        if (data && data.allData && data.allData.length > 0) {
            data.allData.forEach(entry => {
                console.log("UNIQUE", entry)
                if (entry.siteName) {
                    uniqueSiteNames.add(entry.siteName);
                }
            });


            setUniqueSites(Array.from(uniqueSiteNames))
        }
        if (data) setAllData(data.allData);
    }
    const fetch = async () => {

        const data = await getData(CONSTANTVALUE.USEROBJECT);
        const info = await getSiteListWithProjectsAPI(data.id)
        const filteredProjects = info.message.filter((item) => item.project && item.sites.length > 0);
        setProjects(filteredProjects);
        const sites = await getCompleteAssignedSitesByUserIdAPI(data.id);
        setAssignedSites(sites.message.map((item) => item.result));

    }
    const handleSiteChange = (event) => {

        const selectedSiteName = event.target.options[event.target.selectedIndex].text;
        setFilterSiteName(selectedSiteName)
        console.log('Selected Site:', selectedSiteName);
    };



    useEffect(() => {

        if (selectedProject !== 'ALL') {
            const selectedProjectData = projects?.find((item) => item.project._id === selectedProject);
            setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
        } else {

            setSelectedSites([]);
        }
    }, [selectedProject]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(allData.length / pageSize);

        return (
            <ul className="pagination">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                    <li
                        key={pageNumber}
                        className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                        onClick={() => handlePageChange(pageNumber)}
                    >
                        <button className="page-link">{pageNumber}</button>
                    </li>
                ))}
            </ul>
        );
    };

    const renderTableRows = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;

        let currentRecords = allData.slice(startIndex, endIndex);
        if (filterSiteName) currentRecords = currentRecords.filter((item) => filterSiteName == item.siteName);
        console.log("DAATAAAA",allData);
        return <>
            {currentRecords && currentRecords.length > 0 ? (
                currentRecords.map((item) => (
                    <Tr key={item.id}>
                        <Td>{convertUtcTimeToIstTime(item.createdAt, 0)}</Td>
                        <Td>{convertUtcTimeToIstTime(item.createdAt, 1)}</Td>
                        <Td>{item.siteName}</Td>
                        <Td>{item.IMEI}</Td>
                        <Td>
                            <Button colorScheme='blue' onClick={() => { setModalOpen(true); setCurrItem(item) }}>
                                View
                            </Button>
                        </Td>
                    </Tr>
                ))
            ) : (
                <Tr>
                    <Td colSpan="5" textAlign="center">No data found</Td>
                </Tr>
            )}

        </>
    }
    const handleChangeEvent = (e) => {
        if (e.target.name === "inputReportDate") {
            setReportDate(e.target.value);
        }

    }
    const convertUtcTimeToIstTime = (dateTimeStr, i) => {

        return new Date(dateTimeStr).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }).split(',')[i]
    }
    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };
    const renderCurrItem = () => {
        const tableData = [
            { rowName: 'P1', range: currItem.safeRange.p1, value: currItem.paramtersObject.P1 },
            { rowName: 'P2', range: currItem.safeRange.p2, value: currItem.paramtersObject.P2 },
            { rowName: 'P3', range: currItem.safeRange.p3, value: currItem.paramtersObject.P3 },
            { rowName: 'P4', range: currItem.safeRange.p4, value: currItem.paramtersObject.P4 },
            { rowName: 'P5', range: currItem.safeRange.p5, value: currItem.paramtersObject.P5 },
            { rowName: 'P6', range: currItem.safeRange.p6, value: currItem.paramtersObject.P6 },
            { rowName: 'P7', range: currItem.safeRange.p7, value: currItem.paramtersObject.P7 },
            { rowName: 'P8', range: currItem.safeRange.p8, value: currItem.paramtersObject.P8 },
            { rowName: 'P9', range: currItem.safeRange.p9, value: currItem.paramtersObject.P9 },
            { rowName: 'P10', range: currItem.safeRange.p10, value: currItem.paramtersObject.P10 },
            { rowName: 'P11', range: currItem.safeRange.p11, value: currItem.paramtersObject.P11 },
        ];
        return (
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Parameter</Th>
                        <Th>Range</Th>
                        <Th>Value</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData.map((item, index) => (
                        item.range !== "0-0" ? (
                            <Tr textColor={item.value < item.range.split('-')[0] || item.value > item.range.split('-')[1] ? 'red' : 'black'} key={index}>
                                <Td>{item.rowName}</Td>
                                <Td>{item.range}</Td>
                                <Td>{item.value}</Td>
                            </Tr>
                        ) : null
                    ))}
                </Tbody>

            </Table>
        );
    }
    return (
        <>
            <div className='page'>
                {isModalOpen && currItem &&
                    <Modal blockScrollOnMount={false} isOpen={isModalOpen} onClose={() => { setModalOpen(false) }}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader size={'sm'}>
                                {currItem.siteName} {convertUtcTimeToIstTime(currItem.createdAt, 0)} {convertUtcTimeToIstTime(currItem.createdAt, 1)}
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {renderCurrItem()}
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                }
                {console.log("CURRRRRRR", currItem)}
                {console.log("UUUUUU", filterSiteName)}
                <NavBarAdmin />
                <AdminMenu selMenu="Parameter Threshold Logs" />
                <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
                    <div className="row m-0">
                        <div className="col-12 py-4">
                            <div className="sub-head">Parameter Threshold</div>
                            <h3 className="project-title fw-bold">Parameter Threshold Logs</h3>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row d-flex justify-content-center h-100 pt-5">
                            <div className="card text-black" style={{ borderRadius: '10px' }}>
                                <div className="card-body">
                                    <Box mb={5}>
                                        <Box >
                                            Page : {currentPage}
                                        </Box>
                                        <Box mb={5} mt={3}>
                                            <Flex flexWrap="wrap" justifyContent="end" alignItems="center">
                                                {/* Page Size Input */}
                                                <Box width={["100%", "40%"]} pr={[0, 3]} mb={[3, 0]}>
                                                    <FormControl>
                                                        <Select
                                                            id="pageSizeSelect"
                                                            className="form-select"
                                                            value={pageSize}
                                                            onChange={handlePageSizeChange}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={30}>30</option>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                
                                                <Flex >
                                                <Select
                                                    id="pageSizeSelect"
                                                    value={selectedProject}
                                                    variant={'filled'}
                                                    onChange={(e) => setSelectedProject(e.target.value)}
                                                    color="black"
                                                >
                                                    <option value={'ALL'}>Select Project</option>
                                                    {projects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                                                        <option key={item.project._id} value={item.project._id}>
                                                            {item.project.ProjectName}
                                                        </option>
                                                    ))}
                                                </Select>
                                                    {selectedProject !== 'ALL' && (
                                                        <Select
                                                            // w={'15%'}
                                                            variant={'filled'}
                                                            ml={2}
                                                            // value={site}
                                                            onChange={(e) => handleSiteChange(e)}

                                                            color="black"
                                                        >
                                                            <option value={'ALL'}>Select Site</option>
                                                            {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                                                                <option key={site._id} value={site._id} imei={site.IMEI}>
                                                                    {site.pumpName}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    )}
                                                </Flex>
                                                <div style={{ display: 'flex', height: "48px", gap: '6px' }}
                                                ><input style={{ marginLeft: '2%', marginBottom: "3%" }} type="date" class="form-control" name="inputReportDate" value={reportDate} onChange={(e) => handleChangeEvent(e)} />
                                                    <IconButton
                                                        colorScheme='blue'
                                                        aria-label='Search database'
                                                        icon={<SearchIcon />}
                                                        size='md'

                                                        onClick={() => getAllData()}
                                                    />
                                                </div>
                                            </Flex>
                                        </Box>
                                    </Box>


                                </div>
                                <Table variant='striped' colorScheme='gray' size='md'>
                                    <Thead>
                                        <Tr>
                                            <Th>Date</Th>
                                            <Th>Time</Th>
                                            <Th>Site Name</Th>
                                            <Th>IMEI</Th>
                                            <Th>View</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>{renderTableRows()}</Tbody>

                                </Table>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                {renderPagination()}
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </>
    )
}

export default ParameterThresholdLogs;
