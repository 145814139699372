import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddMotor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [motorType, setMotorType] = useState('');
  const [motorKW, setMotorKW] = useState('');
  const [motorRPM, setMotorRPM] = useState('');  
  const [motorCoupling, setMotorCoupling] = useState('');
  const [motorOutDiameter, setMotorOutDiameter] = useState('');
  const [motorMake, setMotorMake] = useState('');
  const [motorOvrCurr, setMotorOvrCurr] = useState('');
  const [motorUndVol, setMotorUndVol] = useState('');
  const [motorOvrVol, setMotorOvrVol] = useState('');
  const [motorUndCurr, setMotorUndCurr] = useState('');
  const [motorSerialNo, setMotorSerialNo] = useState('');
  const [localMotorId, setLocalMotorId] = useState(null); 
  const [currentField, setCurrentField] = useState('sitedetails');
  const toast = useToast()

  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          //console.log('Motor-item',location.state.item);
          setMotorType(location.state.item.motorType);
          setMotorKW(location.state.item.motorKW);
          setMotorRPM(location.state.item.motorRPM);
          setMotorCoupling(location.state.item.motorCoupling);
          setMotorOutDiameter(location.state.item.motorOutDiameter);
          setMotorMake(location.state.item.motorMake);
          setMotorOvrCurr(location.state.item.motorOvrCurr);
          setMotorUndVol(location.state.item.motorUndVol);
          setMotorOvrVol(location.state.item.motorOvrVol);
          setMotorUndCurr(location.state.item.motorUndCurr);
          setLocalMotorId(location.state.item._id)
        }
      }
    }    
  },[])  
  useEffect(()=>{
    // console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          dispatch(clearSiteReducer());
          navigate('/Admin/Motor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.MOTOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Motor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.MOTOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])

  const handleSiteInput=(e)=>{
    //////////////// Motor Popup ////////////////////////////////////
    if(e.target.name==="txtMotorType"){
      setMotorType(e.target.value);
    }       
    if(e.target.name==="txtMotorKW"){
      setMotorKW(e.target.value);
    }
    if(e.target.name==="txtMotorRPM"){
      setMotorRPM(e.target.value);
    } 
    if(e.target.name==="txtMotorCoupling"){
      setMotorCoupling(e.target.value);
    }
    if(e.target.name==="txtMotorOutDiameter"){
      setMotorOutDiameter(e.target.value);
    }
    if(e.target.name==="txtMotorMake"){
      setMotorMake(e.target.value);
    }
    if(e.target.name==="txtMotorOvrCurr"){
      setMotorOvrCurr(e.target.value);
    }
    if(e.target.name==="txtMotorUndVol"){
      setMotorUndVol(e.target.value);
    }
    if(e.target.name==="txtMotorOvrVol"){
      setMotorOvrVol(e.target.value);
    }
    if(e.target.name==="txtMotorUndCurr"){
      setMotorUndCurr(e.target.value);
    }
    if(e.target.name==="txtMotorSerialNo"){
      setMotorSerialNo(e.target.value);
    }     
    ////////////////////////////////////////////////////////////////
  }

  const handleMotorSubmit=()=>{
    if(isEmpty(motorType)){
      toast({
        title: 'please enter motor type',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorKW)){
      toast({
        title: 'please enter motor kW',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorRPM)){
      toast({
        title: 'please enter RPM',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorCoupling)){
      toast({
        title: 'please enter motor coupling',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorOutDiameter)){
      toast({
        title: 'please enter outer diameter',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorMake)){
      toast({
        title: 'please enter motor make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorOvrCurr)){
      toast({
        title: 'please enter motor overload current(A)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorUndVol)){
      toast({
        title: 'please enter motor under voltage(V)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorOvrVol)){
      toast({
        title: 'please enter motor over voltage(V)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(motorUndCurr)){
      toast({
        title: 'please enter motor under current(A)',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localMotorId){
        const request={
          "id": localMotorId,
          "type": CONSTANTVALUEEXTRAPARAM.MOTOR,
          "motorType":motorType,
          "motorKW":motorKW,
          "motorRPM":motorRPM,
          "motorCoupling":motorCoupling,
          "motorOutDiameter":motorOutDiameter,
          "motorMake":motorMake,
          "motorOvrCurr":motorOvrCurr,
          "motorUndVol":motorUndVol,
          "motorOvrVol":motorOvrVol,
          "motorUndCurr":motorUndCurr,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.MOTOR);
        dispatch(updateSiteExtraParameter(request));        
      }
      else{
        const request={
          "type": CONSTANTVALUEEXTRAPARAM.MOTOR,
          "motorType":motorType,
          "motorKW":motorKW,
          "motorRPM":motorRPM,
          "motorCoupling":motorCoupling,
          "motorOutDiameter":motorOutDiameter,
          "motorMake":motorMake,
          "motorOvrCurr":motorOvrCurr,
          "motorUndVol":motorUndVol,
          "motorOvrVol":motorOvrVol,
          "motorUndCurr":motorUndCurr,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.MOTOR);
        dispatch(siteExtraParameter(request));
        // handleMotorShow();
      }
    }    
  } 
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddMotor" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localMotorId?"Update Motor":"Add Motor"}</p>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorType" className="col-sm-4 col-form-label">Motor Type</label>
                        <input type="text" name="txtMotorType" id="txtMotorType" value={motorType} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorKW" className="col-sm-4 col-form-label">Motor KW</label>
                        <input type="text" name="txtMotorKW" id="txtMotorKW" value={motorKW} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorRPM" className="col-sm-4 col-form-label">Motor RPM</label>
                        <input type="text" name="txtMotorRPM" id="txtMotorRPM" value={motorRPM} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorCoupling" className="col-sm-4 col-form-label">Coupling :Regular or Nema</label>
                        <input type="text" name="txtMotorCoupling" id="txtMotorCoupling" value={motorCoupling} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorOutDiameter" className="col-sm-4 col-form-label">Outer Diameter (in inch)</label>
                        <input type="text" name="txtMotorOutDiameter" id="txtMotorOutDiameter" value={motorOutDiameter} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorMake" className="col-sm-4 col-form-label">Motor Make</label>
                        <input type="text" name="txtMotorMake" id="txtMotorMake" value={motorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorOvrCurr" className="col-sm-4 col-form-label">Overload Current (A)</label>
                        <input type="text" name="txtMotorOvrCurr" id="txtMotorOvrCurr" value={motorOvrCurr} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorUndVol" className="col-sm-4 col-form-label">Under Voltage (V)</label>
                        <input type="text" name="txtMotorUndVol" id="txtMotorUndVol" value={motorUndVol} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorOvrVol" className="col-sm-4 col-form-label">Over Voltage (V)</label>
                        <input type="text" name="txtMotorOvrVol" id="txtMotorOvrVol" value={motorOvrVol} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="motorUndCurr" className="col-sm-4 col-form-label">Under Current(A)</label>
                        <input type="text" name="txtMotorUndCurr" id="txtMotorUndCurr" value={motorUndCurr} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                      <Button variant="primary" onClick={()=>handleMotorSubmit()}>Submit</Button>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddMotor;
