import React from 'react';
import NavBarAdmin from './NavBarAdmin';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Flex, useStatStyles } from '@chakra-ui/react';
import HourlyReport from '../Pages/HourlyReport';
import Report from '../Pages/Report';
import Mqttdata from './MqttdataComponent';
import Assetdata from '../Adminpanel/Reports/Assetdata';
import UserAndSiteReports from './UserAndSiteReports';
import Pumpactivity from '../Adminpanel/Reports/Pumpactivity';
import { useEffect } from 'react';
import { getAssignedSiteAction } from '../redux/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { siteList } from '../redux/actions/siteAdminAction';
import { getSiteList } from '../redux/actions/siteAction';
import { getCompleteAssignedSitesByUserIdAPI, getSiteListWithProjectsAPI } from '../store/apis';
import { CONSTANTVALUE, getData } from '../utils';
import { useState } from 'react';


const AllReports = () => {

 
  useEffect(()=> {
    fetch();
  }, [])
  
  const [projects, setProjects] = useState([]);
  const [assignedSites, setAssignedSites] = useState([]);


  const fetch = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    const info = await getSiteListWithProjectsAPI(data.id)
    const filteredProjects = info.message.filter((item) => item.project && item.sites.length > 0);
    setProjects(filteredProjects);
    const sites = await getCompleteAssignedSitesByUserIdAPI(data.id);
    setAssignedSites(sites.message.map((item)=> item.result));
  }
  useEffect(()=> {  
    console.log("TISS", assignedSites)
    console.log("TISS", projects)
  }, [assignedSites],[projects])

  return (
    <div style={{display : 'flex', flexDirection : 'column'}}>
      <div>
        <NavBarAdmin />
      </div>
      <div style={{marginTop : '120px'}}>
      <Tabs variant='enclosed'>
        <TabList>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Hourly Reports</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Daily  Reports</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>MQTT Reports</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Asset Reports</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>User Site Reports</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>DG CONSUMPTION Reports</Tab>  
        </TabList>
        <TabPanels>
          <TabPanel>
            <HourlyReport noHeaders = {true} projectList ={projects} assignedSites = {assignedSites}/>
          </TabPanel>
          <TabPanel>
            <Report noHeaders = {true} projectList ={projects} assignedSites = {assignedSites}/>
          </TabPanel>
        <TabPanel>
            <Mqttdata noHeaders={true} projectList ={projects} newAssignedSites = {assignedSites}/>
          </TabPanel>
          <TabPanel >
            <Assetdata noHeaders={true} projectList ={projects} newAssignedSites = {assignedSites}/>
          </TabPanel>
          <TabPanel>
            <UserAndSiteReports noHeaders = {true}/>
          </TabPanel>
          <TabPanel>
            <Pumpactivity noHeaders = {true} projectList ={projects} newAssignedSites = {assignedSites}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </div>
    </div>
  );
};

export default AllReports;
