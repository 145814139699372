import React, { useEffect, useState } from 'react';
// import angleLeft from "../../assets/images/down-arrow.svg";
// import { leftCanvasToggle } from "../../redux/reducers/dashboardSlice";
import { Link } from "react-router-dom";
import "./offCanvas/offCanvas.css";
import CanvasItem from "./offCanvas/CanvasItem";
import {getBasicInfo} from '../redux/actions/basicInfoAction'
import {types} from '../redux/actions/types';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from 'react-redux';
import { useToast } from '@chakra-ui/react';

const BasicInfoSite = ({projectId}) => {
  const dispatch = useDispatch();
  const toast = useToast()

  const basicInfoReducer = useSelector(state => state.basicInfoReducer);
  const [basicInfo, setBasicInfo] = useState([]);
  // const { isLeftCanvas } = useSelector((state) => state.dashboard);
  const navRef = React.useRef(null);
  useEffect(()=>{
    fetchBasicInfo();
  },[projectId])
  useEffect(()=>{
    console.log('BasicInfo-basicInfoReducer-useEffect',basicInfoReducer);    
    if(basicInfoReducer.action){
      if(basicInfoReducer.action===types.GET_BASIC_INFO_REQUEST_SUCCESS){
        // console.log('OffCanvasLeft-basicInfoReducer-useEffect',basicInfoReducer)        
        if(basicInfoReducer.status==="success"){
          setBasicInfo(basicInfoReducer.data);
          // console.log('OffCanvasLeft-basicInfo-useEffect',basicInfo); 
        }else {
          toast({
            title: basicInfoReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }
      else if(basicInfoReducer.action===types.GET_BASIC_INFO_REQUEST_FAILURE){
        // console.log('BasicInfo-basicInfoReducer-useEffect',basicInfoReducer); 
        // alert(basicInfoReducer.message);
      }
    }
  },[basicInfoReducer.action])
  const fetchBasicInfo=()=>{
    if(projectId)
        dispatch(getBasicInfo("projectId="+projectId));
    else
        dispatch(getBasicInfo());    
  }
  const totalNoProjects=()=>{
    if(basicInfo){
      if(basicInfo.totalProjects){
        return(<CanvasItem label={'The number of sites operation in total'} value={basicInfo.totalProjects.toString()} />)
      }
    }    
  }
  const totalFlowSuppByProject=()=>{
    if(basicInfo){
      if(basicInfo.totalFlowSuppByProject){
        return(<CanvasItem label={'Total flow supplied by projects'} value={basicInfo.totalFlowSuppByProject.toString()} />)
      }
    }    
  }
  const totalFHTCConnections=()=>{
    if(basicInfo){
      if(basicInfo.totalFHTCConnections){
        return(<CanvasItem label={'Total FHTC Connections'} value={basicInfo.totalFHTCConnections.toString()} />)
      }
    }    
  }
  const tubewellsCount=()=>{
    if(basicInfo){
      if(basicInfo.tubewellsCount){
        return(<CanvasItem label={'Tubewells count'} value={basicInfo.tubewellsCount.toString()} />)
      }
    }    
  }  
  const surfaceWaterSchemeCount=()=>{
    if(basicInfo){
      if(basicInfo.surfaceWaterSchemeCount){
        return(<CanvasItem label={'Surface Water Scheme count'} value={basicInfo.surfaceWaterSchemeCount.toString()} />)
      }
    }
  }  
  const totalGroundWaterSchemes=()=>{
    if(basicInfo){
      // console.log('basicInfo',basicInfo);
      if(basicInfo.totalGroundWaterSchemes){
        // console.log('basicInfo.totalGroundWaterSchemes',basicInfo.totalGroundWaterSchemes);
        return(<CanvasItem label={'Total Ground Water schemes'} value={basicInfo.totalGroundWaterSchemes.toString()} />);
      }
    }    
  }
  const totalSurfaceWaterSchemes=()=>{
    if(basicInfo){
      if(basicInfo.totalNoOfSurfaceWate){
        return(<CanvasItem label={'Total no. of surface water schemes'} value={basicInfo.totalNoOfSurfaceWate.toString()} />);
      }
    }    
  }
  const quantityConsPerHouse=()=>{
    if(basicInfo){
      if(basicInfo.quantityPerHouse){
        return(<CanvasItem label={'Quantity cons. per house'} value={basicInfo.quantityPerHouse.toString()} />);
      }
    }   
  }
  const averageTimeDuration=()=>{
    if(basicInfo){
      if(basicInfo.averageTimeDuration){
        return(<CanvasItem label={'Time dur. of water supplied(average)'} value={parseFloat(basicInfo.averageTimeDuration).toFixed(2).toString()} />);
      }
    }   
  }
  const totalPerDayWaterSupply=()=>{
    if(basicInfo){
      if(basicInfo.totalPerDayWaterSupply){
        return(<CanvasItem label={'Total water supplied per day.'} value={basicInfo.totalPerDayWaterSupply+" KL"} />);
      }
    }   
  }
  return (
    <div className="panel_content">
        <h2>Basic Information</h2>
        <ul className="basicinfo-list scroll">
        {/* <CanvasItem label={'Surface Water Scheme count'} value={'100'} /> */}
        {totalNoProjects()}
        {totalFlowSuppByProject()}
        {totalFHTCConnections()}
        {tubewellsCount()}
        {surfaceWaterSchemeCount()}
        {totalGroundWaterSchemes()}
        {totalSurfaceWaterSchemes()}
        {totalPerDayWaterSupply()}
        {/* {quantityConsPerHouse()} */}
        {averageTimeDuration()}
        </ul>
        {!projectId?<Link
            className="more-info"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop">
            More Info
        </Link>:null}
    </div>
  );
};

BasicInfoSite.propTypes = {
    projectId: PropTypes.string,
};
BasicInfoSite.defaultProp = {
    projectId: null,
};
export default BasicInfoSite;
