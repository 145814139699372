import React from "react";
import "../styles/spinner.css";

export default function LoadingSpinner() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
        zIndex: 9999, // Ensure it appears above other elements
      }}
    >
      <div
        style={{
          border: '4px solid rgba(255, 255, 255, 0.3)', // White semi-transparent border
          borderTop: '4px solid #ffffff', // White spinner
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          animation: 'spin 1s linear infinite', // Animation to rotate the spinner
        }}
      />
    </div>
  );
}
