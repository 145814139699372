import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddPressureMeter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [pressureMeterMake,setPressureMeterMake] = useState('');
  const [pressureMeterModel,setPressureMeterModel] = useState('');
  const [pressureMeterMaxValue,setPressureMeterMaxValue] = useState('');
  const [pressureMeterSerialNo,setPressureMeterSerialNo] = useState('');
  const [localPressureMeterId, setLocalPressureMeterId] = useState(null);

  const toast = useToast()
  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          //console.log('Pressure Meter-item',location.state.item);
          setPressureMeterMake(location.state.item.pressureMeterMake);
          setPressureMeterModel(location.state.item.pressureMeterModel);
          setPressureMeterMaxValue(location.state.item.pressureMeterMaxValue);
          setLocalPressureMeterId(location.state.item._id)
        }
      }
    }    
  },[])  
  
  useEffect(()=>{
    // console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          dispatch(clearSiteReducer());
          navigate('/Admin/Pressuremeter', {
            moduleType: CONSTANTVALUEEXTRAPARAM.PRESSUREMETER
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Pressuremeter', {
            moduleType: CONSTANTVALUEEXTRAPARAM.PRESSUREMETER
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])
  const handleSiteInput=(e)=>{
    //////////////// Pressure Meter Popup ////////////////////////////////////
    if(e.target.name==="txtPressureMeterMake"){
      setPressureMeterMake(e.target.value);
    }
    if(e.target.name==="txtPressureMeterModel"){
      setPressureMeterModel(e.target.value);
    }
    if(e.target.name==="txtPressureMeterMaxValue"){
      setPressureMeterMaxValue(e.target.value);
    }
    if(e.target.name==="txtPressureMeterSerialNo"){
      setPressureMeterSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }
  const handlePressureMeterSubmit=()=>{
    if(isEmpty(pressureMeterMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pressureMeterModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pressureMeterMaxValue)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localPressureMeterId){
        const request={
          "id":localPressureMeterId,
          "type": CONSTANTVALUEEXTRAPARAM.PRESSUREMETER,
          "pressureMeterMake":pressureMeterMake,
          "pressureMeterModel":pressureMeterModel,
          "pressureMeterMaxValue":pressureMeterMaxValue,
        };
        // setCurrentField('pressuremeter');
        dispatch(updateSiteExtraParameter(request));
        // handlePressureMeterShow();
      }
      else{
        const request={
          "type": CONSTANTVALUEEXTRAPARAM.PRESSUREMETER,
          "pressureMeterMake":pressureMeterMake,
          "pressureMeterModel":pressureMeterModel,
          "pressureMeterMaxValue":pressureMeterMaxValue,
        };
        // setCurrentField('pressuremeter');
        dispatch(siteExtraParameter(request));
        // handlePressureMeterShow();
      }
    }    
  } 
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddPressureMeter" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localPressureMeterId?"Update Pressure Meter":"Add Pressure Meter"}</p>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="pressureMeterMake" className="col-sm-4 col-form-label">Make</label>
                        <input type="text" name="txtPressureMeterMake" id="txtPressureMeterMake" value={pressureMeterMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="pressureMeterModel" className="col-sm-4 col-form-label">Model</label>
                        <input type="text" name="txtPressureMeterModel" id="txtPressureMeterModel" value={pressureMeterModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>                          
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="pressureMeterMaxValue" className="col-sm-4 col-form-label">Max Flow</label>
                        <input type="text" name="txtPressureMeterMaxValue" id="txtPressureMeterMaxValue" value={pressureMeterMaxValue} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>                       
                    <div className="d-flex justify-content-center mb-3">
                      <Button variant="primary" onClick={()=>handlePressureMeterSubmit()}>Submit</Button>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddPressureMeter;
