import React from 'react';
import PropTypes from "prop-types";

const Block = ({label,value}) => {
    return (<div className="col-12 col-md-6 col-xl-3">
    <div className="box-content">
        <h3 className="box-title text-black">{label}</h3>
        <p className="site-name text-blue">{value}</p>
        </div>
    </div>)
};

Block.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
};
Block.defaultProp = {
    label: null,
    value: null,
};
export default Block;
