import React from 'react';
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';

const MyTimeline = ({ pumpActivityList, reportDate }) => {
  const groups = pumpActivityList.map((item, index) => ({
    id: index + 1,
    title: `${moment(item.startTime).format('HH:mm')} - ${moment(item.endTime).format('HH:mm')}`
  }));

  const timelineItems = pumpActivityList.map((item, index) => ({
    id: index + 1,
    group: index + 1,
    title: `${item.totalProduction}`,
    color: 'rgb(0,0,0)',
    start_time: moment(item.startTime),
    end_time: moment(item.endTime),
  }));

  return (
    <div>
      <h2>DG CONSUMPTION REPORT Timeline</h2>
      <Timeline
        groups={groups}
        items={timelineItems}
        defaultTimeStart={moment(reportDate).startOf('day')}
        defaultTimeEnd={moment(reportDate).endOf('day')}
      />
    </div>
  );
};

export default MyTimeline;
