import React, { useEffect, useState, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from 'prop-types';
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const cDate = new Date();
// export const options = {
//   scales: {
//     y:
//       {
//         min: 0,
//         max: 100,
//         stepSize: 10,
//       },
//     x:
//       {
//         min: 0,
//         max: cDate.getHours(),
//         stepSize: 1,
//       },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Line Chart",
//     },
//   },
// };

// const labels = ["January", "February", "March", "April", "May", "June", "July"];
// const counts = [-100, 2, 3, 4, 5, 6, 7, 8, 9, 1000];
// export const data1 = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: labels.map(() => counts.map((count) => count)),
//       //   faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Dataset 2",
//       data: labels.map(() => counts.map((count) => count)),
//       //   faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };
let data=null;
const LineChartOHRS = ({dataList,xLabel,yLabel}) => {
  const chart = useRef();
  const [graphStatus, setGraphStatus] = useState(false);
  useEffect(()=>{
    // console.log("LineChartOHRS-test",dataList);
    let labels=[];
    let countArray=[];
    dataList.map((item,index)=>{
      labels.push(item.x);
      countArray.push(item.y);
    })
    labels.map(() => countArray.map((count) => {
      return count
    }))
    data = {
      labels,
      datasets: [
        {
          label: "OHSR",
          data: countArray.map((count) => count != null ? parseFloat(count.toFixed(5)) : 0),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        }
      ],
    };
    if(labels.length>0)
      setGraphStatus(true);
  },[dataList])

  const options = {
    scales: {
      y:
        {
          title: {
            display: true,
            text: yLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
          min: 0,
          max: 100,
          stepSize: 10,
        },
      x:
        {
          title: {
            display: true,
            text: xLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
          min: 0,
          max: cDate.getHours(),
          stepSize: 1,
        },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Chart",
      },
    },
  };

  if(graphStatus){
    // console.log('LineChartOHRS-graphData-12',graphData);
    return (<Line ref={chart} options={options} data={data} />);
  } 
};

LineChartOHRS.propTypes = {
  dataList: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};
export default LineChartOHRS;
