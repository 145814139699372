import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import {MdLabelImportant} from 'react-icons/md'
import {Box} from '@chakra-ui/react'


const SensorValue = ({data}) => {
  // console.log('data',data);
  if(data.length>0){
    return (    
      <div className="row">
        <div className="col mb-4">
          <div className="card">
            <div className="card-body p-0">
              <div className="row py-3 py-md-0">
                  <div className="col-12 col-md-12 col-xl-12">
                    <div className="row">
                      {data.map((item,index)=>(<div key={index} className="col-12 col-md-6 col-xl-2">
                        <div className="box-content border-start">
                          {/* <h3 className="box-title text-black"><img src={item.imgIc} className="icon-image" alt={item.value} />{item.label}</h3> */}
                          <h4 class="card-header-title mb-2 mb-sm-0" style={{height: '65%'}}><i class="icon-map"><img src={item.imgIc} alt={item.value} style={{height: '40px', width: '40px'}}/></i>{item.label}</h4>
                          <p className="site-section-name " style={{height: "35%", color: '#3182CE'}}>{item.value}</p>
                        </div>
                      </div>))}                        
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }  

};
SensorValue.propTypes = {
    data: PropTypes.array,
};
SensorValue.defaultProp = {
    data: [],
};
export default SensorValue;
/*

 if(data.length>0){
    return (    
      <div className="row">
        <div className="col mb-4">
          <div className="card">
            <div className="card-body p-0">
              <div className="row py-3 py-md-0">
                  <div className="col-12 col-md-12 col-xl-12">
                    <div className="row">
                      {data.map((item,index)=>(<div key={index} className="col-12 col-md-6 col-xl-2" >
                        {console.log('xxxxxx======+>', item)}
                        <div className="box-content border-start" >
                          <div style={{ width: '100%', height :'60%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: '15px'}}>

                          <h4 class="card-header-title mb-2 mb-sm-0" style={{whiteSpace: 'nowrap'}}><i class="icon-map"><img src={item.imgIc} alt={item.value} height='40px' width='40px'/></i>{item.label}</h4>
                          </div>
                          <div style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', alignContent: 'center', marginTop: '15px', marginBottom: '25px'}}>
                          <hr style={{width: '30%'}}/>
                          <p style={{width: '60%', whiteSpace: 'nowrap', color:'#2F80ED', fontSize: '1.3em', fontWeight: 'bolder'}}>{item.value}</p>
                          </div>
                        </div>
                      </div>))}                        
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }  
*/