import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {getAssetReportAPI,getSiteParameterListAPI,getPumpActivityAPI} from '../../store/apis';

function* getAssetReport({payload, error}) {
  try {
    //console.log('getSiteExtraParam',payload);
    const responseObject = yield call(getAssetReportAPI,payload);   
    console.log('responseObject',responseObject); 
    if(responseObject.status=200)
      yield put({type: types.GET_ASSET_REPORT_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_ASSET_REPORT_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_ASSET_REPORT_FAILURE, payload: err});
    // console.log(err);
  }
}

function* getSiteParameterListReport({payload, error}) {
  try {
    //console.log('getSiteExtraParam',payload);
    const responseObject = yield call(getSiteParameterListAPI,payload);   
    console.log('responseObject',responseObject); 
    if(responseObject.status=200)
      yield put({type: types.GET_SITE_PARAMETER_REPORT_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_SITE_PARAMETER_REPORT_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_SITE_PARAMETER_REPORT_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getPumpActivityReport({payload, error}) {
  try {
    //console.log('getSiteExtraParam',payload);
    const responseObject = yield call(getPumpActivityAPI,payload);   
    // console.log('responseObject',responseObject); 
    console.log('responseObject',responseObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_PUMP_ACTIVITY_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_PUMP_ACTIVITY_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_PUMP_ACTIVITY_FAILURE, payload: err});
    // console.log(err);
  }
}

export default function* siteAdminSaga() {
  yield all([
    yield takeLatest(types.GET_ASSET_REPORT_REQUEST, getAssetReport),
    yield takeLatest(types.GET_SITE_PARAMETER_REPORT_REQUEST, getSiteParameterListReport),
    yield takeLatest(types.GET_PUMP_ACTIVITY_REQUEST, getPumpActivityReport),
    // LOAD_LOGGEDIN_USER(),
  ])
}
