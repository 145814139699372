import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { getMqttData } from '../../redux/actions/siteAction'
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import { types } from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import { siteList } from '../../redux/actions/siteAdminAction'
import { Box, Flex, background } from '@chakra-ui/react';
import { getSiteDetails, clearSiteData } from '../../redux/actions/siteAction'
import { getAssetReport, getSiteParameterListReport } from '../../redux/actions/reportAction'
// import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInUser, checkPermission, CONSTANTVALUEEXTRAPARAM, getSortOrder, getData, CONSTANTVALUE } from '../../utils'
import { getReplacedAssetHistoryAPI, getSiteExtraParameterAPI, replaceAssetAPI, updateAssetParameter, updateSiteExtraParameterAPI } from '../../store/apis';
import { Button, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useToast } from '@chakra-ui/react';
import { useRef } from 'react';
import { AiFillFileExcel, AiFillPrinter, AiFillTool, AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import { apiGetCallWithPromise } from '../../store/axiosApicall';
import { apiName } from '../../store/serverConfig';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import { MdAdd } from 'react-icons/md';
var moment = require('moment');

// let reportTimeout=null;
const Assetdata = ({ noHeaders, projectList, newAssignedSites }) => {
  const navigate = useNavigate();
  const fontFamily = 'Work Sans'
  const dispatch = useDispatch();
  // var intervalId = null;
  const [projectsList, setProjects] = useState(projectList);
  const [selectedProject, setSelectedProject] = useState('');
  useEffect(()=> {
    const p = localStorage.getItem('selectedProject');
    setTimeout(()=> {
      if(p) {
        setSelectedProject(p)
      }
    }, 2000)
  }, [])
  const [selectedSites, setSelectedSites] = useState(newAssignedSites);
  const [site, setSite] = useState('')
  const siteReducer = useSelector(state => state.siteReducer);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const reportReducer = useSelector(state => state.reportReducer);
  const [replacedAssetData, setReplacedAssetData] = useState([]);
  const [imeino, setImeino] = useState();
  const [siteId, setSiteId] = useState(0);
  const [finalLoading, setFinalLoading] = useState(false);
  const [listSite, setListSite] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [pumpObject, setPumpObject] = useState(null);
  const [pumpSerialNo, setPumpSerialNo] = useState('');
  const [assignedSites, setAssignedSites] = useState([]);
  const [currObj, setCurrObj] = useState([]);
  const [isReplace, setIsReplace] = useState(false);
  const [supplyTo, setSupplyTo] = useState('');
  const [isAdd, setIsAdd] = useState(false);
  const [newObj, setNewObj] = useState(null);
  const [motorObject, setMotorObject] = useState(null);
  const [prevActId, setPrevActId] = useState('');
  const [motorSerialNo, setMotorSerialNo] = useState('');
  const [flowMeterObject, setFlowMeterObject] = useState(null);
  const [flowMeterSerialNo, setFlowMeterSerialNo] = useState('');
  const [pressureMeterObject, setPressureMeterObject] = useState(null);
  const [pressureMeterSerialNo, setPressureMeterSerialNo] = useState('');
  const [multiFuncMeterObject, setMultiFuncMeterObject] = useState(null);
  const [multiFuncMeterSerialNo, setMultiFuncMeterSerialNo] = useState('');
  const [currId, setId] = useState('');
  const [phSensorObject, setPHSensorObject] = useState(null);
  const [pHSensorSerialNo, setPHSensorSerialNo] = useState('');
  const [lvlSensorObject, setLvlSensorObject] = useState(null);
  const [lvlSensorSerialNo, setLvlSensorSerialNo] = useState('');
  const [trbtMonObject, setTrbtMonObject] = useState(null);
  const [trbtMonSerialNo, setTrbtMonSerialNo] = useState('');
  const [clrnSensorObject, setClrnSensorObject] = useState(null);
  const [clrnSensorSerialNo, setClrnSensorSerialNo] = useState('');
  const [actuatorObject, setActuatorObject] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [actuatorSerialNo, setActuatorSerialNo] = useState([]);
  const [highLevel, setHighLevel] = useState('');
  const [lowLevel, setLowLevel] = useState('');
  const [makeList, setMakeList] = useState(null);
  const [modelList, setModelList] = useState(null);
  const [makeModelList, setMakeModelList] = useState([]);
  const [assetDetails, setAssetDetails] = useState(null);
  const [updatedObj, setUpdatedObj] = useState({
    make: '',
    model: '',
    srNo: '',
    id: ''
  });
  const [type, setType] = useState('');
  const toast = useToast()
  const assetTableRef = useRef(null);
  const replacementHistoryTableRef = useRef(null);
  const curSites = useSelector(state => { return state.siteAdminReducer.data && state.siteAdminReducer.data.list ? state.siteAdminReducer.data.list : [] });

  const state = useSelector(store => store.userReducer);


  // const [actuatorIds,setActuatorIds] = useState([]);
  // const { isLeftCanvas } = useSelector((state) => state.dashboard);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  // const [isCreate, setIsCreate] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);

  // const navRef = React.useRef(null);
  useEffect(() => {
    chkLogin();
    setPermission();
  }, [])
  useEffect(() => {
    // Fetch sites only if the selected project is not 'ALL'
    if (selectedProject !== 'ALL') {
      const selectedProjectData = projectList?.find((item) => item.project._id === selectedProject);
      setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
    } else {
      // Clear sites when 'All Projects' is selected
      setSelectedSites([]);
    }
  }, [selectedProject]);
  const handleSiteChange = (event) => {
    console.log("EVENT", event.target.value)

    setSiteId(event.target.value);

    const selectedSiteName = event.target.options[event.target.selectedIndex].text;
    const selectedSiteIMEI = event.target.options[event.target.selectedIndex].getAttribute('imei');

    // console.log('Selected Site Name:', selectedSiteName);
    setSite(selectedSiteName);
    // console.log('Selected Site IMEI:', selectedSiteIMEI);
    setImeino(event.target.value);
    console.log('Selected Site IMEI:', imeino);
  };

  useEffect(() => {
    fetchAssignedSites();
  }, []);

  const fetchAssignedSites = async () => {
    try {
      setAssignedSites(state.sites.data)
    } catch (error) {
      console.log(error.message);
    }
  }


  useEffect(() => {
    if (isView) {
      // fetchMqttData();
      fetchSiteList();
    }
  }, [isView])
  useEffect(() => {
    if (siteReducer.action) {
      if (siteReducer.action === types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS) {
        //console.log('siteReducer',siteReducer);        
        // setPumpObject(null);
        // setMotorObject(null); 
        // setFlowMeterObject(null); 
        // setPressureMeterObject(null); 
        // setMultiFuncMeterObject(null);
        // setPHSensorObject(null);
        // setLvlSensorObject(null);
        // setTrbtMonObject(null);        
        // setActuatorObject(null);

        // if(siteReducer.data.pump){
        //   setPumpSerialNo(siteReducer.data.pump.serialNo);
        //   getExtraParameterData(siteReducer.data.pump.id,CONSTANTVALUEEXTRAPARAM.PUMP);
        // }
        // if(siteReducer.data.motor){
        //   setMotorSerialNo(siteReducer.data.motor.serialNo);
        //   getExtraParameterData(siteReducer.data.motor.id,CONSTANTVALUEEXTRAPARAM.MOTOR);
        // }
        // if(siteReducer.data.flowMeter){
        //   setFlowMeterSerialNo(siteReducer.data.flowMeter.serialNo);
        //   getExtraParameterData(siteReducer.data.flowMeter.id,CONSTANTVALUEEXTRAPARAM.FLOWMETER);
        // }
        // if(siteReducer.data.pressureMeter){
        //   setPressureMeterSerialNo(siteReducer.data.pressureMeter.serialNo);
        //   getExtraParameterData(siteReducer.data.pressureMeter.id,CONSTANTVALUEEXTRAPARAM.PRESSUREMETER);
        // }
        // if(siteReducer.data.multiFunctionMeter){
        //   setMultiFuncMeterSerialNo(siteReducer.data.multiFunctionMeter.serialNo);
        //   getExtraParameterData(siteReducer.data.multiFunctionMeter.id,CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER);
        // }
        // if(siteReducer.data.pHSensor){
        //   setPHSensorSerialNo(siteReducer.data.pHSensor.serialNo);
        //   getExtraParameterData(siteReducer.data.pHSensor.id,CONSTANTVALUEEXTRAPARAM.PHSENSOR);
        // }
        // if(siteReducer.data.levelSensor){
        //   setLvlSensorSerialNo(siteReducer.data.levelSensor.serialNo);
        //   getExtraParameterData(siteReducer.data.levelSensor.id,CONSTANTVALUEEXTRAPARAM.LEVELSENSOR);
        // }
        // if(siteReducer.data.turbidityMonitor){
        //   setTrbtMonSerialNo(siteReducer.data.turbidityMonitor.serialNo);
        //   getExtraParameterData(siteReducer.data.turbidityMonitor.id,CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR);
        // }
        // if(siteReducer.data.chlorineSensor){
        //   setClrnSensorSerialNo(siteReducer.data.chlorineSensor.serialNo);
        //   getExtraParameterData(siteReducer.data.chlorineSensor.id,CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR);
        // }
        // else{
        //   setClrnSensorObject(null);
        // }
        // if(siteReducer.data.actuatorSensor){
        //   let listActuatorSerialNo=[];
        //   let listActuatorId=[];
        //   siteReducer.data.actuatorSensor.map((item,index)=>{
        //     listActuatorSerialNo.push(item.serialNo);
        //     listActuatorId.push(item.id);
        //   })
        //   // console.log('listActuatorSerialNo',listActuatorSerialNo);
        //   // console.log('listActuatorId',listActuatorId);
        //   setActuatorSerialNo(listActuatorSerialNo);
        //   // setActuatorIds(listActuatorId);
        //   getExtraParameterData(listActuatorId,CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR);
        // }
      }
      else if (siteReducer.action === types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE) {
        toast({
          title: siteReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
    }
  }, [siteReducer.action])
  useEffect(() => {
    //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer);    
    if (siteAdminReducer.action) {
      if (siteAdminReducer.action === types.LIST_SITE_REQUEST_SUCCESS) {
        //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer)      
        if (siteAdminReducer.status === "success") {
          setListSite(siteAdminReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: siteAdminReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
    }
  }, [siteAdminReducer.action])
  useEffect(() => {
    //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer);    
    if (reportReducer.action) {
      if (reportReducer.action === types.GET_ASSET_REPORT_SUCCESS) {
        // console.log('reportReducer',reportReducer)      
        if (reportReducer.status === 200) {
          // console.log('reportReducer.data.list',reportReducer.data.list)
          const assetList = reportReducer.data.list;
          // console.log('assetList',assetList)
          assetList?.sort(getSortOrder("typeOfValue"));
          // console.log('assetList',assetList)
          setHistoryList(assetList);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: reportReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      if (reportReducer.action === types.GET_SITE_PARAMETER_REPORT_SUCCESS) {
        // console.log('reportReducer',reportReducer)      
        if (reportReducer.status === 200) {
          // console.log('reportReducer.data.list',reportReducer.data.list)
          // const assetList=reportReducer.data;
          // console.log('assetList',assetList)
          // assetList.sort(getSortOrder("typeOfValue"));
          // console.log('assetList',assetList)
          setAssetDetails(reportReducer.data);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: reportReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      if (reportReducer.action === types.GET_ASSET_REPORT_FAILURE) {
        toast({
          title: reportReducer.message,
          status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
      if (reportReducer.action === types.GET_SITE_PARAMETER_REPORT_FAILURE) {
        toast({
          title: reportReducer.message,
          status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
    }
  }, [reportReducer.action])
  const getExtraParameterData = (parameterId, parameterType) => {
    if (parameterType == CONSTANTVALUEEXTRAPARAM.PUMP) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.PUMP).then((responseObject) => {
        setPumpObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.MOTOR) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.MOTOR).then((responseObject) => {
        setMotorObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.FLOWMETER) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.FLOWMETER).then((responseObject) => {
        setFlowMeterObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.PRESSUREMETER) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.PRESSUREMETER).then((responseObject) => {
        setPressureMeterObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER).then((responseObject) => {
        setMultiFuncMeterObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.PHSENSOR) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.PHSENSOR).then((responseObject) => {
        // console.log("FlowMeterObject",responseObject);
        // console.log("FlowMeterObject-parameterId",parameterId);
        setPHSensorObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.LEVELSENSOR) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.LEVELSENSOR).then((responseObject) => {
        setLvlSensorObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR).then((responseObject) => {
        // console.log("FlowMeterObject",responseObject);
        // console.log("FlowMeterObject-parameterId",parameterId);
        setTrbtMonObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR) {
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR).then((responseObject) => {
        // console.log("FlowMeterObject",responseObject);
        // console.log("FlowMeterObject-parameterId",parameterId);
        setClrnSensorObject(responseObject.data.list.filter(item => item._id == parameterId));
      })
    }
    if (parameterType == CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR) {
      // console.log('parameterType',parameterType);
      getSiteExtraParameterAPI('type=' + CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR).then((responseObject) => {
        let listActuatorObject = [];
        // console.log('responseObject',responseObject);
        // console.log('parameterId',parameterId);
        parameterId?.map((actItem, index) => {
          // console.log('actItem',actItem);
          const objActuator = responseObject.data.list.filter(item => item._id == actItem);
          // console.log('objActuator',objActuator);
          const actObject = {
            actuatorMake: objActuator[0].actuatorMake,
            actuatorModel: objActuator[0].actuatorModel
          }
          // console.log('actObject',actObject);
          listActuatorObject.push(actObject);
        })
        //console.log('listActuatorObject',listActuatorObject);
        setActuatorObject(listActuatorObject);
        // setActuatorObject(responseObject.data.list.filter(item=>item._id==parameterId));
      })
    }
  }


  const exportToExcel = () => {
    if (assetTableRef.current) {
      const table = assetTableRef.current;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Asset Data');

      const rows = table.querySelectorAll('tr');
      const headerRow = Array.from(rows[0].children).slice(0, -2).map(cell => cell.textContent);
      worksheet.addRow(headerRow);

      rows.forEach((row, rowIndex) => {
        if (rowIndex > 0) {
          const rowData = Array.from(row.children)
            .slice(0, -2)
            .filter(cell => !cell.querySelector('button'))
            .map(cell => cell.textContent);
          worksheet.addRow(rowData);
        }
      });

      const fifthRow = worksheet.getRow(5);
      fifthRow.eachCell(cell => {
        cell.alignment = { horizontal: 'left' };
      });

      const secondRow = worksheet.getColumn(2);
      secondRow.eachCell(cell => {
        cell.alignment = { horizontal: 'right' };
      });


      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Asset_Data.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  };
  const exportToExcelReplacementHistory = () => {
    if (replacementHistoryTableRef.current) {
      const worksheet = XLSX.utils.table_to_sheet(replacementHistoryTableRef.current);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Replacement History');
      XLSX.writeFile(workbook, 'Asset_Replacement_History.xlsx');
    }
  };

  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  const setPermission = async () => {
    setIsView(await checkPermission('Asset Report', 'view'));
    // setIsView(true);
  }
  const fetchSiteList = () => {
    setSiteId(curSites)
  }
  // const fetchAssetReport=async(siteId)=>{
  //   dispatch(getAssetReport("id="+siteId+"&limit=10000&page=1"));
  // }
  const fetchSiteDetails = () => {
    if (imeino) {
      // fetchAssetReport(siteId);
      dispatch(getSiteParameterListReport("id=" + imeino));
      dispatch(getAssetReport("id=" + imeino + "&limit=10000&page=1"));
      dispatch(getSiteDetails(imeino));
      getReplacedHistoryData();
    }
  }

  const handleChangeEvent = (e) => {
    if (e.target.name === "selSite") {
      setSiteId(e.target.value);
    }
  }
  const bindSites = () => {
    if (newAssignedSites) {
      // console.log('bindProjects1-listProjects',listProjects);
      if (newAssignedSites.length > 0) {
        // console.log('bindProjects2-listProjects',listProjects);
        return (newAssignedSites?.map((item, index) => (
          <option key={index} value={item._id}>{item.pumpName.toString() + "   (IMEI " + item.IMEI + ")"}</option>
        )))
      }
    }
  }
  // const bindPump = (pumpLocalObject, isDisplay) => {

  //   if (pumpLocalObject.oldObject) {
  //     return (
  //       <tr key='0'>
  //         <td>{isDisplay ? 'Pump' : ''}</td>
  //         <td>1</td>
  //         <td>{pumpLocalObject.oldObject.make}</td>
  //         <td>{pumpLocalObject.oldObject.model}</td>
  //         <td>{pumpLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(pumpLocalObject.oldObject.installationOfDate ? pumpLocalObject.oldObject.installationOfDate : pumpLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(pumpLocalObject.oldObject.replacementOfDate ? pumpLocalObject.oldObject.replacementOfDate : pumpLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{pumpLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (pumpLocalObject.newObject) {
  //     return (
  //       <tr key='0'>
  //         <td>{isDisplay ? 'Pump' : ''}</td>
  //         <td>1</td>
  //         <td>{pumpLocalObject.newObject.make}</td>
  //         <td>{pumpLocalObject.newObject.model}</td>
  //         <td>{pumpLocalObject.newObject.serialNo}</td>
  //         <td>{moment(pumpLocalObject.newObject.installationOfDate ? pumpLocalObject.newObject.installationOfDate : pumpLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(pumpLocalObject.newObject.replacementOfDate ? pumpLocalObject.newObject.replacementOfDate : pumpLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{pumpLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (pumpObject) {
  //     return (
  //       <tr key='0'>
  //         <td>{'Pump'}</td>
  //         <td>1</td>
  //         <td>{pumpObject[0].make}</td>
  //         <td>{pumpObject[0].model}</td>
  //         <td>{pumpSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindMotor = (motorLocalObject, isDisplay) => {
  //   if (motorLocalObject.oldObject) {
  //     return (
  //       <tr key='1'>
  //         <td>{isDisplay ? 'Motor' : ''}</td>
  //         <td>1</td>
  //         <td>{motorLocalObject.oldObject.motorMake}</td>
  //         <td>{motorLocalObject.oldObject.motorType}</td>
  //         <td>{motorLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(motorLocalObject.oldObject.installationOfDate ? motorLocalObject.oldObject.installationOfDate : motorLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(motorLocalObject.oldObject.replacementOfDate ? motorLocalObject.oldObject.replacementOfDate : motorLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{motorLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (motorLocalObject.newObject) {
  //     return (
  //       <tr key='1'>
  //         <td>{isDisplay ? 'Motor' : ''}</td>
  //         <td>1</td>
  //         <td>{motorLocalObject.newObject.motorMake}</td>
  //         <td>{motorLocalObject.newObject.motorType}</td>
  //         <td>{motorLocalObject.newObject.serialNo}</td>
  //         <td>{moment(motorLocalObject.newObject.installationOfDate ? motorLocalObject.newObject.installationOfDate : motorLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(motorLocalObject.newObject.replacementOfDate ? motorLocalObject.newObject.replacementOfDate : motorLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{motorLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (motorObject) {
  //     return (
  //       <tr key='1'>
  //         <td>{'Motor'}</td>
  //         <td>1</td>
  //         <td>{motorObject[0].motorMake}</td>
  //         <td>{motorObject[0].motorType}</td>
  //         <td>{motorSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindFlowmeter = (flowMeterLocalObject, isDisplay) => {
  //   // console.log('flowMeterObject',flowMeterObject);
  //   if (flowMeterLocalObject.oldObject) {
  //     return (
  //       <tr key='2'>
  //         <td>{isDisplay ? 'Flow Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{flowMeterLocalObject.oldObject.flowMeterMake}</td>
  //         <td>{flowMeterLocalObject.oldObject.flowMeterModel}</td>
  //         <td>{flowMeterLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(flowMeterLocalObject.oldObject.installationOfDate ? flowMeterLocalObject.oldObject.installationOfDate : flowMeterLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(flowMeterLocalObject.oldObject.replacementOfDate ? flowMeterLocalObject.oldObject.replacementOfDate : flowMeterLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{flowMeterLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (flowMeterLocalObject.newObject) {
  //     return (
  //       <tr key='2'>
  //         <td>{isDisplay ? 'Flow Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{flowMeterLocalObject.newObject.flowMeterMake}</td>
  //         <td>{flowMeterLocalObject.newObject.flowMeterModel}</td>
  //         <td>{flowMeterLocalObject.newObject.serialNo}</td>
  //         <td>{moment(flowMeterLocalObject.newObject.installationOfDate ? flowMeterLocalObject.newObject.installationOfDate : flowMeterLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(flowMeterLocalObject.newObject.replacementOfDate ? flowMeterLocalObject.newObject.replacementOfDate : flowMeterLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{flowMeterLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (flowMeterObject) {
  //     return (
  //       <tr key='2'>
  //         <td>{'Flow Meter'}</td>
  //         <td>1</td>
  //         <td>{flowMeterObject[0].flowMeterMake}</td>
  //         <td>{flowMeterObject[0].flowMeterModel}</td>
  //         <td>{flowMeterSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindPressureMeter = (pressureMeterLocalObject, isDisplay) => {
  //   // console.log('pressureMeterObject',pressureMeterObject);
  //   if (pressureMeterLocalObject.oldObject) {
  //     return (
  //       <tr key='3'>
  //         <td>{isDisplay ? 'Pressure Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{pressureMeterLocalObject.oldObject.pressureMeterMake}</td>
  //         <td>{pressureMeterLocalObject.oldObject.pressureMeterModel}</td>
  //         <td>{pressureMeterLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(pressureMeterLocalObject.oldObject.installationOfDate ? pressureMeterLocalObject.oldObject.installationOfDate : pressureMeterLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(pressureMeterLocalObject.oldObject.replacementOfDate ? pressureMeterLocalObject.oldObject.replacementOfDate : pressureMeterLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{pressureMeterLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (pressureMeterLocalObject.newObject) {
  //     return (
  //       <tr key='3'>
  //         <td>{isDisplay ? 'Pressure Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{pressureMeterLocalObject.newObject.pressureMeterMake}</td>
  //         <td>{pressureMeterLocalObject.newObject.pressureMeterModel}</td>
  //         <td>{pressureMeterLocalObject.newObject.serialNo}</td>
  //         <td>{moment(pressureMeterLocalObject.newObject.installationOfDate ? pressureMeterLocalObject.newObject.installationOfDate : pressureMeterLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(pressureMeterLocalObject.newObject.replacementOfDate ? pressureMeterLocalObject.newObject.replacementOfDate : pressureMeterLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{pressureMeterLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (pressureMeterObject) {
  //     return (
  //       <tr key='3'>
  //         <td>{'Pressure Meter'}</td>
  //         <td>1</td>
  //         <td>{pressureMeterObject[0].pressureMeterMake}</td>
  //         <td>{pressureMeterObject[0].pressureMeterModel}</td>
  //         <td>{pressureMeterSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindMultiFuncMeterObject = (multiFuncMeterLocalObject, isDisplay) => {
  //   // console.log('pressureMeterObject',pressureMeterObject);
  //   if (multiFuncMeterLocalObject.oldObject) {
  //     return (
  //       <tr key='4'>
  //         <td>{isDisplay ? 'Multifunction Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{multiFuncMeterLocalObject.oldObject.multiFuncMeterMake}</td>
  //         <td>{multiFuncMeterLocalObject.oldObject.multiFuncMeterModel}</td>
  //         <td>{multiFuncMeterLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(multiFuncMeterLocalObject.oldObject.installationOfDate ? multiFuncMeterLocalObject.oldObject.installationOfDate : multiFuncMeterLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(multiFuncMeterLocalObject.oldObject.replacementOfDate ? multiFuncMeterLocalObject.oldObject.replacementOfDate : multiFuncMeterLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{multiFuncMeterLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (multiFuncMeterLocalObject.newObject) {
  //     return (
  //       <tr key='4'>
  //         <td>{isDisplay ? 'Multifunction Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{multiFuncMeterLocalObject.newObject.multiFuncMeterMake}</td>
  //         <td>{multiFuncMeterLocalObject.newObject.multiFuncMeterModel}</td>
  //         <td>{multiFuncMeterLocalObject.newObject.serialNo}</td>
  //         <td>{moment(multiFuncMeterLocalObject.newObject.installationOfDate ? multiFuncMeterLocalObject.newObject.installationOfDate : multiFuncMeterLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(multiFuncMeterLocalObject.newObject.replacementOfDate ? multiFuncMeterLocalObject.newObject.replacementOfDate : multiFuncMeterLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{multiFuncMeterLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   }
  //   else if (multiFuncMeterObject) {
  //     return (
  //       <tr key='4'>
  //         <td>{'Multifunction Meter'}</td>
  //         <td>1</td>
  //         <td>{multiFuncMeterObject[0].multiFuncMeterMake}</td>
  //         <td>{multiFuncMeterObject[0].multiFuncMeterModel}</td>
  //         <td>{multiFuncMeterSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindPHSensorObject = (phSensorLocalObject, isDisplay) => {
  //   // console.log('bindPHSensorObject',phSensorObject);
  //   if (phSensorLocalObject.oldObject) {
  //     return (
  //       <tr key='5'>
  //         <td>{isDisplay ? 'PH Sensor Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{phSensorLocalObject.oldObject.pHSensorMake}</td>
  //         <td>{phSensorLocalObject.oldObject.pHSensorModel}</td>
  //         <td>{phSensorLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(phSensorLocalObject.oldObject.installationOfDate ? phSensorLocalObject.oldObject.installationOfDate : phSensorLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(phSensorLocalObject.oldObject.replacementOfDate ? phSensorLocalObject.oldObject.replacementOfDate : phSensorLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{phSensorLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (phSensorLocalObject.newObject) {
  //     return (
  //       <tr key='5'>
  //         <td>{isDisplay ? 'PH Sensor Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{phSensorLocalObject.newObject.pHSensorMake}</td>
  //         <td>{phSensorLocalObject.newObject.pHSensorModel}</td>
  //         <td>{phSensorLocalObject.newObject.serialNo}</td>
  //         <td>{moment(phSensorLocalObject.newObject.installationOfDate ? phSensorLocalObject.newObject.installationOfDate : phSensorLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(phSensorLocalObject.newObject.replacementOfDate ? phSensorLocalObject.newObject.replacementOfDate : phSensorLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{phSensorLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (phSensorObject) {
  //     return (
  //       <tr key='5'>
  //         <td>{'PH Sensor Meter'}</td>
  //         <td>1</td>
  //         <td>{phSensorObject[0].pHSensorMake}</td>
  //         <td>{phSensorObject[0].pHSensorModel}</td>
  //         <td>{pHSensorSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindLvlSensorObject = (lvlSensorLocalObject, isDisplay) => {
  //   // console.log('lvlSensorObject',lvlSensorObject);
  //   if (lvlSensorLocalObject.oldObject) {
  //     return (
  //       <tr key='6'>
  //         <td>{isDisplay ? 'Level Sensor Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{lvlSensorLocalObject.oldObject.levelSensorMake}</td>
  //         <td>{lvlSensorLocalObject.oldObject.levelSensorModel}</td>
  //         <td>{lvlSensorLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(lvlSensorLocalObject.oldObject.installationOfDate ? lvlSensorLocalObject.oldObject.installationOfDate : lvlSensorLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(lvlSensorLocalObject.oldObject.replacementOfDate ? lvlSensorLocalObject.oldObject.replacementOfDate : lvlSensorLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{lvlSensorLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (lvlSensorLocalObject.newObject) {
  //     return (
  //       <tr key='6'>
  //         <td>{isDisplay ? 'Level Sensor Meter' : ''}</td>
  //         <td>1</td>
  //         <td>{lvlSensorLocalObject.newObject.levelSensorMake}</td>
  //         <td>{lvlSensorLocalObject.newObject.levelSensorModel}</td>
  //         <td>{lvlSensorLocalObject.newObject.serialNo}</td>
  //         <td>{moment(lvlSensorLocalObject.newObject.installationOfDate ? lvlSensorLocalObject.newObject.installationOfDate : lvlSensorLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(lvlSensorLocalObject.newObject.replacementOfDate ? lvlSensorLocalObject.newObject.replacementOfDate : lvlSensorLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{lvlSensorLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (lvlSensorObject) {
  //     return (
  //       <tr key='6'>
  //         <td>{'Level Sensor Meter'}</td>
  //         <td>1</td>
  //         <td>{lvlSensorObject[0].levelSensorMake}</td>
  //         <td>{lvlSensorObject[0].levelSensorModel}</td>
  //         <td>{lvlSensorSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindTurbMonObject = (trbtMonLocalObject, isDisplay) => {
  //   // console.log('trbtMonObject',trbtMonObject);
  //   if (trbtMonLocalObject.oldObject) {
  //     // console.log('bindTurbMonObject-If');
  //     return (
  //       <tr key='7'>
  //         <td>{isDisplay ? 'Turbidity Monitor' : ''}</td>
  //         <td>1</td>
  //         <td>{trbtMonLocalObject.oldObject.turbidityMonitorMake}</td>
  //         <td>{trbtMonLocalObject.oldObject.turbidityMonitorModel}</td>
  //         <td>{trbtMonLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(trbtMonLocalObject.oldObject.installationOfDate ? trbtMonLocalObject.oldObject.installationOfDate : trbtMonLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(trbtMonLocalObject.oldObject.replacementOfDate ? trbtMonLocalObject.oldObject.replacementOfDate : trbtMonLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{trbtMonLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (trbtMonLocalObject.newObject) {
  //     // console.log('bindTurbMonObject-If');
  //     return (
  //       <tr key='7'>
  //         <td>{isDisplay ? 'Turbidity Monitor' : ''}</td>
  //         <td>1</td>
  //         <td>{trbtMonLocalObject.newObject.turbidityMonitorMake}</td>
  //         <td>{trbtMonLocalObject.newObject.turbidityMonitorModel}</td>
  //         <td>{trbtMonLocalObject.newObject.serialNo}</td>
  //         <td>{moment(trbtMonLocalObject.newObject.installationOfDate ? trbtMonLocalObject.newObject.installationOfDate : trbtMonLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(trbtMonLocalObject.newObject.replacementOfDate ? trbtMonLocalObject.newObject.replacementOfDate : trbtMonLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{trbtMonLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (trbtMonObject) {
  //     // console.log('bindTurbMonObject-If');
  //     return (
  //       <tr key='7'>
  //         <td>{'Turbidity Monitor'}</td>
  //         <td>1</td>
  //         <td>{trbtMonObject[0].turbidityMonitorMake}</td>
  //         <td>{trbtMonObject[0].turbidityMonitorModel}</td>
  //         <td>{trbtMonSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindClrnSensorObject = (clrnSensorLocalObject, isDisplay) => {
  //   // console.log('clrnSensorObject',clrnSensorObject);
  //   if (clrnSensorLocalObject.oldObject) {
  //     // console.log('clrnSensorObject-If');
  //     return (
  //       <tr key='8'>
  //         <td>{isDisplay ? 'Chlorine Sensor' : ''}</td>
  //         <td>1</td>
  //         <td>{clrnSensorLocalObject.oldObject.chlorineSensorMake}</td>
  //         <td>{clrnSensorLocalObject.oldObject.chlorineSensorModel}</td>
  //         <td>{clrnSensorLocalObject.oldObject.serialNo}</td>
  //         <td>{moment(clrnSensorLocalObject.oldObject.installationOfDate ? clrnSensorLocalObject.oldObject.installationOfDate : clrnSensorLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(clrnSensorLocalObject.oldObject.replacementOfDate ? clrnSensorLocalObject.oldObject.replacementOfDate : clrnSensorLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{clrnSensorLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (clrnSensorLocalObject.newObject) {
  //     // console.log('clrnSensorObject-If');
  //     return (
  //       <tr key='8'>
  //         <td>{isDisplay ? 'Chlorine Sensor' : ''}</td>
  //         <td>1</td>
  //         <td>{clrnSensorLocalObject.newObject.chlorineSensorMake}</td>
  //         <td>{clrnSensorLocalObject.newObject.chlorineSensorModel}</td>
  //         <td>{clrnSensorLocalObject.newObject.serialNo}</td>
  //         <td>{moment(clrnSensorLocalObject.newObject.installationOfDate ? clrnSensorLocalObject.newObject.installationOfDate : clrnSensorLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{moment(clrnSensorLocalObject.newObject.replacementOfDate ? clrnSensorLocalObject.newObject.replacementOfDate : clrnSensorLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //         <td>{clrnSensorLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //       </tr>
  //     )
  //   } else if (clrnSensorObject) {
  //     // console.log('clrnSensorObject-If');
  //     return (
  //       <tr key='8'>
  //         <td>{'Chlorine Sensor'}</td>
  //         <td>1</td>
  //         <td>{clrnSensorObject[0].chlorineSensorMake}</td>
  //         <td>{clrnSensorObject[0].chlorineSensorModel}</td>
  //         <td>{clrnSensorSerialNo}</td>
  //         <td></td>
  //       </tr>
  //     )
  //   }
  // }
  // const bindActuatorObjectObject = (actuatorLocalObject, isDisplay) => {
  //   // console.log('actuatorLocalObject',actuatorLocalObject);
  //   if (actuatorLocalObject.oldObject) {
  //     return <tr>
  //       <td>{isDisplay ? 'Actuator Sensor' : ''}</td>
  //       <td>1</td>
  //       <td>{actuatorLocalObject.oldObject.actuatorMake}</td>
  //       <td>{actuatorLocalObject.oldObject.actuatorModel}</td>
  //       <td>{actuatorLocalObject.oldObject.serialNo}</td>
  //       <td>{moment(actuatorLocalObject.oldObject.installationOfDate ? actuatorLocalObject.oldObject.installationOfDate : actuatorLocalObject.oldObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //       <td>{moment(actuatorLocalObject.oldObject.replacementOfDate ? actuatorLocalObject.oldObject.replacementOfDate : actuatorLocalObject.oldObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //       <td>{actuatorLocalObject.oldObject.replacementStatus ? 'Replaced' : '--'}</td>
  //     </tr>
  //   } else if (actuatorLocalObject.newObject) {
  //     return <tr>
  //       <td>{isDisplay ? 'Actuator Sensor' : ''}</td>
  //       <td>1</td>
  //       <td>{actuatorLocalObject.newObject.actuatorMake}</td>
  //       <td>{actuatorLocalObject.newObject.actuatorModel}</td>
  //       <td>{actuatorLocalObject.newObject.serialNo}</td>
  //       <td>{moment(actuatorLocalObject.newObject.installationOfDate ? actuatorLocalObject.newObject.installationOfDate : actuatorLocalObject.newObject.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //       <td>{moment(actuatorLocalObject.newObject.replacementOfDate ? actuatorLocalObject.newObject.replacementOfDate : actuatorLocalObject.newObject.updatedDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
  //       <td>{actuatorLocalObject.newObject.replacementStatus ? 'Replaced' : '--'}</td>
  //     </tr>
  //   } else if (actuatorObject) {
  //     return actuatorObject.map((item, index) => (
  //       <tr key={item + index}>
  //         <td>{'Actuator Sensor'}</td>
  //         <td>1</td>
  //         <td>{item.actuatorMake}</td>
  //         <td>{item.actuatorModel}</td>
  //         <td>{actuatorSerialNo[index]}</td>
  //       </tr>
  //     ));
  //   }
  // }
  // const bindNoRecord = () => {
  //   if (!pumpObject && !motorObject && !flowMeterObject && !pressureMeterObject && !multiFuncMeterObject && !phSensorObject && !lvlSensorObject && !trbtMonObject && !clrnSensorObject && !actuatorObject) {
  //     return (<tr>
  //       <td colspan="5">{'No any asset found'}</td>
  //     </tr>)
  //   }
  // }
  // const bindNoRecordHistory=()=>{
  //   return (<tr>
  //       <td colspan="9">{'No record found'}</td>
  //     </tr>)      
  // }
  // //comehere
  const bindHistoryData = () => {
    return replacedAssetData?.map((dataItem, index) => { // Loop through each data item
      console.log("EACHHHHH IEREE", dataItem)
      const currData = dataItem.groupedData;
      const value = dataItem.typeOfValue;
      return currData ? currData?.map((item) => (
        <tr key={`${index}-${item._id}`}> {/* Use a unique key combining index and item ID */}
          <td>{getValue(value)}</td>
          <td>1</td>
          <td>{item.oldObject.oldValues.make ? item.oldObject.oldValues.make : item.oldObject.oldValues.chlorineSensorMake ? item.oldObject.oldValues.chlorineSensorMake : item.oldObject.oldValues.levelSensorMake ? item.oldObject.oldValues.levelSensorMake : item.oldObject.oldValues.flowMeterMake ? item.oldObject.oldValues.flowMeterMake : item.oldObject.oldValues.multiFuncMeterMake ? item.oldObject.oldValues.multiFuncMeterMake : item.oldObject.oldValues.turbidityMonitorMake ? item.oldObject.oldValues.turbidityMonitorMake : item.oldObject.oldValues.actuatorMake ? item.oldObject.oldValues.actuatorMake : item.oldObject.oldValues.motorMake}</td>
          <td>{item.oldObject.oldValues.model ? item.oldObject.oldValues.model : item.oldObject.oldValues.chlorineSensorModel ? item.oldObject.oldValues.chlorineSensorModel : item.oldObject.oldValues.levelSensorModel ? item.oldObject.oldValues.levelSensorModel : item.oldObject.oldValues.flowMeterModel ? item.oldObject.oldValues.flowMeterModel : item.oldObject.oldValues.multiFuncMeterModel ? item.oldObject.oldValues.multiFuncMeterModel : item.oldObject.oldValues.turbidityMonitorModel ? item.oldObject.oldValues.turbidityMonitorModel : item.oldObject.oldValues.actuatorModel ? item.oldObject.oldValues.actuatorModel : item.oldObject.oldValues.motorType}</td>
          <td>{item.oldObject.srNo}</td>
          <td>{moment(item.oldObject.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{moment(item.oldObject.replacementOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
        </tr>
      )) : null;
    });
  };

  // const bindAssetData=()=>{
  //   if(assetDetails){
  //     return bindPumpSite(assetDetails.pumpDetails,assetDetails.pump);
  //   }
  // }

  const getValue = (str) => {
    if (str == 'levelSensor') return 'Level Sensor';
    if (str == 'chlorineSensor') return 'Chlorine Sensor';
    if (str == 'multiFunctionMeter') return 'Multifunction Meter';
    if (str == 'flowMeter') return 'Flow Meter';
    if (str == 'actuatorSensor') return 'Actual Sensor';
    if (str == 'pump') return 'Pump';
    if (str == 'turbidityMonitor') return 'Turbidity Monitor';
    if (str === 'motor') return 'Motor'

  }
  const bindPumpSite = (pumpDetails, pump) => {
    if (pumpDetails && pumpDetails.length != 0) {
      console.log("pumpDetails", pumpDetails,);
      return (
        <tr>
          <td>Pump</td>
          <td>{Number(1)}</td>
          <td>{pumpDetails.make}</td>
          <td>{pumpDetails.model}</td>
          <td>{pump.serialNo.toString()}</td>
          <td>{moment(pump.replacementOfDate ? pump.replacementOfDate : pump.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{pump.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(pumpDetails, pump, 'pump')}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(pumpDetails, pump, 'pump', true)}>Replace</Button></td>
        </tr>
      )
    } else {
      return (<tr>
        <td>Pump</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        {/* <td>{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
        <td>-</td>
        <td>-</td>
        <td><Button size='sm' colorScheme='blue' variant={'solid'} onClick={() => updateParamter(null, null, 'pump', null, true)}>Add</Button></td>
        <td>-</td>
        <td>{ }</td>
      </tr>)
    }
  }
  const bindMotorSite = (motorDetails, motor) => {
    console.log("MOTORRRRRR", motorDetails, motor)
    if (motorDetails && motorDetails.length != 0) {
      return (
        <tr>
          <td>Motor</td>
          <td>1</td>
          <td>{motorDetails.motorMake}</td>
          <td>{motorDetails.motorType}</td>
          <td>{motor.serialNo.toString()}</td>
          <td>{moment(motor.replacementOfDate ? motor.replacementOfDate : motor.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{motor.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(motorDetails, motor, 'motor')}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(motorDetails, motor, 'motor', true)}>Replace</Button></td>
        </tr>
      )

    }
  }
  const bindFlowMeterSite = (flowMeterDetails, flowMeter) => {
    console.log("ALL DETAILS ::", flowMeterDetails, flowMeter)
    if (flowMeterDetails && flowMeterDetails.length != 0) {
      return (
        <tr>
          <td>Flow Meter</td>
          <td>1</td>
          <td>{flowMeterDetails.flowMeterMake}</td>
          <td>{flowMeterDetails.flowMeterModel}</td>
          <td>{flowMeter.serialNo.toString()}</td>
          {/* <td>{moment(flowMeterDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(flowMeter.replacementOfDate ? flowMeter.replacementOfDate : flowMeter.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{flowMeter.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(flowMeterDetails, flowMeter, 'flowMeter')}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(flowMeterDetails, flowMeter, 'flowMeter', true)}>Replace</Button></td>
        </tr>
      )
    }
    else {
      console.log("IMMIMIMIIMMIMIM")
      return (<tr>
        <td>Flow Meter</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        {/* <td>{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
        <td>-</td>
        <td>-</td>
        <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(null, null, 'flowMeter', null, true)}>Add</Button></td>
        <td>-</td>
        <td>{ }</td>
      </tr>)
    }
  }
  const bindMultiFunctionMeterDetailsSite = (multiFunctionMeterDetails, multiFunctionMeter) => {
    if (multiFunctionMeterDetails && !multiFunctionMeterDetails.length != 0) {
      return (
        <tr>
          <td>Multi Function Meter</td>
          <td>1</td>
          <td>{multiFunctionMeterDetails.multiFuncMeterMake}</td>
          <td>{multiFunctionMeterDetails.multiFuncMeterModel}</td>
          <td>{multiFunctionMeter.serialNo.toString()}</td>
          {/* <td>{moment(multiFunctionMeterDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(multiFunctionMeter.replacementOfDate ? multiFunctionMeter.replacementOfDate : multiFunctionMeter.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{multiFunctionMeter.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(multiFunctionMeterDetails, multiFunctionMeter, 'multiFunctionMeter')}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(multiFunctionMeterDetails, multiFunctionMeter, 'multiFunctionMeter', true)}>Replace</Button></td>

        </tr>
      )
    } else {
      console.log("IMMIMIMIIMMIMIM")
      return (<tr>
        <td>Multi Function Meter</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        {/* <td>{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
        <td>-</td>
        <td>-</td>
        <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(null, null, 'multiFunctionMeter', null, true)}>Add</Button></td>
        <td>-</td>
        <td>{ }</td>
      </tr>)
    }
  }
  const bindLevelSensorDetailsSite = (levelSensorDetails, levelSensor) => {
    if (levelSensorDetails && levelSensorDetails.length != 0) {
      return (
        <tr>
          <td>Level Sensor</td>
          <td>1</td>
          <td>{levelSensorDetails.levelSensorMake}</td>
          <td>{levelSensorDetails.levelSensorModel}</td>
          <td>{levelSensor.serialNo.toString()}</td>
          {/* <td>{moment(levelSensorDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(levelSensor.replacementOfDate ? levelSensor.replacementOfDate : levelSensor.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{levelSensor.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(levelSensorDetails, levelSensor, 'levelSensor')}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(levelSensorDetails, levelSensor, 'levelSensor', true)}>Replace</Button></td>

        </tr>
      )
    }
    else {
      console.log("IMMIMIMIIMMIMIM")
      return (<tr>
        <td>Level Sensor</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        {/* <td>{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
        <td>-</td>
        <td>-</td>
        <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(null, null, 'levelSensor', null, true)}>Add</Button></td>
        <td>-</td>
        <td>{ }</td>
      </tr>)
    }
  }
  const bindchlorineSensorSite = (chlorineSensorDetails, chlorineSensor) => {
    if (chlorineSensorDetails && chlorineSensorDetails.length != 0) {
      return (
        <tr>
          <td>Chlorine Sensor</td>
          <td>1</td>
          <td>{chlorineSensorDetails.chlorineSensorMake}</td>
          <td>{chlorineSensorDetails.chlorineSensorModel}</td>
          <td>{chlorineSensor.serialNo.toString()}</td>
          {/* <td>{moment(chlorineSensorDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(chlorineSensor.replacementOfDate ? chlorineSensor.replacementOfDate : chlorineSensor.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{chlorineSensor.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(chlorineSensorDetails, chlorineSensor, 'chlorineSensor')}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(chlorineSensorDetails, chlorineSensor, 'chlorineSensor', true)}>Replace</Button></td>
        </tr>
      )
    }
    else {
      console.log("IMMIMIMIIMMIMIM")
      return (<tr>
        <td>Chlorine Sensor</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        {/* <td>{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
        <td>-</td>
        <td>-</td>
        <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(null, null, 'chlorineSensor', null, true)}>Add</Button></td>
        <td>-</td>
        <td>{ }</td>
      </tr>)
    }
  }
  const bindTurbidityMonitorSite = (turbidityMonitorDetails, turbidityMonitor) => {
    if (turbidityMonitorDetails && turbidityMonitorDetails.length != 0) {
      return (
        <tr>
          <td>Turbidity Monitor</td>
          <td>1</td>
          <td>{turbidityMonitorDetails.turbidityMonitorMake}</td>
          <td>{turbidityMonitorDetails.turbidityMonitorModel}</td>
          <td>{turbidityMonitor.serialNo.toString()}</td>
          {/* <td>{moment(turbidityMonitorDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(turbidityMonitor.replacementOfDate ? turbidityMonitor.replacementOfDate : turbidityMonitor.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{turbidityMonitor.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(turbidityMonitorDetails, turbidityMonitor, 'turbidityMonitor')}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(turbidityMonitorDetails, turbidityMonitor, 'turbidityMonitor', true)}>Replace</Button></td>
        </tr>
      )
    }
    else {
      console.log("IMMIMIMIIMMIMIM")
      return (<tr>
        <td>Turbidity Monitor</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        {/* <td>{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
        <td>-</td>
        <td>-</td>
        <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(null, null, 'turbidityMonitor', null, true)}>Add</Button></td>
        <td>-</td>
        <td>{ }</td>
      </tr>)
    }
  }
  const bindActuatorSensorSite = (actuatorSensorDetails, actuatorSensor) => {
    console.log("AXXXXX", actuatorSensorDetails)
    console.log("AXXXX 2", actuatorSensor)

    for (let i = 0; i < actuatorSensorDetails.length; i++) {
      for (let j = 0; j < actuatorSensor.length; j++) {
        if (actuatorSensorDetails[i]._id.toString() == actuatorSensor[j].id.toString()) {
          actuatorSensor[j].sensor = actuatorSensorDetails[i];
          // break;
        }
      }
    }
    console.log("AXXXXXX 3", actuatorSensor)
    if (actuatorSensorDetails && actuatorSensorDetails.length != 0) {
      console.log("IMM 3333", actuatorSensorDetails)
      return actuatorSensor?.map((item, index) => (
        <tr key={index}>
          {console.log("ITEMMMMMM", item)}
          <td>Actuator Sensor</td>
          <td>1</td>
          <td>{item?.sensor?.actuatorMake}</td>
          <td>{item?.sensor?.actuatorModel}</td>
          <td>{item.serialNo.toString()}</td>
          {/* <td>{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(item.replacementOfDate ? item.replacementOfDate : item.installationOfDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{item.replacementStatus ? 'Replaced' : '--'}</td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(actuatorSensor[index].sensor, actuatorSensor[index], 'actuatorSensor', true)}>Update</Button></td>
          <td><Button colorScheme='blue' size='sm' onClick={() => updateParamter(actuatorSensor[index].sensor, actuatorSensor[index], 'actuatorSensor', true)}>Replace</Button></td>
          <td>{ }</td>
        </tr>
      ))
    }
  }

  useEffect(() => {
    setValues();
  }, [currObj])

  const setValues = () => {

    const makeValue = currObj[0] && Object.values(currObj[0])[1];
    const modelValue = currObj[0] && Object.values(currObj[0])[2];
    const srNoValue = currObj[1] && Object.values(currObj[1])[1];

    console.log("HUHUH :", currObj, makeValue, modelValue, srNoValue);

    if (currObj.length > 0 && currObj[1].id) setUpdatedObj({ make: makeValue + " " + modelValue, model: modelValue, srNo: srNoValue, id: currObj[1].id });
    if (currObj.length > 0 && currObj[1].id) setId(currObj[1].id)
  }

  const updateParamter = (data, moreData, type, rep, add) => {
    console.log("PARAMETER", type, rep, add);
    setModalOpen(true)
    if (rep) setIsReplace(true)
    if (add) setIsAdd(true);
    else if (!rep && !add) { setIsAdd(false); setIsReplace(false) }
    if (moreData && moreData._id) setPrevActId(moreData._id);
    getCurrParamterList(type);
    setType(type);
    if (data && moreData) setCurrObj([data, moreData]);
  }

  const replaceParameter = async () => {
    setFinalLoading(true);
    const payload = {
      id: currId,
      obj: updatedObj,
      type: type,
      prevActId: prevActId,
      siteId: siteId,
    }
    try {
      await replaceAssetAPI(payload);

      toast({
        title: 'Asset Has Been Replaced Successfulyy',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } catch (error) {
      toast({
        title: `Error : ${error.message}`,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } finally {
      fetchSiteDetails();
      setFinalLoading(false);
      setModalOpen(false);
      setUpdatedObj({
        make: '',
        model: '',
        srNo: '',
        id: ''
      })
      setIsReplace(false);
    }

  }

  const getReplacedHistoryData = async () => {
    const data = await getReplacedAssetHistoryAPI(`siteId=${imeino}`);
    setReplacedAssetData(data.data);
  }

  const getCurrParamterList = async (type) => {
    const response = await getSiteExtraParameterAPI(`type=${type}`);
    const arr = response.data.list?.map((item) => ({
      label: Object.values(item)[1] + " " + Object.values(item)[2],
      value: Object.values(item)[0],
    }));
    const arr2 = response.data.list?.map((item) => ({
      label: Object.values(item)[2],
      value: Object.values(item)[0],
    }));

    for (let i = 0; i < arr.length; i++) {
    }

    console.log('xxx arrrrr', arr, arr2)
    setMakeList(arr);
    setModelList(arr2);
    console.log("This is my models", modelList)
    console.log("THIS IS MY RESPONSE", arr)
  }

  const AssetreporthandlePrint = () => {
    if (assetTableRef.current) {

      const tableClone = assetTableRef.current.cloneNode(true);
      const rows = tableClone.rows;
      for (let row of rows) {
        if (row.cells.length > 2) {
          row.deleteCell(-1);
          row.deleteCell(-1);
        }
      }
      const buttons = tableClone.querySelectorAll('button');
      buttons.forEach(button => {
        button.style.display = 'none';
      });

      for (let row of rows) {
        if (row.cells.length > 1) {
          const cell = row.cells[row.cells.length - 3];
          cell.classList.add('align-right');
        }
      }

      for (let row of rows) {
        if (row.cells.length > 1) {
          const cell = row.cells[row.cells.length - 4];
          cell.classList.add('align-right');
        }
      }
      const printWindow = window.open('AssetData', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
              <html>
              <head>
                  <title>AssetData</title>
                  <style>
                  /* Add your custom styles here */
                  table {
                      border-collapse: collapse;
                      width: 100%;
                  }
                  th, td {
                      border: 1px solid #ccc;
                      padding: 8px;
                      text-align: left;
                  }
                  th {
                      background-color: #f2f2f2;
                  }
                  td.align-right {
                      text-align: right;
                  }
                  </style>
              </head>
              <body>
                  <table style="font-family: ${fontFamily}">
                  ${tableClone.innerHTML}
                  </table>
                  <script>
                  document.querySelectorAll('td').forEach(cell => {
                      if (!isNaN(cell.innerText) && cell.innerText.trim() !== '') {
                          cell.classList.add('align-right');
                      }
                  });
                  </script>
              </body>
              </html>
          `);
      printWindow.document.close();
      buttons.forEach(button => {
        button.style.display = '';
      });

      printWindow.print();
      printWindow.close();
    }
  };

  const handlePrintReplceMentHistory = () => {
    if (replacementHistoryTableRef.current) {
      const printWindow = window.open('Asset_Replacement_History', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Asset_Replacement_History</title>
            <style>
              /* Add your custom styles here */
              table {
                border-collapse: collapse;
                width: 100%;
              }
              th, td {
                border: 1px solid #ccc;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            </style>
          </head>
          <body>
            <table style="font-family: ${fontFamily}">
              ${replacementHistoryTableRef.current.innerHTML}
            </table>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  const setMakeHandler = (val) => {
    console.log('XXX IN', val);
    console.log('XXX INN', makeList, modelList)
    for (let i = 0; i < makeList.length; i++) {
      if (val?.toString() == makeList[i]?.label?.toString()) {
        console.log("XXX IN VALUES", makeList[i].value)
        setId(makeList[i].value);
        setUpdatedObj({ ...updatedObj, make: val, model: modelList[i].label, id: makeList[i].value });
        break
      }
    }
  }
  const renderBodyModal = () => {

    const makeValue = currObj[0] && Object.values(currObj[0])[1];
    const modelValue = currObj[0] && Object.values(currObj[0])[2];
    const srNoValue = currObj[1] && Object.values(currObj[1])[1];
    console.log("CHECKING ALL", makeList,)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

        {console.log("UPDATED OBJ CONSOLE", updatedObj, "MAKE LIST CONSOLE", makeList, "MAKE",)}
        <Text fontWeight={'bold'} mb={'2'}>Make</Text>
        <Select
          id="operatorNameFilter"
          display={'inline-block'}
          onChange={(item) => { setMakeHandler(item.target.value) }}
        >
          <option value="">Select Make</option>
          {makeList?.map((item) => (
            <option key={item.value} value={item.label} selected={updatedObj.make == item.label}>
              {item.label}
            </option>
          ))}
        </Select>
        <br />

        <>
          <Text fontWeight={'bold'} mb={'2'}>SrNo</Text>

          <Input w={'100%'} value={updatedObj?.srNo} placeholder={'Serial Number'} onChange={(e) => setUpdatedObj({ ...updatedObj, srNo: e.target.value })} />
        </>
        {type == 'flowMeter' && isAdd && <Input placeholder='Supply To' onChange={(e) => setSupplyTo(e.target.value)} />}
        {type == 'levelSensor' && isAdd && <Input mt={2} placeholder='Low Level' onChange={(e) => setLowLevel(e.target.value)} />}
        {type == 'levelSensor' && isAdd && <Input mt={2} placeholder='High Level' onChange={(e) => setHighLevel(e.target.value)} />}

        <Button w={'24%'} mt={'3'} isLoading={finalLoading} alignContent={'flex-end'} onClick={() => isReplace ? replaceParameter() : updateItem()}>{isReplace ? 'Replace' : isAdd ? 'Add' : 'Update'}</Button>
      </div>
    );
  }

  const updateItem = async (id) => {
    setFinalLoading(true)
    if (isAdd) {
      try {
        const payload = {
          "siteId": imeino,
          "details": [
            {
              "type": type,
              "id": updatedObj.id,
              "serialNo": updatedObj.srNo,
              "supplyTo": supplyTo,
              "highLVL": highLevel,
              "lowLVL": lowLevel
            }
          ]
        };
        console.log("CURRRRRR", updatedObj, currId)
        const res = await updateSiteExtraParameterAPI(payload);
        console.log("RESULT ::> ", res);
        toast({
          title: 'Asset Has Been Added Successfulyy',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      } catch (error) {
        toast({
          title: `Error : ${error.message}`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      } finally {
        setModalOpen(false);
        fetchSiteDetails()
        setIsAdd(false);
        setFinalLoading(false);
        setUpdatedObj({
          make: '',
          model: '',
          srNo: '',
          id: ''
        })
      }
    } else {
      const payload = {
        id: currId,
        obj: updatedObj,
        type: type,
        siteId: imeino,
        prevActId: prevActId
      }
      try {
        const resp = await updateAssetParameter(payload);
        toast({
          title: 'Asset Has Been Updated Successfulyy',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      } catch (error) {
        toast({
          title: `Error : ${error.message}`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      } finally {
        setModalOpen(false);
        setUpdatedObj({
          make: '',
          model: '',
          srNo: '',
          id: ''
        })
        fetchSiteDetails()
        setFinalLoading(false);
      }
    }
  }
  const bindPressureMeterSite = (pressureMeterDetails, pressureMeter) => {
    if (pressureMeterDetails) {
      return (
        <tr>
          <td>Pressure Meter</td>
          <td>1</td>
          <td>{pressureMeterDetails.pressureMeterMake}</td>
          <td>{pressureMeterDetails.pressureMeterModel}</td>
          <td>{pressureMeter.serialNo}</td>
          {/* <td>{moment(pressureMeterDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(pressureMeter.replacementOfDate ? pressureMeter.replacementOfDate : pressureMeterDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{pressureMeter.replacementStatus ? 'Replaced' : '--'}</td>

        </tr>
      )
    }
  }
  const bindPHSensorSite = (pHSensorDetails, pHSensor) => {
    if (pHSensorDetails) {
      return (
        <tr>
          <td>pH Sensor</td>
          <td>1</td>
          <td>{pHSensorDetails.pHSensorMake}</td>
          <td>{pHSensorDetails.pHSensorModel}</td>
          <td>{pHSensor.serialNo}</td>
          {/* <td>{moment(pHSensorDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td> */}
          <td>{moment(pHSensor.replacementOfDate ? pHSensor.replacementOfDate : pHSensorDetails.createdDate).format("DD/MM/YYYY hh:mm:ss a")}</td>
          <td>{pHSensor.replacementStatus ? 'Replaced' : '--'}</td>
        </tr>
      )
    }
  }
  const bindData = () => {
    return (
      <div className="panel_contentmqttdata">
        <Modal blockScrollOnMount={false} isOpen={isModalOpen} onClose={() => {
          setModalOpen(false); setUpdatedObj({
            make: '',
            model: '',
            srNo: '',
            id: ''
          })
        }} motionPreset='scale'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {isReplace ? 'REPLACE ASSET' : isAdd ? 'ADD ASSET' : 'EDIT ASSET'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {renderBodyModal()}
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* <div className="row m-3">
          <div className="col mb-8 pl-2"><h2>MQTT Data</h2></div>
          <div className="col"><input name="imeino" id="imeino" value={imeino} onChange={(event) => handleUserInput(event)} className="form-control" /></div>
          <div className="col"><ButtonWithSpinner onClick={()=>fetchMqttData()} isLoading={siteReducer.loading?true:false} label={'Go'} /><br />Note: Data will refresh in 5 sec time interval.</div>
        </div> */}
        <div className="row mt-3 mb-3">
          <div className="col-12 col-md-6 mb-8 pl-2"><h2>Asset Data</h2></div>
          <div className="col-6 col-md-6">
            <div className="row">
              <div className="col">
                <div className="d-flex flex-row">
                  <div className="form-outline flex-fill mb-0">
                    {/* <label className="form-label" for="form3Example3c">Project</label> */}
                    <Flex >
                      <Select
                        id="pageSizeSelect"
                        value={selectedProject}
                        variant={'filled'}
                        onChange={(e) => setSelectedProject(e.target.value)}
                        color="black"
                      >
                        <option value={'ALL'}>Select Project</option>
                        {projectList.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName))?.map((item) => (
                          <option key={item.project._id} value={item.project._id}>
                            {item.project.ProjectName}
                          </option>
                        ))}
                      </Select>
                      {selectedProject !== 'ALL' && (
                        <Select
                          // w={'15%'}
                          variant={'filled'}
                          ml={2}
                          // value={site}
                          onChange={(e) => handleSiteChange(e)}

                          color="black"
                        >
                          <option value={'ALL'}>Select Site</option>
                          {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                            <option key={site._id} value={site._id} imei={site.IMEI}>
                              {site.pumpName}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Flex>
                    {/* Note: Data will refresh in 5 sec time interval. */}
                  </div>
                </div>
              </div>
              {/* <div className="col"><input type={"date"} name="reportDate" id="reportDate" value={dateReport} onChange={(event) => handleUserInput(event)} className="form-control" /></div> */}
              <div className="col">
                <IconButton
                  colorScheme='blue'
                  aria-label='Search database'
                  icon={<SearchIcon />}
                  size='md' onClick={() => fetchSiteDetails()} isLoading={siteAdminReducer.loading ? true : false}
                />
              </div>


              {/* <div className="col"><ButtonWithSpinner onClick={()=>handleStopRequest()} isLoading={siteReducer.loading?true:false} label={'Stop Request'} /></div> */}
            </div>
          </div>
        </div>
        <div className='table-responsive'>
          <table className="table table-striped" ref={assetTableRef}>
            <thead>
              <tr>
                <th scope="col">Name of Material</th>
                <th scope="col">Qty</th>
                <th scope="col">Make</th>
                <th scope="col">Model</th>
                <th scope="col">Sr. No</th>
                <th scope="col">Installed Date</th>
                <th scope="col">Status</th>
                <th scope="col">Options</th>
                <th scope="col">Replace</th>
              </tr>
            </thead>
            <tbody>
              {assetDetails ? bindPumpSite(assetDetails.pumpDetails, assetDetails.pump) : null}
              {assetDetails ? bindMotorSite(assetDetails.motorDetails, assetDetails.motor) : null}
              {assetDetails ? bindFlowMeterSite(assetDetails.flowMeterDetails, assetDetails.flowMeter) : null}
              {assetDetails ? bindMultiFunctionMeterDetailsSite(assetDetails.multiFunctionMeterDetails, assetDetails.multiFunctionMeter) : null}
              {assetDetails ? bindLevelSensorDetailsSite(assetDetails.levelSensorDetails, assetDetails.levelSensor) : null}
              {assetDetails ? bindchlorineSensorSite(assetDetails.chlorineSensorDetails, assetDetails.chlorineSensor) : null}
              {assetDetails ? bindTurbidityMonitorSite(assetDetails.turbidityMonitorDetails, assetDetails.turbidityMonitor) : null}
              {assetDetails ? bindActuatorSensorSite(assetDetails.actuatorSensorDetails, assetDetails.actuatorSensor) : null}
              {assetDetails ? bindPressureMeterSite(assetDetails.pressureMeterDetails, assetDetails.pressureMeter) : null}
              {assetDetails ? bindPHSensorSite(assetDetails.pHSensorDetails, assetDetails.pHSensor) : null}
            </tbody>
          </table>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
            <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={AssetreporthandlePrint}>
              Print
              <AiFillPrinter />
            </Button>
            <Button
              mt='15px'
              colorScheme='blue'
              style={{ display: 'flex', gap: '5px' }}
              onClick={exportToExcel}
            >
              Export to Excel
              <AiFillFileExcel />
            </Button>
          </div>
        </div>
        <div class="row mt-5 mb-3"><div class="col-12"><h2>Asset replacement history</h2></div></div>
        <div className='table-responsive'>
          <table className="table table-striped" ref={replacementHistoryTableRef}>
            <thead>
              <tr>
                <th scope="col">Name of Material</th>
                <th scope="col">Qty</th>
                <th scope="col">Make</th>
                <th scope="col">Model</th>
                <th scope="col">Sr. No</th>
                <th scope="col">Installed Date</th>
                <th scope="col">Replaced Date</th>
              </tr>
            </thead>
            <tbody>{bindHistoryData()}</tbody>
          </table>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
            <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrintReplceMentHistory}>
              Print
              <AiFillPrinter />
            </Button>
            <Button
              mt='15px'
              colorScheme='blue'
              style={{ display: 'flex', gap: '5px' }}
              onClick={exportToExcelReplacementHistory}
            >
              Export to Excel
              <AiFillFileExcel />
            </Button>
          </div>
        </div>
      </div>
    )
  }
  const noViewPermission = () => {
    return (<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className={noHeaders ? null : "page"}>
      {!noHeaders && <NavBarAdmin />}
      {console.log("ORIGINAL ", assetDetails)}
      {!noHeaders && <AdminMenu selMenu="AssetReport" />}
      <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head" style={{ display: "flex", alignItems: 'center' }}>Asset Reports <AiFillTool color='#2b6cb0' style={{ marginLeft: '7px' }} /></div>
            <h3 className="project-title fw-bold">Asset Reports</h3>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row d-flex justify-content-center h-100 pt-5">
            <div className="card text-black" style={{ borderRadius: "10px" }}>
              <div className="card-body">
                {isView ? bindData() : noViewPermission()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// Mqttaata.propTypes = {
//     projectId: PropTypes.number,
// };
// Mqttaata.defaultProp = {
//     projectId: null,
// };
export default Assetdata;