import {types} from './types';

export function doLogin(payload) {
  return {
    type: types.LOGIN_REQUEST_APPSETTINGS,
    payload,
  };
}


export function getAssignedSiteAction(payload) {
  return {
    type : types.ASSIGNED_SITES_REQUEST,
    payload
  }
}
export function clearData() {
  return {
    type: types.CLEAR_LOGIN_REQUEST_APPSETTINGS,
    payload:null,
  };
}