import {all} from 'redux-saga/effects';

// Sagas
import userSaga from './userSaga';
import siteSaga from './siteSaga';
import basicInfoSaga from './basicInfoSaga';
import newsSaga from './newsSaga';
import settingSaga from './settingSaga';
import siteAdminSaga from './siteAdminSaga';
import projectAdminSaga from './projectAdminSaga';
import graphSaga from './graphSaga';
import userAdminSaga from './userAdminSaga';
import companySaga from './companySaga';
import reportSaga from './reportSaga';

// Export the root saga
export default function* rootSaga() {
  // yield spawn(appSettingsSaga,authSaga);
  yield all([
    userSaga(),
    siteSaga(),
    basicInfoSaga(),
    newsSaga(),
    settingSaga(),
    siteAdminSaga(),
    projectAdminSaga(),
    graphSaga(),
    userAdminSaga(),
    companySaga(),
    reportSaga()
  ])
}
