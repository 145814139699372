import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Pumplist = ({data,handleEditEvent,handleDeleteEvent,isDelete,isEdit}) => {
    return (
      <div class="table-responsive">
        <table class="table align-middle mb-0 bg-white table-hover">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Make</th>
              <th scope="col">Model</th>
              {/* <th scope="col">Rating(KW)</th>
              <th scope="col">Head(m)</th>
              <th scope="col">Discharge(m3/hr)</th> */}
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length>0?
            data.map((item,index)=>(
              <tr key={index}>
                <td>{item.make.toString()}</td>
                <td>{item.model.toString()}</td>
                {/* <td>{item.SupportUserAtDesire.toString()}</td>
                <td>{item.SupportAdmin.toString()}</td> */}
                <td><button type="button" className="btn btn-outline-primary" onClick={()=>handleEditEvent(item)}>{isEdit?"Update":"View"}</button>  {isDelete?<Link className="btn btn-outline-danger" onClick={()=>handleDeleteEvent(item)}>Delete</Link>:null}</td>
                {/* <button type="button" className="btn btn-outline-danger" onClick={()=>handleDeleteEvent(item)}>Delete</button> */}
              </tr>)): <tr><td>No Record Found</td></tr>
            }
          </tbody>
        </table>
      </div>
    )
};
Pumplist.propTypes = {
    data: PropTypes.array,
    handleEditEvent: PropTypes.func,
    handleDeleteEvent: PropTypes.func,
    isDelete: PropTypes.bool,
    isEdit: PropTypes.bool
};
Pumplist.defaultProp = {
    data: [],
    handleEditEvent: {},
    handleDeleteEvent: {},
    isDelete: false,
    isEdit: false
};
export default Pumplist;
