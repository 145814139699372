import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {getUsersAPI,addUserAPI,editUserAPI, getUserDetailsAPI, deleteUserAPI,assignSitesToUserAPI,getAssignedSiteListAPI,
        getModulesAPI,getRolesAPI,addRoleAPI,getRoleDetailsAPI,updateRoleAPI} from '../../store/apis';

function* getUserAdminList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('getUserAdminList-payload-',payload);
    const responseObject = yield call(getUsersAPI,payload);   
    console.log('responseObject',responseObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_USERS_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_USERS_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_USERS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getUserAdmin({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('getUserAdmin-payload-',payload);
    const responseObject = yield call(getUserDetailsAPI,payload);   
    console.log('responseObject',responseObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_USER_DETAIL_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_USER_DETAIL_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_USER_DETAIL_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* addUserAdmin({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('addUserAdmin-',payload);
    const responseObject = yield call(addUserAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(responseObject.status=="success")
      yield put({type: types.ADD_USER_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.ADD_USER_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.ADD_USER_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* editUserAdmin({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(editUserAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.EDIT_USER_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.EDIT_USER_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.EDIT_USER_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* deleteUser({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(deleteUserAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.DELETE_USER_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.DELETE_USER_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.DELETE_USER_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* assignSitesToUser({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('addUserAdmin-',payload);
    const responseObject = yield call(assignSitesToUserAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(responseObject.status=="success")
      yield put({type: types.ASSIGN_SITES_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.ASSIGN_SITES_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.ASSIGN_SITES_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getAssignedSiteList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('getAssignedSiteList-',payload);
    const responseObject = yield call(getAssignedSiteListAPI,payload);   
    console.log('responseObject',responseObject); 
    // yield put({type: types.ASSIGNED_SITES_REQUEST_SUCCESS, payload: responseObject});
    if(responseObject.status=="success")
      yield put({type: types.ASSIGNED_SITES_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.ASSIGNED_SITES_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    console.log('appSettings',err);
    yield put({type: types.ASSIGNED_SITES_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getModuleList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('getModuleList',payload);
    const responseObject = yield call(getModulesAPI,payload);   
    console.log('responseObject',responseObject); 
    // yield put({type: types.GET_MODULES_SUCCESS, payload: responseObject});
    if(responseObject.status=="success")
      yield put({type: types.GET_MODULES_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_MODULES_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_MODULES_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getRoleList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('getRoleList',payload);
    const responseObject = yield call(getRolesAPI,payload);   
    console.log('responseObject',responseObject); 
    // yield put({type: types.GET_ROLES_SUCCESS, payload: responseObject});
    if(responseObject.status=="success")
      yield put({type: types.GET_ROLES_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_ROLES_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_ROLES_FAILURE, payload: err});
    // console.log(err);
  }
}
function* addRole({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('addRole',payload);
    const responseObject = yield call(addRoleAPI,payload);   
    console.log('responseObject',responseObject); 
    // yield put({type: types.GET_ROLES_SUCCESS, payload: responseObject});
    if(responseObject.status=="success")
      yield put({type: types.ADD_ROLES_SUCCESS, payload: responseObject});
    else
      yield put({type: types.ADD_ROLES_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.ADD_ROLES_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getRoleDetails({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('getRoleDetails-payload-',payload);
    const responseObject = yield call(getRoleDetailsAPI,payload);   
    console.log('responseObject',responseObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_ROLE_DETAILS_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_ROLE_DETAILS_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_ROLE_DETAILS_FAILURE, payload: err});
    // console.log(err);
  }
}
function* updateRole({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('updateRole',payload);
    const responseObject = yield call(updateRoleAPI,payload);   
    console.log('responseObject',responseObject); 
    // yield put({type: types.GET_ROLES_SUCCESS, payload: responseObject});
    if(responseObject.status=="success")
      yield put({type: types.EDIT_ROLES_SUCCESS, payload: responseObject});
    else
      yield put({type: types.EDIT_ROLES_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.EDIT_ROLES_FAILURE, payload: err});
    // console.log(err);
  }
}
export default function* userAdminSaga() {
  yield all([
    yield takeLatest(types.GET_USERS_REQUEST, getUserAdminList),
    yield takeLatest(types.ADD_USER_REQUEST, addUserAdmin),
    yield takeLatest(types.EDIT_USER_REQUEST, editUserAdmin),
    yield takeLatest(types.GET_USER_DETAIL_REQUEST, getUserAdmin),
    yield takeLatest(types.DELETE_USER_REQUEST, deleteUser),
    yield takeLatest(types.ASSIGN_SITES_REQUEST, assignSitesToUser),
    yield takeLatest(types.ASSIGNED_SITES_REQUEST, getAssignedSiteList), 
    yield takeLatest(types.GET_MODULES, getModuleList),    
    yield takeLatest(types.GET_ROLES, getRoleList),
    yield takeLatest(types.ADD_ROLES, addRole),
    yield takeLatest(types.GET_ROLE_DETAILS, getRoleDetails),
    yield takeLatest(types.EDIT_ROLES, updateRole),    
    // LOAD_LOGGEDIN_USER(),
  ])
}
