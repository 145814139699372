import React from 'react';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import HourlyReportComponent from "../../Components/HourlyReport";

const HourlyReport = () => {
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="News" />
      <HourlyReportComponent />
    </div>
  );
};
export default HourlyReport;
