import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModelPopup = ({isShow,title,children,clickCloseBtn,clickSubmitBtn}) => {
  // useEffect(()=>{
  //   console.log('ModelPopup-isShow',isShow);
  //   console.log('ModelPopup-title',title);
  //   console.log('ModelPopup-children',children);
  //   console.log('ModelPopup-clickCloseBtn',clickCloseBtn);
  //   console.log('ModelPopup-clickSubmitBtn',clickSubmitBtn);
  // },[])

  return (              
        <Modal show={isShow} backdropClassName="model-bg" onHide={clickCloseBtn}>
            <Modal.Header closeButton><Modal.Title>{title}</Modal.Title></Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={clickCloseBtn}>Close</Button>
                <Button variant="primary" onClick={clickSubmitBtn}>Submit</Button>
            </Modal.Footer>
        </Modal>
  );
};
ModelPopup.propTypes = {
    isShow: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
    clickCloseBtn: PropTypes.func,
    clickSubmitBtn: PropTypes.func,
};
ModelPopup.defaultProp = {
    isShow: false,
    title: "AquaLogix",
    clickCloseBtn: null,
    clickSubmitBtn: null,
};
export default ModelPopup;
