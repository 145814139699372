// export const serverPath="http://localhost:1009/";
// export const serverPath="http://al2.desireenergy.in:3000/";
// export const serverPath="http://mqtt.desireenergy.com/";
export const serverPath="http://mqtt.aqualogix.in:85/";
// export const serverPath="http://115.244.32.117/";
export const apiName={
    loginAPI:"users/login",
    siteListAPI:"sites/siteList",   
    projectForLogginedUsrListAPI:"sites/siteListByProjectList",
    siteDetailsAPI:"sites/siteDetails",
    projectDetailsAPI:"projects/projectDetails",
    basicInfoAPI:"sites/basicInfo",
    newsListAPI:"news/list",
    updateAssetParameterAPI : "master/updateAssetParameter",
    addNewsAPI:"news/add",
    fiveMinsData : "projects/get5MinsData",
    editNewsAPI:"news/update",
    newsDetailsByIdAPI:"news/edit",
    deleteNewsAPI:"news/dooelete",
    mqttDataAPI:"logs/logsList",
    sendDataToDeviceAPI:"iot/sendDataToDevice",
    getSitesByProjectIdAPI:"sites/getSitesByProjectId",
    geAllAssignedSitesByUserId: "sites/getAllAssignedSitesByUserId",
    getCompleteAssignedSitesByUserId : "sites/getCompleteAssignedSitesByUserId",
    addSiteAPI:"sites/add",
    updateSiteAPI:"sites/update",
    deleteSiteAPI:"sites/delete",
    updateSitesOfUserAPI: "sites/updateSitesOfUser",
    getSitesByProjectIdLtdAPI : "sites/getSitesByProjectIdLtd",
    getParameterThresholdsLogsAPI : "sites/getParamtersThresholdData",
    getSiteListWithProjects : "sites/getSiteListWithProjects?userId=",


    addProjectAPI:"projects/add",
    updateProjectAPI:"projects/update",
    deleteProjectAPI:"projects/delete",
    projectListAPI:"projects/list",
    getSiteReportAPI:"report/getSiteReport",
    getSiteReportbyDayHoursAPI:"report/getSiteReportbyDayHours",
    projectDetailsByIdAPI:"projects/edit",
    graphAPI:"sites/graph",
    addCompanyAPI:"companies/add",
    getCompanyDetailAPI:"companies/edit",
    updateCompanyAPI:"companies/update",
    deleteCompanyAPI:"companies/delete",
    companyListAPI:"companies/list",

    addUserAPI:"users/user",
    updateUserAPI:"users/user",
    getUserAPI:"users/user",
    deleteUserAPI:"users/delete",
    userListAPI:"users/user",
    assignSitesToUserAPI:"projects/assignProject",
    assignedSiteListAPI:"projects/assignedSiteList",

    addSiteExtraParametersAPI:"master/addExtraParameter",
    updateExtraParametersAPI:"master/updateExtraParameter",
    deleteExtraParameterAPI:"master/deleteExtraParameter",    
    getSiteExtraParametersAPI:"master/getExtraParameterList",
    updateSiteExtraParametersAPI:"sites/updateSiteParameter",
    replaceAssetAPI:"master/replaceAsset",
    getReplacedAssetHistory:"master/replacedAssetHistory",

    getRoles:"users/role",
    getModules:"users/module",
    addRole:"users/addRole",
    updateRole:"users/updateRole",
    approveImei: "users/approveImei",
    getRoleDetails:"users/getRoleDetails",
    homeGraphDataAPI:"sites/homeGraphData",
    getUserSitesReports: 'users/userSiteReports',

    // assetReportAPI:"sites/getSiteParameterhistory",
    assetReportAPI:"report/getAssetReplacementHistory",
    getImeiAprrovalUsers: "report/getImeiUserData",
    siteParameterListAPI:"sites/siteParameterList",
    getSiteNameFromCompanyAPI : "sites/getSiteNameFromCompany",
    getAssignedProjectsByCompanyIdAPI : "sites/getAssignedProjectsByCompanyId",
    getAssignedSitesByCompanyIdAPI : "sites/getAssignedSitesByCompanyId",
    getAssignedCompaniesByCompanyIdAPI: "sites/getAssignedCompaniesByCompanyId",

    getCountryAPI:"master/getCountry",
    getStateListByCountryAPI:"master/getStateListByCountry",
    getDistrictsListByStateAPI:"master/getDistrictsListByState",
    getBlocksListByDistrictAPI:"master/getBlocksListByDistrict",
    getPumpActivityAPI:"report/getPumpActivity",


    addCountryAPI:"citystatemaster/addCountry",
    updateCountryAPI:"citystatemaster/updateCountry",
    deleteCountryAPI:"citystatemaster/deleteCountry",    

    getStateAPI:"citystatemaster/listState",
    addStateAPI:"citystatemaster/addState",
    updateStateAPI:"citystatemaster/updateState",
    deleteStateAPI:"citystatemaster/deleteState", 

    getDistrictAPI:"citystatemaster/listDistrict",
    addDistrictAPI:"citystatemaster/addDistrict",
    updateDistrictAPI:"citystatemaster/updateDistrict",
    deleteDistrictAPI:"citystatemaster/deleteDistrict", 

    getBlockAPI:"citystatemaster/listBlock",
    addBlockAPI:"citystatemaster/addBlock",
    updateBlockAPI:"citystatemaster/updateBlock",
    deleteBlockAPI:"citystatemaster/deleteBlock", 
}

