import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Badge, Box, Button, Flex } from '@chakra-ui/react';

const DateRangePicker = ({ setStartDate, setEndDate, startDate, endDate }) => {

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Flex alignItems={'center'} justifyContent={'start'} mt={'10px'} mb={'10px'} border={'1px solid #ced4da'} gap={'2px'} height={'40px'} padding={'5px'} >
      {console.log(startDate, endDate)}
      <div style={{ borderRight: '1px solid #ced4da', marginRight: '2px' }}>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          padding='2px'
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          dateFormat={"dd-MM-yyyy"}
        />
      </div>
      <div>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEn
          padding='2px'
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          minDate={startDate}
          dateFormat={"dd-MM-yyyy"}
        />
      </div>

      <Button size={'sm'} variant={'solid'} colorScheme='blue' onClick={handleReset}>Reset</Button>
    </Flex>
  );
};

export default DateRangePicker;
