import React from 'react';
import PropTypes from 'prop-types';

const CanvasItem = ({label,value}) => {
    return (
            <li>
              <span className="title">{label}</span>
              <span className="amount">{value}</span>
            </li>
    );
};

CanvasItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
};
export default CanvasItem;
