import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {getNewsList,deleteNews} from '../../redux/actions/newsAction'
import {types} from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import {isEmpty, isLoggedInUser, checkPermission} from '../../utils'
import { Box, useToast } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Link as ChakraLink,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import TickerNews from './TickerNews';


const News = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listNews, setListNews] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);

  const newsReducer = useSelector(state => state.newsReducer);
  const toast = useToast()


  useEffect(()=>{   
    chkLogin();
    setPermission();    
  },[])
  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if(isView)
      fetchNewsList();
  },[isView])
  useEffect(()=>{
    console.log('News-newsReducer-useEffect',newsReducer);    
    if(newsReducer.action){
      if(newsReducer.action===types.GET_NEWS_REQUEST_SUCCESS){
        console.log('News-newsReducer-useEffect',newsReducer)      
        if(newsReducer.status==="success"){
          setListNews(newsReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
            title: newsReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }
      else if(newsReducer.action===types.DELETE_NEWS_REQUEST_SUCCESS){
        console.log('News-newsReducer-useEffect',newsReducer)      
        if(newsReducer.status==="success"){
          fetchNewsList();
          // setListSite(newsReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
            title: newsReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }
    }
  },[newsReducer.action])

  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  
  const setPermission=async()=>{
    setIsCreate(await checkPermission('News','create'));
    setIsEdit(await checkPermission('News','edit'));
    setIsDelete(await checkPermission('News','delete'));
    setIsView(await checkPermission('News','view'));
    console.log('Projects-User Permissions-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView); 
    // if(isView)
    //   fetchNewsList();
  } 
  const fetchNewsList=async()=>{
    // console.log("fetchSiteList");
    dispatch(getNewsList("page="+page+"&limit="+limit+""));
    // const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    // if(isLoggedIn){
    //   navigate('/Admin/SiteListing');
    // }
  } 
  const handleDeleteEvent=(item)=>{
    // Alert('Are you sure you want to remove?');
    // window.confirm("What Is Your Age?");
    // setSelectedId(item._id)
    console.log("adsf!=item",item);
    if (window.confirm("Are you sure you want to delete?")) {
      // setTimeout(() => {
        deleteSelNews(item._id);
      // }, 1000);
      console.log("You pressed OK!");
    } else {
      console.log("You pressed Cancel!");
    }
    // setSelectedId(item._id);
    // const request={id:item._id};
    // dispatch(deleteSite(item._id));
  }
  const handleEditEvent=(item)=>{
    console.log('Project',item);
    navigate('/Admin/AddNews',{state: {
      newsId: item._id,
      newsTitle: item.newsTitle,
    }});
    // navigate('/Admin/AddSite');
  }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   console.log(item);
  // }
  const deleteSelNews=async(selectedId)=>{   
    console.log("deleteSelNews-selectedId",selectedId); 
    dispatch(deleteNews(selectedId));
    // const interval = setInterval(() => {
    //   dispatch(deleteSite(selectedId));
    // }, 1000);
    // return () => clearInterval(interval);
    // console.log("deleteSite-selectedId",selectedId); 
    // dispatch(deleteSite(selectedId));
    // console.log('site',item);
  }
  const bindData=()=>{
    return (
      <TableContainer>
      <Table variant='striped' colorScheme='gray'>
        <Thead>
          <Tr>
            <Th fontSize={'md'}>News Name</Th>
            <Th fontSize={'md'}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {listNews.length > 0 ? (
            listNews.map((item, index) => (
              <Tr key={index}>
                <Td>{item.newsTitle.toString()}</Td>
                <Td>
                  <Button
                    colorScheme='blue'
                    onClick={() => handleEditEvent(item)}
                    size={'sm'}
                  >
                    {isEdit ? 'Update' : 'View'}
                  </Button>{' '}
                  {isDelete ? (
                    <Button
                      colorScheme='red'
                      size={'sm'}
                      onClick={() => handleDeleteEvent(item)}
                    >
                      Delete
                    </Button>
                  ) : null}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>No Record Found</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
    )
  }
  const noViewPermission=()=>{
    return(<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="News" />
      <div className="project-page-head" style={{width: '90%', borderRight : '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop : '10px'}}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">News</div>
            <h3 className="project-title fw-bold">News Listing</h3>
          </div>
        </div>
      </div>
      <section>     
        <Box style={{border : '1px solid black', padding : '3px'}}>
      <TickerNews news = {listNews}/>
        </Box> 
        <div className="container">        
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  {isView?bindData():noViewPermission()}                  
                  <div className="d-flex justify-content-center mx-2 mb-2 mb-lg-2">
                    {isCreate?<Link className="btn btn-primary mt-3" to={"/Admin/Addnews"}>Add News</Link>:null}
                  </div>
                </div>
              </div>              
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default News;
