// import { createSlice } from "@reduxjs/toolkit";
import {types} from '../actions/types';

const initialState = {
  loading: false,
  status:null,
  data:null,
  message:null,
  action:null,
};

const siteReducer=(state = initialState, {type, payload})=>{
  switch (type) {
    case types.GET_SITELIST_REQUEST_APPSETTINGS:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_SITELIST_REQUEST_APPSETTINGS
      };
    case types.GET_SITELIST_REQUEST_APPSETTINGS_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_SITELIST_REQUEST_APPSETTINGS_SUCCESS
      };
    case types.GET_SITELIST_REQUEST_APPSETTINGS_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_SITELIST_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_SITELIST_REQUEST_APPSETTINGS_FAILURE
      };
    case types.GET_SITEDETAILS_REQUEST_APPSETTINGS:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_SITEDETAILS_REQUEST_APPSETTINGS
      };
    case types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS
      };
    case types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE
      };
    case types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS
      };
    case types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS
      };
    case types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_FAILURE
      };
    case types.GET_MQTTDATA_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_MQTTDATA_REQUEST
      };
    case types.GET_MQTTDATA_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_MQTTDATA_REQUEST_SUCCESS
      };
    case types.GET_MQTTDATA_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_MQTTDATA_REQUEST_FAILURE
      };
    case types.PUSH_MQTTDATA_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.PUSH_MQTTDATA_REQUEST
      };
    case types.PUSH_MQTTDATA_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.PUSH_MQTTDATA_REQUEST_SUCCESS
      };
    case types.PUSH_MQTTDATA_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.PUSH_MQTTDATA_REQUEST_FAILURE
      };
    case types.GET_SITELIST_BY_PROJECT:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_SITELIST_BY_PROJECT
      };
    case types.GET_SITELIST_BY_PROJECT_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_SITELIST_BY_PROJECT_SUCCESS
      };
    case types.GET_SITELIST_BY_PROJECT_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_SITELIST_BY_PROJECT_FAILURE
      };
    case types.REPORT_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.REPORT_REQUEST
      };
    case types.REPORT_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.REPORT_REQUEST_SUCCESS
      };
    case types.REPORT_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.REPORT_REQUEST_FAILURE
      };
    case types.REPORT_HOURLY_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.REPORT_HOURLY_REQUEST
      };
    case types.REPORT_HOURLY_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.REPORT_HOURLY_REQUEST_SUCCESS
      };
    case types.REPORT_HOURLY_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.REPORT_HOURLY_REQUEST_FAILURE
      };
    case types.GET_DASHBOARD_LPCD_GRAPH_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_DASHBOARD_LPCD_GRAPH_REQUEST
      };
    case types.GET_DASHBOARD_LPCD_GRAPH_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_DASHBOARD_LPCD_GRAPH_REQUEST_SUCCESS
      };
    case types.GET_DASHBOARD_LPCD_GRAPH_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_DASHBOARD_LPCD_GRAPH_REQUEST_FAILURE
      };
    case types.GET_DASHBOARD_FHTC_GRAPH_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_DASHBOARD_FHTC_GRAPH_REQUEST
      };
    case types.GET_DASHBOARD_FHTC_GRAPH_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_DASHBOARD_FHTC_GRAPH_REQUEST_SUCCESS
      };
    case types.GET_DASHBOARD_FHTC_GRAPH_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_DASHBOARD_FHTC_GRAPH_REQUEST_FAILURE
      };
    case types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST
      };
    case types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_SUCCESS
      };
    case types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_FAILURE
      };
    case types.CLEAR_SITE_DATA:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.CLEAR_SITE_DATA
      };
    case types.CLEAR_SITE_DATA_SUCCESS:
      return {
        loading: false,
        status:null,
        data:null,
        message:null,
        action:types.CLEAR_SITE_DATA_SUCCESS
      };
    case types.CLEAR_SITE_DATA_FAILURE:
      return {
        loading: false,
        status:null,
        data:null,
        message:null,
        action:types.CLEAR_SITE_DATA_FAILURE
      };
    default:
      return state;
  }
}

export default siteReducer;