import React from 'react';
import NavBar from "../Components/NavBar";
import HourlyReportComponent from "../Components/HourlyReport";
import { MdTimer } from 'react-icons/md';

const HourlyReport = ({noHeaders, assignedSites ,projectList}) => {
  return (
    <div className={noHeaders ? null : "page"}>
      <div className="row m-0">
      <div className="project-page-head" style={{width: '90%', borderRight : '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop : '10px'}}>
            <div className="col-12 py-4">
              <div className="sub-head" style={{display : "flex", alignItems : 'center'}}>Hourly Reports <MdTimer color='#2b6cb0' style={{marginLeft : '7px'}}/> </div>
              <h3 className="project-title fw-bold">Hourly Reports</h3>
            </div>
          </div>
          </div>
      {!noHeaders && <NavBar isUserIcon={false} />}
      {console.log("HIHHIH", projectList)}
      <HourlyReportComponent projects = {projectList} newAssignedSites = {assignedSites} />
    </div>
  );
};
export default HourlyReport;
