import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import ClusterComponet from "./ClusterComponet";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Box, Button, Select, Flex, background, filter, Badge, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { getSiteListWithProjectsAPI } from '../../store/apis';
import { CONSTANTVALUE, getData, isEmpty } from '../../utils';
import axios from 'axios';
import { serverPath } from '../../store/serverConfig';
import { Card } from 'antd';
import Loader from '../../styles/Loader';


const MapComponent = ({ center, locations, mapHeight, mapTypeId, onInfoItemClick, zoomLevel, mapMarker }) => {
  const [map, setMap] = useState(null);
  const [initialCenter, setInitialCenter] = useState(center);
  const [initialZoom, setInitialZoom] = useState(zoomLevel);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [activeSites, setActiveSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfActiveSites, setNoOfActiveSites] = useState(0);
  const [numberOfInActiveSites, setNoOfInactiveSites] = useState(0);
  const [site, setSite] = useState('')
  const sites = useSelector((store) => store.siteReducer) || [];
  const [selectedSite, setSelectedSite] = useState(null);
  const [show, setShow] = useState(true);
  const [activeMap, setActiveMap] = useState(null);
  const [statusFilter, setStatusFilter] = useState(-1)
  const [newLocations, setNewLocations] = useState([]);
  const [onSites, setOnSites] = useState(null)
  const [offSites, setOffSites] = useState(null)
  useEffect(() => {
    getProjectSiteGroups();
    setTimeout(() =>
      checkFun()
      , 3000)
  }, [])

  useEffect(() => {
    const p = localStorage.getItem('selectedProject');
    setTimeout(() => {
      if (p) {
        setSelectedProject(p)
      }
    }, 1000)
  }, [])


  useEffect(() => {
    setNewLocationsFun();
  }, [statusFilter])

  const setNewLocationsFun = () => {
    if (!activeSites || activeSites?.length === 0) {
      return;
    }
    if (statusFilter !== -1) {
      const newMap = new Map();
      for (let i = 0; i < activeSites?.length; i++) {
        if (activeSites[i].active)
          newMap.set(activeSites[i].pumpName, activeSites[i].active);
      }
      console.log("DSCSADCASC", newMap)
      setActiveMap(newMap);
    } else {
      setActiveMap(null)
    }
  };


  const calculateCentroid = () => {
    // console.log("xxx cal", points)
    let latitude = 0;
    let longitude = 0;
    let n = newLocations.length;

    for (let i = 0; i < n; i++) {
      latitude += parseFloat(newLocations[i].lat);
      longitude += parseFloat(newLocations[i].lng);
    }
    const ans = { lat: latitude / n, lng: longitude / n }
    // console.log("xxx old", ans)
    return ans
  }



  const calculateZoomLevel = () => {
    const WORLD_WIDTH = 256; // Width of the world map at zoom level 0
    const PADDING_FACTOR = 3.5; // Factor to add padding around the sites

    if (newLocations.length === 0) {
      return 0; // Return default zoom level if there are no newLocations
    }
    if (newLocations.length === 1) {
      return 10; // Return default zoom level for one location
    }

    let minLat = Number.MAX_VALUE;
    let maxLat = Number.MIN_VALUE;
    let minLng = Number.MAX_VALUE;
    let maxLng = Number.MIN_VALUE;

    for (let i = 0; i < newLocations.length; i++) {
      const site = newLocations[i];
      minLat = Math.min(minLat, parseFloat(site.lat));
      maxLat = Math.max(maxLat, parseFloat(site.lat));
      minLng = Math.min(minLng, parseFloat(site.lng));
      maxLng = Math.max(maxLng, parseFloat(site.lng));
    }

    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;
    const zoomLat = Math.log2((WORLD_WIDTH * PADDING_FACTOR) / lngDiff);
    const zoomLng = Math.log2((WORLD_WIDTH * PADDING_FACTOR) / latDiff);
    const zoom = Math.floor(Math.min(zoomLat, zoomLng));

    return zoom;
  };




  useEffect(() => {
    // console.log("CSCDSC", selectedSites)
    getActiveSites()
  }, [selectedSites])

  const getActiveSites = async () => {
    setIsLoading(true);
    const newArr = selectedSites.map((item) => item.pumpName);
    setNewLocations(locations.filter((item) => newArr.includes(item.pumpName)))
    const sites = await axios.post('http://mqtt.aqualogix.in:85/sites/getActiveStatusOfSites', { sites: selectedSites });
    let on = 0;
    let off = 0;
    const onSites = [];
    const offSites = [];
    for (let i = 0; i < sites.data.response?.length; i++) {
      if (sites.data.response[i].active) {
        on++;
        onSites.push(sites.data.response[i]);
      } else {
        off++;
        offSites.push(sites.data.response[i]);
      }
    }
    setNoOfActiveSites(on);
    setNoOfInactiveSites(off);
    setActiveSites(sites.data.response);
    console.table("HEREEE", onSites)
    console.table("HEREEE", offSites)
    setIsLoading(false);
    setOnSites(onSites);
    setOffSites(offSites);
  }

  const getProjectSiteGroups = async () => {
    const user = await getData(CONSTANTVALUE.USEROBJECT);
    // console.log("XPXP", user);
    const info = await getSiteListWithProjectsAPI(user.id)
    const filteredProjects = info.message.filter((item) => item.project && item.sites?.length > 0);
    setProjects(filteredProjects);
  }


  const checkFun = () => {
    // console.log("CHECKTEXXX", sites.data)
    if (!sites.data.list || sites?.length == 0) setShow(false)
  }

  // console.log("POP", sites.data.list)
  useEffect(() => {
    if (center && newLocations?.length > 0) {
      setInitialCenter(center);
    }
  }, [center, newLocations]);

  const handleSiteChange = (event) => {
    const selectedSiteId = event
    const selectedSite = sites.data.list.find((site) => site.aquaLogixID === selectedSiteId);
    onInfoItemClick(selectedSite)
  };

  useEffect(() => {
    setInitialZoom(zoomLevel);
  }, [zoomLevel]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: 'AIzaSyC_mmyHAnkTF9dUjcTngzxG9eHfS3oMbfM',

  });

  const containerStyle = {
    width: "100%",
    height: mapHeight,
    position: "relative",
  };

  const onLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (selectedProject !== 'ALL') {
      const selectedProjectData = projects.find((item) => item.project._id === selectedProject);
      setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
      setNoOfActiveSites(0);
      setNoOfInactiveSites(0);
    } else {
      setSelectedSites([]);
    }
  }, [selectedProject]);

  const onUnmount = () => {
    setMap(null);
  };

  const resetView = () => {
    if (map) {
      map.panTo(initialCenter);
      map.setZoom(initialZoom);
    }
  };

  // const changeProject = (e) => {
  //   setSelectedProject(e.target.value)

  //   localStorage.setItem('selectedProject', e.target.value);
  // }
  const changeProject = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    const selectedName = selectedOption.text;

    localStorage.setItem('selectedProjectName', selectedName);

    setStatusFilter(-1);
    setNewLocations([]);
    setSelectedProject(e.target.value)
    localStorage.setItem('selectedProject', e.target.value);
  }

  const resetButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "100px",
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    cursor: 'pointer',
  };
  const resetButtonStyle2 = {
    position: "absolute",
    top: "10px",
    right: "240px",
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    cursor: 'pointer',
  };
  const resetButtonStyle3 = {
    position: "absolute",
    top: "10px",
    right: "400px",
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    cursor: 'pointer',
  };
  return (
    <div className="map-container">
      <div style={containerStyle}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={calculateCentroid()}
            zoom={calculateZoomLevel()}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapTypeId={mapTypeId ? mapTypeId : 'roadmap'}
          >
            {newLocations ? <ClusterComponet locations={!activeMap || statusFilter == -1 ? newLocations : newLocations.filter((item) => statusFilter == 1 ? activeMap.has(item.pumpName) : !activeMap.has(item.pumpName))} onInfoItemClick={onInfoItemClick} mapMarker={mapMarker} /> : null}
            {show && <>
              <Flex mt={'1%'} ml={'15%'}>

                <Select
                  id="pageSizeSelect"
                  value={selectedProject}
                  w={'15%'}
                  variant={'filled'}
                  onChange={(e) => changeProject(e)}
                  color="black"
                >
                  <option value={'ALL'}>Select Project</option>
                  {projects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                    <option key={item.project._id} value={item.project._id}>
                      {item.project.ProjectName}
                    </option>
                  ))}
                </Select>
                {selectedProject !== 'ALL' && (
                  <Select
                    w={'15%'}
                    variant={'filled'}
                    ml={2}
                    value={site}
                    onChange={(e) => { handleSiteChange(e.target.value) }}
                    color="black"
                  >
                    <option value={'ALL'}>Select Site</option>
                    {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                      <option key={site._id} value={site._id} imei={site.IMEI}>
                        {site.pumpName}
                      </option>
                    ))}
                  </Select>
                )}
              </Flex>
            </>}
          </GoogleMap>
        ) : (<div>Loading....</div>)}

        <div style={{
          position: "absolute",
          top: "5px",
          right: "220px",
          padding: '8px 16px',

        }}>

          {show && selectedProject && (
            isLoading ? <><Card>
              Loading Sites Information .......
            </Card></> : <Flex >
              <Stack direction='row' display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Badge variant='solid' fontSize={'xl'} colorScheme='green'>
                  {onSites.length}
                </Badge>
              </Stack>
              <Select
                w={'95%'}
                variant={'outlined'}
                ml={2}
                // value={site}
                onChange={(e) => { handleSiteChange(e.target.value) }}
                color="black"
              >
                <option value={'ALL'}>Active Sites</option>
                {onSites?.length == 0 ? <option value={'ALL'}>No Active Sites</option> : <></>}

                {onSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((item) => (
                  <option key={item._id} value={item._id} imei={site.IMEI}>
                    {item.pumpName}
                  </option>
                ))}
              </Select>

              <Stack direction='row' ml={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Badge variant='solid' fontSize={'xl'} colorScheme='red'>
                  {offSites.length}
                </Badge>
              </Stack>

              <Select
                w={'95%'}
                variant={'outlined'}
                ml={2}
                // value={site}
                onChange={(e) => { handleSiteChange(e.target.value) }}
                color="black"
              >
                <option value={'ALL'}>Inactive Sites</option>
                {offSites?.length == 0 ? <option value={'ALL'}>No Inactive Sites</option> : <></>}
                {offSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                  <option key={site._id} value={site._id} imei={site.IMEI}>
                    {site.pumpName}
                  </option>
                ))}
              </Select>

            </Flex>
          )
          }
        </div>
        {!isLoading && (!selectedProject || selectedProject != 'ALL') && <><Button style={resetButtonStyle} colorScheme='blue' onClick={() => resetView()}>Reset View</Button>
          {/* <Button style={resetButtonStyle2} colorScheme={statusFilter == 1 ? 'blue' : 'blackAlpha'} onClick={() => {
            if (statusFilter == 1) {
              setStatusFilter(-1)
            } else {
              setStatusFilter(1)
            }
          }}>{`Active Sites : ${numberOfActiveSites}`}</Button>
          <Button style={resetButtonStyle3} colorScheme={statusFilter == 0 ? 'blue' : 'blackAlpha'} onClick={() => {
            if (statusFilter == 0) {
              setStatusFilter(-1);
            } else {
              setStatusFilter(0)
            }
          }}>{`Non Active Sites : ${numberOfInActiveSites}`}</Button>
           */}
        </>}
      </div>
    </div>
  );
};

MapComponent.propTypes = {
  center: PropTypes.object,
  newLocations: PropTypes.array,
  mapHeight: PropTypes.string,
  mapTypeId: PropTypes.string,
  onInfoItemClick: PropTypes.func,
  zoomLevel: PropTypes.number,
  mapMarker: PropTypes.any,
};

MapComponent.defaultProps = {
  zoomLevel: 13,
  mapMarker: null,
};

export default MapComponent;