import React from 'react';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import MqttdataComponent from '../../Components/MqttdataComponent'

const Mqttdata = () => {
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="MQTTReport" />
      <MqttdataComponent />
    </div>
  );
};
export default Mqttdata;
