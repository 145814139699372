export const CONSTANTVALUE = {
    USEROBJECT: 'USEROBJECT',
    USERTOKEN: 'USERTOKEN',
    SECREATEKEY: 'AquaL0G!xKey1020',
    LOGINTYPE: 'LOGINTYPE',
    USERNAME: 'USERNAME',
    PASSWORD: 'PASSWORD',
};

export const CONSTANTVALUEEXTRAPARAM = {
    PUMP: 'pump',
    MOTOR: 'motor',
    FLOWMETER: 'flowMeter',
    PRESSUREMETER: 'pressureMeter',
    MULTIFUNCTIONMETER: 'multiFunctionMeter',
    PHSENSOR: 'pHSensor',
    LEVELSENSOR: 'levelSensor',
    TURBIDITYMONITOR: 'turbidityMonitor',
    CHLORINESENSOR: 'chlorineSensor',
    ACTUATORSENSOR: 'actuatorSensor',
};