import {combineReducers} from 'redux';
import dashboardReducer from '../../redux/reducers/dashboardSlice'
import userReducer from "../../redux/reducers/userReducer";
import siteReducer from "../../redux/reducers/siteReducer";
import basicInfoReducer from "../../redux/reducers/basicInfoReducer";
import newsReducer from "../../redux/reducers/newsReducer";
import settingReducer from "../../redux/reducers/settingReducer";
import siteAdminReducer from "../../redux/reducers/siteAdminReducer";
import projectAdminReducer from "../../redux/reducers/projectAdminReducer";
import graphReducer from "../../redux/reducers/graphReducer";
import companyReducer from "../../redux/reducers/companyReducer";
import userAdminReducer from "../../redux/reducers/userAdminReducer";
import reportReducer from "../../redux/reducers/reportReducer";


export default combineReducers({
    userReducer,dashboardReducer,siteReducer,basicInfoReducer,newsReducer,settingReducer,siteAdminReducer,projectAdminReducer,graphReducer,companyReducer,userAdminReducer,reportReducer
});

