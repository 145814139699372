import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
// import menuBurger from "../assets/menu-burger.svg";
import mainLogo from "../assets/images/aqualogix-logo.png";
import secondaryLogo from "../assets/images/hargharjal-logo.png";
import noimage from "../assets/images/noimage.png";
import userIcon from "../assets/images/NCCLogo.jpeg";
import { clearData } from '../redux/actions/userAction'
import { types } from '../redux/actions/types';
import { CONSTANTVALUE, getData } from '../utils'
// import ButtonWithSpinner from "../Components/ButtonWithSpinner";
// import {isEmpty} from '../utils'
import "../index.css";
import { MdAdminPanelSettings, MdDashboard, MdInfo, MdLogout, MdReport, MdTimer } from 'react-icons/md';

const NavBar = ({ page,projectLogo,isUserIcon }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if(isUserIcon==undefined)
      isUserIcon=true;
  // const [IMEINumber, setIMEINumber] = useState('');
  const [userObject, setUserObject] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  // const [userCompanyLogo, setUserCompanyLogo] = useState(null);
  const userReducer = useSelector(state => state.userReducer);
  // let userObject=null;
  const doLogout = async () => {
    //console.log('doLogout');
    localStorage.clear();
    dispatch(clearData());
    setTimeout(()=> {
      window.location.reload();
    }, 700)

  }
  const defaultSiteClick = () => {
    // navigate("/site-dashboard");
    navigate('/site-dashboard', {
      state: {
        siteId: "63bfb7db1623d4bbf263df0e",
        siteName: "Scheme-PIPRASI",
      }
    });
  }
  useEffect(() => {
    getUserObject();    
  }, [])
  useEffect(() => {
    //console.log('userReducer', userReducer);
    if (userReducer.action) {
      if (userReducer.action === types.CLEAR_LOGIN_REQUEST_APPSETTINGS_SUCCESS) {
        navigate('/');
      }
    }
    // if (isAdmin && isAdmin.role === 'admin') {
    //   setIsAdmin(true);
    // }
    // else{
    //   setIsAdmin(false);
    // }
   // setIsAdmin(isAdmin);

  }, [userReducer.action])
  const getUserObject = async () => {
    const userObject = await getData(CONSTANTVALUE.USEROBJECT);
    setUserObject(userObject);
    //console.log('userObject', userObject);
    if(userObject?.EmploymentType == "Admin"){
      setIsAdmin(true);
    }
    else{
      setIsAdmin(false);
    }
  }
  const handleError = (e) => {
    console.log(e)
  };
  return (
    <nav className="navBar navbar navbar-expand-lg navbar-light fixed-top bg-light w-100">
      <div className="container-fluid">
        <h1 className="logo-main">
          <img
            src={mainLogo}
            alt="mainLogo"
            className="d-inline-block align-top"
          />{" "}
        </h1>

        <Link className="navbar-brand d-none d-md-block" to={"/"}>
          <img
            src={secondaryLogo}
            alt="secondaryLogo"
            style={{ height: "80px", width: "294px" }}
            className="d-inline-block align-top"
          />
        </Link>

        <div className="dropdown user-dropdown">
          <Link
            className="dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            to={"#"}>
            {isUserIcon?<img src={projectLogo?projectLogo:(userObject ? userObject.companyDetails.Logo : noimage)} alt="User icon" style={{ height: "31px", width: "80px" }} className="d-inline-block align-top" onError={(e) => {e.target.src = noimage}} />:null}
            <i class="fa fa-bars" style={{ color: '#1989c6', fontSize: '28px', marginLeft: '20px', border: 0 }}></i>
          </Link>
          <div
            className="dropdown-menu user-dropdown-menu"
            aria-labelledby="navbarDropdown">
            <span className="dropdown-arrow"></span>
            <ul className="user-dropdown-menu">
              <li className="user-info">
                {/* <img
                  src={userIcon}
                  alt="User icon"
                  style={{ height: "31px", width: "80px" }}
                  className="d-inline-block align-top"
                /> */}
                <Link className="user-name" to={"#"}>{userObject ? userObject.OperatorName : ""}</Link></li>
              {isAdmin === true ? <li><Link style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}} to={"/Admin"}><MdAdminPanelSettings/> Admin</Link></li> : null}
              {page != 'Dashboard' ? <li><Link  to={"/dashboard"} style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}><MdDashboard/>Dashboard</Link></li> : null}
              {/*<li><button className="dropdown-item" onClick={()=>defaultSiteClick()}>Site Details(Scheme-PIPRASI)</button></li>
              <li><Link className="dropdown-item" to={"/mqttdata"}>MQTT Data</Link></li>*/}
             <li><Link to={"/AllReports"}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdReport style={{marginRight: '4px'}}/> Reports </div></Link></li>
             <li><Link to={"/ParameterThresholdLogs"}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color: 'white'}}> <MdReport style={{marginRight: '4px'}}/> Threshold Logs </div></Link></li>

              <li>
              <Link
            className="more-info"
            data-bs-toggle="modal"
            style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}
            data-bs-target="#staticBackdrop">
              <MdInfo/>
            More Info
        </Link>
              </li>
              <li><Link onClick={doLogout}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdLogout style={{marginRight: '4px'}}/>LOGOUT</div></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
NavBar.propTypes = {
  page: PropTypes.string,
  projectLogo: PropTypes.string,
  isUserIcon: PropTypes.bool,
};
NavBar.defaultProp = {
  page: "",
  projectLogo:null,
  isUserIcon:true,
};
export default NavBar;
