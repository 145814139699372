import {types} from './types';

export function getNewsList(payload) {
  return {
    type: types.GET_NEWS_REQUEST,
    payload,
  };
}
export function addNews(payload) {
  return {
    type: types.ADD_NEWS_REQUEST,
    payload,
  };
}
export function editNews(payload) {
  return {
    type: types.EDIT_NEWS_REQUEST,
    payload,
  };
}
export function deleteNews(payload) {
  return {
    type: types.DELETE_NEWS_REQUEST,
    payload,
  };
}
export function getNewsById(payload) {
  return {
    type: types.NEWS_BY_ID_REQUEST,
    payload,
  };
}
export function clearData() {
  return {
    type: types.CLEAR_NEWS_DATA_FAILURE,
    payload:null,
  };
}