import {types} from './types';

export function getCompany(payload) {
  return {
    type: types.GET_COMPANY_REQUEST,
    payload,
  };
}
export function getCompanyDetails(payload) {
  return {
    type: types.GET_COMPANY_DETIALS_REQUEST,
    payload,
  };
}
export function addCompany(payload) {
    return {
      type: types.ADD_COMPANY_REQUEST,
      payload,
    };
}
export function editCompany(payload) {
  return {
    type: types.EDIT_COMPANY_REQUEST,
    payload,
  };
}
export function deleteCompany(payload) {
    return {
      type: types.DELETE_COMPANY_REQUEST,
      payload,
    };
}