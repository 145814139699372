import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, deleteCompany } from '../../redux/actions/companyAction'
import { types } from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import { isEmpty, isLoggedInUser, checkPermission, getData, CONSTANTVALUE } from '../../utils'
import { apiGetCallWithPromise } from '../../store/axiosApicall';
import { apiName } from '../../store/serverConfig';
import { Box, Flex, Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Link as ChakraLink,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';

// import { Alert } from 'react-bootstrap';
// import { Link } from "react-router-dom";
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";
// import ButtonWithSpinner from "../../Components/ButtonWithSpinner";

const Company = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [listSite, setListSite] = useState([]);
  const [listCompany, setListCompany] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);
  const [assignedCompanies, setAssignedCompanies] = useState([])
  const [userId, setUserId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [searchValue, setSearchValue] = useState('')
  // const [selectedId, setSelectedId] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const companyReducer = useSelector(state => state.companyReducer);
  const toast = useToast()
  useEffect(() => {
    // setIsLoading(true);
    console.log('LogIn-useEffect');
    chkLogin();
    fetchUserData();
    setPermission();
  }, [])


  const fetchUserData = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    setUserId(data.id);
  }

  // useEffect(()=>{
  //   console.log('Company-useEffect-location',location);    
  //   // console.log('ProjectDashboard-useEffect-location.state.projectId',location.state.projectId);    
  //   if(location.state){
  //     if(location.state.isReload){
  //       // window.location.reload(false);
  //       // chkLogin(); 
  //       // setPermission();
  //     }
  //   }
  // },[location])
  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if (isView)
      fetchCompanyList();
  }, [isView])

  useEffect(() => {
    if (userId.length > 0)
      fetchAssignedCompanies();
  }, [userId])

  const fetchAssignedCompanies = async () => {
    try {
      const data = await apiGetCallWithPromise(apiName.getAssignedCompaniesByCompanyIdAPI, `userId=${userId}`);
      if (data && data.data) setAssignedCompanies(data.data);
    } catch (err) {
      console.log("Error while fetching assigned companies", err.message)
    }
  }

  useEffect(() => {
    console.log('Company-companyReducer-useEffect', companyReducer);
    if (companyReducer.action) {
      if (companyReducer.action === types.GET_COMPANY_REQUEST_SUCCESS) {
        console.log('Company-companyReducer-useEffect', companyReducer)
        if (companyReducer.status === "success") {
          setListCompany(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: companyReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (companyReducer.action === types.DELETE_COMPANY_REQUEST_SUCCESS) {
        console.log('Company-companyReducer-useEffect', companyReducer)
        if (companyReducer.status === "success") {
          fetchCompanyList();
          // setListSite(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: companyReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
    }
  }, [companyReducer.action])

  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  const setPermission = async () => {
    setIsCreate(await checkPermission('Company', 'create'));
    setIsEdit(await checkPermission('Company', 'edit'));
    setIsDelete(await checkPermission('Company', 'delete'));
    setIsView(await checkPermission('Company', 'view'));
    console.log('Company-User Permissions-isCreate=' + isCreate + '-isEdit-' + isEdit + '-isDelete-' + isDelete + '-isView-' + isView);
  }
  const fetchCompanyList = async () => {
    // console.log("fetchSiteList");
    dispatch(getCompany("page=" + page + "&limit=" + limit + ""));
    // const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    // if(isLoggedIn){
    //   navigate('/Admin/SiteListing');
    // }
  }
  const handleDeleteEvent = (item) => {
    // Alert('Are you sure you want to remove?');
    // window.confirm("What Is Your Age?");
    // setSelectedId(item._id)
    // console.log("adsf!=item",item);
    if (window.confirm("Are you sure you want to delete?")) {
      // setTimeout(() => {
      deleteSelCompany(item._id);
      // }, 1000);
      console.log("You pressed OK!");
    } else {
      console.log("You pressed Cancel!");
    }
    // setSelectedId(item._id);
    // const request={id:item._id};
    // dispatch(deleteSite(item._id));
  }
  const handleEditEvent = (item) => {
    // console.log('Company',item);
    navigate('/Admin/AddCompany', {
      state: {
        companyId: item._id,
        companyName: item.CompanyName,
      }
    });
    // navigate('/Admin/AddSite');
  }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   console.log(item);
  // }
  const deleteSelCompany = async (selectedId) => {
    // console.log("deleteSelCompany-selectedId",selectedId); 
    dispatch(deleteCompany(selectedId));
  }
  const bindData = () => {
    return (
      <TableContainer>
        <Table variant='striped' colorScheme='gray'>
          <Thead>
            <Tr>
              <Th fontSize={'md'}>Company Name</Th>
              <Th fontSize={'md'}>Remarks</Th>
              <Th fontSize={'md'}>Support User At Desire</Th>
              <Th fontSize={'md'}>Support Admin</Th>
              <Th fontSize={'md'}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {listCompany.length > 0 ? (
              listCompany.filter((item) => item.CompanyName.toString().toLowerCase().includes(searchValue.toLowerCase())).map((item, index) =>
                assignedCompanies.includes(item.CompanyName.toString()) ? (
                  <Tr key={index}>
                    <Td>{item.CompanyName.toString()}</Td>
                    <Td>{item.Remarks.toString() === 'nil' || item.Remarks.toString() === 'NA' ? '-' : item.Remarks.toString()}</Td>
                    <Td>{item.SupportUserAtDesire.toString()}</Td>
                    <Td>{item.SupportAdmin.toString()}</Td>
                    <Td>
                      <Button
                        colorScheme='blue'
                        onClick={() => handleEditEvent(item)}
                        size={'sm'}
                      >
                        {isEdit ? 'Update' : 'View'}
                      </Button>{' '}
                      {isDelete ? (
                        <Button
                          colorScheme='red'
                          size='sm'
                          onClick={() => handleDeleteEvent(item)}
                        >
                          Delete
                        </Button>
                      ) : null}
                    </Td>
                  </Tr>
                ) : null
              )
            ) : (
              <Tr>
                <Td>No Record Found</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };
  const noViewPermission = () => {
    return (<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Company" />
      <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">Company</div>
            <h3 className="project-title fw-bold">Company Listing</h3>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <Flex alignItems={'flex-end'} justifyContent={'end'} gap={'3'}>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <AiOutlineSearch color='gray.300' />
                </InputLeftElement>
                <Input size={'md'} placeholder='Enter company name' colorScheme='blue' variant='filled' onChange={(e) => setSearchValue(e.target.value)} />
              </InputGroup>
            </Box>
          </Flex>
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
            <div className="card text-black" style={{ borderRadius: "10px" }}>
              <div className="d-flex justify-content-end mx-2 mb-2 mb-lg-2">
                {isCreate ? <Link className="btn btn-primary mt-3" to={"/Admin/AddCompany"}>Add Company</Link> : null}
              </div>
              <div className="card-body">
                {isView ? bindData() : noViewPermission()}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Company;
