import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   scales: {
//     x: {
//       title: {
//         display: true,
//         text: "X-axis Label",
//       },
//     },
//     y: {
//       title: {
//         display: true,
//         text: "Y-axis Label",
//       },
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Bar Chart",
//     },
//   },
// };

let data=null;
const BarChartLPCD = ({dataList,xLabel,yLabel}) => {
  const [graphStatus, setGraphStatus] = useState(false);
  useEffect(()=>{      ;
    let labels=[];
    let countArray=[];
    dataList.map((item,index)=>{
      labels.push(item.x);
      countArray.push(item.y);
    })
    labels.map(() => countArray.map((count) => {
      return count
    }))
    data = {
      labels,
      datasets: [
        {
          label: "LPCD",
          data: countArray.map((count) => count != null ? parseFloat(count.toFixed(5)) : 0),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        }
      ],
    };
    if(labels.length>0)
      setGraphStatus(true);
  },[dataList])
  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel,
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Bar Chart",
      },
    },
  };
  if(graphStatus){
    // console.log('graphData-12',data);
    return (<Bar options={options} data={data} />);
  }    
};

BarChartLPCD.propTypes = {
  dataList: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};
export default BarChartLPCD;
