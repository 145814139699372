import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import ButtonWithSpinner from "../../../Components/ButtonWithSpinner";
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addBlockAPI,updateBlockAPI,getCountryAPI,getStateListPI,getStateAPI,getDistrictAPI} from '../../../store/apis';
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddDistrict = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [blockName, setBlockName] = useState('');
  // const [stateId, setStateId] = useState(null);  
  // const [countryId, setCountryId] = useState(null);
  const [localBlockId, setLocalBlockId] = useState(null); 
  const [selCountry,setSelCountry] = useState(null);
  const [countryList,setCountryList] = useState([]);
  const [selState,setSelState] = useState(null);
  const [stateList,setStateList] = useState([]);
  const [selDistrict,setSelDistrict] = useState(null);
  const [districtList,setDistrictList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const toast = useToast()

  useEffect(()=>{   
    chkLogin(); 
    setPermission(); 
    fetchCountryList();
    // fetchStateList();
    if(location){
      if(location.state){
        if(location.state.item){
          setBlockName(location.state.item.name);
          setSelState(location.state.item.stateId);
          setSelCountry(location.state.item.countryId);
          setSelDistrict(location.state.item.districtId);
          setLocalBlockId(location.state.item._id);
        }
      }
    }    
  },[])
  useEffect(()=>{        
    fetchStateList();
  },[selCountry])
  useEffect(()=>{        
    fetchDistrictList();
  },[selState])
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  
  const setPermission=async()=>{
    setIsEdit(await checkPermission('Block','edit'));
  }
  const fetchCountryList=async()=>{
    getCountryAPI().then((response)=>{
      // console.log('fetchCountryList-response',response);
      if(response)
        setCountryList(response.data);
        // selCountry?setSelCountry(response.filter((item)=>item._id==selCountry).name):setSelCountry(null);
    })
  }
  const fetchStateList=async()=>{
    getStateAPI("countryId="+selCountry).then((response)=>{
      // console.log('fetchCountryList-response',response);
      if(response)
        setStateList(response.data);
        // console.log('fetchStateList-response.data',response.data);
        // console.log('fetchStateList-selState',selState);
        // selState?setSelState(response.data.filter((item)=>item._id==selState).name):setSelState(null);
        // selState?setSelState(response.filter((item)=>item._id==selState).name):setSelState(null);
    })
  }
  const fetchDistrictList=async()=>{
    getDistrictAPI("stateId="+selState).then((response)=>{
      // console.log('fetchCountryList-response',response);
      if(response)
        setDistrictList(response.data);
        // console.log('fetchStateList-response.data',response.data);
        // console.log('fetchStateList-selState',selState);
        // selState?setSelState(response.data.filter((item)=>item._id==selState).name):setSelState(null);
        // selState?setSelState(response.filter((item)=>item._id==selState).name):setSelState(null);
    })
  }
//   useEffect(()=>{
//     console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
//     if(siteAdminReducer.action){
//       if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
//         if(siteAdminReducer.status==="success"){          
//           //navigate('/Admin/Pump');
//           dispatch(clearSiteReducer()); 
//           navigate('/Admin/Pump', {
//             moduleType: CONSTANTVALUEEXTRAPARAM.PUMP
//           });
//         }else {
//           toast({
//         title: siteAdminReducer.message,
//         status: 'warning',
//         duration: 2000,
//         isClosable: true,
//         position: "top-right",
//         positionOptions: {
//           right: "0",
//           top: "0",
//         },
//     });
//         }
//       }
//       if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
//         if(siteAdminReducer.status==="success"){          
//           //navigate('/Admin/Pump');          
//           dispatch(clearSiteReducer());   
//           navigate('/Admin/Pump', {
//             moduleType: CONSTANTVALUEEXTRAPARAM.PUMP
//           });
//         }else {
//           toast({
//         title: siteAdminReducer.message,
//         status: 'warning',
//         duration: 2000,
//         isClosable: true,
//         position: "top-right",
//         positionOptions: {
//           right: "0",
//           top: "0",
//         },
//     });
//         }
//       }
//     }
//   },[siteAdminReducer.action])
  const handleSiteInput=(e)=>{    
    //////////////// State input validation ////////////////////////////////////
    if(e.target.name==="txtName"){
      setBlockName(e.target.value);
    }
    // if(e.target.name==="txtCode"){
    //   setStateCode(e.target.value);
    // }
    // if(e.target.name==="txtISOcode"){
    //   setISOCode(e.target.value);
    // }
    ////////////////////////////////////////////////////////////////
  }
  const handleChangeEvent=(e)=>{
    if(e.target.name==="selCountry"){
      setSelCountry(e.target.value);
    }
    if(e.target.name==="selState"){
      setSelState(e.target.value);
    }
    if(e.target.name==="selDistrict"){
      setSelDistrict(e.target.value);
    }
  }
  const handleSubmit=()=>{
    console.log('selCountry',selCountry);
    console.log('selState',selState);
    if(isEmpty(selCountry)){
      toast({
        title: 'please select country',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(selState)){
      toast({
        title: 'please select state',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(selDistrict)){
      toast({
        title: 'please select district',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(blockName)){
      toast({
        title: 'please enter block name',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localBlockId){
        const request={
          "id": localBlockId,
          "name":blockName,
          "stateId":selState,
          "countryId":selCountry,
          "districtId":selDistrict,
        };
        updateBlockAPI(request).then((response)=>{
            if(response)
                navigate('/Admin/Block');
            // setSelCountry(response.data[0]._id);
        });     
      }
      else{
        const request={
            "name":blockName,
            "stateId":selState,
            "countryId":selCountry,
            "districtId":selDistrict,
        };
        addBlockAPI(request).then((response)=>{
            if(response)
                navigate('/Admin/Block');
            // setSelCountry(response.data[0]._id);
        });
      }
      // handlePumpShow();
    }    
  }  
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddBlock" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localBlockId?"Update Block":"Add Block"}</p>
                    {/* <form className="mx-1 mx-md-2"> */}
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="sourcetype" className="col-sm-3 col-form-label">Country</label>
                          <select className="selectpicker form-control" name="selCountry" onChange={(e)=>handleChangeEvent(e)}>
                            <option value="">Select Country</option>
                            {countryList.map((item,index)=>{
                              return <option key={index} value={item._id} selected={selCountry==item._id?true:false}>{item.name}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="sourcetype" className="col-sm-3 col-form-label">State</label>
                          <select className="selectpicker form-control" name="selState" onChange={(e)=>handleChangeEvent(e)}>
                            <option value="">Select State</option>
                            {stateList.length>0?stateList.map((item,index)=>{
                              console.log("selState",selState);
                              return <option key={index} value={item._id} selected={selState==item._id?true:false}>{item.name}</option>
                            }):null}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="sourcetype" className="col-sm-3 col-form-label">District</label>
                          <select className="selectpicker form-control" name="selDistrict" onChange={(e)=>handleChangeEvent(e)}>
                            <option value="">Select District</option>
                            {districtList.length>0?districtList.map((item,index)=>{
                              console.log("selDistrict",selDistrict);
                              return <option key={index} value={item._id} selected={selDistrict==item._id?true:false}>{item.name}</option>
                            }):null}
                          </select>
                        </div>
                      </div>
                      {/* <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="sourcetype" className="col-sm-3 col-form-label">State</label>
                          <select className="selectpicker form-control" name="selState" onChange={(e)=>handleChangeEvent(e)}>
                            <option value="">Select State</option>
                            {stateList.map((item,index)=>{
                              return <option key={index} value={item._id} selected={selState==item._id?true:false}>{item.name}</option>
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="make" className="col-sm-4 col-form-label">Name</label>
                          <input type="text" name="txtName" id="txtName" value={blockName} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>                                
                      <div className="d-flex justify-content-center mb-3">
                        {/* <Button variant="primary" onClick={()=>handleSubmit()}>Submit</Button> */}
                        {isEdit?<ButtonWithSpinner onClick={()=>handleSubmit()} isLoading={loading?true:false} label={'Save'} />:null}
                      </div>  
                    {/* </form>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddDistrict;
