import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLeftCanvas: false,
    isRightCanvas: false,
    isTopCanvas: false,
    // isLoading: false,
  },
  reducers: {
    leftCanvasToggle: (state) => {
      state.isLeftCanvas = !state.isLeftCanvas;
      // state.isLoading = true;
    },
    rightCanvasToggle: (state) => {
      state.isRightCanvas = !state.isRightCanvas;
    },
    topCanvasToggle: (state) => {
      state.isTopCanvas = !state.isTopCanvas;
    },
  },
});
export const { leftCanvasToggle, rightCanvasToggle, topCanvasToggle } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
