/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import NavBar from "../Components/NavBar";
import CardPressure from "../Components/Card/CardPressure";
import './site.css'
import CardLPCD from "../Components/Card/CardLPCD";
import CardChlorine from "../Components/Card/CardChlorine";
import CardWatersupplied from "../Components/Card/CardWatersupplied";
import CardTurbidity from "../Components/Card/CardTurbidity";
import CardOHRS from "../Components/Card/CardOHRS";
import avtarImage from "../assets/images/icon-user.svg";
import MapComponent from "../Components/Map/MapComponent";
import mapMarker from "../assets/images/member.svg";
import { getSiteDetails, pushMqttData, getProjectDetails } from '../redux/actions/siteAction'
import { types } from '../redux/actions/types';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// import ButtonWithSpinner from "../Components/ButtonWithSpinner";
import SensorValue from "../Components/SensorValue";
import { isEmpty, checkPermission } from '../utils';
import { setIMEINumber } from '../redux/actions/settingAction';
import { initializeApp } from "firebase/app";
import srcImage from './image_2023_10_05T11_56_29_197Z.png'
import { getDatabase, onValue, ref, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { Badge, Image, Tooltip, useToast } from '@chakra-ui/react';
import Loader from '../styles/Loader';
import gif from './Untitled design (5).gif'
import wave from './wave.gif'
import manual from './icons8-manual.gif';
import auto from './icons8-gears.gif'
import { Card, CardBody, Heading, Box, Text, Stack, StackDivider, Switch } from '@chakra-ui/react'
import { Button } from 'antd';
// import { projectDetailsAPI } from '../store/apis';
var moment = require('moment');


let locations = [];
const center = {
  lat: 23.0363778,
  lng: 72.5416146,
};
let timeOutSiteDetails = null;
const SiteDashboard = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // debugger;  
  const siteReducer = useSelector(state => state.siteReducer);
  const [isFlip, setIsFlip] = useState(true);
  const settingReducer = useSelector(state => state.settingReducer);
  const [siteDetails, setSiteDetails] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [siteId, setSiteId] = useState("");
  const [lastUpdate, setLastUpdate] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [waterSupplied, setWaterSupplied] = useState("");
  const [flowRate, setFlowRate] = useState("");
  const [level, setLevel] = useState("");
  const [discharge, setDischarge] = useState("60 M3/H");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [panchaayat, setPanchaayat] = useState("");
  const [dmts, setDmts] = useState(false);
  const [popSerThSchemes, setPopSerThSchemes] = useState("");
  const [locWatSources, setLocWatSources] = useState("");
  const [scheme, setScheme] = useState("");
  const [yearOfComi, setYearOfComi] = useState("");
  const [noOfHabit, setNoOfHabit] = useState("");
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [IMEINu, setIMEINu] = useState('860987058180299');
  const [waterData, setWaterData] = useState([]);
  const [motorData, setMotorData] = useState([]);
  const [flushValve, setFlushValve] = useState("");
  const [inletValveAM, setInletValveAM] = useState(0);
  const [outeletVavleAM, setOutletValveAM] = useState(0);
  const [flushVavleAM, setFlushValveAM] = useState(0);
  const [flushValveStyl, setFlushValveStyl] = useState("valve-on");
  const [inletValve, setInletValve] = useState("");
  const [inletValveStyl, setInletValveStyl] = useState("valve-on");
  const [outletValve, setOutletValve] = useState(0);
  const [outletValveLocal, setOutletValveLocal] = useState(false);
  const [systemMode, setSystemMode] = useState(false);
  const [systemModeLocal, setSystemModeLocal] = useState(false);
  const [isSystemModeStatus, setIsSystemModeStatus] = useState(false);
  const [isOutletValveStatus, setIsOutletValveStatus] = useState(false);
  const [pumpStatus, setPumpStatus] = useState("");
  const [pumpStatusStyl, setPumpStatusStyl] = useState("valve-on");
  const [depthSensor, setDepthSensor] = useState("");
  const [todayProduction, setTodayProduction] = useState("");
  const [rating, setRating] = useState("");
  const [serverFile, setServerFile] = useState();
  const [totalEmp, setTotalEmp] = useState(0);
  const [parametersList1, setParametersList1] = useState([]);
  const [parametersList2, setParametersList2] = useState([]);
  const [parametersList3, setParametersList3] = useState([]);
  const [parametersList4, setParametersList4] = useState([]);
  const [projectId, setProjectId] = useState('')
  const [parametersList5, setParametersList5] = useState([]);
  const [graphs, setGraphs] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [siteDetailsLocal, setSiteDetailsLocal] = useState(null);
  const toast = useToast()
  let firstTime = true;

  // const [centerPoint, setCenterPoint]=useState({
  //     lat: 23.0363778,
  //     lng: 72.5416146,
  //   });
  const [centerPoint, setCenterPoint] = useState(null);
  const [centerPointDriver, setCenterPointDriver] = useState(null);
  const [locationList, setLocationList] = useState(locations);
  const [markerList, setMarkerList] = useState([]);
  const [isOutletValveChecked, setIsOutletValveChecked] = useState(false);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isExtraInformationPermission, setIsExtraInformationPermission] = useState(false);

  const [isLoading, setIsLoading] = useState(false)


  // useEffect(() => {
  //   fetchTemp();
  // }, [projectName, projectId])
  // const fetchTemp = async () => {
  //   const data = await projectDetailsAPI(projectId);
  // }
  let driverMarker = [];
  const location = useLocation();

  useEffect(() => {
    ////////////////////////////////////////////////////////////
    /// Following code is to get the firebase database value 
    //////////////////////////////////////////////////////////// 
    const firebaseConfig = {
      apiKey: "AIzaSyBWTpB6tDBfW-2gK_4ygJeyihWtyAnvdj4",
      authDomain: "desire-o-and-m.firebaseapp.com",
      databaseURL: "https://desire-o-and-m.firebaseio.com",
      projectId: "desire-o-and-m",
      storageBucket: "desire-o-and-m.appspot.com",
      messagingSenderId: "820152855217"
    }
    const app = initializeApp(firebaseConfig);
    // Firebase.initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const commentsRef = ref(database);
    onChildAdded(commentsRef, (data) => {
      if (data.val().groupFor.toUpperCase() == "AQUALOGIX") {
        //console.log('onChildAdded-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
        addMarker(data, "A");
      }
    });

    onChildChanged(commentsRef, (data) => {
      const item = driverMarker.findIndex((item) => item.empData.empID == data.val().empID);
      // console.log('onChildChanged-item',item);
      if (parseInt(item) == -1) {
        if (data.val().groupFor.toUpperCase() == "AQUALOGIX") {
          //console.log('onChildChanged-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
          addMarker(data, "A");
        }

      }
      else {
        // console.log('onChildChanged-ELSE');
        if (data) {
          const markerListLocal = {
            lat: parseFloat(data.val().lat),
            lng: parseFloat(data.val().long),
            _id: data.val().uniqId,
            empData: data.val(),
          };
          driverMarker.splice(item, 1, markerListLocal);
          // console.log('onChildChanged-driverMarker',driverMarker);
          // console.log('onChildChanged-item',item);
          // console.log('onChildChanged-data',data);          
          if (data.val().groupFor.toUpperCase() == "AQUALOGIX") {
            //console.log('onChildChanged-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
            addMarker("", "E");
          }

        }
      }
    });

    onChildRemoved(commentsRef, (data) => {
      // console.log('data-onChildRemoved',data);
      // console.log('data-onChildRemoved-data',data);
      const item = driverMarker.findIndex((item) => item._id.toString() == data.val().uniqId.toString());
      if (parseInt(item) > -1) {
        // console.log('onChildChanged-ELSE');
        if (data) {
          driverMarker.splice(item, 1);
          addMarker("", "D");
        }
      }
    });
    ////////////////////////////////////////////////////////////

    setPermission();
  }, []);
  useEffect(() => {
    // console.log('SiteDashboard-useEffect-location',location);    
    // setSiteId(location.state.siteId);
    if (location.state) {
      setSiteName(location.state.siteName);
      fetchSiteDetails();
      // commented beacuse its running very slow due to unkown reasons
      timeOutSiteDetails = setInterval(() => { fetchSiteDetails(); }, 40000);
    }
    // console.log('ProjectDetails-siteId',location.state.siteId);
    // console.log('ProjectDetails-siteName',location.state.siteName);     
    // fetchBasicInfo();
    return () => clearInterval(timeOutSiteDetails);
  }, [location])
  useEffect(() => {
    // console.log('Dashboard-siteReducer-useEffect',siteReducer);    
    //comecomecome

    if (siteReducer.action) {
      ((!siteReducer.data || graphs.length == 0) && setIsLoading(true))
      if (siteReducer.action === types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS) {
        // console.log('ProjectDetails-siteReducer-useEffect',siteReducer)        
        if (siteReducer.status === "success") {
          if (siteReducer.data) {
            console.log("DVCSDVSD", siteReducer.data)
            setSiteDetailsLocal(siteReducer.data);
            setState(siteReducer.data.address.state);
            setDistrict(siteReducer.data.address.district);
            setBlock(siteReducer.data.address.block);
            setPanchaayat(siteReducer.data.address.panchayat);
            setPopSerThSchemes(siteReducer.data.population);
            setScheme(siteReducer.data.scheme);
            setYearOfComi(siteReducer.data.yearCommissioning);
            setNoOfHabit(siteReducer.data.noConnections);
            setProjectId(siteReducer.data.projectID);
            setProjectName(siteReducer.data.projectName);
            setServerFile(siteReducer.data.logo);
            setWaterSupplied(siteReducer.data.cummFlowKL);
            setFlowRate(siteReducer.data.flowMeterPerHourClosring);
            setLocWatSources(siteReducer.data.sourceType);
            setLevel(siteReducer.data.pHSensor);
            setLastUpdate(siteReducer.data.parameterUpdatedDate);
            setIMEINu(siteReducer.data.IMEI);
            setTodayProduction(siteReducer.data.todayProduction);
            setRating(siteReducer.data.rating);
            // center = {
            //   lat:siteReducer.data.latitude,
            //   lng:siteReducer.data.longitude
            // };


            //#####################################//
            // API call to get dynamic parameters //
            // Note: As of now keep comment because client want to keep it commented.
            //#####################################//
            if (siteReducer.data.projectID)
              fetchProjectDetails(siteReducer.data.projectID);
            //#####################################//


            const locations = [{
              lat: parseFloat(siteReducer.data.latitude),
              lng: parseFloat(siteReducer.data.longitude),
              _id: siteReducer.data._id,
              lastUpdate: siteReducer.data.updatedDate,
              projectName: siteReducer.data.pumpName,
              pumpName: siteReducer.data.pumpName,
              site: {
                lastUpdate: siteReducer.data.updatedDate,
                projectName: siteReducer.data.pumpName,
                pumpName: siteReducer.data.pumpName,
                // content: [
                //   { lbl: "Maximum Discharge", val: "425 V" },
                //   { lbl: "Maximum Discharge", val: "425 V" },
                //   { lbl: "Maximum Discharge", val: "425 V" },
                //   { lbl: "Maximum Discharge", val: "425 V" },
                // ],
              },
            }]
            setLocationList(locations);
            setCenterPoint({ lat: parseFloat(siteReducer.data.latitude), lng: parseFloat(siteReducer.data.longitude) });

            if (siteReducer.data.F1 == 0) {
              setFlushValve("Closed");
              setFlushValveStyl("valve-off");
            } else if (siteReducer.data.F1 == "1") {
              setFlushValve("Open");
              setFlushValveStyl("valve-on");
            } else if (siteReducer.data.F1 == 2) {
              setFlushValve("In Process");
              setFlushValveStyl("valve-inprocess");
            } else if (siteReducer.data.F1 == 3) {
              setFlushValve("Error");
              setFlushValveStyl("valve-error");
            } else if (siteReducer.data.F1 == 4) {
              setFlushValve("Error");
              setFlushValveStyl("valve-error");
            }

            if (siteReducer.data.F3 == 0) {
              setInletValve("Closed");
              setInletValveStyl("valve-off");
            } else if (siteReducer.data.F3 == 1) {
              setInletValve("Open");
              setInletValveStyl("valve-on");
            } else if (siteReducer.data.F3 == 2) {
              setInletValve("In Process");
              setInletValveStyl("valve-inprocess");
            } else if (siteReducer.data.F3 == 3) {
              setInletValve("Error");
              setInletValveStyl("valve-error");
            } else if (siteReducer.data.F3 == 4) {
              setInletValve("Error");
              setInletValveStyl("valve-error");
            }

            if (siteReducer.data.F4) {
              setOutletValve(siteReducer.data.F4)
            }

            if (siteReducer.data.F16 == 0) {
              setSystemMode(false);
            } else if (siteReducer.data.F16 == 1) {
              setSystemMode(true);
            } else if (siteReducer.data.F16 == 2) {
              setSystemMode(false);
            } else if (siteReducer.data.F16 == 3) {
              setSystemMode(false);
            } else if (siteReducer.data.F16 == 4) {
              setSystemMode(false);
            }

            if (siteReducer.data.F7 == 0) {
              setPumpStatus("Off");
              setPumpStatusStyl("valve-off");
            } else if (siteReducer.data.F7 == 1) {
              setPumpStatus("On");
              setPumpStatusStyl("valve-on");
            } else if (siteReducer.data.F7 == 2) {
              setPumpStatus("In Process");
              setPumpStatusStyl("valve-inprocess");
            } else if (siteReducer.data.F7 == 3) {
              setPumpStatus("Error");
              setPumpStatusStyl("valve-error");
            } else if (siteReducer.data.F7 == 4) {
              setPumpStatus("Error");
              setPumpStatusStyl("valve-error");
            }
            if (siteReducer.data.F2 && siteReducer.data.F2 == 1) setFlushValveAM(1)
            if (siteReducer.data.F5 && siteReducer.data.F5 == 1) setInletValveAM(1)
            if (siteReducer.data.F6 && siteReducer.data.F6 == 1) setOutletValveAM(1)

            setDepthSensor(siteReducer.data.P3 ? siteReducer.data.P3.toFixed(2) + " Mtr" : "0 Mtr");

            // const waterDataArray=[];                    
            // waterDataArray.push({"imgIc":require("../assets/icons/Turbidity.png"),"label":"Turbidity","value":siteReducer.data.P5?(siteReducer.data.P5 * 10).toFixed(2)+" NTU":"0 NTU"});
            // waterDataArray.push({"imgIc":require("../assets/icons/Pressure.png"),"label":"Pressure","value":siteReducer.data.P1?(siteReducer.data.P1 * 10).toFixed(2)+" BAR":"0 BAR"});
            // waterDataArray.push({"imgIc":require("../assets/icons/FlowRate.png"),"label":"Flow Rate","value":siteReducer.data.P12?siteReducer.data.P12.toFixed(2)+" LPM":"0 LPM"});
            // waterDataArray.push({"imgIc":require("../assets/icons/TotalFlow.png"),"label":"Total Flow","value":siteReducer.data.P13?siteReducer.data.P13 +" L":"0 L"});
            // waterDataArray.push({"imgIc":require("../assets/icons/Chlorine.png"),"label":"Chlorine","value":siteReducer.data.P4?(siteReducer.data.P4/10).toFixed(2)+" PPM":"0 PPM"});
            // waterDataArray.push({"imgIc":require("../assets/icons/LevelSensor.png"),"label":"Level Sensor","value":siteReducer.data.P2?siteReducer.data.P2.toFixed(2)+"%":"0%"});
            // setParametersList1(waterDataArray);
            // setWaterData(waterDataArray)

            // const motorDataArray=[]; 
            // motorDataArray.push({"imgIc":require("../assets/icons/SolarPower.png"),"label":"Power","value":siteReducer.data.P8?(siteReducer.data.P8 * 100).toFixed(2)+" KW":"0 KW"});
            // motorDataArray.push({"imgIc":require("../assets/icons/DCVoltage.png"),"label":"DC Volt","value":siteReducer.data.P10?siteReducer.data.P10.toFixed(2)+" V":"0 V"});
            // motorDataArray.push({"imgIc":require("../assets/icons/Turbidity.png"),"label":"Depth in Mtr","value":siteReducer.data.P3?(siteReducer.data.P3/10).toFixed(2)+" Mtr":"0 Mtr"});
            // motorDataArray.push({"imgIc":require("../assets/icons/MotarCurrent.png"),"label":"Motor Current","value":siteReducer.data.P7?(siteReducer.data.P7 * 100).toFixed(2)+" A":"0 A"});
            // motorDataArray.push({"imgIc":require('../assets/new icons/freq.gif'),"label":"Motor Frequency","value":siteReducer.data.P9?(siteReducer.data.P9 * 100).toFixed(2)+" Hz":"0 Hz"});
            // motorDataArray.push({"imgIc":require("../assets/icons/KWH.png"),"label":"KWH","value":siteReducer.data.kwhDiff?(siteReducer.data.kwhDiff/10).toFixed(2)+" KWH":"0 KWH"});
            // setParametersList2(motorDataArray);
            // setMotorData(motorDataArray)
          }
        } else {
          toast({
            title: siteReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
        setIsLoading(false)

      }
      else if (siteReducer.action === types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE) {
        toast({
          title: siteReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        setIsLoading(false)

      }
      else if (siteReducer.action === types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS) {
        console.log('ProjectDetails-siteReducer-useEffect', siteReducer)
        setDmts(siteReducer.data.dmtsOption)
        if (siteReducer.status === "success") {
          //###########################################//
          //Success block to manage parameter dynamically.
          //##########################################//
          if (siteReducer.data) {
            if (siteReducer.data.extraInformation != null && siteReducer.data.extraInformation != undefined) setIsExtraInformationPermission(siteReducer.data.extraInformation)
            if (siteReducer.data.parameters) {
              let siteParametersLocalList1 = [];
              let siteParametersLocalList2 = [];
              let siteParametersLocalList3 = [];
              let siteParametersLocalList4 = [];
              let siteParametersLocalList5 = [];
              let siteGraphs = [];
              let siteFeedbacks = [];
              let count = 0;
              siteReducer.data.parameters.map((item, index) => {
                if (item) {
                  if (item.isActive) {
                    // console.log('item',item);
                    if (manageParameterObj(item)) {
                      if (count < 6) {
                        siteParametersLocalList1.push(manageParameterObj(item));
                      }
                      else if (count => 6 && count < 12) {
                        siteParametersLocalList2.push(manageParameterObj(item));
                        console.log("HEREEEEEE", siteParametersLocalList2)
                        console.log("HEREEEEEE", siteParametersLocalList2)
                      }
                      else if (count => 12 && count < 18) {
                        siteParametersLocalList3.push(manageParameterObj(item));
                      }
                      else if (count => 18 && count < 24) {
                        siteParametersLocalList4.push(manageParameterObj(item));
                      }
                      else if (count => 24 && count < 30) {
                        siteParametersLocalList5.push(manageParameterObj(item));
                      }
                      count++;
                    }
                  }
                }
              })
              siteReducer.data.graphs.map((item, index) => {
                if (item) {
                  if (item.isActive) {
                    siteGraphs.push(item);
                  }
                }
              })

              siteReducer.data.feedbacks.map((item, index) => {
                if (item) {
                  if (item.isActive) {
                    siteFeedbacks.push(item);
                  }
                }
              })


              siteFeedbacks ? setFeedbacks(siteFeedbacks) : setFeedbacks([]);

              siteGraphs ? setGraphs(siteGraphs) : setGraphs([]);
              // setParametersList1([]);
              // setParametersList2([]);
              // setParametersList3([]);
              // setParametersList4([]);
              // setParametersList5([]);
              siteParametersLocalList1 ? setParametersList1(siteParametersLocalList1) : setParametersList1([]);
              siteParametersLocalList2 ? setParametersList2(siteParametersLocalList2) : setParametersList2([]);
              siteParametersLocalList3 ? setParametersList3(siteParametersLocalList3) : setParametersList3([]);
              siteParametersLocalList4 ? setParametersList4(siteParametersLocalList4) : setParametersList4([]);
              siteParametersLocalList5 ? setParametersList5(siteParametersLocalList5) : setParametersList5([]);
            }
          }
          setIsLoading(false)

        } else {
          toast({
            title: siteReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
        setIsLoading(false)

      }
    }
    firstTime = false
  }, [siteReducer.action])
  useEffect(() => {
    if (settingReducer.action) {
      if (settingReducer.action === types.SET_IMEI_NUMBER_SUCCESS) {
        if (settingReducer.status === "success") {
          setIMEINu(settingReducer.IMEINumber);
        } else {
          toast({
            title: settingReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (settingReducer.action === types.SET_IMEI_NUMBER_FAILURE) {
        toast({
          title: settingReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
    }
  }, [settingReducer.action])

  const setPermission = async () => {
    // setIsCreate(await checkPermission('Site Dashboard','create'));
    setIsEdit(await checkPermission('Site Dashboard', 'edit'));
    // setIsDelete(await checkPermission('Site Dashboard','delete'));
    // setIsView(await checkPermission('Site Dashboard','view'));
    // console.log('Site Dashboard-isEdit-'+isEdit);   
  }
  const addMarker = (data, type) => {
    // console.log('addMarker-type',type);
    //console.log('addMarker-data',data.val().groupFor);
    ///groupFor:"Aqualogix"
    //console.log('addMarker-data',data.val());
    if (type.toString() == "A") {
      if (data) {
        const markerListLocal = {
          lat: parseFloat(data.val().lat),
          lng: parseFloat(data.val().long),
          _id: data.val().uniqId,
          empData: data.val(),
        };
        driverMarker.push(markerListLocal)
        setCenterPointDriver({ lat: parseFloat(data.val().lat), lng: parseFloat(data.val().long) });
        setMarkerList(driverMarker);
      }
    }
    else if (type.toString() == "E") {
      setCenterPointDriver({ lat: parseFloat(driverMarker[0].lat), lng: parseFloat(driverMarker[0].lng) });
      setMarkerList(driverMarker);
    }
    else if (type.toString() == "D") {
      setCenterPointDriver({ lat: parseFloat(driverMarker[0].lat), lng: parseFloat(driverMarker[0].lng) });
      setMarkerList(driverMarker);
    }
  }
  const fetchSiteDetails = () => {
    dispatch(getSiteDetails(location.state.siteId));
  }

  const fetchProjectDetails = (projectId) => {
    // console.log('ProjectDetails-siteId',location.state.siteId);
    dispatch(getProjectDetails(projectId));
  }
  const onClickMapType = (mapTypeId) => {
    setMapTypeId(mapTypeId);
  }
  const handleOutletValveStatus = (e) => {
    let isChecked = e.target.checked;
    setOutletValveLocal(isChecked);
    setIsOutletValveStatus(true);
    setTimeout(() => {
      setIsOutletValveStatus(false);
    }, 60000)
    if (isChecked)
      setOutletValve(true);
    else
      setOutletValve(false);
    // 860987058180299
    const IOT = {
      "IMEI": IMEINu,
      "C2": isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT));
    // const IOT1={
    //   "IMEI": IMEINu,
    //   "C9": !isChecked?1:0
    // }
    // dispatch(pushMqttData(IOT1));
  }
  const handleInletValveStatus = (e) => {
    let isChecked = e.target.checked;
    // 860987058180299
    const IOT = {
      "IMEI": IMEINu,
      "C6": isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT));
    const IOT1 = {
      "IMEI": IMEINu,
      "C7": !isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT1));
  }
  const handleValveStatus = (e) => {
    let isChecked = e.target.checked;
    // 860987058180299
    const IOT = {
      "IMEI": IMEINu,
      "C4": isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT));
    const IOT1 = {
      "IMEI": IMEINu,
      "C5": !isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT1));
  }
  const handlePumpStatus = (e) => {
    let isChecked = e.target.checked;
    const IOT = {
      "IMEI": IMEINu,
      "C2": isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT));
    const IOT1 = {
      "IMEI": IMEINu,
      "C3": !isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT1));
  }
  const handleAutoManualStatus = (e) => {
    let isChecked = e.target.checked;
    setSystemModeLocal(isChecked);
    setIsSystemModeStatus(true);
    // setTimeout(() => {
    //   setIsSystemModeStatus(false);
    // }, 10000)
    if (isChecked)
      setSystemMode(true);
    else
      setSystemMode(false);
    const IOT = {
      "IMEI": IMEINu,
      "C1": isChecked ? 1 : 0
    }
    dispatch(pushMqttData(IOT));
  }
  const handleUserInput = (e) => {
    // console.log('e.target.name',e.target.name);
    // console.log('e.target.value',e.target.value);
    if (e.target.name === "inputIMEINumber") {
      setIMEINu(e.target.value);
    }
  }
  const doStoreIMEI = () => {
    if (isEmpty(IMEINu)) {
      // alert(string.strFNameValMsg);
      return;
    }
    dispatch(setIMEINumber(IMEINu));
    // navigate('/dashboard');
  }
  const bindMap = () => {
    if (locationList.length > 0 && centerPoint)
      return <MapComponent center={centerPoint} locations={locationList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} />
  }
  const bindMapDriver = () => {
    if (markerList.length > 0) {
      // setCenterPointDriver({lat:parseFloat(markerList[0].lat),lng:parseFloat(markerList[0].lng)});
      return <MapComponent center={null} locations={markerList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} zoomLevel={7} mapMarker={mapMarker} />
    }
    else if (centerPoint)
      return <MapComponent center={centerPoint} locations={markerList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} zoomLevel={12} mapMarker={mapMarker} />
    else
      return <MapComponent center={center} locations={markerList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} zoomLevel={12} mapMarker={mapMarker} />
  }
  const bindEmpList = () => {
    if (markerList) {
      if (markerList.length > 0) {
        // console.log('markerList',markerList);
        return markerList.map((item, index) =>
        (
          <li key={item._id} className="list-group-item border-0" >
            <div className="d-flex">
              <div className="flex-shrink-0"><img src={avtarImage} className="avatar avatar-xs avatar-4x3" alt="Image Description" /></div>
              <div className="flex-grow-1 ms-3">
                <h5 className="mb-1">{item.empData.empName + " " + item.empData.empID}</h5>
                <span className="text-success">Status: {item.empData.totalSites > 0 ? item.empData.inWorkingLnNo > 0 ? "Working" : "InTransite  " : "No Route Assigned"}</span>
              </div>
            </div>
          </li>
        )
        );
      }
    }
  }
  const bindSystemMode = () => {
    // console.log('isSystemModeStatus',isSystemModeStatus);

    return (
      <p className="site-name text-blue d-flex align-items-center">
        <div className={!isSystemModeStatus ? "autoManualswitch" : "autoManualswitch loadingOpacity"}>
          <input
            type="checkbox"
            checked={!isSystemModeStatus ? systemMode : systemModeLocal}
            name="autoManualswitch"
            className="autoManualswitch-checkbox"
            id="autoManual"
            disabled={isSystemModeStatus}
            onChange={(e) => handleAutoManualStatus(e)}
            tabIndex="2"
          />
          <label className="autoManualswitch-label" for="autoManual">
            <span className="autoManualswitch-inner"></span>
            <span className="autoManualswitch-switch"></span>
          </label>
        </div>
        {isSystemModeStatus ? <span className='loaderStyle'>Loading....</span> : <span className='loaderStyle'></span>}
      </p>
    )
  }

  const getPrecentage = (val) => {
    let value = "0";

    // Check if parametersList1 exists and has at least two elements
    if (parametersList1 && parametersList1.length > 0 && parametersList1[1].value) {
      // Extract the numeric value from parametersList1[1].value and set it to 'value'
      if (val == 1) return parametersList1[1].value
      value = +parametersList1[1].value.split(" ")[0];
    }
    if (val == 1) return "0%"

    console.log("PIKACHU 1", value);

    // Calculate the percentage based on the extracted value
    value = ((value / 100) * 12) + "%";

    console.log("PIKACHU", value);

    // Return the formatted percentage as a string
    return `${value}`;
  }


  const waterLevel = (val) => {
    let value = "0";

    // Check if parametersList1 exists and has at least two elements
    if (parametersList1 && parametersList1.length > 0 && parametersList1[1].value) {
      // Extract the numeric value from parametersList1[1].value and set it to 'value'
      if (val == 1) return parametersList1[1].value
      value = +parametersList1[1].value.split(" ")[0];
    }
    if (val == 1) return "0%"

    return value
  }
  const bindOutletValve = () => {
    const outletValveStatusText = ''

    return (
      <p className="site-name text-red d-flex align-items-center">
        <div className={outletValve === 1 ? "opencloseswitch open" : "opencloseswitch close"}>
          <input
            type="checkbox"
            checked={outletValve === 1}
            name="opencloseswitch"
            className="opencloseswitch-checkbox"
            id="outletValve"
            disabled={isOutletValveStatus}
            onChange={(e) => handleOutletValveStatus(e)}
            tabIndex="2"
          />
          <label className="opencloseswitch-label" htmlFor="outletValve">
            <span className="opencloseswitch-inner"></span>
            <span className="opencloseswitch-switch"></span>
          </label>
        </div>
        <span className='loaderStyle'>{outletValveStatusText}</span>
        {outletValve == "2" && <Badge colorScheme='orange' variant={'outline'}>In Process</Badge>}
        {outletValve == "3" && <Badge colorScheme='red' variant={'outline'}>Fault</Badge>}

        {isOutletValveStatus && <span className='loaderStyle'>Loading....</span>}
      </p>
    );
  };


  //###########################################//
  //Function to manage parameter dynamically.
  //##########################################//
  const manageParameterObj = (obj) => {
    // console.log('manageParameterObj-obj',obj);
    // console.log('manageParameterObj-siteDetailsLocal',siteDetailsLocal);
    // console.log('manageParameterObj-obj.parameter.toUpperCase()',obj.parameter.toUpperCase());    
    if (siteDetailsLocal) {
      if (obj) {
        { console.log("xx this is my obj", obj) }
        if (obj.parameter) {
          if (obj.parameter.toUpperCase() == "P5") {
            return { "imgIc": require("../assets/icons/Turbidity.png"), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P1") {
            return { "imgIc": require('../assets/new icons/bar.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P12") {
            return { "imgIc": require('../assets/new icons/flowrate.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P13") {
            return { "imgIc": require('../assets/new icons/totalflow.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()] + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P4") {
            return { "imgIc": require('../assets/new icons/chlorine.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] / 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P2") {
            return { "imgIc": require("../assets/icons/LevelSensor.png"), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P8") {
            return { "imgIc": require('../assets/new icons/solorpower.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 100).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P10") {
            return { "imgIc": require('../assets/new icons/electric-current.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P3") {
            return { "imgIc": require('../assets/icons/Turbidity.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] / 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P7") {
            return { "imgIc": require('../assets/new icons/energy.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 100).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P9") {
            return { "imgIc": require('../assets/new icons/freq.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] * 100).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else if (obj.parameter.toUpperCase() == "P11") {
            return { "imgIc": require('../assets/new icons/energy.gif'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? (siteDetailsLocal[obj.parameter.toUpperCase()] / 10).toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
          else {
            return { "imgIc": require('../assets/new icons/voltage.png'), "label": obj.title, "value": siteDetailsLocal[obj.parameter.toUpperCase()] ? siteDetailsLocal[obj.parameter.toUpperCase()].toFixed(2) + " " + obj.measurement : "0 " + obj.measurement }
          }
        }
      }
    }
  }

  //###########################################//
  //Function to manage graph dynamically.
  //##########################################//
  const manageGraphs = (type) => {
    return graphs.filter((item, index) => (item.title.trim() == type));
  }

  //###########################################//
  //Function to manage feedbacks dynamically.
  //##########################################//
  const manageFeedbacks = (type) => {
    return feedbacks.filter((item, index) => (item.title.trim() == type));
  }

  const imageStyles = {

    width: "100%",
    height: '600px',


  }
  if (window.innerWidth <= 767) {
    // imageStyles.display = "flex";
    // imageStyles.justifyContent = 'center';
    // imageStyles.alignItems = 'center';
    // imageStyles.top="50vh";
    imageStyles.height = '350px';
  }

  return (
    <div className="page">
      {console.log("COOOOOOO", outletValve)}
      <NavBar projectLogo={serverFile} />
      <div className="project-page-head">
        <div className="row py-4 m-0">
          <div className="col-12 col-xl-7 col-xxl-7">
            <div className="box-content">
              <h3 className="box-title">Scheme Name</h3>
              <p className="site-name">{siteName}
                {/* TW Gautam Namager, Mehsana Nagar Palika */}
              </p>
            </div>
          </div>
          {isLoading && <Loader />}

          <div className="col-12 col-xl-5 col-xxl-5">
            <div className="row">
              <div className="col-12 col-md-6 border-start">
                <div className="box-content">
                  <h3 className="box-title">Last Updated</h3>
                  <div className="time">
                    <i className="icon-time flex-shrink-0"></i>{lastUpdate ? moment(lastUpdate).format("DD/MM/YYYY hh:mm:ss a") : "--/--/----"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 border-start">
                <div className="box-content">
                  <h3 className="box-title">Today's Production Ltr<br /><div className="box-subtitle">(12:00 AM to 11:59 PM)</div></h3>
                  <div className="person-name">{todayProduction + " Ltr"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col mb-4">
            <div className="card">
              <div className="card-body p-0">
                <div className="row py-3 py-md-0">
                  {manageFeedbacks('Pump Auto/Manual').length ? <div className="col-12 col-md-12 col-xl-3"><div className="box-content"><h3 className="box-title text-black">System Mode</h3>{isEdit ? bindSystemMode() : <p className="site-name text-blue d-flex align-items-center">{systemMode == "0" ? 'Manual' : 'Auto'}<Switch ml={3} isChecked={systemMode == "1"} size={'lg'}></Switch></p>}</div></div> : null}
                  <div className="col-12 col-md-12 col-xl-9">
                    <div className="row">
                    {/* come here  */}
                      {manageFeedbacks('Pump on/off status').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">Pumps Status</h3><p className="site-name text-blue"><div className={"valve-status " + pumpStatusStyl}>{pumpStatus}</div></p></div></div> : null}
                      {manageFeedbacks('Flush Status').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">FLUSH VALVE {' '}{isExtraInformationPermission == true && <Badge ml={2} variant={'outline'} colorScheme='green'>{flushVavleAM == 1 ? "A" : "M"}</Badge>}</h3><p className="site-name text-blue"><div className={"valve-status " + flushValveStyl}>{flushValve}</div></p></div></div> : null}
                      {manageFeedbacks('Inlet Valve Status').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">INLET VALVE{' '}{isExtraInformationPermission == true && <Badge ml={2} variant={'outline'} colorScheme='green'>{inletValveAM == 1 ? "A" : "M"}</Badge>}</h3><p className="site-name text-green"><div className={"valve-status " + inletValveStyl}>{inletValve}</div></p></div></div> : null}
                      {manageFeedbacks('Outlet Valve').length ? <div className="col-12 col-md-6 col-xl-3"><div className="box-content border-start"><h3 className="box-title text-black">OUTLET VALVE{' '}{isExtraInformationPermission == true && <Badge ml={2} variant={'outline'} colorScheme='green'>{outeletVavleAM == 1 ? "A" : "M"}</Badge>}</h3>{isEdit ? bindOutletValve() : <p className="site-name text-blue d-flex align-items-center"></p>}</div></div> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {parametersList1 ? <SensorValue data={parametersList1} /> : null}
        {parametersList2 ? <SensorValue data={parametersList2} /> : null}
        {parametersList3 ? <SensorValue data={parametersList3} /> : null}
        {parametersList4 ? <SensorValue data={parametersList4} /> : null}
        {parametersList5 ? <SensorValue data={parametersList5} /> : null}
        <div className="row" style={{ height: "750px" }}>
          {!isFlip && <div className="col-12 col-md-8 col-xxl-9 mb-4">

            <div className="card">
              <div className="card-header d-flex flex-column flex-xl-row justify-content-between">
                <h4 className="card-header-title  mb-3 mb-xl-0">{projectName}</h4>
                <div
                  className="btn-group project-btn-group"
                  role="group"
                  aria-label="Default button group">
                  <button type="button" className="btn btn-outline-dark" onClick={() => {
                    setIsFlip(isFlip)
                    onClickMapType('roadmap')
                  }}>Map</button>
                  <button type="button" className="btn btn-outline-dark" onClick={() => {
                    setIsFlip(isFlip)
                    onClickMapType('satellite')
                  }}>Satellite</button>
                  <button type="button" className="btn btn-outline-dark" onClick={() => {
                    setIsFlip(isFlip)
                    onClickMapType('roadmap')
                  }}>Map</button>
                  <button type="button" className="btn btn-outline-dark" onClick={() => {
                    setIsFlip(isFlip)
                    onClickMapType('satellite')
                  }}>Satellite</button>
                  <button type="button" className="btn btn-outline-dark" onClick={() => setIsFlip(!isFlip)}>Flip</button>

                  {/* <button type="button" className="btn btn-outline-dark">Show sites</button>
                  <button type="button" className="btn btn-outline-dark">Show Devices</button> */}
                </div>
              </div>
              <div className="card-body" style={{ height: "663px" }}>
                <div className="map-area">
                  {/* <img src={mapImage} alt="map" /> */}
                  {bindMap()}
                  {/* {(locationList.length>0 && centerPoint)?<MapComponent center={centerPoint} locations={locationList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} />:null} */}
                </div>
              </div>
            </div>
          </div>
          }

          {isFlip && <div className="col-12 col-md-8 col-xxl-9  mb-4">
            <div className="card" >
              <div className="card-header d-flex flex-column flex-xl-row justify-content-between"   >
                <h4 className="card-header-title  mb-3 mb-xl-0" >Pump Diagram</h4>
                <div
                  className="btn-group project-btn-group"
                  role="group"
                  aria-label="Default button group" >

                  <button type="button" className="btn btn-outline-dark" onClick={() => {
                    setIsFlip(!isFlip)
                    onClickMapType('roadmap')
                  }}>Map</button>
                  <button type="button" className="btn btn-outline-dark" onClick={() => {
                    setIsFlip(!isFlip)
                    onClickMapType('satellite')
                  }}>Satellite</button>
                  <button type="button" className="btn btn-outline-dark" onClick={() => setIsFlip(!isFlip)}>Flip</button>
                  {/* <button type="button" className="btn btn-outline-dark">Show sites</button>
                  <button type="button" className="btn btn-outline-dark">Show Devices</button> */}

                </div>

              </div>


              <div className="card-body" >

                <div className="map-area  " style={{ position: "relative", height: "635px" }}>
                  <img src={srcImage} alt="Empty Water Tank" style={imageStyles} />
                  {isOutletValveStatus === "true" && (<Tooltip label="Outlet Valve is ON"> <img
                    className="outlet-valve"
                    src={gif}
                    style={{
                      height: `4.7%`,
                      width: '3.1%',
                      backgroundColor: 'white',
                      position: "absolute",
                      bottom: '29.9%',
                      left: '81%',
                      transform: 'translateX(-50%)',
                      transition: 'height 0.5s ease',
                      animation: 'wave 3s infinite linear'
                    }}
                  >
                  </img></Tooltip>)}
                  {flushValve === "Open" && (<Tooltip label="Flush Valve Is ON"><img
                    className="flush-valve"
                    src={gif}
                    style={{
                      height: `4.7%`,
                      width: '2.6%',
                      backgroundColor: 'while',
                      position: "absolute",
                      bottom: '16%',
                      left: '21.10%',
                      transform: 'translateX(-50%)',
                      transition: 'height 0.5s ease',
                      animation: 'wave 3s infinite linear'
                    }}
                  >
                  </img></Tooltip>)}

                  {inletValve === "Open" && (
                    <Tooltip label="Inlet Valve is ON">
                      <img
                        className="inlet-valve"
                        src={gif}

                        style={{
                          zIndex: 99,
                          height: `4.4%`,
                          width: '3%',
                          backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                          position: "absolute",
                          bottom: '33.5%',
                          left: '31.8%',
                          transform: 'translateX(-50%)',
                          transition: 'height 0.5s ease',
                          animation: 'wave 3s infinite linear'
                        }}
                      />
                    </Tooltip>
                  )}
                  <div
                    className="system-mode"
                    style={{
                      height: `12%`,
                      width: '40%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '63%',
                      left: '6.5%',
                    }}
                  > System Mode : {systemMode == "0" ? "MANUAL" : "AUTO"}
                  </div>


                  <div
                    style={{
                      height: `1.9%`,
                      width: '14%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '35.1%',
                      left: '44%',
                    }}
                  >
                    <div className={inletValve == "Open" ? 'flowingWater' : 'flowingWaterStop'}>
                      </div>
                  </div>

                  <div
                    style={{
                      height: `1.9%`,
                      width: '5.4%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '35.6%',
                      left: '84%',
                    }}
                  >
                    <div className={outletValve == '1' ? 'flowingWater' : 'flowingWaterStop'}>
                      </div>
                  </div>
                  <div
                    style={{
                      height: `1.9%`,
                      width: '1.3%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '35.6%',
                      left: '76.55%',
                    }}
                  >
                    <div className='flowingWater'>
                      </div>
                  </div>

                  <div
                    style={{
                      height: `32%`,
                      width: '1.5%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '35.5%',
                      left: '57.4%',
                    }}
                  >
                    <div className={inletValve == "Open" ? 'flowingWater' : 'flowingWaterStop'}>
                      </div>
                  </div>

                  <div
                    style={{
                      height: `1.9%`,
                      width: '3%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '67.4%',
                      left: '58.4%',
                    }}
                  >
                    <div className={inletValve == "Open" ? 'flowingWater' : 'flowingWaterStop'}>
                      </div>
                  </div>




                  <div
                    style={{
                      height: `1.9%`,
                      width: '7%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '35.4%',
                      zIndex :1,
                      left: '15.3%',
                    }}
                  >
                    <div className='flowingWater'>
                      </div>
                  </div>


                  <div
                    style={{
                      height: `1.9%`,
                      width: '6.5%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '35.4%',
                      left: '22.1%',
                    }}
                  >
                    <div className='flowingWater'>
                      </div>
                  </div>
                  <div
                    style={{
                      height: `1.9%`,
                      width: '2.6%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '35.4%',
                      left: '35.1%',
                    }}
                  >
                    <div className={inletValve == "Open" ? 'flowingWater' : 'flowingWaterStop'}>
                      </div>
                  </div>


                  <div
                    style={{
                      height: `18%`,
                      width: '1.5%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '37%',
                      left: '75.4%',
                    }}
                  >
                    <div className={inletValve == "Open" ? 'flowingWater' : 'flowingWaterStop'}>
                      </div>
                  </div>

                  

                  <div
                    style={{
                      height: `10%`,
                      width: '1.5%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '27.5%',
                      left: '20.4%',
                    }}
                  >
                    <div className={flushValve == "Open" ? 'flowingWater' : 'flowingWaterStop'}>
                      </div>
                  </div>

                  <div
                    style={{
                      height: `10%`,
                      width: '1.5%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '7.5%',
                      left: '20.4%',
                    }}
                  >
                    <div className='flowingWater'>
                      </div>
                  </div>

                  <div
                    style={{
                      height: `6.8%`,
                      width: '1.5%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '5.9%',
                      left: '12.4%',
                    }}
                  >
                    <div className='flowingWater'>
                      </div>
                  </div>

                  <div
                    style={{
                      height: `21%`,
                      width: '1.3%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '14.3%',
                      left: '14.4%',
                    }}
                  >
                    <div className='flowingWater'>
                      </div>
                  </div>
                  




                  <div
                    className="waterlevel"
                    style={{
                      height: `3%`,
                      width: '20%',
                      fontWeight: 'bolder',
                      // backgroundColor: 'white', 
                      position: "absolute",
                      bottom: '75%',
                      left: '66%',
                    }}
                  > {waterLevel() + "%"}
                  </div>
                  <div
                    className="depth"
                    style={{
                      height: `0%`,
                      width: '6%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '15%',
                      left: '3%',
                    }}

                  >
                    {console.log("HIHIHIHI", parametersList1)}
                    {parametersList1.find(item => item.label === "Borewell Level")?.value}
                  </div>

                  <div
                    className="pressure"
                    style={{
                      height: `0%`,
                      width: '20%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '51%',
                      left: '21%',
                    }}
                  > {parametersList1.find(item => item.label === "Pressure")?.value}
                  </div>
                  <div
                    className="flowrate"
                    style={{
                      height: `0%`,
                      width: '20%',
                      fontWeight: 'bolder',
                      backgroundColor: 'white',
                      backgroundColor: 'white',
                      position: "absolute",
                      bottom: '46%',
                      left: '39%',
                    }}
                  >

                    {parametersList2.find(item => item.label === "Flow Rate")?.value}
                  </div>

                  <Tooltip label={parametersList1[2]?.value}>


                    <div
                      className="depth"
                      style={{
                        height: `5%`,
                        width: '10%',
                        fontWeight: 'bolder',
                        position: "absolute",
                        bottom: '10%',
                        fontSize: '0.7rem',
                        left: '0.5%',
                      }}
                    >
                      {/* {parametersList1[2]?.value} */}
                    </div>

                  </Tooltip>

                  <Tooltip label={parametersList1[0]?.value}>
                    <div
                      className="bar pressure"
                      style={{
                        height: `5%`,
                        width: '10%',
                        fontWeight: 'bolder',
                        position: "absolute",
                        bottom: '40%',
                        fontSize: '0.7rem',
                        left: '20%',
                      }}
                    >
                      {/* {parametersList1[0]?.value} */}
                    </div>

                  </Tooltip>
                  <Tooltip label={parametersList2[4]?.value}>
                    <div
                      className="flowRate"
                      style={{
                        height: `5%`,
                        width: '7%',
                        fontWeight: 'bolder',
                        position: "absolute",
                        bottom: '33%',
                        fontSize: '0.7rem',
                        left: '38%',
                      }}
                    >
                      {/* {parametersList2[4]?.value} */}
                    </div>

                  </Tooltip>

                  <img
                    className="system-mode"
                    src={!isSystemModeStatus ? auto : manual}
                    style={{
                      height: `12%`,
                      width: '12%',
                      backgroundColor: 'while', // It seems like there's a typo here, it should be 'white'
                      position: "absolute",
                      bottom: '77%',
                      left: '12%',
                      transform: 'translateX(-50%)',
                      transition: 'height 0.5s ease',
                      animation: 'wave 3s infinite linear'
                    }}
                  />
                  <Tooltip label={getPrecentage(1)} fontSize="md" hasArrow>
                    <img
                      className="water-level image-container"
                      src={gif}
                      style={{
                        height: `${getPrecentage()}`,
                        width: '14.2%',
                        backgroundColor: 'blue',
                        position: "absolute",
                        bottom: '60.2%',
                        left: '68.62%',
                        transform: 'translateX(-50%)',
                        transition: 'height 0.5s ease',
                        animation: 'wave 3s infinite linear'
                      }}
                    >

                      {/* <div
      style={{
        position: 'absolute',
        bottom: '50%', // Adjust this based on your layout
        left: '50%',
        fontWeight: 'bold',
        transform: 'translate(-50%, 50%)',
        textAlign: 'center',
        color: 'white', // Adjust the color as needed
        fontSize: '0.8rem' // Adjust the font size as needed
      }}
    >
      {getPrecentage()}
    </div> */}
                    </img>
                  </Tooltip>

                </div>
              </div>
            </div>
          </div>}

          <div className="col-12 col-md-4 col-xxl-3 mb-2 ">
            {/* <div className="notification ">
              <div className="notiglow"></div>
              <div className="notiborderglow"></div>
              <div className="notititle">Site Infomation</div>
              <div className="notibody">
                <Card>
 
 
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='3'>
 
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          Water Level : {waterLevel() + "%"}
                        </Heading>
                      </Box>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          Depth : {parametersList1[2]?.value}
                        </Heading>
                      </Box>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          Pressure : {parametersList1[0]?.value}
                        </Heading>
 
                      </Box>
                    </Stack>
                  </CardBody>
                </Card>
              </div>
            </div> */}
            {/* <div className="card advanced-info " style={{ height: "225px" }}>
              <div className="card-body ">
                <div className="panel_content p-0">
                  <h2 className='p-0'>Site Information</h2>
                  <ul className="basicinfo-list scroll">
                    <li>
                      <span className="">Water Level:</span>
                      <span className="amount">{waterLevel() + "%"}</span>
                    </li>
                    <li>
                      <span className="">Depth:</span>
                      <span className="amount">{parametersList1[2]?.value}</span>
                    </li>
                    <li>
                      <span className="">Pressure:</span>
                      <span className="amount">{parametersList1[0]?.value}</span>
                    </li>
                    <li>
                      <span className="">Flow Rate:</span>
                      <span className="amount">{parametersList2[4]?.value}</span>
                    </li>
 
                  </ul>
                 <ul className="info-list mt-auto">
                    <li>Flow Meter</li>
                    <li>Pressure</li>
                    <li>pH</li>
                    <li>TDS</li>
                  </ul> 
                </div>
 
              </div>
 
 
            </div> */}


            <div className="card advanced-info" style={{ height: "732px" }}>
              <div className="card-body   p-0">
                <div className="panel_content">
                  <h2>Advanced Information</h2>
                  <ul className="basicinfo-list scroll">
                    <li>
                      <span className="">State</span>
                      <span className="amount">{state}</span>
                    </li>
                    <li>
                      <span className="">District</span>
                      <span className="amount">{district}</span>
                    </li>
                    <li>
                      <span className="">Block</span>
                      <span className="amount">{block}</span>
                    </li>
                    <li>
                      <span className="">Panchaayat</span>
                      <span className="amount">{panchaayat}</span>
                    </li>
                    <li>
                      <span className="title">Population served through schemes</span>
                      <span className="amount">{popSerThSchemes}</span>
                    </li>
                    <li>
                      <span className="">Location Water sources</span>
                      <span className="amount">{locWatSources}</span>
                    </li>
                    <li>
                      <span className="">Scheme</span>
                      <span className="amount">{scheme}</span>
                    </li>
                    <li>
                      <span className="">Year of Comissioning</span>
                      <span className="amount">{yearOfComi}</span>
                    </li>
                    {/* <li>
                      <span className="title">No. of habitations</span>
                      <span className="amount">{noOfHabit}</span>
                    </li> */}
                    <li>
                      <span className="">Tubewell Waterlevel</span>
                      <span className="amount">
                        {siteReducer.data && siteReducer.data.siteDetails && siteReducer.data.siteDetails.length > 0
                          ? (() => {
                            const matchedItem = siteReducer.data.siteDetails.find((item) => item.pumpName === siteName);
                            { console.log("INNNER P", matchedItem) }
                            { console.log("INNNER P", matchedItem) }
                            return "0 Mtr";
                          })()
                          : ""}
                      </span>
                    </li>
                    <li>
                      <span className="">Pump Rating</span>
                      {/* <span className="amount">{siteReducer.data ? siteReducer.data.rating : ""}</span> */}
                      <span className="amount">
                        {siteReducer.data && siteReducer.data.siteDetails && siteReducer.data.siteDetails.length > 0
                          ? (() => {
                            const matchedItem = siteReducer.data.siteDetails.find((item) => item.pumpName === siteName);
                            return matchedItem ? matchedItem.rating : "";
                          })()
                          : ""}
                      </span>

                    </li>
                  </ul>
                  {/* <ul className="info-list mt-auto">
                    <li>Flow Meter</li>
                    <li>Pressure</li>
                    <li>pH</li>
                    <li>TDS</li>
                  </ul> */}
                </div>

              </div>


            </div>

          </div>
        </div>


        <div className="row">
          <div className="col-lg mb-4">
            <div className="card">
              {/* <div className="card-header d-flex justify-content-end">
                <button type="button" className="btn btn-outline-dark">Hourly</button>
                <button type="button" className="btn btn-outline-dark ms-3">Daily</button>
              </div> */}
              {isLoading && <Loader />}
              <div className="card-body">
                <div className="row">
                  {manageGraphs("LPCD").length ? <div className="col-md-4 mb-3">{location.state ? <CardLPCD content={"Card-1"} title={'LPCD: Litres per capita per day'} type={'B'} graphFor="lpcd" siteId={location.state.siteId} /> : null}</div> : null}
                  {manageGraphs("Chlorine").length ? <div className="col-md-4 mb-3">{location.state ? <CardChlorine content={"Card-1"} title={'Residual Chlorine (mg/l)'} type={'L'} graphFor="chlorine" siteId={location.state.siteId} /> : null}</div> : null}
                  {manageGraphs("Pressure").length ? <div className="col-md-4 mb-3">{location.state ? <CardPressure content={"Card-1"} title={'Pressure (meter)'} type={'A'} graphFor="pressure" siteId={location.state.siteId} /> : null}</div> : null}
                  {manageGraphs("Achieved Litres").length ? <div className="col-md-4 mb-3">{location.state ? <CardWatersupplied content={"Card-1"} title={'Achieved Ltr vs last 7 days'} type={'L'} graphFor="watersupplied" siteId={location.state.siteId} /> : null}</div> : null}
                  {manageGraphs("Turbidity").length ? <div className="col-md-4 mb-3">{location.state ? <CardTurbidity content={"Card-1"} title={'Turbibity(NTU)'} type={'A'} graphFor="turbidity" siteId={location.state.siteId} /> : null}</div> : null}
                  {manageGraphs("OHSR").length ? <div className="col-md-4 mb-3">{location.state ? <CardOHRS content={"Card-1"} title={'OHSR Level'} type={'L'} graphFor="ohsr" siteId={location.state.siteId} /> : null}</div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {dmts && <div className="row">
          <div className="col mb-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-8 col-xxl-9">
                    <div className="card operation-tracker-card">
                      <div className="card-header d-flex justify-content-between px-0 ">
                        <h4 className="card-header-title">Operation and Maintenance Tracker</h4>
                        <span>Total NUMBER OF EMPLOYEE: {markerList ? markerList.length : 0}</span>
                      </div>
                      <div className="card-body px-0">
                        <div className="map-area">
                          {/* <img src={mapImage} alt="map" /> */}
                          {bindMapDriver()}
                          {/* {(locationList.length>0 && centerPoint)?<MapComponent center={centerPoint} locations={locationList} mapHeight="600px" onInfoItemClick={null} />:null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xxl-3">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="card">
                          <div className="card-header card-header-content-between"><h4 className="card-header-title">Employee LIVE LOCATION STATUS</h4></div>
                          <div className="card-body " style={{ height: "590px" }}><ul className="list-group list-group-flush list-group-no-gutters scroll">{bindEmpList()}</ul></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default SiteDashboard;