import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Container, VStack } from '@chakra-ui/react';
import { message, Popconfirm } from 'antd';
import io from 'socket.io-client';


// import StateList from "./stateList";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Toast
} from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Select
} from '@chakra-ui/react';

import { getCompleteAssignedSitesByUserIdAPI, getSiteListWithProjectsAPI } from '../store/apis';
import { isLoggedInUser, CONSTANTVALUE, getData, checkPermission } from '../utils'
import { useToast } from '@chakra-ui/react';
import NavBarAdmin from './NavBarAdmin';
import AdminMenu from './AdminMenu';
import { serverPath } from '../store/serverConfig';
import axios from 'axios';
import { projectList } from '../redux/actions/projectAdminAction';
import { siteList } from '../redux/actions/siteAdminAction';
import moment from 'moment';
const TokenManagement = () => {
    // const [districtTokenPermissions, setDistrictTokenPermissions] = useState({
    //     isCreate: false,
    //     isEdit: false,
    //     isDelete: false,
    //     isView: false
    // });

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [assignedSites, setAssignedSites] = useState([]);
    const [projects, setProjects] = useState();
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedSites, setSelectedSites] = useState();
    const [selectedLogItem, setSelectedLogItem] = useState(null);
    const { isOpen: isLogModalOpen, onOpen: openLogModal, onClose: closeLogModal } = useDisclosure();

    /////////////////////////////////////////////////////////////////
    /////Parameters for getting permission value/////////////////////
    /////////////////////////////////////////////////////////////////

    const [recordUpdateId, setrecoredUpdateId] = useState();
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [liveData, setLiveData] = useState([]);
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const [formData, setFormData] = useState({
        projectName: selectedProject,
        siteName: '',
        tokenValue: '',
        schemeId: '',
        villageCode: '',
        coveredVillageCodes: [""],
        userId: ''
    });

    useEffect(() => {
        const socket = io('http://mqtt.aqualogix.in:85');

        const handleMessage = (data) => {
            setLiveData(prevData => [...prevData, data]);
        };

        socket.on('govData', handleMessage);

        return () => {
            socket.off('govData', handleMessage);
        };
    }, []);

    const [districtTokenPermissions, setDistrictTokenPermissions] = useState({
        isCreate: false,
        isEdit: false,
        isDelete: false,
        isView: false
    });

    const fetch = async () => {

        const data = await getData(CONSTANTVALUE.USEROBJECT);
        const info = await getSiteListWithProjectsAPI(data.id)
        const filteredProjects = info.message.filter((item) => item.project && item.sites.length > 0);
        setProjects(filteredProjects);
        const sites = await getCompleteAssignedSitesByUserIdAPI(data.id);
        setAssignedSites(sites.message.map((item) => item.result));

    }



    useEffect(() => {

        if (selectedProject !== 'ALL') {
            const selectedProjectData = projects?.find((item) => item.project._id === selectedProject);
            setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
        } else {

            setSelectedSites([]);
        }
    }, [selectedProject]);

    const cancel = (e) => {
        console.log(e);
        message.error('Click on No');
    };

    const handleSiteChange = (event) => {

        const selectedSiteName = event.target.options[event.target.selectedIndex].text;
        setFormData(prevState => ({
            ...prevState,
            siteName: selectedSiteName
        }));
        console.log('Selected Site:', selectedSiteName);
    };

    const handleProjectChange = (event) => {


        const selectedProject = event.target.options[event.target.selectedIndex].text;
        setFormData(prevState => ({
            ...prevState,
            projectName: selectedProject
        }));
        console.log('Selected project:', selectedProject);
    };


    const handleCloseModal = () => {

        setFormData({
            siteName: 'Select Site',
            tokenValue: '',
            schemeId: '',
            villageCode: '',
            coveredVillageCodes: [""],
            userId: '',
            projectName: "Select Project"
        });
        setSelectedProject('ALL');
        setSelectedSites([]);
        setrecoredUpdateId(null);
        onClose();
    }

    useEffect(() => {

        checkLogin();
        fetchTokens();
        fetch();
        setPermission();

    }, [])

    const setPermission = async () => {
        setDistrictTokenPermissions({
            isCreate: await checkPermission("Distict Token Management", 'create'),
            isEdit: await checkPermission("Distict Token Management", 'edit'),
            isDelete: await checkPermission("Distict Token Management", 'delete'),
            isView: await checkPermission("Distict Token Management", 'view')
        })
        console.log("District Token Permissions:final", districtTokenPermissions);
    }

    const checkLogin = async () => {
        const isLoggedIn = await isLoggedInUser();
        if (!isLoggedIn) {
            navigate('/Admin');
        }
    }

    const fetchTokens = async () => {

        const tokenUrl = serverPath + 'sites/getSiteToken';

        try {
            const response = await axios.get(tokenUrl);
            console.log("Ressspon", response)
            setData(response.data.data);

        } catch (error) {
            console.log(error);
        }

    }

    const handleSave = async () => {

        // console.log(formData);

        if (!formData.siteName || !formData.tokenValue || !formData.schemeId || !formData.villageCode || formData.coveredVillageCodes.some(code => code === '') || !formData.userId || !formData.projectName) {
            toast({
                title: `Fill all the fields to save token`,
                status: "error",
                isClosable: true,
            })
            return;
        }
        try {
            // console.log("JOJJ",formData);
            const response = await axios.post(serverPath + 'sites/addSiteToken', formData);
            // console.log("JOJJ",response);

            setFormData({
                siteName: '',
                tokenValue: '',
                schemeId: '',
                villageCode: '',
                coveredVillageCodes: [""],
                userId: '',
                projectName: "ALL"
            });
            setSelectedProject('ALL');
            setSelectedSites([]);
            toast({
                title: `Token Data Saved`,
                status: "success",
                isClosable: true,
            })
            fetchTokens();
        } catch (error) {
            console.log(error);
        }
        onClose();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleDelete = async (id) => {
        try {

            const response = await axios.delete(`${serverPath}/sites/deleteSiteToken/${id}`);
            console.log(response.data.data)
            fetchTokens();

        } catch (error) {
            console.error('There was an error!', error);
        }
    }
    const handleUpdate = (item) => {
        setrecoredUpdateId(item._id);
        console.log("JHOHIJIJ", item.siteName);
        console.log("JHOHIJIJ", item.projectName);
        setSelectedProject(item.projectName);

        // setSelectedSites([item.siteName]);
        setFormData({
            projectName: item.projectName,
            siteName: item.siteName,
            tokenValue: item.tokenValue,
            schemeId: item.scheme_ID,
            villageCode: item.imiS_Village_Code,
            coveredVillageCodes: item.covered_IMISVillage_Code,
            userId: item.userID
        });

        onOpen();
        console.log("JHOHIJIJ", formData);
    }


    const handleSaveUpdate = async () => {


        if (!formData.siteName || !formData.tokenValue || !formData.schemeId || !formData.villageCode || formData.coveredVillageCodes.some(code => code === '') || !formData.userId || !formData.projectName) {
            toast({
                title: `Fill all the fields to save token`,
                status: "error",
                isClosable: true,
            })
            return;
        }
        console.log('Update Data:', formData)

        try {
            const response = await axios.put(`${serverPath}sites/updateSiteToken/${recordUpdateId}`, {
                ...formData
            });
            console.log(response);
            toast({
                title: `Token Data Updated`,
                status: "success",
                isClosable: true,
            })
            setFormData({
                siteName: '',
                tokenValue: '',
                schemeId: '',
                villageCode: '',
                coveredVillageCodes: [""],
                userId: '',
                projectName:"ALL"
            })
            setSelectedProject('ALL');
            setSelectedSites([]);
            fetchTokens();
            setrecoredUpdateId(null);
        } catch (error) {
            console.log(error);
        }
        onClose();
    }
    const handleViewLogs = (item) => {
        console.log("Opening logs modal for item:", item); // Add this line for debugging
        setSelectedLogItem(item);
        openLogModal();
    };

    const handleAddClick = () => {
        setFormData(prevState => ({
            ...prevState,
            coveredVillageCodes: [...formData.coveredVillageCodes, ""]
        }));
    };
    const handleRemoveClick = index => {
        const list = [...formData.coveredVillageCodes];
        list.splice(index, 1);
        setFormData(prevState => ({
            ...prevState,
            coveredVillageCodes: list
        }));
    };

    return (

        <div className="page">
            {console.log("PIKACHII", liveData)}
            <Modal
                closeOnOverlayClick={false}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={handleCloseModal}
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{recordUpdateId != null ? 'Update Details' : 'Save Details'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <FormControl>
                                    <FormLabel> Project Name:</FormLabel>
                                    <Select
                                        id="pageSizeSelect"
                                        value={selectedProject}
                                        variant={'filled'}
                                        onChange={(e) => {
                                            setSelectedProject(e.target.value)
                                            handleProjectChange(e);
                                        }
                                        }
                                        color="black"
                                    >
                                        {console.log("HIHIIHI", formData.projectName)}
                                        {selectedProject ? (
                                                <option value={formData.projectName} style={{ color: 'red' }}>{formData.projectName}</option>
                                            ) : (
                                                <option value={'ALL'}>Select Project</option>
                                            )}
                                        {/* <option value={'ALL'}>Select Project</option> */}
                                   
                                        {projects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                                            <option key={item.project._id} value={item.project._id}>
                                                {item.project.ProjectName}
                                            </option>
                                        ))}
                                    </Select>

                                </FormControl>

                                <FormControl mt={2}>
                                    <FormLabel>Token Value</FormLabel>
                                    <Input
                                        name="tokenValue"
                                        value={formData.tokenValue}
                                        onChange={handleChange}
                                        placeholder='Token Value'
                                    />
                                </FormControl>
                                <FormControl mt={2}>
                                    <FormLabel>Scheme ID:</FormLabel>
                                    <Input
                                        name="schemeId"
                                        value={formData.schemeId}
                                        onChange={handleChange}
                                        placeholder='Scheme ID'
                                    />
                                </FormControl>
                                <FormControl mt={2}>
                                    <FormLabel>User ID:</FormLabel>
                                    <Input
                                        name="userId"
                                        value={formData.userId}
                                        onChange={handleChange}
                                        placeholder='User ID'
                                    />
                                </FormControl>
                            </div>
                            <div style={{ flex: 1 }}>
                                <FormControl>
                                    <FormLabel>Site Name:</FormLabel>
                                    {console.log("PIKACHU", formData.siteName)}

                                    {selectedProject !== 'ALL' && (
                                        <Select
                                            variant={'filled'}
                                            ml={2}
                                            // value={formData.siteName}
                                            onChange={(e) => handleSiteChange(e)}
                                            color="black"
                                        >
                                            {formData.siteName ? (
                                                <option value={formData.siteName} style={{ color: 'red' }}>{formData.siteName}</option>
                                            ) : (
                                                <option value={'ALL'}>Select Site</option>
                                            )}
                                            {/* <option value={'ALL'}>Select Site</option>
                                            <option value={formData.siteName}>{formData.siteName}</option> */}
                                            {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                                                <option key={site._id} value={site._id} imei={site.IMEI}>
                                                    {site.pumpName}
                                                </option>
                                            ))}
                                        </Select>
                                    )}

                                </FormControl>
                                <FormControl mt={2}>
                                    <FormLabel>Village Code:</FormLabel>
                                    <Input
                                        name="villageCode"
                                        value={formData.villageCode}
                                        onChange={handleChange}
                                        placeholder='Village Code'
                                    />
                                </FormControl>
                                {console.log("RAIMEN", formData)}
                                <FormControl mt={2}>
                                    <FormLabel>Covered Village Codes:</FormLabel>
                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                        <Input
                                            name="coveredVillageCodes"
                                            value={formData?.coveredVillageCodes[0]}
                                            onChange={e => {
                                                const list = [...formData.coveredVillageCodes];
                                                list[0] = e.target.value;
                                                setFormData(prevState => ({
                                                    ...prevState,
                                                    coveredVillageCodes: list
                                                }));
                                            }}
                                            placeholder={`Covered Village Code 1`}
                                        />
                                        <Button colorScheme="blue" onClick={() => handleAddClick()} ml={2}>+</Button>
                                    </div>
                                    {formData.coveredVillageCodes.slice(1).map((code, index) => (
                                        <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                                            <Input
                                                name="coveredVillageCodes"
                                                value={code}
                                                onChange={e => {
                                                    const list = [...formData.coveredVillageCodes];
                                                    list[index + 1] = e.target.value;
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        coveredVillageCodes: list
                                                    }));
                                                }}
                                                placeholder={`Covered Village Code ${index + 2}`}
                                            />
                                            <Button colorScheme="red" ml={2} onClick={() => handleRemoveClick(index + 1)}>-</Button>
                                        </div>
                                    ))}
                                </FormControl>

                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={recordUpdateId != null ? handleSaveUpdate : handleSave}>
                            {recordUpdateId != null ? 'Update' : 'Save'}
                        </Button>
                        <Button onClick={handleCloseModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isLogModalOpen} onClose={closeLogModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedLogItem ? `Logs for ${selectedLogItem.siteName}` : ''}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box p="6" bg="gray.100" borderRadius="xl" mt="8">
                            <Text fontSize="xl" fontWeight="bold">Live Data Logs</Text>
                            <VStack spacing="4" align="stretch">
                                {liveData.filter((item) => JSON.parse(item)?.scheme_ID === selectedLogItem?.scheme_ID).map((data, index) => (
                                    <Box key={index} p="4" borderWidth="1px" borderRadius="lg">
                                        <Text fontSize="md" fontWeight="bold">Data Sent:</Text>
                                        <Text fontSize="sm">{index + 1}. {data}</Text>
                                    </Box>
                                ))}
                            </VStack>

                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={closeLogModal}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <NavBarAdmin />
            <AdminMenu selMenu="Company" />
            {/* <Header moduleType={moduleType} /> */}
            <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
                <div className="row m-0">
                    <div className="col-12 py-4">
                        <div className="sub-head">District Token</div>
                        <h3 className="project-title fw-bold">Government Data Token Listing</h3>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row d-flex justify-content-center h-100 pt-5">
                        {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
                        <div className="card text-black" style={{ borderRadius: "10px" }}>
                            <div className="d-flex justify-content-end mx-2 my-3 mb-2 mb-lg-2">
                                {districtTokenPermissions.isCreate ? <Button colorScheme='blue' onClick={onOpen}>Add Data</Button> : "No Rights to Add Data"}
                            </div>
                            <div className="card-body">
                                <div class="table-responsive">
                                    <TableContainer>
                                        <Table variant='striped' colorScheme='gray'>
                                            <Thead>
                                                <Tr>
                                                    <Th fontSize={'md'}>Site Name</Th>
                                                    <Th fontSize={'md'}>Project Name</Th>
                                                    <Th fontSize={'md'}>Created On</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {data.length > 0 ?
                                                    data?.map((item, index) => (
                                                        <Tr key={index}>
                                                            {/* {console.log("EACH", item)} */}
                                                            <Td>{item.siteName}</Td>
                                                            <Td>{item.projectName}</Td>
                                                            <Td>{moment(item.createdAt).format("DD-MM-YYYY")}</Td>
                                                            <Td>{item.IMEI}</Td>
                                                            <Td width={'100px'}>
                                                                <Button colorScheme='blackAlpha' onClick={() => handleViewLogs(item)}>View Logs</Button>
                                                                {districtTokenPermissions.isEdit && <Button colorScheme='blue' className='mx-2' size={'sm'} onClick={() => handleUpdate(item)}>Update</Button>}
                                                                {districtTokenPermissions.isDelete && <Popconfirm title="Delete The Token"
                                                                    description="Are you sure to delete this Token?"
                                                                    onConfirm={() => {
                                                                        handleDelete(item._id)
                                                                    }}
                                                                    onCancel={cancel}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button colorScheme='red' size={'sm'}>Delete</Button>

                                                                </Popconfirm>}
                                                            </Td>
                                                        </Tr>
                                                    )) : <Tr><Td colSpan={3}>No Record Found</Td></Tr>
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </section>
        </div>

    )
}

export default TokenManagement
