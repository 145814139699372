/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import NavBar from "../Components/NavBar";
// import CardV1 from "../Components/Card/CardV1";
import avtarImage from "../assets/images/icon-user.svg";
import MapComponent from "../Components/Map/MapComponent";
import SiteList from "../Components/SiteList";
import BasicInfoSite from "../Components/BasicInfoSite";
import Block from "../Components/Block";
import mapMarker from "../assets/images/member.svg";
import {getProjectDetails,getSiteListByProject} from '../redux/actions/siteAction'
import {types} from '../redux/actions/types';
import { useLocation,useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { initializeApp } from "firebase/app";
import { getDatabase,onValue, ref,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { useToast } from '@chakra-ui/react';
var moment = require('moment');
// import { useNavigate } from "react-router-dom";


const center = {
  lat: 23.0363778,
  lng: 72.5416146,
};
const ProjectDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [projectId, setProjectId] = useState(null);
  const [sitePage, setSitePage] = useState(1);
  const [limit, setLimit] = useState(2);
  const [totalSiteRecords, setTotalSiteRecords] = useState(0);
  const [projectName, setProjectName] = useState(null);
  const [totalPopulation, setTotalPopulation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [centerPoint, setCenterPoint]=useState(null);
  // const [noOfConnections, setNoOfConnections] = useState(null);
  const [totalWtrSupplied, setTotalWtrSupplied] = useState(0);
  const [totalWtrSuppliedLPCD, setTotalWtrSuppliedLPCD] = useState(null);
  const [siteList, setSiteList] = useState([]);
  const [totalSiteCount, setTotalSiteCount] = useState(null);
  const siteReducer = useSelector(state => state.siteReducer);
  const [markerList, setMarkerList]=useState([]);
  const [centerPointDriver, setCenterPointDriver]=useState(null);
  const [serverFile, setServerFile] = useState();
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const toast = useToast()
  let driverMarker=[];
  useEffect(()=>{
    ////////////////////////////////////////////////////////////
    /// Following code is to get the firebase database value 
    //////////////////////////////////////////////////////////// 
    const firebaseConfig={
      apiKey: "AIzaSyBWTpB6tDBfW-2gK_4ygJeyihWtyAnvdj4",
      authDomain: "desire-o-and-m.firebaseapp.com",
      databaseURL: "https://desire-o-and-m.firebaseio.com",
      projectId: "desire-o-and-m",
      storageBucket: "desire-o-and-m.appspot.com",
      messagingSenderId: "820152855217"
    }
    const app = initializeApp(firebaseConfig);
    // Firebase.initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const commentsRef = ref(database);
    onChildAdded(commentsRef, (data) => {
      if(data.val().groupFor.toUpperCase()=="AQUALOGIX"){
        //console.log('onChildAdded-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
        addMarker(data,"A");
      }
    });

    onChildChanged(commentsRef, (data) => {
      const item=driverMarker.findIndex((item)=>item.empData.empID==data.val().empID);
      // //console.log('onChildChanged-item',item);
      if(parseInt(item)==-1){
        if(data.val().groupFor.toUpperCase()=="AQUALOGIX"){
          //console.log('onChildChanged-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
          addMarker(data,"A");
        }
      }
      else{
        // console.log('onChildChanged-ELSE');
        if(data){
          const markerListLocal={
            lat:parseFloat(data.val().lat),
            lng:parseFloat(data.val().long),
            _id:data.val().uniqId,
            empData:data.val(),
          };
          driverMarker.splice(item,1,markerListLocal);
          // console.log('onChildChanged-driverMarker',driverMarker);
          // console.log('onChildChanged-item',item);
          // console.log('onChildChanged-data',data);
          if(data.val().groupFor.toUpperCase()=="AQUALOGIX"){
            //console.log('onChildChanged-data.val().groupFor.toUpperCase()',data.val().groupFor.toUpperCase());
            addMarker("","E");
          }
        }               
      }
    });

    onChildRemoved(commentsRef, (data) => {
      // console.log('data-onChildRemoved',data);
      // console.log('data-onChildRemoved-data',data);
      const item=driverMarker.findIndex((item)=>item._id.toString()==data.val().uniqId.toString());
      if(parseInt(item)>-1){
        // console.log('onChildChanged-ELSE');
        if(data){
          driverMarker.splice(item,1);
          addMarker("","D");
        }               
      }
    });
    ////////////////////////////////////////////////////////////
  },[]);
  useEffect(()=>{
    // console.log('ProjectDashboard-useEffect-location',location);    
    // console.log('ProjectDashboard-useEffect-location.state.projectId',location.state.projectId);    
    if(location.state){
      setProjectName(location.state.projectName);
      setProjectId(location.state.projectId);
      fetchProjectDetails(location.state.projectId);
      fetchProjectSiteList(location.state.projectId,1);
    }
  },[location])
  useEffect(()=>{
    // console.log('Dashboard-siteReducer-useEffect',siteReducer);    
    if(siteReducer.action){
      if(siteReducer.action===types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS){
        // console.log('ProjectDetails-siteReducer-useEffect',siteReducer)        
        if(siteReducer.status==="success"){
          if(siteReducer.data){
            setTotalPopulation(siteReducer.data.totalPopulations);
            setLocations(siteReducer.data.siteDetails);
            // setNoOfConnections(siteReducer.data.noConnections);
            setTotalWtrSupplied(siteReducer.data.totalWaterSupplied);
            setTotalWtrSuppliedLPCD(siteReducer.data.waterSuppliedLPCD);
            setTotalSiteCount(siteReducer.data.totalSitesCount);          
            // setSiteList(siteReducer.data.siteDetails);
            setTotalSiteRecords(siteReducer.data.siteDetails.length); 
            setServerFile(siteReducer.data.logo); 
            if(siteReducer.data){
              if(siteReducer.data.siteDetails){
                if(siteReducer.data.siteDetails.length>-1){
                  setCenterPoint({lat:parseFloat(siteReducer.data.siteDetails[0].latitude),lng:parseFloat(siteReducer.data.siteDetails[0].longitude)}); 
                }
              }
            }
            
          }
        }else {
          toast({
        title: siteReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      else if(siteReducer.action==types.GET_SITELIST_BY_PROJECT_SUCCESS){
        // console.log('ProjectDetails-GET_SITELIST_BY_PROJECT_SUCCESS-useEffect',siteReducer)
        if(siteReducer.data.list)
          setSiteList(siteReducer.data.list);
        else if(siteReducer.data)
          setSiteList(siteReducer.data);
        // setTotalSiteRecords(siteReducer.data.total_records);
        setSitePage(siteReducer.data.page);
        setLimit(siteReducer.data.limit);
      }
      else if(siteReducer.action===types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE){
        toast({
        title: siteReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
      }
    }
  },[siteReducer.action])
  const addMarker=(data,type)=>{
    // console.log('addMarker-type',type);
    // console.log('addMarker-driverMarker',driverMarker);
    if(type.toString()=="A"){
      if(data){
        const markerListLocal={
          lat:parseFloat(data.val().lat),
          lng:parseFloat(data.val().long),
          _id:data.val().uniqId,
          empData:data.val(),
        };
        // centerPointDriver==null?setCenterPointDriver({lat:parseFloat(data.val().lat),lng:parseFloat(data.val().long)}):null;
        setCenterPointDriver({lat:parseFloat(data.val().lat),lng:parseFloat(data.val().long)});
        driverMarker.push(markerListLocal)
        setMarkerList(driverMarker);
      }
    }
    else if(type.toString()=="E"){
      // centerPointDriver==null?setCenterPointDriver({lat:parseFloat(driverMarker[0].lat),lng:parseFloat(driverMarker[0].lng)}):null;
      setCenterPointDriver({lat:parseFloat(driverMarker[0].lat),lng:parseFloat(driverMarker[0].lng)});
      setMarkerList(driverMarker);
    }
    else if(type.toString()=="D"){
      // centerPointDriver==null?setCenterPointDriver({lat:parseFloat(driverMarker[0].lat),lng:parseFloat(driverMarker[0].lng)}):null;
      setCenterPointDriver({lat:parseFloat(driverMarker[0].lat),lng:parseFloat(driverMarker[0].lng)});
      // setCenterPointDriver({lat:parseFloat(driverMarker[0].lat),lng:parseFloat(driverMarker[0].lng)});
      setMarkerList(driverMarker);
    }
  }
  const fetchProjectDetails=(projectId)=>{
    // console.log('ProjectDetails-siteId',location.state.siteId);
    dispatch(getProjectDetails(projectId));
  }
  const fetchProjectSiteList=(projectId,page)=>{
    // console.log('ProjectDetails-fetchProjectSiteList-page',page);
    const postObject={
      projectID:projectId,
      page:page,
      limit:limit
    }
    dispatch(getSiteListByProject(postObject));
  }
  const onInfoItemClick=(site)=>{
    // console.log('site',site);
    navigate('/site-dashboard',{state: {
      siteId: site._id,
      siteName: site.pumpName,
    }});
  }
  const handleSitePagination=(pageEvent)=>{
    // console.log('ProjectDashboard-handleSitePagination-pageEvent',pageEvent);    
    if(pageEvent=="N"){
      const nPage=sitePage+1;
      // console.log('ProjectDashboard-handleSitePagination-nPage',nPage);
      setSitePage(nPage);
      fetchProjectSiteList(location.state.projectId,nPage)    
    }else if(pageEvent=="P"){
      const pPage=sitePage-1;
      // console.log('ProjectDashboard-handleSitePagination-pPage',pPage);
      setSitePage(pPage);
      fetchProjectSiteList(location.state.projectId,pPage)
    }
    // if(page==1)
    // setPage
    // fetchProjectSiteList
  }
  const bindMap=()=>{   
    // console.log("bindMap-locationList",locationList);
    // console.log("bindMap-centerPoint",centerPoint); 
    if(centerPoint)
      return <MapComponent center={centerPoint} locations={locations} mapHeight="600px" onInfoItemClick={onInfoItemClick} />
      // return <MapComponent center={centerPoint} locations={locationList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} />    
  }
  const bindMapDriver=()=>{
    if(markerList.length>0 && centerPointDriver){
      // setCenterPointDriver({lat:parseFloat(markerList[0].lat),lng:parseFloat(markerList[0].lng)});
      return <MapComponent center={centerPointDriver} locations={markerList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} zoomLevel={7} mapMarker={mapMarker} />
    }      
    else if(centerPoint)
      return <MapComponent center={centerPoint} locations={markerList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} zoomLevel={12} mapMarker={mapMarker} />
    else
      return <MapComponent center={center} locations={markerList} mapTypeId={mapTypeId} mapHeight="600px" onInfoItemClick={null} zoomLevel={12} mapMarker={mapMarker} />
  }
  const bindEmpList=()=>{    
    if(markerList)
    {      
      if(markerList.length>0){
        // console.log('markerList',markerList);
        return markerList.map((item,index)=>
          (
            <li key={item._id} className="list-group-item border-0">
              <div className="d-flex">
                <div className="flex-shrink-0"><img src={avtarImage} className="avatar avatar-xs avatar-4x3" alt="Image Description" /></div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="mb-1">{item.empData.empName+" "+item.empData.empID}</h5>
                  <span className="text-success">Status: {item.empData.totalSites>0?item.empData.inWorkingLnNo>0?"Working":"InTransite  ":"No Route Assigned"}</span>
                </div>
              </div>
            </li>
          )
        );
      }      
    }    
  }
  return (
    <div className="page">
      <NavBar projectLogo={serverFile} />
      <div className="project-page-head">
        <div className="row m-0">
          <div className="col-12 py-4">
            <h3 className="project-title">Dashboard: {projectName?projectName:""}</h3>
          </div>
        </div>
      </div>
      <div className="container-fluid project-dashboard">
        <div className="row">
          <div className="col mb-4">
            <div class="card">
              <div class="card-body py-0">
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="box-content first">
                      <h3 className="box-title text-black">
                        Water Supplied (LPCD)
                      </h3>
                      <p className="site-name text-blue">{totalWtrSuppliedLPCD?totalWtrSuppliedLPCD.toFixed(2)+" L":""}</p>
                    </div>
                  </div>
                  <Block label={'Water supplied(Total Litres)'} value={totalWtrSupplied ? (totalWtrSupplied/1000).toFixed(2) + " KL" : "0 KL"} />
                  <Block label={'Total Pumps'} value={totalSiteCount} />
                  <Block label={'Total Population Supplied'} value={totalPopulation} />
                  {/* <div className="col-9">
                    <div className="row">
                      <Block label={'Water supplied(Total Litres)'} value={totalWtrSupplied?totalWtrSupplied+" L":"0 L"} />
                      <Block label={'Total Pumps'} value={totalSiteCount} />
                      <Block label={'Total Population Supplied'} value={totalPopulation} />
                      <div className="col-3">
                        <div className="box-content border-start">
                          <h3 className="box-title text-black">
                            Green energy share(optional)
                          </h3>
                          <p className="site-name text-red">20</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-xxl-9 mb-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <h4 className="card-header-title">{projectName}</h4>
              </div>
              <div class="card-body">
                <div className="map-area">
                  {/* <img src={mapImage} alt="map" /> */}
                  {bindMap()}
                  {/* <MapComponent
                    center={center}
                    locations={locations}
                    mapHeight="600px"
                    onInfoItemClick={onInfoItemClick}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xxl-3 mb-4">
            <div className="card advanced-info">
              <div class="card-body p-0">
                <BasicInfoSite projectId={projectId} />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-4 mb-4">
            <CardV1 content={"Card-1"} title={'Daily water supply trend ( LPCD)'} type={'B'} />
          </div>
          <div className="col-md-4 mb-4">
            <CardV1 content={"Card-1"} title={'Residual Chlorine (mg/l)'} type={'L'} />
          </div>
          <div className="col-md-4 mb-4">
            <CardV1 content={"Card-1"} title={'Pressure (meter)'} type={'A'} />
          </div>
          <div className="col-md-4 mb-4">
            <CardV1 content={"Card-1"} title={'Water supplied (LPCD): '} type={'L'} />
          </div>
          <div className="col-md-4 mb-4">
            <CardV1 content={"Card-1"} title={'Water Supplied'} type={'A'} />
          </div>
          <div className="col-md-4 mb-4">
            <CardV1 content={"Card-1"} title={'SR Level'} type={'B'} />
          </div>
        </div> */}

        <div className="row">
          <div className="col mb-4">
            <SiteList data={siteList} page={sitePage} totalRecords={totalSiteRecords} pageSize={limit} handleSitePagination={handleSitePagination} />
          </div>
        </div>

        <div className="row">
          <div className="col mb-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8 col-xxl-9">
                    <div className="card operation-tracker-card">
                      <div className="card-header d-flex justify-content-between px-0 ">
                        <h4 className="card-header-title">Operation and Maintenance Tracker</h4>
                        <span>Total NUMBER OF EMPLOYEE: {markerList?markerList.length:0}</span>
                      </div>
                      <div className="card-body px-0"><div className="map-area">{bindMapDriver()}</div></div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xxl-3">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div class="card">
                          <div class="card-header card-header-content-between"><h4 class="card-header-title">Employee LIVE LOCATION STATUS</h4></div>
                          <div class="card-body"><ul class="list-group list-group-flush list-group-no-gutters scroll">{bindEmpList()}</ul></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDashboard;
