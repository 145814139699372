import React, { useEffect, useState } from 'react';
import { useNavigate,Link,useLocation } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from "prop-types";
import {getCompany,deleteCompany} from '../../../redux/actions/companyAction'
import {types} from '../../../redux/actions/types';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import Header from "./header";
import Pumplist from "./pumpList";
import MotorList from "./motorList";
import FlowMeterList from "./flowMeterList";
import PressureMeterList from "./pressureMeterList";
import MultiFunctionMeterList from "./multiFunctionMeterList";
import PhSensorList from "./phSensorList";
import LevelSensorList from "./levelSensorList";
import TurbiditySensorList from "./turbiditySensorList";
import ChlorineSensorList from "./chlorineSensorList";
import ActuatorSensorList from "./actuatorSensorList";
import {getSiteExtraParameterAPI,updateSiteExtraParameterAPI} from '../../../store/apis';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {deleteSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';
// import { Alert } from 'react-bootstrap';
// import { Link } from "react-router-dom";
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";
// import ButtonWithSpinner from "../../Components/ButtonWithSpinner";

const Pump = ({moduleType}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [listSite, setListSite] = useState([]);
  const [listCompany, setListCompany] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pumpList,setPumpList] = useState([]);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false); 
  const [addBtnLbl, setAddBtnLbl] = useState(null); 
  const toast = useToast()

  // const [selectedId, setSelectedId] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const companyReducer = useSelector(state => state.companyReducer);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  useEffect(()=>{
    // setIsLoading(true);
    //console.log('LogIn-useEffect');    
    init();
  },[])
  const init=()=>{
    chkLogin(); 
    setPermission();
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PUMP)
      setAddBtnLbl("Add Pump");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.MOTOR)
      setAddBtnLbl("Add Motor");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.FLOWMETER)
      setAddBtnLbl("Add Flow Meter");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PRESSUREMETER)
      setAddBtnLbl("Add Pressure Meter");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER)
      setAddBtnLbl("Add Multifunction Meter");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PHSENSOR)
      setAddBtnLbl("Add PH Sensor");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.LEVELSENSOR)
      setAddBtnLbl("Add Level Sensor");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR)
      setAddBtnLbl("Add Turbidity Monitor");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR)
      setAddBtnLbl("Add Chlorine Sensor");
    if(moduleType==CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR)
      setAddBtnLbl("Add Actuator Sensor");
  }
  // useEffect(()=>{
  //   console.log('Company-useEffect-location',location);    
  //   // console.log('ProjectDashboard-useEffect-location.state.projectId',location.state.projectId);    
  //   if(location.state){
  //     if(location.state.isReload){
  //       // window.location.reload(false);
  //       // chkLogin(); 
  //       // setPermission();
  //     }
  //   }
  // },[location])
  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if(isView)
      fetchExtraParams(moduleType);  
    // fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PUMP);
      // fetchCompanyList(); 
  },[isView])
  useEffect(()=>{
    //console.log('Company-companyReducer-useEffect',companyReducer);    
    if(companyReducer.action){
      if(companyReducer.action===types.GET_COMPANY_REQUEST_SUCCESS){
        //console.log('Company-companyReducer-useEffect',companyReducer)      
        if(companyReducer.status==="success"){
          setListCompany(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
        title: companyReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      else if(companyReducer.action===types.DELETE_COMPANY_REQUEST_SUCCESS){
        //console.log('Company-companyReducer-useEffect',companyReducer)      
        if(companyReducer.status==="success"){
          fetchCompanyList();
          // setListSite(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
        title: companyReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[companyReducer.action])
  useEffect(()=>{
    //console.log('siteAdminReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.DELETE_SITE_EXTRA_PARAMETER_SUCCESS){
        //console.log('siteAdminReducer-useEffect',siteAdminReducer)              
        if(siteAdminReducer.status==="success"){          
          if(isView)
            fetchExtraParams(moduleType);
          dispatch(clearSiteReducer());
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])
  
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }
  const setPermission=async()=>{
    // setIsCreate(await checkPermission('Company','create'));
    // setIsEdit(await checkPermission('Company','edit'));
    // setIsDelete(await checkPermission('Company','delete'));
    // setIsView(await checkPermission('Company','view'));
    setIsCreate(true);
    setIsEdit(true);
    setIsDelete(true);
    setIsView(true);
    // console.log('Company-User Permissions-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView);    
  }
  const fetchCompanyList=async()=>{
    // console.log("fetchSiteList");
    dispatch(getCompany("page="+page+"&limit="+limit+""));
    // const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    // if(isLoggedIn){
    //   navigate('/Admin/SiteListing');
    // }
  } 
  // const handleDeleteEvent=(item)=>{
  //   // Alert('Are you sure you want to remove?');
  //   // window.confirm("What Is Your Age?");
  //   // setSelectedId(item._id)
  //   // console.log("adsf!=item",item);
  //   if (window.confirm("Are you sure you want to delete?")) {
  //     // setTimeout(() => {
  //       deleteSelCompany(item._id);
  //     // }, 1000);
  //     console.log("You pressed OK!");
  //   } else {
  //     console.log("You pressed Cancel!");
  //   }
  //   // setSelectedId(item._id);
  //   // const request={id:item._id};
  //   // dispatch(deleteSite(item._id));
  // }
  // const handleEditEvent=(item)=>{
  //   // console.log('Company',item);
  //   navigate('/Admin/AddCompany',{state: {
  //     companyId: item._id,
  //     companyName: item.CompanyName,
  //   }});
  //   // navigate('/Admin/AddSite');
  // }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   console.log(item);
  // }
  // const deleteSelCompany=async(selectedId)=>{   
  //   // console.log("deleteSelCompany-selectedId",selectedId); 
  //   // console.log("adsf!=item",item);
  //   if (window.confirm("Are you sure you want to delete?")) {
  //     dispatch(deleteCompany(selectedId));
  //     console.log("You pressed OK!");
  //   } else {
  //     console.log("You pressed Cancel!");
  //   }    
  // }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////// Function to make api call based on extra parameter ///////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const fetchExtraParams=async(type)=>{
    getSiteExtraParameterAPI('type='+type).then((responseObject)=>{
      setPumpList(responseObject.data.list);
    })
  }
  const handlePumpEdit=(item,type)=>{
    if(type===CONSTANTVALUEEXTRAPARAM.PUMP){
      navigate('/Admin/AddPump',{state: {
        item: item
      }});
    } 
    if(type===CONSTANTVALUEEXTRAPARAM.MOTOR){
      navigate('/Admin/AddMotor',{state: {
        item: item
      }});
    }  
    if(type===CONSTANTVALUEEXTRAPARAM.FLOWMETER){
      navigate('/Admin/AddFlowmeter',{state: {
        item: item
      }});
    }
    if(type===CONSTANTVALUEEXTRAPARAM.PRESSUREMETER){
      navigate('/Admin/AddPressureMeter',{state: {
        item: item
      }});
    }
    if(type===CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER){
      navigate('/Admin/AddMultifunctionmeter',{state: {
        item: item
      }});
    }
    if(type===CONSTANTVALUEEXTRAPARAM.PHSENSOR){
      navigate('/Admin/AddPHSensor',{state: {
        item: item
      }});
    }
    if(type===CONSTANTVALUEEXTRAPARAM.LEVELSENSOR){
      navigate('/Admin/AddLevelSensor',{state: {
        item: item
      }});
    } 
    if(type===CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR){
      navigate('/Admin/AddTurbidityMonitor',{state: {
        item: item
      }});
    } 
    if(type===CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR){
      navigate('/Admin/AddChlorineSensor',{state: {
        item: item
      }});
    }
    if(type===CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR){
      navigate('/Admin/AddActuatorSensor',{state: {
        item: item
      }});
    }   
  }
  const handlePumpDelete=(item,type)=>{
    if (window.confirm("Are you sure you want to delete?")) {
      //console.log('User',item);
      const request={
        "id": item._id,
        "type": type
      }    
      dispatch(deleteSiteExtraParameter(request));
      //console.log("You pressed OK!");
    } else {
      //console.log("You pressed Cancel!");
    }    
  }  
  const bindData=()=>{
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PUMP)
      return (<Pumplist data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.PUMP)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.PUMP)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.MOTOR)
      return (<MotorList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.MOTOR)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.MOTOR)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.FLOWMETER)
      return (<FlowMeterList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.FLOWMETER)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.FLOWMETER)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PRESSUREMETER)
      return (<PressureMeterList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.PRESSUREMETER)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.PRESSUREMETER)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER)
      return (<MultiFunctionMeterList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PHSENSOR)
      return (<PhSensorList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.PHSENSOR)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.PHSENSOR)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.LEVELSENSOR)
      return (<LevelSensorList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.LEVELSENSOR)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.LEVELSENSOR)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR)
      return (<TurbiditySensorList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR)
      return (<ChlorineSensorList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR)} isDelete={true} isEdit={true} />)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR)
      return (<ActuatorSensorList data={pumpList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR)} isDelete={true} isEdit={true} />)
  }
  const noViewPermission=()=>{
    return(<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  const bindAddButton=()=>{
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PUMP)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddPump"}>Add Pump</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.MOTOR)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddMotor"}>Add Motor</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.FLOWMETER)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddFlowmeter"}>Add Flow Meter</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PRESSUREMETER)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddPressureMeter"}>Add Pressure Meter</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddMultifunctionmeter"}>Add Multifunction Meter</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PHSENSOR)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddPHSensor"}>Add PH Sensor</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.LEVELSENSOR)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddLevelSensor"}>Add Level Sensor</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddTurbidityMonitor"}>Add Turbidity Monitor</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddChlorineSensor"}>Add Chlorine Sensor</Link>)
    if(moduleType==CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR)
      return (<Link className="btn btn-primary mt-3" to={"/Admin/AddActuatorSensor"}>Add Actuator Sensor</Link>)
    
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Company" />
      <Header moduleType={moduleType} />
      <section>      
        <div className="container">        
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "10px" }}>
                  <div className="d-flex justify-content-end mx-2 mb-2 mb-lg-2">
                    {isCreate?bindAddButton():null}
                  </div>
                <div className="card-body">
                  {isView?bindData():noViewPermission()}                  
                </div>
              </div>              
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};
Pump.propTypes = {
  moduleType: PropTypes.string,
};
Pump.defaultProp = {
  moduleType: CONSTANTVALUEEXTRAPARAM.PUMP,
};
export default Pump;
