import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddPHSensor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [pHSensorModel,setPHSensorModel] = useState('');
  const [pHSensorMake,setPHSensorMake] = useState('');
  const [pHSensorMaxVal,setPHSensorMaxVal] = useState('');
  const [pHSensorMinVal,setPHSensorMinVal] = useState('');
  const [pHSensorSerialNo,setPHSensorSerialNo] = useState('');
  const [localpHSensorId, setLocalpHSensorId] = useState(null);
  const toast = useToast()

  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          //console.log('Pressure Meter-item',location.state.item);
          setPHSensorModel(location.state.item.pHSensorModel);
          setPHSensorMake(location.state.item.pHSensorMake);
          setPHSensorMaxVal(location.state.item.pHSensorMaxValue);
          setPHSensorMinVal(location.state.item.pHSensorMinValue);
          setLocalpHSensorId(location.state.item._id)
        }
      }
    }    
  },[])
  useEffect(()=>{
    //console.log('PHSENSOR-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          dispatch(clearSiteReducer());
          navigate('/Admin/Phsensor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.PHSENSOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Phsensor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.PHSENSOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])


  const handleSiteInput=(e)=>{
    //////////////// pH Sensor Popup ////////////////////////////////////
    if(e.target.name==="txtPHSensorModel"){
      setPHSensorModel(e.target.value);
    }
    if(e.target.name==="txtPHSensorMake"){
      setPHSensorMake(e.target.value);
    }
    if(e.target.name==="txtPHSensorMaxVal"){
      setPHSensorMaxVal(e.target.value);
    }
    if(e.target.name==="txtPHSensorMinVal"){
      setPHSensorMinVal(e.target.value);
    }
    if(e.target.name==="txtPHSensorSerialNo"){
      setPHSensorSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }
  
  const handlePHSensorSubmit=()=>{
    if(isEmpty(pHSensorModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pHSensorMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pHSensorMaxVal)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pHSensorMinVal)){
      toast({
        title: 'Please select min value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localpHSensorId){
        const request={
          "id":localpHSensorId,
          "type": CONSTANTVALUEEXTRAPARAM.PHSENSOR,
          "pHSensorModel":pHSensorModel,
          "pHSensorMake":pHSensorMake,
          "pHSensorMaxValue":pHSensorMaxVal,
          "pHSensorMinValue":pHSensorMinVal,
        };
        // setCurrentField(CONSTANTVALUEEXTRAPARAM.PHSENSOR);
        dispatch(updateSiteExtraParameter(request));
        // handlePHSensorShow();
      }
      else{
        const request={
          "type": CONSTANTVALUEEXTRAPARAM.PHSENSOR,
          "pHSensorModel":pHSensorModel,
          "pHSensorMake":pHSensorMake,
          "pHSensorMaxValue":pHSensorMaxVal,
          "pHSensorMinValue":pHSensorMinVal,
        };
        // setCurrentField(CONSTANTVALUEEXTRAPARAM.PHSENSOR);
        dispatch(siteExtraParameter(request));
        // handlePHSensorShow();
      }      
    }    
  }
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddPHSensor" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localpHSensorId?"Update pH Sesnor":"Add pH Sesnor"}</p> 
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="pHSensorModel" className="col-sm-4 col-form-label">pH Model</label>
                        <input type="text" name="txtPHSensorModel" id="txtPHSensorModel" value={pHSensorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="pHSensorMake" className="col-sm-4 col-form-label">pH Make</label>
                        <input type="text" name="txtPHSensorMake" id="txtPHSensorMake" value={pHSensorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="pHSensorMaxVal" className="col-sm-4 col-form-label">Max Value</label>
                        <input type="text" name="txtPHSensorMaxVal" id="txtPHSensorMaxVal" value={pHSensorMaxVal} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="pHSensorMinVal" className="col-sm-4 col-form-label">Min Value</label>
                        <input type="text" name="txtPHSensorMinVal" id="txtPHSensorMinVal" value={pHSensorMinVal} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>                     
                    <div className="d-flex justify-content-center mb-3">
                      <Button variant="primary" onClick={()=>handlePHSensorSubmit()}>Submit</Button>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddPHSensor;
