import React, { useEffect, useState, useRef, useCallback } from 'react';
import './TickerNews.css';
import { MdNewspaper } from 'react-icons/md';

const Element = (props) => {
  const { title } = props;
  return (
    <div className="element">{title}</div>
  );
};

const TickerNews = ({ news }) => {
  const [items, setItems] = useState(news);
  const wrapperRef = useRef();
  const indexRef = useRef();

  useEffect(() => {
    if (news && news.length > 1) {
      // Update the news items correctly
      const updatedNewsItems = news.map((item) => ({ ...item, key: item.newsTitle.toString() }));
      setItems(updatedNewsItems);
    }
  }, [news]);

  const handleRefUpdate = useCallback((node) => {
    if (node !== null && items.length > 0) {
      indexRef.current = node.firstChild;
      wrapperRef.current = node;
      document.documentElement.style.setProperty('--animationDistance', `${0 - indexRef.current.offsetWidth}px`);
      document.documentElement.style.setProperty('--animationDuration', `${Math.round(indexRef.current.offsetWidth / 33)}s`);
      wrapperRef.current.classList.add('moving');
    }
  }, [items]);

  const handleLoop = () => {
    wrapperRef.current.classList.remove('moving');
    wrapperRef.current.style.animation = 'none';
    const t = wrapperRef.current.offsetHeight; /* trigger reflow */
    wrapperRef.current.style.animation = null;
  
    setItems((prevItems) => {
      // Rotate the array to move the first item to the end
      const rotatedItems = [...prevItems];
      const firstItem = rotatedItems.shift();
      rotatedItems.push(firstItem);
      return rotatedItems;
    });
  };
  

  const shiftNext = (copy) => {
    const firstItem = copy.shift();
    copy.push(firstItem);
    setItems(copy);
  };

  const handleAnimationEnd = () => {
    handleLoop();
  };

  return (
    <div className="wrapper">
      <div className="inner" ref={handleRefUpdate} onAnimationEnd={handleAnimationEnd}>
        {items.map((obj, index) =><Element title={obj.key} key={obj.key + index} />)}
      </div>
    </div>
  );
};

export default TickerNews;
