import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {addProjectAPI,updateProjectAPI,adminProjectListAPI,deleteProjectAPI,adminProjectDetailsAPI} from '../../store/apis';

function* addProject({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('addProject',payload);
    const responseObject = yield call(addProjectAPI,payload);   
    console.log('responseObject',responseObject); 
    // if(userObject.status=200)
    yield put({type: types.ADD_PROJECT_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.ADD_PROJECT_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* updateProject({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    console.log('updateProject',payload);
    const responseObject = yield call(updateProjectAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.EDIT_PROJECT_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.EDIT_PROJECT_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* deleteProject({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(deleteProjectAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.DELETE_PROJECT_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.DELETE_PROJECT_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getProjectList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(adminProjectListAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.LIST_PROJECT_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.LIST_PROJECT_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getProjectById({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(adminProjectDetailsAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.PROJECT_BY_ID_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.PROJECT_BY_ID_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
export default function* projectAdminSaga() {
  yield all([
    yield takeLatest(types.ADD_PROJECT_REQUEST, addProject),
    yield takeLatest(types.EDIT_PROJECT_REQUEST, updateProject),
    yield takeLatest(types.DELETE_PROJECT_REQUEST, deleteProject), 
    yield takeLatest(types.LIST_PROJECT_REQUEST, getProjectList),
    yield takeLatest(types.PROJECT_BY_ID_REQUEST, getProjectById),    
    // LOAD_LOGGEDIN_USER(),
  ])
}
