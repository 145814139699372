import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {addProject,updateProject,getProjectById} from '../../redux/actions/projectAdminAction'
// import {getSiteDetails} from '../../redux/actions/siteAction'
import {types} from '../../redux/actions/types';
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import { Box, Checkbox, Input, Switch, Text, VStack } from "@chakra-ui/react";
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import {getCompany} from '../../redux/actions/companyAction'
import {isEmpty, isLoggedInUser,checkPermission} from '../../utils'
import { useToast } from '@chakra-ui/react';
 
const AddProjectDetails = () => {
  const navigate = useNavigate();
  const parameterNames = [
    "Pressure p1",
    "OHSR level p2",
    "Borewell level p3",
    "Chlorine p4",
    "Turbidity p5",
    "Motor Voltage p6",
    "Motor Current p7",
    "Power p8",
    "Motor Frequency p9",
    "DC voltage p10",
    "MOTOR KW p11",
    "Flow Rate p12",
    "Total Flow p13",
    "Fuel Level of DG p14"
  ];
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState('');
  const [stateName, setStateName] = useState('');
  const [inLongitude, setInLongitude] = useState('');
  const [inLatitude, setInLatitude] = useState('');
  const [listCompany, setListCompany] = useState([]);
  const [empType, setEmpType] = useState('');
  const [dmts, setDmts] = useState(false);
  const [extraInformation, setExtraInformation] = useState(false);
  const [company, setCompany] = useState('');
  const [paramtersThresholdValues, setThresholdValues] = useState(["0-0","0-0","0-0","0-0","0-0","0-0","0-0","0-0","0-0","0-0","0-0","0-0","0-0","0-0"]);
  const [inImei, setInImei] = useState('');
  const [isActive, setIsACtive] = useState(null);
  const [file, setFile] = useState();
  const [serverFile, setServerFile] = useState();
  const [localProjectId, setLocalProjectId] = useState(null);
  const handleMinValueChange = (index, e) => {
    const newThresholdValues = [...paramtersThresholdValues];
    const min = parseInt(e.target.value); // Parse min as an integer
    const max = parseInt(newThresholdValues[index].split('-')[1]);
  
    if (min <= max) {
      newThresholdValues[index] = `${min}-${max}`;
    } else {
      // Handle the case where min is greater than max (set it to zero or handle as needed)
      newThresholdValues[index] = `0-${max}`;
    }
  
    setThresholdValues(newThresholdValues);
  };
  
  const handleMaxValueChange = (index, e) => {
    const newThresholdValues = [...paramtersThresholdValues];
    const min = newThresholdValues[index].split('-')[0];
    newThresholdValues[index] = `${min}-${e.target.value}`;
    console.log("NEWWWW", newThresholdValues)
    setThresholdValues(newThresholdValues);
  }
  // const [feedbacks, setFeedbacks] = useState([{title:"Pump Auto/Manual",parameter:"f16",isActive:true,measurement:"",logo:""},{title:"Pump on/off status",parameter:"f7",isActive:true,measurement:"",logo:""},{title:"Flush Status",parameter:"f1",isActive:true,measurement:"",logo:""},{title:"Inlet Valve Status",parameter:"f3",isActive:true,measurement:"",logo:""},{title:"Outlet Valve",parameter:"f4",isActive:true,measurement:"",logo:""},{title:"Supply Source",parameter:"f17",isActive:true,measurement:"",logo:""}]); 
  // const [parameters, setParameters] = useState([{title:"Pressure",parameter:"p1",isActive:true,measurement:"BAR",logo:""},{title:"OHSR level",parameter:"p2",isActive:true,measurement:"%",logo:""},{title:"Borewell level",parameter:"p3",isActive:true,measurement:"Mtr",logo:""},{title:"Chlorine",parameter:"p4",isActive:true,measurement:"PPM",logo:""},{title:"Turbidity",parameter:"p5",isActive:true,measurement:"NTU",logo:""},{title:"Motor Voltage",parameter:"p6",isActive:true,measurement:"",logo:""},{title:"Motor Current",parameter:"p7",isActive:true,measurement:"A",logo:""},{title:"Energy( it was power earlier)",parameter:"p8",isActive:true,measurement:"KW",logo:""},{title:"Motor Frequency",parameter:"p9",isActive:true,measurement:"Hz",logo:""},{title:"DC voltage",parameter:"p10",isActive:false,measurement:"",logo:""},{title:"Flow Rate",parameter:"p12",isActive:true,measurement:"LPM",logo:""},{title:"Total Flow",parameter:"p13",isActive:true,measurement:"L",logo:""},{title:"Fuel Level of DG",parameter:"p14",isActive:true,measurement:"",logo:""},{title:"KWH(DG)",parameter:"p18",isActive:true,measurement:"",logo:""},{title:"Current(DG)",parameter:"p19",isActive:true,measurement:"",logo:""},{title:"KW(DG)",parameter:"p17",isActive:true,measurement:"",logo:""},{title:"DG Running Hours",parameter:"p15",isActive:true,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p16",isActive:true,measurement:"",logo:""}]); 
  // const [graphs,setGraphs]=useState([{title:"LPCD",isActive:true,measurement:"",logo:""},{title:"pH",isActive:true,measurement:"",logo:""},{title:"Borewell level",isActive:true,measurement:"",logo:""},{title:"Power",isActive:true,measurement:"",logo:""},{title:"Chlorine",isActive:true,measurement:"",logo:""},{title:"Turbidity",isActive:true,measurement:"",logo:""},{title:"Tubewell Level",isActive:true,measurement:"",logo:""},{title:"Pressure",isActive:true,measurement:"",logo:""},{title:"OHSR",isActive:true,measurement:"",logo:""},{title:"Achieved Litres ",isActive:true,measurement:"",logo:""}]); 
  const [feedbacks, setFeedbacks] = useState([]); 
  // const [feedbacks, setFeedbacks] = useState([{title:"Pump Auto/Manual",parameter:"f16",isActive:false,measurement:"",logo:""},{title:"Pump on/off status",parameter:"f7",isActive:false,measurement:"",logo:""},{title:"Flush Status",parameter:"f1",isActive:false,measurement:"",logo:""},{title:"Inlet Valve Status",parameter:"f3",isActive:false,measurement:"",logo:""},{title:"Outlet Valve",parameter:"f4",isActive:false,measurement:"",logo:""},{title:"Supply Source",parameter:"f17",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-01",parameter:"f8",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-02",parameter:"f9",isActive:false,measurement:"",logo:""},{title:"Dosing Pump ON/OFF Status",parameter:"f10",isActive:false,measurement:"",logo:""}]);
  const [parameters, setParameters] = useState([]); 
  const [graphs,setGraphs]=useState([]); 
  const projectAdminReducer = useSelector(state => state.projectAdminReducer);
  const companyReducer = useSelector(state => state.companyReducer);
  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  ///////////////////////////////////////////////////////////////// 
  const [isEdit, setIsEdit] = useState(false);
  const toast = useToast()
  const location = useLocation();
  const convertStringToArray = (str) => {
    const parts = str.split('-');
    const numbers = parts.map(Number);
    return numbers;
  }
  
  useEffect(()=>{
    // setIsLoading(true);
    console.log('location',location);    
    chkLogin(); 
    setPermission();
    fetchCompanyList();
    if(location){
      if(location.state){
        if(location.state.projectId){
          setLocalProjectId(location.state.projectId);
          fetchProjectDetails();
        }
      }
      else {
        setFeedbacks([{title:"Pump Auto/Manual",parameter:"f16",isActive:false,measurement:"",logo:""},{title:"Pump on/off status",parameter:"f7",isActive:false,measurement:"",logo:""},{title:"Flush Status",parameter:"f1",isActive:false,measurement:"",logo:""},{title:"Inlet Valve Status",parameter:"f3",isActive:false,measurement:"",logo:""},{title:"Outlet Valve",parameter:"f4",isActive:false,measurement:"",logo:""},{title:"Supply Source",parameter:"f17",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-01",parameter:"f8",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-02",parameter:"f9",isActive:false,measurement:"",logo:""},{title:"Dosing Pump ON/OFF Status",parameter:"f10",isActive:false,measurement:"",logo:""}]);
        setParameters([{title:"Pressure",parameter:"p1",isActive:false,measurement:"BAR",logo:""},{title:"OHSR level",parameter:"p2",isActive:false,measurement:"%",logo:""},{title:"Borewell level",parameter:"p3",isActive:false,measurement:"Mtr",logo:""},{title:"Chlorine",parameter:"p4",isActive:false,measurement:"PPM",logo:""},{title:"Turbidity",parameter:"p5",isActive:false,measurement:"NTU",logo:""},{title:"Motor Voltage",parameter:"p6",isActive:false,measurement:"",logo:""},{title:"Motor Current",parameter:"p7",isActive:false,measurement:"A",logo:""},{title:"Power",parameter:"p8",isActive:false,measurement:"KW",logo:""},{title:"Motor Frequency",parameter:"p9",isActive:false,measurement:"Hz",logo:""},{title:"DC voltage",parameter:"p10",isActive:false,measurement:"V",logo:""},{title:"MOTOR KW",parameter:"p11",isActive:false,measurement:"KWH",logo:""},{title:"Flow Rate",parameter:"p12",isActive:false,measurement:"LPM",logo:""},{title:"Total Flow",parameter:"p13",isActive:false,measurement:"L",logo:""},{title:"Fuel Level of DG",parameter:"p14",isActive:false,measurement:"",logo:""},{title:"KWH(DG)",parameter:"p18",isActive:false,measurement:"",logo:""},{title:"Current(DG)",parameter:"p19",isActive:false,measurement:"",logo:""},{title:"KW(DG)",parameter:"p17",isActive:false,measurement:"",logo:""},{title:"DG Running Hours",parameter:"p15",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p16",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p20",isActive:false,measurement:"",logo:""},{title:"DG YN VOLTAGE",parameter:"p21",isActive:false,measurement:"",logo:""},{title:"DG BN VOLTAGE",parameter:"p22",isActive:false,measurement:"",logo:""},{title:"DG RY VOLTAGE",parameter:"p23",isActive:false,measurement:"",logo:""},{title:"DG YB VOLTAGE",parameter:"p24",isActive:false,measurement:"",logo:""},{title:"DG BR VOLTAGE",parameter:"p25",isActive:false,measurement:"",logo:""}]);
        setGraphs([{title:"LPCD",isActive:false,measurement:"",logo:""},{title:"pH",isActive:false,measurement:"",logo:""},{title:"Borewell level",isActive:false,measurement:"",logo:""},{title:"Power",isActive:false,measurement:"",logo:""},{title:"Chlorine",isActive:false,measurement:"",logo:""},{title:"Turbidity",isActive:false,measurement:"",logo:""},{title:"Tubewell Level",isActive:false,measurement:"",logo:""},{title:"Pressure",isActive:false,measurement:"",logo:""},{title:"OHSR",isActive:false,measurement:"",logo:""},{title:"Achieved Litres ",isActive:false,measurement:"",logo:""}]);
      } 
    }  
    else {
      setFeedbacks([{title:"Pump Auto/Manual",parameter:"f16",isActive:false,measurement:"",logo:""},{title:"Pump on/off status",parameter:"f7",isActive:false,measurement:"",logo:""},{title:"Flush Status",parameter:"f1",isActive:false,measurement:"",logo:""},{title:"Inlet Valve Status",parameter:"f3",isActive:false,measurement:"",logo:""},{title:"Outlet Valve",parameter:"f4",isActive:false,measurement:"",logo:""},{title:"Supply Source",parameter:"f17",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-01",parameter:"f8",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-02",parameter:"f9",isActive:false,measurement:"",logo:""},{title:"Dosing Pump ON/OFF Status",parameter:"f10",isActive:false,measurement:"",logo:""}]);
      setParameters([{title:"Pressure",parameter:"p1",isActive:false,measurement:"BAR",logo:""},{title:"OHSR level",parameter:"p2",isActive:false,measurement:"%",logo:""},{title:"Borewell level",parameter:"p3",isActive:false,measurement:"Mtr",logo:""},{title:"Chlorine",parameter:"p4",isActive:false,measurement:"PPM",logo:""},{title:"Turbidity",parameter:"p5",isActive:false,measurement:"NTU",logo:""},{title:"Motor Voltage",parameter:"p6",isActive:false,measurement:"",logo:""},{title:"Motor Current",parameter:"p7",isActive:false,measurement:"A",logo:""},{title:"Power",parameter:"p8",isActive:false,measurement:"KW",logo:""},{title:"Motor Frequency",parameter:"p9",isActive:false,measurement:"Hz",logo:""},{title:"DC voltage",parameter:"p10",isActive:false,measurement:"V",logo:""},{title:"MOTOR KW",parameter:"p11",isActive:false,measurement:"KWH",logo:""},{title:"Flow Rate",parameter:"p12",isActive:false,measurement:"LPM",logo:""},{title:"Total Flow",parameter:"p13",isActive:false,measurement:"L",logo:""},{title:"Fuel Level of DG",parameter:"p14",isActive:false,measurement:"",logo:""},{title:"KWH(DG)",parameter:"p18",isActive:false,measurement:"",logo:""},{title:"Current(DG)",parameter:"p19",isActive:false,measurement:"",logo:""},{title:"KW(DG)",parameter:"p17",isActive:false,measurement:"",logo:""},{title:"DG Running Hours",parameter:"p15",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p16",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p20",isActive:false,measurement:"",logo:""},{title:"DG YN VOLTAGE",parameter:"p21",isActive:false,measurement:"",logo:""},{title:"DG BN VOLTAGE",parameter:"p22",isActive:false,measurement:"",logo:""},{title:"DG RY VOLTAGE",parameter:"p23",isActive:false,measurement:"",logo:""},{title:"DG YB VOLTAGE",parameter:"p24",isActive:false,measurement:"",logo:""},{title:"DG BR VOLTAGE",parameter:"p25",isActive:false,measurement:"",logo:""}]);
      setGraphs([{title:"LPCD",isActive:false,measurement:"",logo:""},{title:"pH",isActive:false,measurement:"",logo:""},{title:"Borewell level",isActive:false,measurement:"",logo:""},{title:"Power",isActive:false,measurement:"",logo:""},{title:"Chlorine",isActive:false,measurement:"",logo:""},{title:"Turbidity",isActive:false,measurement:"",logo:""},{title:"Tubewell Level",isActive:false,measurement:"",logo:""},{title:"Pressure",isActive:false,measurement:"",logo:""},{title:"OHSR",isActive:false,measurement:"",logo:""},{title:"Achieved Litres ",isActive:false,measurement:"",logo:""}]);
    }  
  },[])
 
 
  useEffect(()=> {
    if(!localProjectId){
      setParameters([{title:"Pressure",parameter:"p1",isActive:false,measurement:"BAR",logo:""},{title:"OHSR level",parameter:"p2",isActive:false,measurement:"%",logo:""},{title:"Borewell level",parameter:"p3",isActive:false,measurement:"Mtr",logo:""},{title:"Chlorine",parameter:"p4",isActive:false,measurement:"PPM",logo:""},{title:"Turbidity",parameter:"p5",isActive:false,measurement:"NTU",logo:""},{title:"Motor Voltage",parameter:"p6",isActive:false,measurement:"",logo:""},{title:"Motor Current",parameter:"p7",isActive:false,measurement:"A",logo:""},{title:"Power",parameter:"p8",isActive:false,measurement:"KW",logo:""},{title:"Motor Frequency",parameter:"p9",isActive:false,measurement:"Hz",logo:""},{title:"DC voltage",parameter:"p10",isActive:false,measurement:"V",logo:""},{title:"MOTOR KW",parameter:"p11",isActive:false,measurement:"KWH",logo:""},{title:"Flow Rate",parameter:"p12",isActive:false,measurement:"LPM",logo:""},{title:"Total Flow",parameter:"p13",isActive:false,measurement:"L",logo:""},{title:"Fuel Level of DG",parameter:"p14",isActive:false,measurement:"",logo:""},{title:"KWH(DG)",parameter:"p18",isActive:false,measurement:"",logo:""},{title:"Current(DG)",parameter:"p19",isActive:false,measurement:"",logo:""},{title:"KW(DG)",parameter:"p17",isActive:false,measurement:"",logo:""},{title:"DG Running Hours",parameter:"p15",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p16",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p20",isActive:false,measurement:"",logo:""},{title:"DG YN VOLTAGE",parameter:"p21",isActive:false,measurement:"",logo:""},{title:"DG BN VOLTAGE",parameter:"p22",isActive:false,measurement:"",logo:""},{title:"DG RY VOLTAGE",parameter:"p23",isActive:false,measurement:"",logo:""},{title:"DG YB VOLTAGE",parameter:"p24",isActive:false,measurement:"",logo:""},{title:"DG BR VOLTAGE",parameter:"p25",isActive:false,measurement:"",logo:""}]);
      setGraphs([{title:"LPCD",isActive:false,measurement:"",logo:""},{title:"pH",isActive:false,measurement:"",logo:""},{title:"Borewell level",isActive:false,measurement:"",logo:""},{title:"Power",isActive:false,measurement:"",logo:""},{title:"Chlorine",isActive:false,measurement:"",logo:""},{title:"Turbidity",isActive:false,measurement:"",logo:""},{title:"Tubewell Level",isActive:false,measurement:"",logo:""},{title:"Pressure",isActive:false,measurement:"",logo:""},{title:"OHSR",isActive:false,measurement:"",logo:""},{title:"Achieved Litres ",isActive:false,measurement:"",logo:""}]);            
      setFeedbacks([{title:"Pump Auto/Manual",parameter:"f16",isActive:false,measurement:"",logo:""},{title:"Pump on/off status",parameter:"f7",isActive:false,measurement:"",logo:""},{title:"Flush Status",parameter:"f1",isActive:false,measurement:"",logo:""},{title:"Inlet Valve Status",parameter:"f3",isActive:false,measurement:"",logo:""},{title:"Outlet Valve",parameter:"f4",isActive:false,measurement:"",logo:""},{title:"Supply Source",parameter:"f17",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-01",parameter:"f8",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-02",parameter:"f9",isActive:false,measurement:"",logo:""},{title:"Dosing Pump ON/OFF Status",parameter:"f10",isActive:false,measurement:"",logo:""}]);
    } else {
      setParameters(projectAdminReducer.data.parameters);
      setGraphs(projectAdminReducer.data.graphs);
      setFeedbacks(projectAdminReducer.data.feedbacks)
    }
  }, localProjectId)
 
  useEffect(()=>{
    if(localProjectId && projectAdminReducer && projectAdminReducer.data) {
      setDmts(projectAdminReducer.data.dmtsOption);
      const stat = projectAdminReducer.data.extraInformation == true ? true : false;
      console.log("dcvsvsv", stat);
      setExtraInformation(stat);
    }
  }, [projectAdminReducer])
 
  const setThresholdValuesFun = () => {
    const tVals = projectAdminReducer.threshold
    console.log("TVALS", tVals)
    if(tVals) {
      let newArr = paramtersThresholdValues;
      if (tVals.p1) newArr[0] = tVals.p1;
      if (tVals.p2) newArr[1] = tVals.p2;
      if (tVals.p3) newArr[2] = tVals.p3;
      if (tVals.p4) newArr[3] = tVals.p4;
      if (tVals.p5) newArr[4] = tVals.p5;
      if (tVals.p6) newArr[5] = tVals.p6;
      if (tVals.p7) newArr[6] = tVals.p7;
      if (tVals.p8) newArr[7] = tVals.p8;
      if (tVals.p9) newArr[8] = tVals.p9;
      if (tVals.p10) newArr[9] = tVals.p10;
      if (tVals.p11) newArr[10] = tVals.p11;
      setThresholdValues(newArr)
    }
  }
 
 
  useEffect(()=>{
    //console.log('AddProject-userReducer-useEffect',projectAdminReducer);    
    if(projectAdminReducer.action){
      if(projectAdminReducer.action===types.ADD_PROJECT_REQUEST_SUCCESS){
        console.log("xxx case 1")
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if(projectAdminReducer.status==="success"){
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/ProjectListing');
        }else {
          toast({
            title: projectAdminReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }else if(projectAdminReducer.action===types.EDIT_PROJECT_REQUEST_SUCCESS){
      console.log("xxx case 2")
 
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if(projectAdminReducer.status==="success"){
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/ProjectListing');
        }else {
          toast({
            title: projectAdminReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }else if(projectAdminReducer.action===types.PROJECT_BY_ID_REQUEST_SUCCESS){
      console.log("xxx case 3")
 
        console.log('projectAdminReducer.data',projectAdminReducer)
        // if(projectAdminReducer.activeCheckbox==="activeCheckbox"){
          setProjectName(projectAdminReducer.data.ProjectName);
          setStateName(projectAdminReducer.data.StateName);
          setInLongitude(projectAdminReducer.data.longitude);
          setInLatitude(projectAdminReducer.data.latitude);
          setLocalProjectId(projectAdminReducer.data._id);
          setServerFile(projectAdminReducer.data.logo);  
          setThresholdValuesFun();
          setCompany(projectAdminReducer.data.companyId);
 
          if(projectAdminReducer.data.feedbacks)
          {
            if(projectAdminReducer.data.feedbacks.length>0){
              setFeedbacks(projectAdminReducer.data.feedbacks);
            }
            else
              setFeedbacks([{title:"Pump Auto/Manual",parameter:"f16",isActive:false,measurement:"",logo:""},{title:"Pump on/off status",parameter:"f7",isActive:false,measurement:"",logo:""},{title:"Flush Status",parameter:"f1",isActive:false,measurement:"",logo:""},{title:"Inlet Valve Status",parameter:"f3",isActive:false,measurement:"",logo:""},{title:"Outlet Valve",parameter:"f4",isActive:false,measurement:"",logo:""},{title:"Supply Source",parameter:"f17",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-01",parameter:"f8",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-02",parameter:"f9",isActive:false,measurement:"",logo:""},{title:"Dosing Pump ON/OFF Status",parameter:"f10",isActive:false,measurement:"",logo:""}]);
          }
          else
            setFeedbacks([{title:"Pump Auto/Manual",parameter:"f16",isActive:false,measurement:"",logo:""},{title:"Pump on/off status",parameter:"f7",isActive:false,measurement:"",logo:""},{title:"Flush Status",parameter:"f1",isActive:false,measurement:"",logo:""},{title:"Inlet Valve Status",parameter:"f3",isActive:false,measurement:"",logo:""},{title:"Outlet Valve",parameter:"f4",isActive:false,measurement:"",logo:""},{title:"Supply Source",parameter:"f17",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-01",parameter:"f8",isActive:false,measurement:"",logo:""},{title:"Dosing Tank-02",parameter:"f9",isActive:false,measurement:"",logo:""},{title:"Dosing Pump ON/OFF Status",parameter:"f10",isActive:false,measurement:"",logo:""}]);
          
 
          if(projectAdminReducer.data.graphs){
            if(projectAdminReducer.data.graphs.length>0){
              setGraphs(projectAdminReducer.data.graphs);
              console.log("xxx ifffffffffffff")
            }
            else {
              setGraphs([{title:"LPCD",isActive:false,measurement:"",logo:""},{title:"pH",isActive:false,measurement:"",logo:""},{title:"Borewell level",isActive:false,measurement:"",logo:""},{title:"Power",isActive:false,measurement:"",logo:""},{title:"Chlorine",isActive:false,measurement:"",logo:""},{title:"Turbidity",isActive:false,measurement:"",logo:""},{title:"Tubewell Level",isActive:false,measurement:"",logo:""},{title:"Pressure",isActive:false,measurement:"",logo:""},{title:"OHSR",isActive:false,measurement:"",logo:""},{title:"Achieved Litres ",isActive:false,measurement:"",logo:""}]);
              console.log('xxx iffff 1')
            }
          }
          else {
            setGraphs([{title:"LPCD",isActive:false,measurement:"",logo:""},{title:"pH",isActive:false,measurement:"",logo:""},{title:"Borewell level",isActive:false,measurement:"",logo:""},{title:"Power",isActive:false,measurement:"",logo:""},{title:"Chlorine",isActive:false,measurement:"",logo:""},{title:"Turbidity",isActive:false,measurement:"",logo:""},{title:"Tubewell Level",isActive:false,measurement:"",logo:""},{title:"Pressure",isActive:false,measurement:"",logo:""},{title:"OHSR",isActive:false,measurement:"",logo:""},{title:"Achieved Litres ",isActive:false,measurement:"",logo:""}]);            
            console.log('xxx ifffff')
          }
 
          if(projectAdminReducer.data.parameters){
            if(projectAdminReducer.data.parameters.length>0){
              setParameters(projectAdminReducer.data.parameters);
            }
            else 
              setParameters([{title:"Pressure",parameter:"p1",isActive:false,measurement:"BAR",logo:""},{title:"OHSR level",parameter:"p2",isActive:false,measurement:"%",logo:""},{title:"Borewell level",parameter:"p3",isActive:false,measurement:"Mtr",logo:""},{title:"Chlorine",parameter:"p4",isActive:false,measurement:"PPM",logo:""},{title:"Turbidity",parameter:"p5",isActive:false,measurement:"NTU",logo:""},{title:"Motor Voltage",parameter:"p6",isActive:false,measurement:"",logo:""},{title:"Motor Current",parameter:"p7",isActive:false,measurement:"A",logo:""},{title:"Power",parameter:"p8",isActive:false,measurement:"KW",logo:""},{title:"Motor Frequency",parameter:"p9",isActive:false,measurement:"Hz",logo:""},{title:"DC voltage",parameter:"p10",isActive:false,measurement:"V",logo:""},{title:"MOTOR KW",parameter:"p11",isActive:false,measurement:"KWH",logo:""},{title:"Flow Rate",parameter:"p12",isActive:false,measurement:"LPM",logo:""},{title:"Total Flow",parameter:"p13",isActive:false,measurement:"L",logo:""},{title:"Fuel Level of DG",parameter:"p14",isActive:false,measurement:"",logo:""},{title:"KWH(DG)",parameter:"p18",isActive:false,measurement:"",logo:""},{title:"Current(DG)",parameter:"p19",isActive:false,measurement:"",logo:""},{title:"KW(DG)",parameter:"p17",isActive:false,measurement:"",logo:""},{title:"DG Running Hours",parameter:"p15",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p16",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p20",isActive:false,measurement:"",logo:""},{title:"DG YN VOLTAGE",parameter:"p21",isActive:false,measurement:"",logo:""},{title:"DG BN VOLTAGE",parameter:"p22",isActive:false,measurement:"",logo:""},{title:"DG RY VOLTAGE",parameter:"p23",isActive:false,measurement:"",logo:""},{title:"DG YB VOLTAGE",parameter:"p24",isActive:false,measurement:"",logo:""},{title:"DG BR VOLTAGE",parameter:"p25",isActive:false,measurement:"",logo:""}]);
          }
          else 
            setParameters([{title:"Pressure",parameter:"p1",isActive:false,measurement:"BAR",logo:""},{title:"OHSR level",parameter:"p2",isActive:false,measurement:"%",logo:""},{title:"Borewell level",parameter:"p3",isActive:false,measurement:"Mtr",logo:""},{title:"Chlorine",parameter:"p4",isActive:false,measurement:"PPM",logo:""},{title:"Turbidity",parameter:"p5",isActive:false,measurement:"NTU",logo:""},{title:"Motor Voltage",parameter:"p6",isActive:false,measurement:"",logo:""},{title:"Motor Current",parameter:"p7",isActive:false,measurement:"A",logo:""},{title:"Power",parameter:"p8",isActive:false,measurement:"KW",logo:""},{title:"Motor Frequency",parameter:"p9",isActive:false,measurement:"Hz",logo:""},{title:"DC voltage",parameter:"p10",isActive:false,measurement:"V",logo:""},{title:"MOTOR KW",parameter:"p11",isActive:false,measurement:"KWH",logo:""},{title:"Flow Rate",parameter:"p12",isActive:false,measurement:"LPM",logo:""},{title:"Total Flow",parameter:"p13",isActive:false,measurement:"L",logo:""},{title:"Fuel Level of DG",parameter:"p14",isActive:false,measurement:"",logo:""},{title:"KWH(DG)",parameter:"p18",isActive:false,measurement:"",logo:""},{title:"Current(DG)",parameter:"p19",isActive:false,measurement:"",logo:""},{title:"KW(DG)",parameter:"p17",isActive:false,measurement:"",logo:""},{title:"DG Running Hours",parameter:"p15",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p16",isActive:false,measurement:"",logo:""},{title:"DG Running Minutes",parameter:"p20",isActive:false,measurement:"",logo:""},{title:"DG YN VOLTAGE",parameter:"p21",isActive:false,measurement:"",logo:""},{title:"DG BN VOLTAGE",parameter:"p22",isActive:false,measurement:"",logo:""},{title:"DG RY VOLTAGE",parameter:"p23",isActive:false,measurement:"",logo:""},{title:"DG YB VOLTAGE",parameter:"p24",isActive:false,measurement:"",logo:""},{title:"DG BR VOLTAGE",parameter:"p25",isActive:false,measurement:"",logo:""}]);
 
          // setIsACtive(activeCheckbox)
          // activeCheckbox
          // setInImei(projectAdminReducer.data.IMEI);
        // }else {
        //   alert(projectAdminReducer.message);
        // }
      } 
    }
  },[projectAdminReducer.action])
  // useEffect (()=> {
  // }, [projectAdminReducer])
 
  useEffect(()=>{
    //console.log('Company-companyReducer-useEffect',companyReducer);    
    if(companyReducer.action){
      if(companyReducer.action===types.GET_COMPANY_REQUEST_SUCCESS){
        //console.log('Company-companyReducer-useEffect',companyReducer)      
        if(companyReducer.status==="success"){
          setListCompany(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
            title: companyReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }
    }
  },[companyReducer.action])
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  
  const setPermission=async()=>{
    setIsEdit(await checkPermission('Projects','edit'));
  }
  const submitProject=()=>{
    //console.log('projectName',projectName);
    //console.log('stateName',stateName);
    //console.log('inLatitude',inLatitude);
    //console.log('inLongitude',inLongitude);
    //console.log('parameters',parameters);
    //console.log('feedbacks',feedbacks);
    //console.log('graphs',graphs);   
    // console.log('inImei',inImei);
    if (isEmpty(projectName)) {
      toast({
        title: 'Please enter name',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(stateName)) {
      toast({
        title: 'Please enter state',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(inLatitude)) {
      toast({
        title: 'Please enter latitude',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(inLongitude)) {
      toast({
        title: 'Please enter longitude',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (isEmpty(company)) {
      toast({
        title: 'Please select company',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (localProjectId == null) {
      if (isEmpty(file)) {
        toast({
          title: 'Please select logo',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
        return;
      }
    }
    
    // console.log('parameters',parameters);
    // console.log('feedbacks',feedbacks);
    // console.log('graphs',graphs); 
    let settings=null;
    if(parameters && feedbacks)
      settings={"parameters":parameters};
    if(feedbacks)
      // settings={...settings,{"feedbacks":feedbacks}};
      settings=Object.assign({}, settings, {"feedbacks":feedbacks});
    if(graphs)
      // settings=settings.concat({"graphs":graphs});
      settings=Object.assign({}, settings, {"graphs":graphs});
    console.log('settings',JSON.stringify(settings));
    if(localProjectId){
      let request=null;
      if(file){
        request={
          "id":localProjectId,
          "name":projectName,
          "state":stateName,
          "latitude":inLatitude,
          "longitude":inLongitude,
          "companyId":company,
          extraInformation,
          paramtersThresholdValues,
          "setting":JSON.stringify(settings),
          "logo":file,
        };
      }else{
        request={
          "id":localProjectId,
          "name":projectName,
          "state":stateName,
          "latitude":inLatitude,
          extraInformation,
          "longitude":inLongitude,
          paramtersThresholdValues,
          "setting":JSON.stringify(settings),
          "companyId":company,
        };
      }
      request.dmtsOption = dmts;
      //console.log('AddProject-request',request);
      dispatch(updateProject(request));
    }else{
      const request={
        "name":projectName,
        "state":stateName,
        "latitude":inLatitude,
        "longitude":inLongitude,
        "companyId":company,
        extraInformation,
        paramtersThresholdValues,
        "setting":JSON.stringify(settings),
        "logo":file,
      };
      //console.log('AddProject-request',request);
      dispatch(addProject(request));
    }      
    // navigate('/dashboard');
  }
  const handleProjectInput=(e)=>{
    // console.log('e.target.name',e.target.name);
    // console.log('e.target.value',e.target.vwalue);
    if(e.target.name==="txtName"){
        setProjectName(e.target.value);
    }
    if(e.target.name==="txtStateName"){
        setStateName(e.target.value);
    }
    if(e.target.name==="txtLong"){
        setInLongitude(e.target.value);
    }
    if(e.target.name==="txtLat"){
        setInLatitude(e.target.value);
    }
  }
  const handleFileChange = (e) => {
    if (e.target.name=="txtLogo") {
      setFile(e.target.files[0]);
    }      
  };
  const handleActiveStatus=(e)=>{
    //console.log('e.target.name',e.target.name);
 
  }
  const fetchProjectDetails=()=>{
    //console.log('AddProjectDetails-fetchProjectDetails-projectId',location.state.projectId);
    dispatch(getProjectById(location.state.projectId));
  }
  const fetchCompanyList=async()=>{
    dispatch(getCompany("page=1&limit=1000"));
  } 
  const handleChangeEvent=(e)=>{
    
    if(e.target.name==="selCompany"){
      setCompany(e.target.value);
    }
  }
  const handleSettings=(type,item,e)=>{
    // console.log('type',type);
    // console.log('feedbacks',feedbacks);
    // console.log('e.target',e.target.checked);
    if(type=="feedback"){
      //console.log('item.parameter',item.parameter);
      const indexVal=feedbacks.findIndex(paramObj=>paramObj.parameter==item.parameter);
      //console.log('indexVal',indexVal);
      feedbacks[indexVal].isActive=e.target.checked;
      bindFeedback();
    }
    if(type=="parameter"){
      // console.log('item.parameter',item.parameter);
      const indexVal=parameters.findIndex(paramObj=>paramObj.parameter==item.parameter);
      //console.log('indexVal',indexVal);
      parameters[indexVal].isActive=e.target.checked;
      bindParameters();
    }
    if(type=="graph"){
      //console.log('item.parameter',item.parameter);
      const indexVal=graphs.findIndex(paramObj=>paramObj.title==item.title);
      //console.log('indexVal',indexVal);
      graphs[indexVal].isActive=e.target.checked;
      bindGraphs();
    }
    
  }
  const bindFeedback=()=>{
    if(feedbacks){
      return feedbacks.map((item,index)=>{
        //console.log('item',item);
        //console.log('item.isActive',item.isActive);
        return(
          <div key={index} className="col-md-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e)=>handleSettings("feedback",item,e)}
                defaultChecked={item.isActive}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label fs-7"
                for="flexCheckDefault"
              >{item.title}<br />{item.parameter}
              </label>
            </div>
          </div>
      )}
      )
    }    
  }
  const bindParameters=()=>{
    {console.log("MY PARAMS", parameters)}
    if(parameters){
      return parameters.map((item,index)=>(
        <div key={index} className="col-md-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={(e)=>handleSettings("parameter",item,e)}
              defaultChecked={item.isActive}
              // checked={item.isActive}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fs-7"
              for="flexCheckDefault"
            >{item.title == 'MOTOR KW' ? 'MOTOR KWH' : item.title}<br />{item.parameter}
            </label>
          </div>
        </div>
      ))
    } 
  }
  const bindThresholdParameters = () => {
    if (parameters) {
      return parameters.map((item, index) =>
        item.isActive ? (
          <Box key={index} className="col-md-2">
            <VStack spacing={2} mt={2}>
              <VStack alignItems="flex-start" spacing={0}>
                <Text alignItems='flex-start'  mb={2} fontSize="md">
                  {parameterNames[index]}
                </Text>
                <Text fontSize="xs">Min:</Text>
                <Input
                  type="text"
                  size="sm"
                  value={paramtersThresholdValues[index].split('-')[0]}
                  onChange={(e) => handleMinValueChange(index, e)}
                />
              </VStack>
              <VStack alignItems="flex-start" spacing={0}>
                <Text fontSize="xs">Max:</Text>
                <Input
                  type="text"
                  size="sm"
                  value={paramtersThresholdValues[index].split('-')[1]}
                  onChange={(e) => handleMaxValueChange(index, e)}
                />
              </VStack>
            </VStack>
          </Box>
        ) : null
      );
    }
  };
  const bindGraphs=()=>{
    console.log("xxx calling graphs")
    if(graphs){
      const data = graphs.map((item,index)=>(
        <div className="col-md-6">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={(e)=>handleSettings("graph",item,e)}
              defaultChecked={item.isActive}
              // checked={item.isActive}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fs-7"
              for="flexCheckDefault"
            >{item.title}<br />{item.parameter}
            </label>
          </div>
        </div>
      ))
 
      console.log('xxx calling graphs data', data);
      return data;
    }    
  }
  return (
    <div className="page">
      {console.log('xxx jadu', projectAdminReducer, dmts)}
      <NavBarAdmin />
      <AdminMenu selMenu="Project" />
        <section>      
        <div className="container">        
            <div className="row d-flex justify-content-center pt-4 h-100">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
                <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body pt-5">
                    <div className="row justify-content-center">                  
                    <div className="col-12 order-2 order-lg-1">  
                        <p className="h1 fw-bold mb-4 ">{localProjectId?"Update Project":"Add Project"}</p>
                        <form className="mx-1 mx-md-2">
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">Project Name</label>
                                <input type="text" name="txtName" id="txtName" value={projectName} onChange={(event) => handleProjectInput(event)} className="form-control" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">State Name</label>
                                <input type="text" name="txtStateName" id="txtStateName" value={stateName} onChange={(event) => handleProjectInput(event)} className="form-control" />
                            </div>
                        </div>                        
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">Latitude</label>
                                <input type="text" name="txtLat" id="txtLat" value={inLatitude} onChange={(event) => handleProjectInput(event)} className="form-control" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">Longitude</label>
                                <input type="text" name="txtLong" id="txtLong" value={inLongitude} onChange={(event) => handleProjectInput(event)} className="form-control" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">Company</label>
                                <div className="col-sm-10">
                                  <select className="selectpicker form-control" name="selCompany" onChange={(e)=>handleChangeEvent(e)}>
                                    <option value="">Select Company</option>
                                    {listCompany.map((item,index)=>(
                                      <option value={item._id} selected={company==item._id?true:false}>{item.CompanyName}</option>
                                    ))}
                                    {/* <option value="63c045b6a193a308bca7f581" selected={company=="63c045b6a193a308bca7f581"?true:false}>NCC Company</option> */}
                                  </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-1" style={{display: 'flex',  alignItems: 'center', gap : '10px'}}>
                              <label className="form-label" for="form3Example3c">DMTS Option :</label>
                              {console.log("STATE", dmts)}
                              <Switch onChange={()=>{console.log("xxx pikaaa", dmts); setDmts(!dmts)}} isChecked={dmts}/>
                          </div>
                        </div>
                        <div className="form-outline flex-fill mb-1" style={{display: 'flex',  alignItems: 'center', gap : '10px'}}>
                              <label className="form-label" for="form3Example3c">{`Extra Information (Flush/Inlet/Outlet Valve) :`}</label>
                              <Switch onChange={()=>{setExtraInformation(!extraInformation)}} isChecked={extraInformation}/>
                          </div>
  
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                              <label className="form-label" for="form3Example3c">Logo</label>
                              <input type="file" name="txtLogo" id="txtLogo" onChange={(event) => handleFileChange(event)} className="form-control" />
                              {serverFile?<img src={`data:image/png;base64,${serverFile}`} height='100px' width={'100px'}/>:null}
                          </div>
                        </div>
                        {/* <div className="d-flex flex-row mb-3">
                            <div className="form-outline flex-fill mb-0">
                              <input className="form-check-input me-2" type="checkbox" value="" id="activeCheckbox" onChange={(event)=>handleActiveStatus(event)} />
                              <label className="form-check-label" for="activeCheckbox">Check for make it active</label>
                            </div>
                        </div> */}
                      <div className="row g-2 mt-3">
                        <h5 className="col-12 mb-3 h5 text-center">Feedback</h5>
                        {bindFeedback()}
                      </div>
                      <div className="row g-2">
                        <h5 className="col-12 mb-3 h5 text-center">Parameters</h5>
                        {bindParameters()}
                      </div>
                      <div className="row g-2 mb-5">
                        <h5 className="col-12 mb-3 h5 text-center">Graphs</h5>
                        {bindGraphs()}
                      </div>
                      <div className="row g-2 mb-5">
                        <h5 className="col-12 mb-3 h5 text-center">Parameters Threshold</h5>
                        {bindThresholdParameters()}
                      </div>
                      <div className="d-flex justify-content-center mb-3">
                          {/* <button type="button" className="btn btn-primary btn-lg" disabled={isLoading} onClick={doSignin}>{userReducer.loading?<LoadingSpinner />:"Submit"}</button> */}
                          <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/ProjectListing"}>Back</Link>
                          {isEdit?<ButtonWithSpinner onClick={()=>submitProject()} isLoading={projectAdminReducer.loading?true:false} label={'Save'} />:null}
                      </div>
                      </form>                        
                    </div>                  
                  </div>
                </div>
              </div>
            {/* </div> */}
            </div>
        </div>
      </section>
    </div>
  );
};
 
export default AddProjectDetails;
 
 