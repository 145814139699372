import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const cDate = new Date();
// export const options = {
//   scales: {
//     y:
//       {
        
//       },
//     x:
//       {
//         min: 0,
//         max: cDate.getHours(),
//         stepSize: 1,
//       },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Area Chart",
//     },
//   },
// };

// const labels = ["January", "February", "March", "April", "May", "June", "July"];
// const counts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

// export const data = {
//   labels,
//   datasets: [
//     {
//       fill: true,
//       label: "Dataset 2",
//       data: labels.map(() => counts.map((count) => count)),
//       //   labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };
let data=null;
const AreaChart = ({dataList,xLabel,yLabel}) => {
  const [graphStatus, setGraphStatus] = useState(false);
  useEffect(()=>{      ;
    let labels=[];
    let countArray=[];
    dataList.map((item,index)=>{
      labels.push(item.x);
      countArray.push((item.y* 10));
    })
    labels.map(() => countArray.map((count) => {
      return count
    }))
    data = {
      labels,
      datasets: [
        {
          fill: true,
          label: "Pressure",
          data: countArray.map((count) => count != null ? parseFloat(count.toFixed(5)) : 0),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        }
      ],
    };
    if(labels.length>0)
      setGraphStatus(true);
  },[dataList])
  
  const options = {
    scales: {
      y:
        {
          title: {
            display: true,
            text: yLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      x:
        {
          title: {
            display: true,
            text: xLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
          min: 0,
          max: cDate.getHours(),
          stepSize: 1,
        },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Area Chart",
      },
    },
  };
  
  if(graphStatus){
    // console.log('graphData-12',data);
    return (<Line options={options} data={data} />);
  }
  // // console.log('AreaChart-data',data);
  // return (
  //   // <div className="card p-4">
  //     <Line options={options} data={data} />
  //   // </div>
  // );
};

AreaChart.propTypes = {
  dataList: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};
export default AreaChart;
