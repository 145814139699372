import {types} from './types';

export function getAssetReport(payload) {
  return {
    type: types.GET_ASSET_REPORT_REQUEST,
    payload,
  };
}

export function getSiteParameterListReport(payload) {
  return {
    type: types.GET_SITE_PARAMETER_REPORT_REQUEST,
    payload,
  };
}

export function getPumpActivityReport(payload) {
  return {
    type: types.GET_PUMP_ACTIVITY_REQUEST,
    payload,
  };
}