import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Line Chart",
//     },
//   },
// };

let data=null;
const LineChartWS = ({dataList,xLabel,yLabel}) => {
  const [graphStatus, setGraphStatus] = useState(false);
  useEffect(()=>{      
    let labels=[];
    let countArray=[];
    // console.log('LineChartWS-dataList',dataList);
    dataList.map((item,index)=>{
      labels.push(item.x);
      countArray.push(item.y);
    })
    labels.map(() => countArray.map((count) => {
      return count
    }))
    // console.log('LineChartWS-labels',labels);
    data = {
      labels,
      datasets: [
        {
          label: "Water supplied",
          data: countArray.map((count) => parseFloat(count.toFixed(2))),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        }
      ],
    };
    if(labels.length>0)
      setGraphStatus(true);
  },[dataList])

  const options = {
    scales: {
      y:
        {
          title: {
            display: true,
            text: yLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      x:
        {
          title: {
            display: true,
            text: xLabel,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Chart",
      },
    },
  };

  if(graphStatus){
    // console.log('LineChartWS-data-',data);
    // console.log('LineChartWS-graphStatus',graphStatus);
    return (
        <Line options={options} data={data} />
    );
  } 
};

LineChartWS.propTypes = {
  dataList: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};
export default LineChartWS;
