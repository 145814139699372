import {apiCallWithPromise,apiGetCallWithPromise,apiDeleteCallWithPromise,apiCallWithPromiseToken,apiGetRecordDetailsCallWithPromise,
        apiCallWithPromiseTokenPUT,apiCallWithPromiseTokenFormPosting,apiPUTCallWithPromise,apiDeletePostCallWithPromise} from './axiosApicall';
import {apiName} from './serverConfig.js';
/////////// Login API To ////////////////////////
export const doLoginAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const userObject = await apiCallWithPromise(apiName.loginAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return userObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};

export const getSiteListWithProjectsAPI = async (payload) => {
    const info = await apiGetCallWithPromise(apiName.getSiteListWithProjects, payload);
    return info
}
/////////////////////////////////////////////////
/////////// Site List API ////////////////////////
export const siteListAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const siteListObject = await apiGetCallWithPromise(apiName.projectForLogginedUsrListAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return siteListObject;
    } catch (err) {
        // console.log('siteListAPI-err=', err)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Site Details API ////////////////////////
export const siteDetailsAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const siteDetailsObject = await apiGetCallWithPromise(apiName.siteDetailsAPI,"id="+payload);
        // console.log('getSiteDetails-siteDetailsAPI-Response',siteDetailsObject); 
        return siteDetailsObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Project Details API ////////////////////////
export const projectDetailsAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const projectDetailsObject = await apiGetCallWithPromise(apiName.projectDetailsAPI,"projectId="+payload);
        // console.log('doLoginAPI-success=', userObject);
        return projectDetailsObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Basic Info API ////////////////////////
export const basicInfoAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const basicInfoObject = await apiGetCallWithPromise(apiName.basicInfoAPI,payload);
        // console.log('basicInfoAPI-basicInfoObject-success=', basicInfoObject);
        return basicInfoObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// News List API ////////////////////////
export const newsListAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const newsArray = await apiGetCallWithPromise(apiName.newsListAPI,payload);
        // console.log('newsListAPI-newsArray-success=', newsArray);
        return newsArray;
    } catch (err) {
        // console.log('newsListAPI-err=', err)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add NEWS API ////////////////////////
export const addNewsAPI = async (payload) => {
    try {
        // console.log('addProjectAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addNewsAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Edit NEWS API ////////////////////////
export const editNewsAPI = async (payload) => {
    try {
        // console.log('updateProjectAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.editNewsAPI,payload);
        // console.log('updateProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('updateProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete NEWS API ////////////////////////
export const deleteNewsAPI = async (payload) => {
    try {
        // console.log('deleteProjectAPI-call=', payload);
        const responseObject = await apiDeleteCallWithPromise(apiName.deleteNewsAPI,payload);
        // console.log('deleteProjectAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('deleteProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// NEWS Details By Id API ////////////////////////
export const newsDetailsAPI = async (payload) => {
    try {
        // console.log('adminProjectListAPI-call=', payload);
        const responseObject = await apiGetCallWithPromise(apiName.newsDetailsByIdAPI,"id="+payload);
        // console.log('adminProjectListAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('adminProjectListAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// MQTT Data API ////////////////////////
export const mqttDataAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const mqttDataObject = await apiGetCallWithPromise(apiName.mqttDataAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return mqttDataObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// PUSH MQTT Data API ////////////////////////
export const pushMqttDataAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const mqttDataObject = await apiCallWithPromise(apiName.sendDataToDeviceAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return mqttDataObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// GET Site List Data By Project Id API ////////////////////////
export const getSitesByProjectIdAPI = async (payload) => {
    try {
        console.log('getSitesByProjectIdAPI-payload=', payload);
        const siteListByProjectObject = await apiGetCallWithPromise(apiName.getSitesByProjectIdAPI,"projectID="+payload.projectID+"&page="+payload.page+"&limit="+payload.limit);
        // console.log('doLoginAPI-success=', userObject);
        return siteListByProjectObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add Site API ////////////////////////
export const addSiteAPI = async (payload) => {
    try {
        // console.log('addSiteAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addSiteAPI,payload);
        // console.log('addSiteAPI-response=', response);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Edit Site API ////////////////////////
export const updateSiteAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.updateSiteAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Admin Site List API ////////////////////////
export const adminSiteListAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const siteListObject = await apiGetCallWithPromise(apiName.siteListAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return siteListObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete Site API ////////////////////////
export const deleteSiteAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const responseObject = await apiDeleteCallWithPromise(apiName.deleteSiteAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add Project API ////////////////////////
export const addProjectAPI = async (payload) => {
    try {
        // console.log('addProjectAPI-call=', payload);
        const response = await apiCallWithPromiseTokenFormPosting(apiName.addProjectAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Edit Project API ////////////////////////
export const updateProjectAPI = async (payload) => {
    try {
        // console.log('updateProjectAPI-call=', payload);
        const response = await apiCallWithPromiseTokenFormPosting(apiName.updateProjectAPI,payload);
        // console.log('updateProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('updateProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Admin Project List API ////////////////////////
export const adminProjectListAPI = async (payload) => {
    try {
        // console.log('adminProjectListAPI-call=', payload);
        const siteListObject = await apiGetCallWithPromise(apiName.projectListAPI,payload);
        // console.log('adminProjectListAPI-success=', userObject);
        return siteListObject;
    } catch (err) {
        // console.log('adminProjectListAPI-err=', payload)
        return err;
    }
};

export const getProjectListByCompanyId = async (payload) => {
    try {
        // console.log('adminProjectListAPI-call=', payload);
        const siteListObject = await apiGetCallWithPromise("sites/getProjectListByCompanyId",payload);
        // console.log('adminProjectListAPI-success=', userObject);
        return siteListObject;
    } catch (err) {
        // console.log('adminProjectListAPI-err=', payload)
        return err;
    }
}
/////////////////////////////////////////////////
/////////// Delete Project API ////////////////////////
export const deleteProjectAPI = async (payload) => {
    try {
        // console.log('deleteProjectAPI-call=', payload);
        const responseObject = await apiDeleteCallWithPromise(apiName.deleteProjectAPI,payload);
        // console.log('deleteProjectAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('deleteProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Report Data API ////////////////////////
export const reportDataAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const responseObject = await apiCallWithPromise(apiName.getSiteReportAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Report Hour API ////////////////////////
export const reportHourWiseAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const responseObject = await apiCallWithPromise(apiName.getSiteReportbyDayHoursAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Admin Project Details By Id API ////////////////////////
export const adminProjectDetailsAPI = async (payload) => {
    try {
        // console.log('adminProjectListAPI-call=', payload);
        const responseObject = await apiGetCallWithPromise(apiName.projectDetailsByIdAPI,"id="+payload);
        // console.log('adminProjectListAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('adminProjectListAPI-err=', payload)
        return err;
    }
};
export const get5MinsData = async (payload, userId, isAdmin) => {
    try {
        // console.log('adminProjectListAPI-call=', payload);
        const responseObject = await apiGetCallWithPromise(apiName.fiveMinsData,"companyId="+payload+"&userId="+userId+"&isAdmin="+isAdmin);
        // console.log('adminProjectListAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('adminProjectListAPI-err=', payload)
        return err;
    }
};

export const getCompleteAssignedSitesByUserIdAPI = async (payload) => {
    try {
        const res = await apiGetCallWithPromise(apiName.getCompleteAssignedSitesByUserId,"userId="+payload);
        return res;
   } catch (error) {
       return error
   } 
}

export const getParameterLogsByCompanyId = async (payload, date, userId, userData) => {
    try {
         const isAdmin = userData == 'Admin'
         const res = await apiGetCallWithPromise(apiName.getParameterThresholdsLogsAPI,"companyId="+payload+"&date="+date+"&userId="+userId+"&isAdmin="+isAdmin);
         return res;
    } catch (error) {
        return error
    }
}
/////////////////////////////////////////////////
/////////// Get Graph Data API ////////////////////////
export const getGraphAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.graphAPI,"siteId="+payload.siteId+"&type="+payload.graphFor);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Company API ////////////////////////
export const getCompanyAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const newsArray = await apiGetCallWithPromise(apiName.companyListAPI,payload);
        // console.log('newsListAPI-newsArray-success=', newsArray);
        return newsArray;
    } catch (err) {
        // console.log('newsListAPI-err=', err)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add Company API ////////////////////////
export const addCompanyAPI = async (payload) => {
    try {
        // console.log('addProjectAPI-call=', payload);
        const response = await apiCallWithPromiseTokenFormPosting(apiName.addCompanyAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Edit Company API ////////////////////////
export const editCompanyAPI = async (payload) => {
    try {
        // console.log('updateProjectAPI-call=', payload);
        const response = await apiCallWithPromiseTokenFormPosting(apiName.updateCompanyAPI,payload);
        // console.log('updateProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('updateProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete Company API ////////////////////////
export const deleteCompanyAPI = async (payload) => {
    try {
        // console.log('deleteProjectAPI-call=', payload);
        const responseObject = await apiDeleteCallWithPromise(apiName.deleteCompanyAPI,payload);
        // console.log('deleteProjectAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('deleteProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Company Details API ////////////////////////
export const getCompanyDetailsAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const newsArray = await apiGetCallWithPromise(apiName.getCompanyDetailAPI,"id="+payload);
        // console.log('newsListAPI-newsArray-success=', newsArray);
        return newsArray;
    } catch (err) {
        // console.log('newsListAPI-err=', err)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Users API ////////////////////////
export const getUsersAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const responseObject = await apiGetCallWithPromise(apiName.userListAPI,payload);
        console.log('getUsersAPI-responseObject-success=', responseObject);
        return responseObject;
    } catch (err) {
        // console.log('newsListAPI-err=', err)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add User API ////////////////////////
export const addUserAPI = async (payload) => {
    try {
        console.log('addUserAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addUserAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Edit User API ////////////////////////
export const editUserAPI = async (payload) => {
    try {
        // console.log('updateProjectAPI-call=', payload);
        const response = await apiCallWithPromiseTokenPUT(apiName.updateUserAPI,payload);
        // console.log('updateProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('updateProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get User Detail API ////////////////////////
export const getUserDetailsAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const responseObject = await apiGetRecordDetailsCallWithPromise(apiName.getUserAPI,payload);
        console.log('getUsersAPI-responseObject-success=', responseObject);
        return responseObject;
    } catch (err) {
        // console.log('newsListAPI-err=', err)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete User API ////////////////////////
export const deleteUserAPI = async (payload) => {
    try {
        // console.log('deleteProjectAPI-call=', payload);
        const responseObject = await apiDeleteCallWithPromise(apiName.deleteUserAPI,payload);
        // console.log('deleteProjectAPI-success=', userObject);
        return responseObject;
    } catch (err) {
        // console.log('deleteProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Assign Sites To User API ////////////////////////
export const assignSitesToUserAPI = async (payload) => {
    try {
        console.log('assignSitesToUser-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.assignSitesToUserAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Assigned Sites To User API ////////////////////////
export const getAssignedSiteListAPI = async (payload) => {
    try {
        console.log('assignSitesToUser-call=', payload);
        const response = await apiGetCallWithPromise(apiName.assignedSiteListAPI,"projectId="+payload.projectId+"&userId="+payload.userId);
        console.log('getAssignedSiteListAPI-response=', response);
        return response;
    } catch (err) {
        console.log('addProjectAPI-err=', err)
        // return err;
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add Site Extra Parameter API ////////////////////////
export const addSiteExtraParameterAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addSiteExtraParametersAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Update Site Extra Parameter API ////////////////////////
export const updateExtraParametersAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.updateExtraParametersAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete Site Extra Parameter API ////////////////////////
export const deleteExtraParameterAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiDeletePostCallWithPromise(apiName.deleteExtraParameterAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Site Extra Parameter API ////////////////////////
export const getSiteExtraParameterAPI = async (payload) => {
    try {
        const response = await apiGetCallWithPromise(apiName.getSiteExtraParametersAPI,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};

export const replaceAssetAPI = async (payload) => {
    try {
        const response = await apiCallWithPromise(apiName.replaceAssetAPI,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Update Site Extra Parameter API ////////////////////////
export const updateSiteExtraParameterAPI = async (payload) => {
    try {
        const response = await apiCallWithPromiseToken(apiName.updateSiteExtraParametersAPI,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Module List API ////////////////////////
export const getModulesAPI = async (payload) => {
    try {
        const response = await apiGetCallWithPromise(apiName.getModules,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};

export const getReplacedAssetHistoryAPI = async (payload) => {
    try {
        const response = await apiGetCallWithPromise(apiName.getReplacedAssetHistory,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Role List API ////////////////////////
export const getRolesAPI = async (payload) => {
    try {
        const response = await apiGetCallWithPromise(apiName.getRoles,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add Role API ////////////////////////
export const addRoleAPI = async (payload) => {
    try {
        const response = await apiCallWithPromiseToken(apiName.addRole,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Role Detail API ////////////////////////
export const getRoleDetailsAPI = async (payload) => {
    try {
        const response = await apiGetRecordDetailsCallWithPromise(apiName.getRoleDetails,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Update Role API ////////////////////////
export const updateRoleAPI = async (payload) => {
    try {
        const response = await apiPUTCallWithPromise(apiName.updateRole,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Dashboard Graph Data API ////////////////////////
export const getDashboardGraphAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.homeGraphDataAPI,"type="+payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};

export const getUserSitesReportsAPI = async (payload) => {
    try {
        const response = await apiGetCallWithPromise(apiName.getUserSitesReports, payload);
        return response;
    } catch (err) {
        return err;
    }
}

export const updateAssetParameter = async (payload) => {
    try {

        const repsonse = await apiCallWithPromise(apiName.updateAssetParameterAPI, {data : payload});
        return repsonse
    } catch (err) {
        return err;
    }
}
 /////////////////////////////////////////////////
/////////// Get Asset Report Data API ////////////////////////
export const getAssetReportAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.assetReportAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Site Parameter List API ////////////////////////
export const getSiteParameterListAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.siteParameterListAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Country Data API ////////////////////////
export const getCountryAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getCountryAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get State Data API ////////////////////////
export const getStateAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getStateListByCountryAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get District Data API ////////////////////////
export const getDistrictAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getDistrictsListByStateAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Block Data API ////////////////////////
export const getBlockAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getBlocksListByDistrictAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get DG CONSUMPTION REPORT API ////////////////////////
export const getPumpActivityAPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getPumpActivityAPI,payload);
        // console.log('getPumpActivityAPI-success=', response);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', err)
        // console.log('APICALL-err.response',err.status)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add Country API ////////////////////////
export const addCountryAPI = async (payload) => {
    try {
        // console.log('addUserAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addCountryAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Update Country API ////////////////////////
export const updateCountryAPI = async (payload) => {
    try {
        const response = await apiCallWithPromiseToken(apiName.updateCountryAPI,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete Country API ////////////////////////
export const deleteCountryAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiDeleteCallWithPromise(apiName.deleteCountryAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get State API ////////////////////////
export const getStateListPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getStateAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add State API ////////////////////////
export const addStateAPI = async (payload) => {
    try {
        // console.log('addUserAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addStateAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Update State API ////////////////////////
export const updateStateAPI = async (payload) => {
    try {
        const response = await apiCallWithPromiseToken(apiName.updateStateAPI,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete State API ////////////////////////
export const deleteStateAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiDeleteCallWithPromise(apiName.deleteStateAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get District API ////////////////////////
export const getDistrictListPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getDistrictAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add District API ////////////////////////
export const addDistrictAPI = async (payload) => {
    try {
        // console.log('addUserAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addDistrictAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Update District API ////////////////////////
export const updateDistrictAPI = async (payload) => {
    try {
        const response = await apiCallWithPromiseToken(apiName.updateDistrictAPI,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete District API ////////////////////////
export const deleteDistrictAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiDeleteCallWithPromise(apiName.deleteDistrictAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Get Block API ////////////////////////
export const getBlockListPI = async (payload) => {
    try {
        // console.log('getDashboardGraphAPI-call=', payload);
        const response = await apiGetCallWithPromise(apiName.getBlockAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Add Block API ////////////////////////
export const addBlockAPI = async (payload) => {
    try {
        // console.log('addUserAPI-call=', payload);
        const response = await apiCallWithPromiseToken(apiName.addBlockAPI,payload);
        // console.log('addProjectAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('addProjectAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Update Block API ////////////////////////
export const updateBlockAPI = async (payload) => {
    try {
        const response = await apiCallWithPromiseToken(apiName.updateBlockAPI,payload);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////
/////////// Delete Block API ////////////////////////
export const deleteBlockAPI = async (payload) => {
    try {
        // console.log('doLoginAPI-call=', payload);
        const response = await apiDeleteCallWithPromise(apiName.deleteBlockAPI,payload);
        // console.log('doLoginAPI-success=', userObject);
        return response;
    } catch (err) {
        // console.log('appSettingsAPI-err=', payload)
        return err;
    }
};
/////////////////////////////////////////////////