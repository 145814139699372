// import { createSlice } from "@reduxjs/toolkit";
import {types} from '../actions/types';

const initialState = {
  loading: false,
  status:null,
  data:null,
  message:null,
  action:null,
};

const userAdminReducer=(state = initialState, {type, payload})=>{
  switch (type) {
    case types.GET_USERS_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_USERS_REQUEST
      };
    case types.GET_USERS_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_USERS_REQUEST_SUCCESS
      };
    case types.GET_USERS_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_USERS_REQUEST_FAILURE
      };
    case types.ADD_USER_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.ADD_USER_REQUEST
      };
    case types.ADD_USER_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.ADD_USER_REQUEST_SUCCESS
      };
    case types.ADD_USER_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.ADD_USER_REQUEST_FAILURE
      };  
    case types.EDIT_USER_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.EDIT_USER_REQUEST
      };
    case types.EDIT_USER_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.EDIT_USER_REQUEST_SUCCESS
      };
    case types.EDIT_USER_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.EDIT_USER_REQUEST_FAILURE
      };  
    case types.DELETE_USER_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.DELETE_USER_REQUEST
      };
    case types.DELETE_USER_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.DELETE_USER_REQUEST_SUCCESS
      };
    case types.DELETE_USER_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.DELETE_USER_REQUEST_FAILURE
      };
    case types.GET_USER_DETAIL_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_USER_DETAIL_REQUEST
      };
    case types.GET_USER_DETAIL_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_USER_DETAIL_REQUEST_SUCCESS
      };
    case types.GET_USER_DETAIL_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_USER_DETAIL_REQUEST_FAILURE
      };
    case types.ASSIGN_SITES_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.ASSIGN_SITES_REQUEST
      };
    case types.ASSIGN_SITES_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.ASSIGN_SITES_REQUEST_SUCCESS
      };
    case types.ASSIGN_SITES_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.ASSIGN_SITES_REQUEST_FAILURE
      };
    case types.ASSIGNED_SITES_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.ASSIGNED_SITES_REQUEST
      };
    case types.ASSIGNED_SITES_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.ASSIGNED_SITES_REQUEST_SUCCESS
      };
    case types.ASSIGNED_SITES_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.ASSIGNED_SITES_REQUEST_FAILURE
      };
    case types.GET_MODULES:
      // console.log('GET_MODULES');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_MODULES
      };
    case types.GET_MODULES_SUCCESS:
      // console.log('GET_MODULES_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_MODULES_SUCCESS
      };
    case types.GET_MODULES_FAILURE:
      // console.log('GET_MODULES_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_MODULES_FAILURE
      };
    case types.GET_ROLES:
      // console.log('GET_ROLES');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_ROLES
      };
    case types.GET_ROLES_SUCCESS:
      // console.log('GET_ROLES_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_ROLES_SUCCESS
      };
    case types.GET_ROLES_FAILURE:
      // console.log('GET_ROLES_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_ROLES_FAILURE
      };
    case types.ADD_ROLES:
      // console.log('GET_ROLES');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.ADD_ROLES
      };
    case types.ADD_ROLES_SUCCESS:
      // console.log('GET_ROLES_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.ADD_ROLES_SUCCESS
      };
    case types.ADD_ROLES_FAILURE:
      // console.log('GET_ROLES_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.ADD_ROLES_FAILURE
      };
    case types.GET_ROLE_DETAILS:
      // console.log('GET_ROLES');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_ROLE_DETAILS
      };
    case types.GET_ROLE_DETAILS_SUCCESS:
      // console.log('GET_ROLES_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_ROLE_DETAILS_SUCCESS
      };
    case types.GET_ROLE_DETAILS_FAILURE:
      // console.log('GET_ROLES_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_ROLE_DETAILS_FAILURE
      };
    case types.EDIT_ROLES:
      // console.log('GET_ROLES');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.EDIT_ROLES
      };
    case types.EDIT_ROLES_SUCCESS:
      // console.log('GET_ROLES_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.EDIT_ROLES_SUCCESS
      };
    case types.EDIT_ROLES_FAILURE:
      // console.log('GET_ROLES_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.EDIT_ROLES_FAILURE
      };
    default:
      return state;
  }
}

export default userAdminReducer;