import {types} from './types';

export function getUsers(payload) {
  return {
    type: types.GET_USERS_REQUEST,
    payload,
  };
}
export function addUser(payload) {
    return {
      type: types.ADD_USER_REQUEST,
      payload,
    };
}
export function editUser(payload) {
  return {
    type: types.EDIT_USER_REQUEST,
    payload,
  };
}
export function deleteUser(payload) {
    return {
      type: types.DELETE_USER_REQUEST,
      payload,
    };
}
export function getUserDetails(payload) {
  return {
    type: types.GET_USER_DETAIL_REQUEST,
    payload,
  };
}
export function assignSitesToUser(payload) {
  return {
    type: types.ASSIGN_SITES_REQUEST,
    payload,
  };
}
export function getAssignedSitesToUser(payload) {
  return {
    type: types.ASSIGNED_SITES_REQUEST,
    payload,
  };
}
export function getModules(payload) {
  return {
    type: types.GET_MODULES,
    payload,
  };
}
export function getRoles(payload) {
  return {
    type: types.GET_ROLES,
    payload,
  };
}
export function addRoles(payload) {
  return {
    type: types.ADD_ROLES,
    payload,
  };
}
export function getRoleDetails(payload) {
  return {
    type: types.GET_ROLE_DETAILS,
    payload,
  };
}
export function updateRole(payload) {
  return {
    type: types.EDIT_ROLES,
    payload,
  };
}